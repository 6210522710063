import React from "react";
import styled from "../../~reusables/contexts/ThemeContext";
import { MAX__PAGE_WIDTH } from "../../~reusables/constants/metrics";

const MaxWidthContainer: React.FC = ({ children }) => {
  return <StyledMWContainer>{children}</StyledMWContainer>;
};

const StyledMWContainer = styled.div`
  max-width: ${MAX__PAGE_WIDTH}px;
  overflow-x: hidden;
  padding: ${(p) => `${p.theme.space[7]}px ${p.theme.space[8]}px`};
  margin: 0 auto;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints[1]}) {
    padding: ${(p) => `${p.theme.space[6]}px ${p.theme.space[7]}px`};
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints[0]}) {
    padding: ${(p) => `${p.theme.space[5]}px ${p.theme.space[6]}px`};
  }
`;

export default MaxWidthContainer;
