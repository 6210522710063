// modules
import React from "react";
import styled from "../../~reusables/contexts/ThemeContext";
import { RouteComponentProps } from "react-router-dom";

type ISettings = RouteComponentProps;

const Settings: React.FC<ISettings> = () => {
  return <StyledSettings>Settings</StyledSettings>;
};

const StyledSettings = styled.main``;

export default Settings;
