import React from "react";
import styled from "@emotion/styled";
import { Row, Col, Switch } from "antd";
import {
  FullscreenOutlined,
  CheckOutlined,
  CloseOutlined,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import QuestionTooltip from "../atoms/QuestionTooltip";
import css from "@emotion/css";
import NumberField from "../atoms/NumberField";
import { useStore, selectedApp } from "../../store";
import {
  updateWindowOptions,
  updateAppLiveField,
} from "../../~reusables/actions";
import { heightValidation, IApp, widthValidation } from "@todesktop/shared";
import { track } from "../../~reusables/util/analytics";

const InputCollectionRow = styled(Row)`
  padding: 10px 0px 20px;

  @media screen and (min-width: 640px) {
    padding: 10px 20px 0px 20px;
  }
`;

const updateStartInFullscreenMode = (isFullScreenStartEnabled: boolean) => {
  track({
    event: "Update Windows Dimensions",
    properties: {
      field: "isFullScreenStartEnabled",
      checked: isFullScreenStartEnabled,
    },
  });
  updateWindowOptions({ isFullScreenStartEnabled });
};

const updateIsResizable = (isResizable: boolean) => {
  track({
    event: "Update Windows Dimensions",
    properties: {
      field: "isResizable",
      checked: isResizable,
    },
  });
  updateWindowOptions({ isResizable });
};

const updateWidth = (width: number) => {
  updateWindowOptions({ width });
  track({
    event: "Update Windows Dimensions",
    properties: { field: "defaultWidth" },
  });
};
const updateMinWidth = (value: number) =>
  updateWindowOptions({ minWidth: { value } });
const updateHasMinWidth = (enabled: boolean) => {
  updateWindowOptions({ minWidth: { enabled } });
  track({
    event: "Update Windows Dimensions",
    properties: {
      field: "minWidth",
      checked: enabled,
    },
  });
};
const updateMaxWidth = (value: number) =>
  updateWindowOptions({ maxWidth: { value } });
const updateHasMaxWidth = (enabled: boolean) => {
  updateWindowOptions({ maxWidth: { enabled } });
  track({
    event: "Update Windows Dimensions",
    properties: {
      field: "maxWidth",
      checked: enabled,
    },
  });
};

const updateHeight = (height: number) => {
  track({
    event: "Update Windows Dimensions",
    properties: { field: "defaultHeight" },
  });
  updateWindowOptions({ height });
};
const updateMinHeight = (value: number) =>
  updateWindowOptions({ minHeight: { value } });
const updateHasMinHeight = (enabled: boolean) => {
  track({
    event: "Update Windows Dimensions",
    properties: {
      field: "minHeight",
      checked: enabled,
    },
  });
  updateWindowOptions({ minHeight: { enabled } });
};
const updateMaxHeight = (value: number) =>
  updateWindowOptions({ maxHeight: { value } });
const updateHasMaxHeight = (enabled: boolean) => {
  track({
    event: "Update Windows Dimensions",
    properties: {
      field: "maxHeight",
      checked: enabled,
    },
  });
  updateWindowOptions({ maxHeight: { enabled } });
};

const updateWidthLive = updateAppLiveField("width");
const updateHeightLive = updateAppLiveField("height");

interface IWindowDimensionsInputProps {
  windowOptions: IApp["windowOptions"];
  isMenubar: boolean;
  showTitle?: boolean;
}

const WindowDimensionsInput: React.SFC<IWindowDimensionsInputProps> = React.memo(
  ({ windowOptions, isMenubar, showTitle = true }) => {
    return (
      <div>
        {showTitle && (
          <h3
            css={css`
              font-weight: bold;
              font-size: 16px;
            `}
          >
            Window Dimensions
          </h3>
        )}
        {!isMenubar && (
          <InputCollectionRow>
            <Col span={24} style={{ margin: "10px 0 20px" }}>
              <FullscreenOutlined />
              <span
                css={css`
                  display: inline-block;
                  margin-right: 10px;
                `}
              >
                &nbsp; Start in Full-screen mode
                <QuestionTooltip
                  text={
                    <>
                      Whether the app window should <b>start in full-screen.</b>
                      <br />
                      This does not prevent the user from exiting full-screen
                      mode after the app has been launched.
                    </>
                  }
                />
              </span>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={windowOptions.isFullScreenStartEnabled}
                onChange={updateStartInFullscreenMode}
              />
            </Col>
          </InputCollectionRow>
        )}

        <InputCollectionRow>
          <Col span={24} style={{ margin: "10px 0 20px" }}>
            <ArrowsAltOutlined />
            <span
              css={css`
                display: inline-block;
                margin-right: 10px;
              `}
            >
              &nbsp; Resizable
              <QuestionTooltip
                text={
                  <>
                    Turning this off will{" "}
                    <b>prevent the user from resizing the application window</b>
                    .<br />
                    The window will be stuck at the default width and height as
                    specified below.
                  </>
                }
              />
            </span>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={windowOptions.isResizable}
              onChange={updateIsResizable}
            />
          </Col>
        </InputCollectionRow>

        <InputCollectionRow>
          <Col span={8}>
            <NumberField
              onChange={updateWidth}
              onImmediateChange={updateWidthLive}
              validationSchema={widthValidation}
              value={windowOptions.width}
              label={
                <>
                  <ArrowsAltOutlined
                    style={{
                      verticalAlign: "middle",
                      transform: "rotate(45deg)",
                    }}
                  />
                  &nbsp; Default Width
                  <QuestionTooltip
                    text={
                      <>
                        This is the <b>width</b> that the application window
                        will be <b>when first launched</b>.
                      </>
                    }
                  />
                </>
              }
            />
          </Col>

          <Col span={8}>
            <NumberField
              onChange={updateMinWidth}
              onToggleChange={updateHasMinWidth}
              toggleable={true}
              toggleValue={windowOptions.minWidth.enabled}
              value={windowOptions.minWidth.value}
              label={
                <>
                  Minimum Width
                  <QuestionTooltip
                    text={
                      <>
                        The <b>minimum width</b> that a user can resize the
                        application window to.
                      </>
                    }
                  />
                </>
              }
            />
          </Col>

          <Col span={8}>
            <NumberField
              onChange={updateMaxWidth}
              onToggleChange={updateHasMaxWidth}
              toggleable={true}
              toggleValue={windowOptions.maxWidth.enabled}
              value={windowOptions.maxWidth.value}
              label={
                <>
                  Maximum Width
                  <QuestionTooltip
                    text={
                      <>
                        The <b>maximum width</b> that a user can resize the
                        application window to.
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </InputCollectionRow>

        <InputCollectionRow>
          <Col span={8}>
            <NumberField
              onChange={updateHeight}
              onImmediateChange={updateHeightLive}
              validationSchema={heightValidation}
              value={windowOptions.height}
              label={
                <>
                  <ArrowsAltOutlined
                    style={{
                      verticalAlign: "middle",
                      transform: "rotate(-45deg)",
                    }}
                  />
                  &nbsp; Default Height
                  <QuestionTooltip
                    text={
                      <>
                        This is the <b>height</b> that the application window
                        will be <b>when first launched</b>.
                      </>
                    }
                  />
                </>
              }
            />
          </Col>

          <Col span={8}>
            <NumberField
              onChange={updateMinHeight}
              onToggleChange={updateHasMinHeight}
              toggleable={true}
              toggleValue={windowOptions.minHeight.enabled}
              value={windowOptions.minHeight.value}
              label={
                <>
                  Minimum Height
                  <QuestionTooltip
                    text={
                      <>
                        The <b>minimum height</b> that a user can resize the
                        application window to.
                      </>
                    }
                  />
                </>
              }
            />
          </Col>

          <Col span={8}>
            <NumberField
              onChange={updateMaxHeight}
              onToggleChange={updateHasMaxHeight}
              toggleable={true}
              toggleValue={windowOptions.maxHeight.enabled}
              value={windowOptions.maxHeight.value}
              label={
                <>
                  Maximum Height
                  <QuestionTooltip
                    text={
                      <>
                        The <b>maximum height</b> that a user can resize the
                        application window to.
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </InputCollectionRow>
      </div>
    );
  }
);

const WindowDimensionsInputContainer: React.SFC<{ showTitle?: boolean }> = ({
  showTitle = true,
}) => {
  const app = useStore(selectedApp);
  if (!app) {
    return null;
  }

  const { windowOptions, appType } = app;
  const isMenubar = appType === "menubar";
  return (
    <WindowDimensionsInput
      showTitle={showTitle}
      windowOptions={windowOptions}
      isMenubar={isMenubar}
    />
  );
};

export default WindowDimensionsInputContainer;
