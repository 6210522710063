import * as yup from "yup";
import { useState } from "react";

const useForm = <T>(
  initialValues: T,
  callback: (value: T) => void | Promise<void>,
  validationRules?: yup.ObjectSchema<any>
) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({} as Partial<T>);
  const [formError, setFormError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editValue = (newValues: Partial<T>) => {
    setValues({ ...values, ...newValues });
  };

  const resetForm = () => setValues(initialValues);

  const handleSubmit = async (event) => {
    event?.preventDefault?.();

    if (isSubmitting) {
      return;
    }
    try {
      if (validationRules) {
        await validationRules.validate(values, {
          abortEarly: false,
        });
      }
      setErrors({}); // No individual form field errors
      setIsSubmitting(true);
      await callback(values);
      setFormError(null); // No top-level form errors
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      const errorsObj = {};
      if (err && err.inner) {
        err.inner.forEach((err) => {
          errorsObj[err.path] = err.message;
        });
        setErrors(errorsObj);
      } else {
        setFormError(err.message);
      }
    }
  };

  const handleChange = (event) => {
    if (event.persist) {
      event.persist();
    }
    setValues((vals) => ({
      ...vals,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    formError,
    isSubmitting,
    resetForm,
    editValue,
  };
};

export default useForm;
