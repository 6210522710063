import {
  DeepPartial,
  IFirestoreWhere,
  IFirestoreOrderBy,
  ValueOf,
} from "../types";
import type firebase from "firebase/app";

export const getData = <T>(
  documentRef: firebase.firestore.DocumentReference
) => {
  return documentRef.get().then((resp) => resp.data()) as Promise<T>;
};

export const getDataFromCollectionWhere = async <T>(
  collectionRef: firebase.firestore.CollectionReference,
  orderBy: {
    fieldPath: string | firebase.firestore.FieldPath;
    direction: firebase.firestore.OrderByDirection;
  },
  firstWhere?: IFirestoreWhere,
  secondWhere?: IFirestoreWhere
) => {
  let query: firebase.firestore.Query = collectionRef;

  if (firstWhere) {
    const { fieldPath, opStr, value } = firstWhere;
    query = query.where(fieldPath, opStr, value);
  }

  if (secondWhere) {
    const { fieldPath, opStr, value } = secondWhere;
    query = query.where(fieldPath, opStr, value);
  }

  return query
    .orderBy(orderBy.fieldPath, orderBy.direction)
    .limit(1)
    .get()
    .then((resp) => {
      let result: T = null;
      if (!resp.empty) {
        result = resp.docs[0].data() as T;
      }
      return result;
    });
};

export const getCollection = <T>(
  collectionRef: firebase.firestore.CollectionReference
) => {
  return collectionRef.get().then((resp) => {
    const result: T[] = [];
    resp.forEach((doc) => result.push(doc.data() as T));
    return result;
  });
};

export const getCollectionSnapshot = <T>(
  collectionRef: firebase.firestore.CollectionReference,
  listener: (data: T[], snapshot?: firebase.firestore.QuerySnapshot) => void
) => {
  return collectionRef.onSnapshot((querySnapshot) => {
    const result: T[] = [];
    querySnapshot.forEach((doc) => result.push(doc.data() as T));
    return listener(result, querySnapshot);
  });
};

export const getCollectionCursor = <T>(
  collectionRef: firebase.firestore.CollectionReference,
  orderBy: IFirestoreOrderBy,
  limit: number
) => (startAfter: ValueOf<T>) => {
  if (!startAfter) return;

  return collectionRef
    .orderBy(orderBy.fieldPath, orderBy.direction)
    .startAfter(startAfter)
    .limit(limit)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => doc.data() as T);
    });
};

export const getCollectionSnapshotWhere = <T>(
  collectionRef: firebase.firestore.CollectionReference,
  { limit, orderBy }: { limit?: number; orderBy: IFirestoreOrderBy },
  listener: (data: T[], snapshot?: firebase.firestore.QuerySnapshot) => void
) => {
  let query: firebase.firestore.Query = collectionRef;

  if (limit) {
    query = query.limit(limit);
  }

  return query
    .orderBy(orderBy.fieldPath, orderBy.direction)
    .onSnapshot((querySnapshot) => {
      const result: T[] = [];
      querySnapshot.forEach((doc) => result.push(doc.data() as T));
      return listener(result, querySnapshot);
    });
};

export const getCollectionsByFilter = <T>(
  collectionRef: firebase.firestore.CollectionReference,
  config?: {
    limit?: number;
    orderBy?: {
      fieldPath: string | firebase.firestore.FieldPath;
      direction: firebase.firestore.OrderByDirection;
    };
    firstWhere?: IFirestoreWhere;
    secondWhere?: IFirestoreWhere;
  }
) => {
  const { limit, firstWhere, orderBy, secondWhere } = config;
  let query: firebase.firestore.Query = collectionRef;

  if (firstWhere) {
    const { fieldPath, opStr, value } = firstWhere;
    query = query.where(fieldPath, opStr, value);
  }

  if (secondWhere) {
    const { fieldPath, opStr, value } = secondWhere;
    query = query.where(fieldPath, opStr, value);
  }

  if (orderBy) {
    query = query.orderBy(orderBy.fieldPath, orderBy.direction);
  }

  if (limit) {
    query = query.limit(limit);
  }

  return query.get().then((resp) => {
    const result: T[] = [];
    resp.forEach((doc) => result.push(doc.data() as T));
    return result;
  });
};

export const setData = <T>(
  documentRef: firebase.firestore.DocumentReference,
  data: T,
  options?: firebase.firestore.SetOptions
) => {
  // changes any undefined values to null (makes firebase happy)
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value === undefined) data[key] = null;
  });

  return documentRef.set(data, options);
};

export const updateOrCreateData = <T>(
  documentRef: firebase.firestore.DocumentReference,
  data: DeepPartial<T>
) => {
  return documentRef.set(data, { merge: true });
};

export const deleteDoc = (
  documentRef: firebase.firestore.DocumentReference
) => {
  return documentRef.delete();
};

export const getSnapshot = <T>(
  documentRef: firebase.firestore.DocumentReference,
  listener: (data: T, snapshot?: firebase.firestore.DocumentSnapshot) => void
) => {
  return documentRef.onSnapshot((snap) => listener(snap.data() as T, snap));
};
