import styled from "../../~reusables/contexts/ThemeContext";

import {
  space,
  SpaceProps,
  color,
  ColorProps,
  width,
  WidthProps,
  height,
  HeightProps,
  minHeight,
  MinHeightProps,
  display,
  DisplayProps,
  flex,
  FlexProps as StyledSystemFlexProps,
  order,
  OrderProps,
  overflow,
  OverflowProps,
  alignSelf,
  AlignSelfProps,
  flexWrap,
  FlexWrapProps,
  flexBasis,
  FlexBasisProps,
  flexDirection,
  FlexDirectionProps,
  alignItems,
  AlignItemsProps,
  justifyContent,
  JustifyContentProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  textAlign,
  TextAlignProps,
  borders,
  BordersProps,
  borderColor,
  BorderColorProps,
  borderRadius,
  BorderRadiusProps,
  boxShadow,
  BoxShadowProps,
  opacity,
  OpacityProps,
  position,
  PositionProps,
  top,
  TopProps,
  left,
  LeftProps,
  right,
  RightProps,
  bottom,
  BottomProps,
  zIndex,
  ZIndexProps,
  maxHeight,
  maxWidth,
  MaxHeightProps,
  MaxWidthProps,
  minWidth,
  MinWidthProps,
  fontFamily,
  FontFamilyProps,
  lineHeight,
  LineHeightProps,
  letterSpacing,
  LetterSpacingProps,
} from "styled-system";

interface IBaseOverride<E> {
  color?: string;
  onClick?: (event: React.MouseEvent<E, MouseEvent>) => void;
}

export type IBox = IBaseOverride<HTMLDivElement> &
  BorderRadiusProps &
  BordersProps &
  BorderColorProps &
  SpaceProps &
  WidthProps &
  HeightProps &
  OverflowProps &
  ColorProps &
  FontSizeProps &
  FontWeightProps &
  StyledSystemFlexProps &
  MinHeightProps &
  MaxHeightProps &
  MinWidthProps &
  MaxWidthProps &
  DisplayProps;

export type IFlex = IBox &
  AlignSelfProps &
  OrderProps &
  FlexBasisProps &
  FlexWrapProps &
  FlexDirectionProps &
  AlignItemsProps &
  JustifyContentProps;

export type IContainer = IFlex &
  BoxShadowProps &
  OpacityProps &
  PositionProps &
  TopProps &
  BottomProps &
  LeftProps &
  RightProps &
  ZIndexProps &
  TextAlignProps;

export type ITypography<E = HTMLParagraphElement> = ColorProps &
  DisplayProps &
  FontFamilyProps &
  FontSizeProps &
  FontWeightProps &
  TextAlignProps &
  LineHeightProps &
  LetterSpacingProps &
  SpaceProps &
  IBaseOverride<E>;

export const Box = styled.div<IBox>(
  borders,
  borderColor,
  borderRadius,
  space,
  width,
  height,
  color,
  fontSize,
  fontWeight,
  flex,
  overflow,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  display
);

export const Flex = styled(Box)<IFlex>(
  {
    display: "flex",
  },
  alignSelf,
  order,
  flexWrap,
  flexBasis,
  flexDirection,
  alignItems,
  justifyContent
);

export const Container = styled(Flex)<IContainer>(
  boxShadow,
  opacity,
  position,
  top,
  bottom,
  left,
  right,
  zIndex,
  textAlign
);

export const Typography = styled.p<ITypography>(
  space,
  color,
  display,
  fontSize,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing
);

export const Anchor = styled.a<ITypography<HTMLAnchorElement>>(
  space,
  color,
  display,
  fontSize,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing
);

export const Span = styled.span<ITypography>(
  space,
  color,
  display,
  fontSize,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing
);
