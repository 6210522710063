import { Arch, Build } from "@todesktop/shared";
import gitUrlParse from "git-url-parse";
import githubUrlFromGit from "github-url-from-git";
import { DownloadLinkStats } from "./common";

export const parseBuildCommit = (build: Build) => {
  const {
    repositoryRemoteUrl,
    commitId,
    versionControlSystemName,
  } = build.versionControlInfo;

  const displayText =
    versionControlSystemName === "git" ? commitId.substring(0, 7) : commitId;
  const copyText = commitId;

  const href =
    repositoryRemoteUrl &&
    gitUrlParse(repositoryRemoteUrl).source === "github.com"
      ? githubUrlFromGit(repositoryRemoteUrl) + "/commit/" + commitId
      : "";

  return { displayText, copyText, href };
};

export interface BuildArtifact {
  key: string;
  platform: "linux" | "mac" | "windows";
  architecture: Arch;
  artifact: string;
  downloadUrl: string;
  disabledTooltip: string;
  stats: DownloadLinkStats;
}
