import React from "react";
import css from "@emotion/css";
import { Checkbox, Alert } from "antd";
import QuestionTooltip from "../atoms/QuestionTooltip";
import styled from "@emotion/styled";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { updateWindowOptions } from "../../~reusables/actions";
import { useStore, selectedApp } from "../../store";
import { showDraggableTitlebarHelp } from "../elements/HelpDialogs";
import { track } from "../../~reusables/util/analytics";
import { IApp } from "@todesktop/shared";

interface IFieldContainerProps {
  inset?: boolean;
}
const FieldContainer = styled.div`
  margin: 10px 0;
  ${(props: IFieldContainerProps) => props.inset && "padding-left: 20px"}
`;

const updateWindowOptionsBoolean = (field: keyof IApp["windowOptions"]) => (
  e: CheckboxChangeEvent
) => {
  const { checked } = e.target;
  updateWindowOptions({ [field]: checked });
  track({ event: "Update Windows Option", properties: { field, checked } });
};

const WindowOptions: React.SFC<{ showTitle?: boolean }> = ({
  showTitle = true,
}) => {
  const app = useStore(selectedApp);
  if (!app) {
    return null;
  }
  const isMenubar = app.appType === "menubar";

  const {
    isAlwaysOnTop,
    isInsetTitlebarTransparent,
    isTitlebarTransparent,
    isAutoHideMenuBar,
  } = app.windowOptions;

  const toggleTransparentTitlebar = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    updateWindowOptions({
      isTitlebarTransparent: checked,
    });
    if (checked === false) {
      updateWindowOptions({ isInsetTitlebarTransparent: false });
    }
  };

  return (
    <>
      {showTitle && (
        <h3
          css={css`
            font-weight: bold;
            font-size: 16px;
          `}
        >
          Window Options
        </h3>
      )}

      <FieldContainer>
        <Checkbox
          checked={isAlwaysOnTop}
          onChange={updateWindowOptionsBoolean("isAlwaysOnTop")}
        >
          Always On Top
          <QuestionTooltip text="Your app's window always remain on top of other windows" />
        </Checkbox>
      </FieldContainer>

      {!isMenubar && (
        <FieldContainer>
          <Checkbox
            onChange={toggleTransparentTitlebar}
            checked={isTitlebarTransparent}
          >
            Transparent Titlebar (Mac only)
            <QuestionTooltip
              text="Results in a hidden title bar and a full size content window,
          yet the title bar still has the standard window controls
          (“traffic lights”) in the top left."
            />
          </Checkbox>
        </FieldContainer>
      )}

      <FieldContainer inset={true} hidden={!isTitlebarTransparent}>
        <Checkbox
          onChange={updateWindowOptionsBoolean("isInsetTitlebarTransparent")}
          disabled={!isTitlebarTransparent}
          checked={isInsetTitlebarTransparent}
        >
          Inset (Mac only)
          <QuestionTooltip text="Results in a hidden title bar with an alternative look where the traffic light buttons are slightly more inset from the window edge." />
        </Checkbox>
        <Alert
          message={
            <a onClick={showDraggableTitlebarHelp}>
              Make transparent titlebar draggable
            </a>
          }
          type="info"
          showIcon={true}
          style={{
            cursor: "pointer",
            marginTop: 15,
          }}
        />
      </FieldContainer>

      {!isMenubar && (
        <FieldContainer>
          <Checkbox
            onChange={updateWindowOptionsBoolean("isAutoHideMenuBar")}
            checked={isAutoHideMenuBar}
          >
            Auto hide menu bar (Windows/Linux only)
            <QuestionTooltip
              text={
                <>
                  Automatically hides the application&apos;s menu bar unless the{" "}
                  <code>Alt</code> key is pressed.
                  <br />
                  The application menu is located underneath the titlebar (at
                  the top of the window).
                </>
              }
            />
          </Checkbox>
        </FieldContainer>
      )}
    </>
  );
};

export default WindowOptions;
