import { useEffect } from "react";

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    if (title?.length) {
      document.title = `${title} | ToDesktop`;
    } else {
      document.title = "ToDesktop";
    }
    return () => {
      document.title = prevTitle;
    };
  });
}
