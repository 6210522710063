import { IApp, IAppFromServerProps } from "@todesktop/shared";

import { DeepPartial } from "../types";
import {
  getData,
  setData,
  updateOrCreateData,
  getCollection,
  deleteDoc,
  getSnapshot,
} from "./common";
import { firestore } from "./firestore";
import type firebase from "firebase/app";

export const getCustomDomains = (customDomain: string) =>
  firestore
    .collectionGroup("applications")
    .where("customDomain", "==", customDomain)
    .limit(1)
    .get();

const getUserApplication = (id: string, appId: string) =>
  firestore.doc(`users/${id}/applications/${appId}`);

const getTempApplication = (appId: string) =>
  firestore.doc(`temporaryApplications/${appId}`);

const getUserApplications = (id: string) =>
  firestore.collection(`users/${id}/applications/`);

export const getUserApplicationData = (id: string, appId: string) =>
  getData<IApp>(getUserApplication(id, appId));

export const getTempApplicationData = (appId: string) =>
  getData<IAppFromServerProps>(getTempApplication(appId));

export const getUserApplicationsData = (id: string) =>
  getCollection<IApp>(getUserApplications(id));

export const saveUserApplicationData = (
  id: string,
  appId: string,
  application: IApp
) => setData<IApp>(getUserApplication(id, appId), application);

export const updateUserApplicationData = (
  id: string,
  appId: string,
  application: DeepPartial<IApp>
) => updateOrCreateData<IApp>(getUserApplication(id, appId), application);

export const deleteUserApplication = (id: string, appId: string) => {
  return deleteDoc(getUserApplication(id, appId));
};

export const onUserApplicationUpdate = (
  id: string,
  appId: string,
  handler: (arg: IApp, snapshot?: firebase.firestore.DocumentSnapshot) => void
) => {
  if (appId && id) {
    return getSnapshot<IApp>(getUserApplication(id, appId), handler);
  }
};
