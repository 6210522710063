// modules
import React, { useState } from "react";
import { IAppFromServerProps } from "@todesktop/shared";
import { withRouter } from "react-router-dom";

// components
import { Divider, Modal } from "antd";

// logic
import { updateUIState, addAppFromServer } from "../../../~reusables/actions";
import { useStore } from "../../../store";
import { Heading } from "../../atoms/Heading";
import { Space } from "../../atoms/Space";
import { NewAppModalContext } from "./types";
import { CreateWebApp } from "./CreateWebApp";

const CreateLegacyAppModal = ({ history }) => {
  const { selectedTeam } = useStore((state) => ({
    selectedTeam: state.selectedTeam,
  }));

  const [isModalVisible, setModalVisibility] = useState(true);

  const loadCreatedApp = (app: IAppFromServerProps) => {
    if (selectedTeam) {
      // ITodos - add app to team instead of user
    } else {
      addAppFromServer(app);
    }
    updateUIState(undefined);
    history.push(`/apps/${app.id}`);
  };

  const onCancel = () => setModalVisibility(false);

  const newAppModalContext: NewAppModalContext = {
    onCancel,
    loadCreatedApp,
  };

  return (
    <Modal
      visible={isModalVisible}
      closable={true}
      centered={true}
      onCancel={onCancel}
      afterClose={() => updateUIState(undefined)}
      footer={null}
    >
      <section>
        <Heading textAlign="center" variant="h3" as="h3">
          Create new app
        </Heading>
        <Divider />
        <CreateWebApp {...newAppModalContext} />
      </section>
    </Modal>
  );
};

export default withRouter(CreateLegacyAppModal);
