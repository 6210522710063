// modules
import React, { useState, useEffect } from "react";
import { RouteComponentProps, Redirect, useHistory } from "react-router-dom";
import styled, { useTheme } from "../~reusables/contexts/ThemeContext";
import css from "@emotion/css";
import queryString from "query-string";
import curvedGradientImg from "../~reusables/images/curved-gradient.svg";
import { Button, Input } from "antd";

// components
import { Flex } from "../components/atoms/Primitives";
import { CopyOutlined } from "@ant-design/icons";
import LoginModalInner from "../components/account/LoginModalInner";
import RegisterModalInner from "../components/account/RegisterModalInner";
import logoSVG from "../~reusables/images/logo-new-with-transparent-bg.svg";
import PasswordAccountForm from "../components/account/PasswordAccountForm";

// logic
import {
  onGithubAuth,
  onGoogleAuth,
  onTwitterAuth,
  onPasswordSignup,
} from "../~reusables/actions";
import { track } from "../~reusables/util/analytics";
import { copyToClipboard } from "../~reusables/util";
import { useStore } from "../store";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";
import { Space } from "../components/atoms/Space";
import { callFirebaseFunction } from "../~reusables/firebase";
import { BlockBackground } from "../components/atoms/BlockBackground";

interface ISignUpOrLogin {
  onAuthCallback: () => void;
}

export const SignUp: React.FC<ISignUpOrLogin> = ({ onAuthCallback }) => {
  const [isPasswordSignup, setPasswordSignup] = useState(false);

  return (
    <Space direction="column">
      <Heading variant="h4" as="h4">
        Sign up for an account
      </Heading>
      {isPasswordSignup ? (
        <>
          <Text
            css={css`
              cursor: pointer;
            `}
            color="primary"
            onClick={() => setPasswordSignup(false)}
          >
            Go back
          </Text>
          <PasswordAccountForm
            buttonText="Create Account"
            callback={onPasswordSignup(onAuthCallback)}
          />
        </>
      ) : (
        <RegisterModalInner
          onGithubAuth={onGithubAuth(onAuthCallback)}
          onGoogleAuth={onGoogleAuth(onAuthCallback)}
          onTwitterAuth={onTwitterAuth(onAuthCallback)}
          onPasswordSignup={() => {
            track({
              event: "Started Signing Up",
              properties: { provider: "password" },
            });
            setPasswordSignup(true);
          }}
        />
      )}
    </Space>
  );
};

export const LogIn: React.FC<ISignUpOrLogin> = ({ onAuthCallback }) => {
  return (
    <Space size="md" direction="column" align="flex-start">
      <Heading variant="h4" as="h4">
        Log in to your account
      </Heading>
      <LoginModalInner
        onGithubAuth={onGithubAuth(onAuthCallback)}
        onGoogleAuth={onGoogleAuth(onAuthCallback)}
        onTwitterAuth={onTwitterAuth(onAuthCallback)}
      />
    </Space>
  );
};

interface IAuthenticate {
  type: "login" | "signup";
}

const Authenticate: React.FC<IAuthenticate> = ({ type }) => {
  const history = useHistory();
  const [isSignUp, setIsSignUp] = useState(type === "signup");
  const user = useStore((state) => state.user);
  const query = queryString.parse(window.location.search);

  const search = window.location.search;
  // grab everything that comes after `redirectTo` from the search
  const redirectTo = query["redirectTo"]
    ? search.slice(search.lastIndexOf("redirectTo=") + "redirectTo=".length)
    : "/";

  const onAuthCallback = () => history.push(redirectTo);
  if (user) return <Redirect to={redirectTo} />;

  return (
    <StyledAuthenticate>
      <section>
        <div>
          <img
            src={logoSVG}
            alt="ToDesktop white logo"
            height="65"
            width="72"
          />
        </div>
        <section>
          {isSignUp ? (
            <SignUp onAuthCallback={onAuthCallback} />
          ) : (
            <LogIn onAuthCallback={onAuthCallback} />
          )}
        </section>
        <Text>
          {isSignUp ? "Already have an account?" : "Don't have an account?"}
          <span onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? " Log in." : " Sign up."}
          </span>
        </Text>
      </section>
    </StyledAuthenticate>
  );
};

export const AuthenticateDesktopApp: React.FC<RouteComponentProps> = () => {
  const { space } = useTheme();
  const user = useStore((state) => state.user);
  const [isSignUp, setIsSignUp] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [
    isLogInWithTokenInstructionsVisible,
    setIsLogInWithTokenInstructionsVisible,
  ] = useState(false);

  useEffect(() => {
    if (token) {
      window.location.href = `todesktop://login?token=${token}`;
    }
  }, [token]);

  const loginOnDesktop = async () => {
    setIsLoading(true);
    const resp = await callFirebaseFunction("createCustomToken")();
    setToken(resp.data.token);
    setIsLoading(false);
  };

  const revealLogInWithTokenInstructions = () => {
    setIsLogInWithTokenInstructionsVisible(true);
  };

  useEffect(() => {
    if (user) {
      loginOnDesktop();
    }
  }, [user]);

  return (
    <BlockBackground>
      {user ? (
        <>
          <div>
            <img
              src={logoSVG}
              alt="ToDesktop white logo"
              height="65"
              width="72"
            />
          </div>
          <section>
            <Flex flexDirection="column" alignItems="center">
              <Heading color="white" variant="h2" as="h2">
                Authenticate for ToDesktop Builder
              </Heading>
              <div
                css={css`
                  text-align: center;
                `}
              >
                <Button
                  type="default"
                  size="large"
                  style={{ background: "white" }}
                  onClick={loginOnDesktop}
                  loading={isLoading}
                >
                  Authenticate
                </Button>
              </div>
              {isLogInWithTokenInstructionsVisible ? (
                <div
                  css={css`
                    margin: ${space[7]}px;
                    margin-top: 0;
                  `}
                >
                  <Heading mt={7} mb={4} color="white" variant="h4" as="h3">
                    Log in with token
                  </Heading>
                  <StyledInstructionList>
                    <ol>
                      <li>
                        <div
                          css={css`
                            margin-bottom: ${space[2]}px;
                          `}
                        >
                          Copy the access token below.
                        </div>
                        <Input.Password
                          width={200}
                          style={{ width: "300px" }}
                          placeholder="access token"
                          addonAfter={
                            <CopyOutlined
                              onClick={copyToClipboard("access token", token)}
                            />
                          }
                          value={token}
                        />
                      </li>
                      <li>Open ToDesktop Builder desktop app.</li>
                      <li>
                        In the menu bar, click Account &gt; Log in with token.
                      </li>
                      <li>
                        Paste the token you copied in step one into the input
                        and click the Log In button.
                      </li>
                    </ol>
                  </StyledInstructionList>
                </div>
              ) : (
                <>
                  <Text color="white" m={space[7]} mb={space[7]}>
                    Having trouble authenticating?&nbsp;
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={revealLogInWithTokenInstructions}
                    >
                      Log in with a token.
                    </span>
                  </Text>
                </>
              )}
            </Flex>
          </section>
        </>
      ) : (
        <section className="styled-authenticate">
          <div>
            <img
              src={logoSVG}
              alt="ToDesktop white logo"
              height="65"
              width="72"
            />
          </div>
          <Heading mb={6} color="white" variant="h3" as="h3">
            Authenticate for ToDesktop Builder
          </Heading>
          <section>
            {isSignUp ? (
              <SignUp onAuthCallback={() => {}} />
            ) : (
              <LogIn onAuthCallback={() => {}} />
            )}
          </section>
          <Text color="white">
            {isSignUp ? "Already have an account?" : "Don't have an account?"}
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? " Log in." : " Sign up."}
            </span>
          </Text>
        </section>
      )}
    </BlockBackground>
  );
};

const StyledAuthenticate = styled.main`
  min-height: 100vh;
  background-color: ${(p) => p.theme.colors.lightBackground};
  background: url(${curvedGradientImg});
  background-repeat: no-repeat;
  background-size: 100% 450px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div,
    & > p {
      margin: ${(p) => p.theme.space[7]}px;

      span {
        color: ${(p) => p.theme.colors.primary};
        font-weight: ${(p) => p.theme.fontWeights[4]};
        cursor: pointer;
      }
    }

    & > section {
      border-radius: ${(p) => p.theme.radii[2]}px;
      background: ${(p) => p.theme.colors.white};
      padding: ${(p) => p.theme.space[8]}px;
      box-shadow: ${(p) => p.theme.shadows.card};
      -webkit-box-shadow: ${(p) => p.theme.shadows.card};
      -mox-box-shadow: ${(p) => p.theme.shadows.card};
    }
  }
`;

const StyledInstructionList = styled.ol`
  color: white;
  padding: 0;

  li {
    margin-bottom: ${(p) => p.theme.space[4]}px;
  }
`;

export default Authenticate;
