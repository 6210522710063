import React, { useEffect } from "react";
import { Empty, Button, Space } from "antd";
import { useStore, selectedApp, appIsBuilding } from "../../store";
import { usePrevious } from "../../~reusables/util";
import { updateUIState } from "../../~reusables/actions";
import { StyledAntdCard } from "../atoms/StyledAntdCard";
import BuildApp from "../build/BuildApp";
import { Text } from "../atoms/Text";

export const DesktopBuilderApp: React.FC = () => {
  const app = useStore(selectedApp);
  const previousAppId = usePrevious(app ? app.id : "");
  const isAppBuilding = useStore(appIsBuilding);
  const { uiState, user } = useStore((state) => ({
    uiState: state.uiState,
    user: state.user,
  }));

  useEffect(() => {
    if (!app?.meta?.currentBuildProgress) {
      updateUIState(null);
    } else if (isAppBuilding) {
      updateUIState("build");
    }
  }, [isAppBuilding, app, previousAppId, window.location.pathname]);

  return (
    <StyledAntdCard title="Application">
      {uiState === "build" ? <BuildApp app={app} /> : null}
      <Empty description="">
        <Space direction="vertical" size="middle">
          <Text fontWeight={500}>
            This app can only be edited using ToDesktop Builder.
          </Text>
          <Space size="small">
            <Button href="https://builder.todesktop.com">
              Download ToDesktop Builder
            </Button>
            <Button
              type="primary"
              onClick={() => {
                window.location.href = `todesktop://switch-app?appId=${app.id}&userId=${user.id}`;
              }}
            >
              Open in ToDesktop Builder
            </Button>
          </Space>
        </Space>
      </Empty>
    </StyledAntdCard>
  );
};
