// modules
import React, { useState } from "react";
import type firebase from "firebase/app";

// components
import { UploadField } from "../atoms/UploadField";

// logic
import {
  updateFirestoreApp,
  isFileChangeError,
  isFileUploadError,
  handleFileChange,
} from "../../~reusables/actions";
import { useStore, selectedApp } from "../../store";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { track } from "../../~reusables/util/analytics";
import { pngIconValidation } from "../../~reusables/util/validationRules";
import { storageRef } from "../../~reusables/firebase/firestore";

const IconPicker: React.SFC = () => {
  let firebaseUploadTask: firebase.storage.UploadTask;
  const [isUploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const app = useStore(selectedApp);

  const iconChange = ({ file, event }: UploadChangeParam) => {
    if (isFileChangeError(file)) {
      return;
    }

    if (event) {
      setUploading(true);
      setUploadPercent(event.percent);
    }
    if (file.status === "done") {
      const newFile = file;
      newFile.url = newFile.response.url; // .split('?')[0];
      updateFirestoreApp({
        icon: newFile.url || "",
      });
      setUploading(false);
      setUploadPercent(0);
    }
  };

  const imageUpload = async ({ file, onSuccess, onProgress, onError }: any) => {
    try {
      const imageRef = storageRef.child(`${app.id}/${file.name}`);
      firebaseUploadTask = imageRef.put(file);
      firebaseUploadTask.on(
        "state_changed",
        (snapshot) => {
          setUploading(true);
          onProgress(
            {
              percent: Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              ).toFixed(2),
            },
            file
          );
        },
        (error) => isFileUploadError(error, onError),
        async () => {
          const downloadURL = await imageRef.getDownloadURL();
          onSuccess({
            url: downloadURL,
          });
          track({ event: "Update App Icon" });
        }
      );
    } catch (error) {
      isFileUploadError(error, onError);
    }
  };

  return (
    <UploadField
      onChange={iconChange}
      firebaseUploadTask={firebaseUploadTask}
      customRequest={handleFileChange(imageUpload, firebaseUploadTask)}
      isUploading={isUploading}
      uploadPercent={uploadPercent}
      icon={app.icon}
      accept=".png"
      appFieldsValidationSchema={pngIconValidation}
      label="App Icon (PNG only)"
      tooltipText="Click to upload and replace current icon"
      hiddenHelpText={
        <>
          You can upload a PNG image as an icon for your app.
          <br />
          For best results please use a square image that is 512x512 or
          1024x1024.
        </>
      }
    />
  );
};

export default IconPicker;
