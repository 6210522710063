import React from "react";
import { Row, Col } from "antd";
import LoginButton from "./LoginButton";

export interface ILoginModalInnerProps {
  onGoogleAuth?(isLogin?: boolean): void;
  onGithubAuth?(isLogin?: boolean): void;
  onTwitterAuth?(isLogin?: boolean): void;
  onPasswordSignup?(): void;
}

const RegisterModalInner: React.SFC<ILoginModalInnerProps> = ({
  onGoogleAuth,
  onGithubAuth,
  onTwitterAuth,
  onPasswordSignup,
}) => {
  return (
    <Row gutter={12}>
      <Col
        span={24}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "16px",
        }}
      >
        <LoginButton kind="google" onClick={() => onGoogleAuth(false)}>
          Sign up with Google
        </LoginButton>
        <LoginButton kind="github" onClick={() => onGithubAuth(false)}>
          Sign up with Github
        </LoginButton>
        <LoginButton kind="x" onClick={() => onTwitterAuth(false)}>
          Sign up with X
        </LoginButton>
        <LoginButton kind="password" onClick={onPasswordSignup}>
          Sign up with Password
        </LoginButton>
      </Col>
    </Row>
  );
};

export default RegisterModalInner;
