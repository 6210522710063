// modules
import React from "react";
import styled from "../../~reusables/contexts/ThemeContext";
import { RouteComponentProps } from "react-router-dom";

// components
import {
  StyledAntdCard,
  CardIsEmpty,
} from "../../components/atoms/StyledAntdCard";
import { Button } from "antd";
import AppSnippet from "../../components/elements/AppSnippet";

// logic
import { goToCreateNewAppModal } from "../../~reusables/actions";
import { useStore } from "../../store";
import { track } from "../../~reusables/util/analytics";
import { Text } from "../../components/atoms/Text";
import { IApp } from "@todesktop/shared";

type IAppsOverview = RouteComponentProps;

const AppsOverview: React.FC<IAppsOverview> = () => {
  const { usersApps } = useAppsOverview();
  const { userDataLoaded } = useStore((state) => ({
    userDataLoaded: state.userDataLoaded,
  }));

  return (
    <>
      {Object.values(usersApps).map(({ id, apps, label, isOwner }) => {
        const title = isOwner ? "Apps Overview" : `${label}'s apps`;

        return (
          <StyledAppsOverview key={id}>
            <StyledAntdCard
              loading={!userDataLoaded}
              title={title}
              bodyStyle={{ padding: apps && apps.length > 0 ? 0 : "auto" }}
              extra={
                isOwner ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      track({ event: "Create New App Modal" });
                      goToCreateNewAppModal();
                    }}
                  >
                    Create new app
                  </Button>
                ) : null
              }
            >
              {apps && apps.length > 0 ? (
                apps.map((app) => <AppSnippet key={app.id} app={app} />)
              ) : (
                <CardIsEmpty>
                  <Text color="support">No apps created yet</Text>
                </CardIsEmpty>
              )}
            </StyledAntdCard>
          </StyledAppsOverview>
        );
      })}
    </>
  );
};

export const useAppsOverview = () => {
  const { apps, appToUser, user } = useStore((state) => ({
    apps: state.apps,
    userDataLoaded: state.userDataLoaded,
    appToUser: state.appToUser,
    user: state.user,
  }));

  const usersApps: {
    [key: string]: {
      id: string;
      label: string;
      apps: IApp[];
      isOwner: boolean;
    };
  } = {
    [user.id]: {
      id: user.id,
      label: user.firstName,
      apps: [],
      isOwner: true,
    },
  };

  Object.values(appToUser)
    .filter(Boolean)
    .forEach((user) => {
      usersApps[user.id] = { ...user, apps: [] };
    });

  apps.forEach((app) => {
    const user = appToUser[app.id];
    if (app.id && usersApps[user?.id]) {
      usersApps[user.id].apps.push(app);
    }
  });

  return { usersApps };
};

const StyledAppsOverview = styled.main``;

export default AppsOverview;
