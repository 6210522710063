/* eslint-disable react/display-name */
import { AnalysisStatus, Build, IApp } from "@todesktop/shared";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { ColumnsType, TableProps } from "antd/lib/table";
import React, { useEffect, useMemo, useState } from "react";
import { CodeHighlighter } from "../../../components/atoms/CodeHighlighter";
import { Pill } from "../../../components/atoms/Pill";
import { Box, Flex } from "../../../components/atoms/Primitives";
import { selectedAppUser, useStore } from "../../../store";
import { updateFirestoreApp } from "../../../~reusables/actions";
import { useStaticAnalysisFunction } from "../../../~reusables/hooks/useFirebaseFunction";
import { useReleaseContext } from "./ReleaseContext";
import { ReleaseSection, ReleaseSectionProps } from "./ReleaseSection";
import { TextProps } from "antd/lib/typography/Text";
import { copyToClipboard } from "../../../~reusables/util";

const { Text, Paragraph } = Typography;

export const StaticAnalysisSection: React.FC<ReleaseSectionProps> = (props) => {
  const {
    props: { app, build },
    setAnalysisState,
  } = useReleaseContext();
  const appUser = useStore(selectedAppUser);

  const [hasHighSeverityIssues, setHighSeverityIssues] = useState(false);
  const analysisFn = useStaticAnalysisFunction();
  const isDone = build.staticAnalysis?.status === AnalysisStatus.done;
  const isError = build.staticAnalysis?.status === AnalysisStatus.error;
  const isCompleted = isDone || isError;

  useEffect(() => {
    if (isCompleted) return;
    analysisFn
      .callFn({ appId: app.id, buildId: build.id, userId: appUser?.id })
      .catch(console.error);
  }, [app.id, isCompleted, build.id, appUser?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (build.staticAnalysis?.status) {
      setAnalysisState("staticAnalysis", {
        states: hasHighSeverityIssues
          ? ["warning"]
          : [build.staticAnalysis.status],
        checks: {
          "unacknowledged-issues": hasHighSeverityIssues
            ? "warning"
            : "success",
        },
      });
    } else {
      setAnalysisState(
        "staticAnalysis",
        { states: [analysisFn.state] },
        "warn"
      );
    }
  }, [
    hasHighSeverityIssues,
    analysisFn.state,
    build.staticAnalysis?.status,
    setAnalysisState,
  ]);

  return (
    <ReleaseSection {...props}>
      <Flex css={{ justifyContent: "flex-end" }}>
        <Button
          loading={isCompleted ? false : true}
          disabled={isDone || analysisFn.state === "calling"}
          onClick={() => {
            analysisFn
              .callFn({
                appId: app.id,
                buildId: build.id,
                userId: appUser?.id,
              })
              .catch(console.error);
          }}
        >
          Run static analysis
        </Button>
      </Flex>
      <StaticAnalysisComponent
        app={app}
        build={build}
        onCallback={({ hasHighSeverityIssues }) => {
          setHighSeverityIssues(hasHighSeverityIssues);
        }}
      />
    </ReleaseSection>
  );
};

export const StaticAnalysisComponent: React.FC<{
  app: IApp;
  build: Build;
  onCallback?: (options: { hasHighSeverityIssues: boolean }) => void;
}> = ({ app, build, onCallback }) => {
  const data = useMemo((): StaticAnalysisTableRow[] => {
    const result = build.staticAnalysis?.result;
    const issues: StaticAnalysis[] = result ? JSON.parse(result)?.issues : [];

    const priorities: Record<StaticAnalysis["severity"]["name"], number> = {
      HIGH: 0,
      MEDIUM: 1,
      LOW: 2,
      INFORMATIONAL: 3,
    };

    return issues
      .map(({ id, file, location, ...props }) => {
        const compositeKey = `${id}:${file}:${location.line}`;

        return {
          key: compositeKey,
          file: file === "N/A" ? "" : `${file}:${location.line}`,
          suggestion: StaticAnalysisIdSuggestionMapping[id] || id,
          hidden: !!app.meta?.staticAnalysis?.[compositeKey]?.hidden,
          id,
          location,
          priority: priorities[props.severity.name],
          url: `https://www.todesktop.com/static-analysis/${id
            .toLowerCase()
            .replace(/_/g, "-")}`,
          ...props,
        };
      })
      .sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (b.priority < a.priority) return 1;
        return 0;
      });
  }, [app, build.staticAnalysis?.result]);

  const hasHighSeverityIssues = data.some(
    (data) => data.severity.name === "HIGH" && !data.hidden
  );
  useEffect(() => {
    onCallback?.({ hasHighSeverityIssues });
  }, [hasHighSeverityIssues]);

  const [activeKey, setActiveKey] = useState(data[0]?.key || "");
  const selectedRow = data.find((row) => row.key === activeKey);

  const status = build.staticAnalysis?.status;
  const isDone = status === AnalysisStatus.done;
  const isError = status === AnalysisStatus.error;
  const errorText = build.staticAnalysis?.error;
  const isCompleted = isDone || isError;

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={selectedRow ? 12 : 24}>
        <StaticAnalysisTable
          style={{ cursor: "pointer", width: "100%" }}
          onRow={(record) => {
            return {
              className:
                activeKey === record.key ? "ant-table-row-selected" : undefined,
              onClick: () => setActiveKey(record.key),
            };
          }}
          dataSource={data}
          loading={isCompleted || !status ? false : { tip: status }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  isError ? errorText || "Static analysis failed" : "No data"
                }
              />
            ),
          }}
        />
      </Col>
      {selectedRow && (
        <Col
          xs={24}
          lg={12}
          style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          <ElectronNegativityCard data={selectedRow} />
        </Col>
      )}
    </Row>
  );
};

const ElectronNegativityCard: React.FC<{
  data: StaticAnalysisTableRow;
}> = ({ data }) => {
  const {
    key,
    hidden,
    url,
    suggestion,
    severity,
    confidence,
    description,
    lines,
    location,
    file,
  } = data;

  const codeLines = lines
    ? [
        { line: lines["previousLine-1"], location: location.line - 2 },
        { line: lines.previousLine, location: location.line - 1 },
        { line: lines.targetLine, location: location.line },
        { line: lines.nextLine, location: location.line + 1 },
        { line: lines["nextLine+1"], location: location.line + 2 },
      ].filter((entry) => entry.line)
    : [];

  return (
    <Card
      style={{ position: "sticky", top: 0 }}
      title={suggestion}
      type="inner"
      extra={
        <Tooltip title={hidden ? "Unresolve" : "Resolve"}>
          <div>
            <Checkbox
              checked={hidden}
              onChange={async (e) => {
                e.stopPropagation();
                await updateFirestoreApp({
                  meta: {
                    staticAnalysis: { [key]: { hidden: e.target.checked } },
                  },
                });
              }}
            />
          </div>
        </Tooltip>
      }
    >
      <Flex
        css={{ gap: "12px", justifyContent: "flex-between", flexWrap: "wrap" }}
      >
        {[
          {
            label: "Confidence",
            component: <ConfidenceTag name={confidence.name} />,
          },
          {
            label: "Severity",
            component: <SeverityTag name={severity.name} />,
          },
          ...(file
            ? [{ label: "File", component: <FileName name={file} /> }]
            : []),
        ].map(({ label, component }) => (
          <Flex
            key={label}
            css={{
              flexDirection: "column",
              gap: "8px",
              flex: "1",
              width: "100%",
            }}
          >
            <Text type="secondary">{label}</Text>
            <Box style={{ overflow: "auto" }}>{component}</Box>
          </Flex>
        ))}
      </Flex>
      <Divider />
      <Box>
        <Paragraph>
          {description}.{" "}
          <a href={url} target="_blank" rel="noreferrer">
            Learn more
          </a>
          .
        </Paragraph>

        {codeLines.length ? (
          <CodeHighlighter
            clipboardTitle="code"
            showLineNumbers
            startingLineNumber={codeLines[0]?.location}
            codeString={codeLines.map((codeLine) => codeLine.line).join("\n")}
          />
        ) : null}
      </Box>
    </Card>
  );
};

export const SeverityTag: React.FC<{
  name: StaticAnalysis["severity"]["name"];
}> = ({ name, children }) => {
  const title =
    name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase();

  switch (name) {
    case "HIGH":
      return (
        <Pill color="red">
          {children}
          {title}
        </Pill>
      );
    case "MEDIUM":
      return (
        <Pill color="orange">
          {children}
          {title}
        </Pill>
      );
    case "LOW":
      return (
        <Pill color="yellow">
          {children}
          {title}
        </Pill>
      );
    default:
      return <Pill color="gray">{children}Informational</Pill>;
  }
};

export const ConfidenceTag: React.FC<{
  name: StaticAnalysis["confidence"]["name"];
}> = ({ name, children }) => {
  const getBarColors = (name: StaticAnalysis["confidence"]["name"]) => {
    switch (name) {
      case "CERTAIN":
        return ["grey", "grey", "grey"];
      case "FIRM":
        return ["grey", "grey", "lightgrey"];
      case "TENTATIVE":
        return ["grey", "lightgrey", "lightgrey"];
      default:
        return ["lightgrey", "lightgrey", "lightgrey"];
    }
  };

  return (
    <Flex css={{ display: "inline-flex", gap: "8px", alignItems: "center" }}>
      <Flex
        css={{
          display: "inline-flex",
          gap: "2px",
          height: "16px",
          alignItems: "flex-end",
        }}
      >
        {getBarColors(name).map((background, index) => {
          return (
            <Box
              key={index}
              css={{
                background,
                display: "inline",
                width: "3px",
                height: `${50 + index * 25}%`,
                borderTopLeftRadius: "1px",
                borderTopRightRadius: "1px",
              }}
            />
          );
        })}
      </Flex>
      <Typography.Text>
        {children}
        {name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase()}
      </Typography.Text>
    </Flex>
  );
};

export const FileName: React.FC<{ name: string } & TextProps> = ({
  name,
  ...props
}) => {
  if (!name) return null;

  const parts = name.split("/");
  return (
    <Flex css={{ display: "inline-flex", alignItems: "center" }}>
      <Tooltip title="Copy to clipboard">
        <CopyOutlined
          style={{ marginRight: "4px" }}
          onClick={copyToClipboard(name, name)}
        />
      </Tooltip>
      <Text code {...props} style={{ whiteSpace: "nowrap" }}>
        {parts.map((part, i) => {
          if (i === parts.length - 1) return <b key={i}>{part}</b>;
          return <span key={i}>{part}/</span>;
        })}
      </Text>
    </Flex>
  );
};

export const StaticAnalysisTable: React.FC<TableProps<
  StaticAnalysisTableRow
>> = (props) => {
  const columns: ColumnsType<StaticAnalysisTableRow> = [
    {
      title: "Confidence",
      dataIndex: "confidence",
      key: "confidence",
      filters: (["TENTATIVE", "FIRM", "CERTAIN"] as const).map((key) => {
        const size = props.dataSource.filter(
          (data) => data.confidence.name === key
        ).length;

        return {
          value: key,
          text: <ConfidenceTag name={key}>{size} | </ConfidenceTag>,
        };
      }),
      onFilter: (value: string, record) => {
        return record.confidence.name === value;
      },
      render: ({ name }: StaticAnalysisTableRow["confidence"]) => {
        return <ConfidenceTag name={name} />;
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      filters: (["INFORMATIONAL", "LOW", "MEDIUM", "HIGH"] as const).map(
        (key) => {
          const size = props.dataSource.filter(
            (data) => data.severity.name === key
          ).length;

          return {
            value: key,
            text: <SeverityTag name={key}>{size} | </SeverityTag>,
          };
        }
      ),
      onFilter: (value: string, record) => {
        return record.severity.name === value;
      },
      render: ({ name }: StaticAnalysisTableRow["severity"]) => {
        return <SeverityTag name={name} />;
      },
    },

    {
      title: "Suggestion",
      dataIndex: "suggestion",
      key: "suggestion",
      render: (suggestion: StaticAnalysisTableRow["suggestion"]) => {
        return <Text css={{ whiteSpace: "nowrap" }}>{suggestion}</Text>;
      },
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (file) => <FileName name={file} />,
    },
    {
      title: "",
      className: "no-title",
      dataIndex: "hidden",
      key: "hidden",
      defaultFilteredValue: ["visible"],
      fixed: "right",
      filters: [
        { value: "visible", text: "Unresolved" },
        { value: "hidden", text: "Resolved" },
      ],
      onFilter: (value: string, record) => {
        if (record.hidden) return value === "hidden";
        return value === "visible";
      },
      render: (hidden: StaticAnalysisTableRow["hidden"], record) => {
        return (
          <Tooltip title={hidden ? "Unresolve" : "Resolve"}>
            <div>
              <Checkbox
                checked={hidden}
                onChange={async (e) => {
                  e.stopPropagation();
                  await updateFirestoreApp({
                    meta: {
                      staticAnalysis: {
                        [record.key]: { hidden: e.target.checked },
                      },
                    },
                  });
                }}
              />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const count = props.dataSource.filter(
    (data) => data.severity.name === "HIGH" && data.hidden === false
  ).length;

  return (
    <Flex
      css={{
        ".no-title .ant-table-filter-column-title": { display: "none" },
      }}
    >
      <Table
        title={
          count
            ? () => {
                return (
                  <Box>
                    <Text style={{ fontSize: "12px", marginRight: "4px" }}>
                      There {count > 1 ? `are ${count}` : `is ${count}`}
                    </Text>
                    <Pill color="red">High</Pill>
                    <Text style={{ fontSize: "12px", marginLeft: "4px" }}>
                      severity issue that requires attention. Resolve{" "}
                      {count > 1 ? "them" : "it"} by addressing errors in your
                      source code or by toggling the checkbox.
                    </Text>
                  </Box>
                );
              }
            : undefined
        }
        pagination={false}
        size="small"
        scroll={{ x: true }}
        columns={columns}
        {...props}
      />
    </Flex>
  );
};

export type StaticAnalysisTableRow = {
  key: string;
  suggestion: string;
  hidden: boolean;
  url: string;
} & StaticAnalysis;

export interface StaticAnalysis {
  file: string | "N/A";
  location: {
    line: number;
    column: number;
  };
  id: string;
  description: string;
  shortenedURL: string;
  severity: {
    value: number;
    name: "INFORMATIONAL" | "LOW" | "MEDIUM" | "HIGH";
  };
  confidence: {
    value: number;
    name: "CERTAIN" | "FIRM" | "TENTATIVE";
  };
  manualReview: boolean;
  lines?: {
    "previousLine-1": string;
    previousLine: string;
    targetLine: string;
    nextLine: string;
    "nextLine+1": string;
  };
}

export const StaticAnalysisIdSuggestionMapping: Record<string, string> = {
  AFFINITY_GLOBAL_CHECK: 'Limit use of "affinity"',
  ALLOWPOPUPS_HTML_CHECK: "Disable web view popups",
  AUXCLICK_JS_CHECK: "Limit navigation on untrusted origins",
  AUXCLICK_HTML_CHECK: "Limit navigation on untrusted origins",
  AVAILABLE_SECURITY_FIXES_GLOBAL_CHECK: "Apply Electron security patches",
  BLINK_FEATURES_JS_CHECK: "Avoid using experimental features",
  BLINK_FEATURES_HTML_CHECK: "Avoid using experimental features",
  CERTIFICATE_ERROR_EVENT_JS_CHECK: "Insecure TLS validation",
  CERTIFICATE_VERIFY_PROC_JS_CHECK: "Insecure TLS validation",
  CONTEXT_ISOLATION_JS_CHECK: "Enable context isolation",
  CUSTOM_ARGUMENTS_JS_CHECK: "Review CLI arguments",
  CUSTOM_ARGUMENTS_JSON_CHECK: "Review CLI arguments",
  CSP_GLOBAL_CHECK: "Improve content security policy",
  DANGEROUS_FUNCTIONS_JS_CHECK: "Avoid dangerous functions",
  ELECTRON_VERSION_JSON_CHECK: "Keep Electron updated",
  EXPERIMENTAL_FEATURES_HTML_CHECK: "Avoid using experimental features",
  EXPERIMENTAL_FEATURES_JS_CHECK: "Avoid using experimental features",
  HTTP_RESOURCES_JS_CHECK: "Disallow insecure HTTP connections",
  HTTP_RESOURCES_HTML_CHECK: "Disallow insecure HTTP connections",
  HTTP_RESOURCES_WITH_NODE_INTEGRATION_GLOBAL_CHECK:
    "Reduce node integration risk",
  INSECURE_CONTENT_HTML_CHECK: "Disallow insecure HTTP connections",
  INSECURE_CONTENT_JS_CHECK: "Disallow insecure HTTP connections",
  LIMIT_NAVIGATION_JS_CHECK: "Ensure safe navigation",
  LIMIT_NAVIGATION_GLOBAL_CHECK: "Ensure safe navigation",
  NODE_INTEGRATION_HTML_CHECK: "Disable node integration for untrusted origins",
  NODE_INTEGRATION_ATTACH_EVENT_JS_CHECK:
    "Disable node integration for untrusted origins",
  NODE_INTEGRATION_JS_CHECK: "Disable node integration for untrusted origins",
  OPEN_EXTERNAL_JS_CHECK: "Review use of openExternal",
  PERMISSION_REQUEST_HANDLER_JS_CHECK:
    "Use request handler for untrusted origins",
  PERMISSION_REQUEST_HANDLER_GLOBAL_CHECK:
    "Use request handler for untrusted origins",
  PRELOAD_JS_CHECK: "Review preload scripts",
  PROTOCOL_HANDLER_JS_CHECK: "Review custom protocol handlers",
  SANDBOX_JS_CHECK: "Use sandbox for untrusted origins",
  SECURITY_WARNINGS_DISABLED_JS_CHECK: "Re-enable security warnings",
  SECURITY_WARNINGS_DISABLED_JSON_CHECK: "Re-enable security warnings",
  WEB_SECURITY_HTML_CHECK: "Re-enable web security",
  WEB_SECURITY_JS_CHECK: "Re-enable web security",
};
