import type firebase from "firebase/app";
import {
  getData,
  updateOrCreateData,
  getCollectionSnapshotWhere,
  deleteDoc,
} from "./common";
import { firestore } from "./firestore";
import { getCurrentUser } from "./auth";
import { IUser, UserIHaveAcceptedInviteFrom } from "@todesktop/shared";

export const getAcceptedUserData = (id: string, userId: string) =>
  getData<IUser>(firestore.doc(`/users/${id}/acceptedUsers/${userId}`));

export const getAcceptedUsers = (id: string) =>
  firestore.collection(`users/${id}/acceptedUsers`);

export const onAcceptedUsersUpdate = (
  id: string,
  handler: (
    arg: UserIHaveAcceptedInviteFrom[],
    snapshot?: firebase.firestore.QuerySnapshot
  ) => void
) => {
  return getCollectionSnapshotWhere<UserIHaveAcceptedInviteFrom>(
    getAcceptedUsers(id),
    { orderBy: { fieldPath: "email", direction: "desc" } },
    handler
  );
};

export const getOwnAcceptedUsers = (id: string) =>
  firestore.collection(`users/${id}/acceptedUsers`);

const getAcceptedUser = (id: string, userId: string) =>
  firestore.doc(`users/${id}/acceptedUsers/${userId}`);

export const upsertAcceptedUser = async (
  id: string,
  user: Partial<UserIHaveAcceptedInviteFrom>
) => {
  const userId = getCurrentUser().uid;
  await updateOrCreateData<UserIHaveAcceptedInviteFrom>(
    getAcceptedUser(userId, id),
    {
      ...user,
    }
  );
};

export const deleteAcceptedUser = async (id: string) => {
  const userId = getCurrentUser().uid;
  await deleteDoc(getAcceptedUser(userId, id));
};
