// libs
import React from "react";

// components
import { Flex, Box, IBox } from "./Primitives";

type SizeProps = "xs" | "sm" | "md" | "lg" | "xl";

export interface ISpace extends IBox {
  direction?: "row" | "column";
  align?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | ("flex-start" | "flex-end" | "center" | "baseline")[];
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  size?: SizeProps | SizeProps[] | number | number[];
  color?: string;
  offsetTop?: boolean;
  width?: string;
}

const spaceSize = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
};

export const Space: React.FC<ISpace> = ({
  direction = "row",
  align = "center",
  size = "sm",
  offsetTop,
  children,
  width,
  ...restProps
}) => {
  const items = React.Children.toArray(children);
  const len = items.length;

  let padding: SizeProps[] | number[];

  switch (typeof size) {
    case "string":
      padding = [spaceSize[size]];
      break;
    case "number":
      padding = [size];
      break;
    case "object":
      padding =
        typeof size[0] === "string"
          ? (size as SizeProps[]).map((s) => spaceSize[s])
          : size;
  }

  const paddingBottom = direction === "row" ? 0 : padding;
  const paddingRight = direction === "column" ? 0 : padding;

  if (!len) {
    return (
      <Box
        paddingTop={offsetTop ? padding : 0}
        paddingBottom={paddingBottom}
        paddingRight={paddingRight}
        {...restProps}
      />
    );
  }

  return (
    <Flex flexDirection={direction} alignItems={align} {...restProps}>
      {items.map((child, i) => (
        <Box
          key={i}
          width={width}
          paddingBottom={i === len - 1 && len !== 1 ? 0 : paddingBottom}
          paddingRight={i === len - 1 && len !== 1 ? 0 : paddingRight}
        >
          {child}
        </Box>
      ))}
    </Flex>
  );
};
