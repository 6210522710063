import React from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import css from "@emotion/css";
import js from "react-syntax-highlighter/dist/cjs/languages/hljs/javascript";
import tomorrowNightBlue from "react-syntax-highlighter/dist/cjs/styles/hljs/tomorrow-night-blue";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../~reusables/util";

SyntaxHighlighter.registerLanguage("javascript", js);

interface ICodeHighlighter {
  startingLineNumber?: number;
  showLineNumbers?: boolean;
  clipboardTitle?: string;
  codeString: string;
  showCopyIcon?: boolean;
}

export const CodeHighlighter: React.FC<ICodeHighlighter> = ({
  codeString,
  clipboardTitle,
  showCopyIcon = true,
  ...props
}) => {
  const { radii, space, colors } = useTheme();
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SyntaxHighlighter
        language="javascript"
        style={tomorrowNightBlue}
        css={css`
          border-radius: ${radii[2]}px;
          padding: ${space[6]}px !important;
        `}
        {...props}
      >
        {codeString}
      </SyntaxHighlighter>
      {showCopyIcon && (
        <CopyOutlined
          onClick={copyToClipboard(clipboardTitle || codeString, codeString)}
          style={{
            color: colors.greys[7],
            position: "absolute",
            right: "16px",
            opacity: "0.8",
            top: "16px",
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
};
