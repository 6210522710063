import React, { useState } from "react";
import {
  Divider,
  Button,
  Input,
  Modal,
  message,
  notification,
  Alert,
} from "antd";
import { Flex } from "../../atoms/Primitives";
import { NewAppModalContext, NewAppType } from "./types";
import { useStore } from "../../../store";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import { track } from "../../../~reusables/util/analytics";
import { generateAccessToken } from "../../../~reusables/actions";
import {
  ThemeContextProvider,
  useTheme,
} from "../../../~reusables/contexts/ThemeContext";
import { Text } from "../../atoms/Text";
import { RevealableText } from "../../atoms/TextUtils";
import { hrefLinks } from "../../../~reusables/constants";
import { Heading } from "../../atoms/Heading";

const createNewApp = callFirebaseFunction("createNewApp");

export const CreateCLIApp: React.FC<NewAppModalContext> = ({
  loadCreatedApp,
}) => {
  const { colors } = useTheme();
  const { accessToken } = useStore((state) => ({
    accessToken: state.user ? state.user.accessToken : null,
  }));
  const [electronAppName, setElectronAppName] = useState("");
  const [loading, setLoading] = useState(false);

  const onCreate = async () => {
    setLoading(true);
    track({
      event: "Submitted URL Form",
      properties: {
        url: "",
        name: electronAppName,
        host: "app",
        source: "create new app modal",
        type: NewAppType.CLI,
      },
    });

    try {
      const { data } = await createNewApp({
        name: electronAppName,
        appType: "electron",
      });

      if (!accessToken) {
        const token = await generateAccessToken();
        notification.open({
          duration: 0,
          message: "Access token generated",
          description: (
            <ThemeContextProvider>
              <Text variant="body3">
                An access token of <RevealableText revealText={token} /> has
                been generated for use with the ToDesktop CLI.{" "}
                <a target="_blank" rel="noreferrer" href={hrefLinks.cliNpmDocs}>
                  Learn more here.
                </a>
              </Text>
            </ThemeContextProvider>
          ),
        });
      }

      loadCreatedApp(data);
    } catch (err) {
      const { message: msg, details: app } = err;
      if (app && app.id) {
        Modal.confirm({
          title: msg,
          content: <>Are you sure you want to continue creating this app?</>,
          okText: "Yes, continue",
          cancelText: "No, change the app",
          onOk: () => loadCreatedApp(app),
          onCancel: () => setLoading(false),
        });
      } else {
        setLoading(false);
        message.error(
          "We had trouble completing this action. Please try again."
        );
      }
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === "Enter" && !loading ? onCreate() : null;

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Alert
        type="info"
        showIcon
        style={{ background: "transparent" }}
        message={
          <Flex
            css={{ flexDirection: "column", gap: "6px", color: colors.text }}
          >
            <Heading as="h6" variant="h6">
              Who should use the ToDesktop Platform?
            </Heading>
            <Text variant="body3">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.todesktop.com/electron"
              >
                The ToDesktop Platform
              </a>{" "}
              is suitable for those who are comfortable writing Electron code,
              but want a managed distribution service. Here are some feature
              highlights:
            </Text>
            <ul>
              <li>
                Secure cloud-based <b>code-signing.</b>
              </li>
              <li>
                <b>Native installers</b> for Windows, Max, and Linux.
              </li>
              <li>
                <b>CDN-Backed</b> download links for fast distribution.
              </li>
              <li>
                Zero-configuration, rock-solid <b>auto-updates</b>.
              </li>
            </ul>
          </Flex>
        }
      />
      <Divider />
      <Flex css={{ flexDirection: "column", gap: "16px" }}>
        <Input
          value={electronAppName}
          onChange={(e) => setElectronAppName(e.target.value)}
          size="large"
          onKeyPress={handleKeypress}
          placeholder="App name"
          autoFocus
        />
        <Flex css={{ alignSelf: "end", gap: "4px" }}>
          <Button
            disabled={!electronAppName}
            loading={loading}
            type="primary"
            onClick={onCreate}
          >
            Create App
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
