import { IUser } from "@todesktop/shared";

export const defaultUser: Partial<IUser> = {
  lastName: null,
  firstName: null,
  authInfo: {},
  avatar: null,
  currentApplication: null,
  customerId: null,
  schemaVersion: 2,
};
