import React from "react";
import { ThemeProvider, useTheme as useEmotionTheme } from "emotion-theming";
import styled, { CreateStyled } from "@emotion/styled";

export const theme = {
  breakpoints: ["420px", "767px", "1280px"],
  space: [
    0, // 0
    1, // 1
    2, // 2
    3, // 3
    5, // 4
    8, // 5
    14, // 6
    21, // 7
    34, // 8
    55, // 9
    89, // 10
    144, // 11
    233, // 12
  ],
  fontSizes: [
    10, // 0
    12, // 1
    14, // 2
    16, // 3
    20, // 4
    24, // 5
    32, // 6
    40, // 7
    48, // 8
  ],
  fontWeights: [
    100, // 0
    200, // 1
    300, // 2
    400, // 3
    500, // 4
    600, // 5
    700, // 6
    800, // 7
    900, // 8
  ],
  radii: [
    0, // 0
    2, // 1
    4, // 2
    8, // 3
    16, // 4
    "100%", // 5
  ],
  borders: [
    0, // 0
    "1px solid", // 1
    "2px solid", // 2
    "4px solid", // 3
  ],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacing: {
    normal: "normal",
    tracked: "0.1em",
    tight: "0.05em",
    mega: "0.25em",
  },
  fonts: {
    sansSerif:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  },
  shadows: {
    shallow: "0 2px 15px 0 rgba(0, 0, 0, 0.07)",
    card: "0 4px 30px 0 rgba(0, 0, 0, 0.15)",
    "0dp": "none",
    "1dp":
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
    "2dp":
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)",
    "3dp":
      "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)",
    "4dp":
      "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
    "6dp":
      "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)",
    "8dp":
      "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)",
    "9dp":
      "0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20)",
    "12dp":
      "0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)",
    "16dp":
      "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)",
    "24dp":
      "0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)",
  },
  colors: {
    white: "#FFFFFF",
    primary: "#006DF6",
    title: "#1A202C",
    lightTitle: "#FFFFFF",
    text: "#4A5568",
    lightText: "#a0aec0",
    support: "#828C9A",
    lightBackground: "#F8F8F8",
    success: "#52c418",
    danger: "#f5222d",
    warning: "#f7ad14",
    blueGradient: "linear-gradient(127.41deg, #009EFF 0%, #0242E4 101.17%)",
    blueGradientAlternateAngle:
      "linear-gradient(344deg, #009EFF 0%, #0242E4 101.17%)",
    primaries: [
      "#2139c4",
      "#145ce3",
      "#006ff6",
      "#0082ff",
      "#0091ff",
      "#0fa2ff",
      "#50b3ff",
      "#89c9ff",
      "#b9ddff",
      "#e2f2ff",
    ],
    greys: [
      "#272727",
      "#484849",
      "#686868",
      "#7c7c7d",
      "#a6a6a7",
      "#c4c4c5",
      "#e6e6e7",
      "#FBFBFC",
      "#FAFAFA",
      "#FBFBFC",
    ],
  },
};

// defines the theme type so that it can be imported and used anywhere
export type Theme = typeof theme;

export const ThemeContextProvider: React.FC = (props) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

// exposes a fully typed styled and useTheme methods
export const useTheme = () => useEmotionTheme<Theme>();
export default styled as CreateStyled<Theme>;
