import { getData, setData, updateOrCreateData, getSnapshot } from "./common";
import { firestore } from "./firestore";
import { getCurrentUser } from "./auth"; // TODO discuss whether we need such dependencies
import { createNewUserNewTeamBatch } from "./usersTeamsCollection";
import { enableTeamsFeature } from "../constants/featureFlags";
import { IUser } from "@todesktop/shared";
import { DeepPartial } from "../types";

interface ICreateUpdateUserOptions {
  isNewUser: boolean;
}

const getUser = (id: string) => firestore.doc(`users/${id}`);

export const getUserData = (id: string) => getData<IUser>(getUser(id));

export const createUserData = async (
  id: string,
  user: IUser,
  options: ICreateUpdateUserOptions = { isNewUser: false }
) => {
  if (options.isNewUser) {
    await setData<IUser>(getUser(id), { ...user, schemaVersion: 2 });
    if (enableTeamsFeature) {
      createNewUserNewTeamBatch(user);
    }
  } else {
    return await setData<IUser>(getUser(id), user);
  }
};

export const setUserData = (id: string, data: DeepPartial<IUser>) =>
  setData<DeepPartial<IUser>>(getUser(id), data, {
    merge: true,
  });

export const updateOrCreateUserData = async (
  id: string,
  user: Partial<IUser>,
  options: ICreateUpdateUserOptions = { isNewUser: false }
) => {
  if (options.isNewUser) {
    await updateOrCreateData<IUser>(getUser(id), {
      ...user,
      schemaVersion: 2,
    });
    if (enableTeamsFeature) {
      createNewUserNewTeamBatch({ ...user, id });
    }
  } else {
    return updateOrCreateData<IUser>(getUser(id), user);
  }
};

export const updateOrCreateCurrentUserData = async (
  user: Partial<IUser>,
  options: ICreateUpdateUserOptions = { isNewUser: false }
) => {
  const userId = getCurrentUser().uid;

  if (options.isNewUser) {
    await updateOrCreateData<IUser>(getUser(userId), {
      ...user,
      schemaVersion: 2,
    });
    if (enableTeamsFeature) {
      createNewUserNewTeamBatch({ ...user, id: userId });
    }
  } else {
    return updateOrCreateData<IUser>(getUser(userId), user);
  }
}; // TODO Now we are adding unneeded depencencies. I think this should be done via action. In general, this layer should contain only pure users interaction functionality.

export const onUserUpdate = (id: string, handler: (arg: IUser) => void) =>
  getSnapshot<IUser>(getUser(id), handler);
