import React, { useEffect } from "react";
import { Modal, Tabs } from "antd";
import { CaretLeftFilled } from "@ant-design/icons";
import { useState } from "react";
import {
  updateUIState,
  onGoogleAuth,
  onGithubAuth,
  onTwitterAuth,
  setShouldCreateSubWithoutBuild,
} from "../../~reusables/actions";
import AppIcon from "../atoms/AppIcon";
import { track } from "../../~reusables/util/analytics";
import LoginModalInner from "./LoginModalInner";
import RegisterModalInner from "./RegisterModalInner";
import { Box, Anchor } from "../atoms/Primitives";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { Space } from "../atoms/Space";
import { LogIn, SignUp } from "../../pages/Authenticate";
import { useStore } from "../../store";
import { IApp } from "@todesktop/shared";

const { TabPane } = Tabs;

interface IConvertAccountProps {
  app: IApp;
  next?: () => void;
}

const onPasswordSignup = () => {
  track({ event: "Started Signing Up", properties: { provider: "password" } });
  updateUIState("password-signup");
};

const afterCloseModal = () => {
  track({ event: "Aborted Signing Up", properties: { provider: null } });
  updateUIState(null);
};

enum SignUpTabs {
  Signup = "Signup",
  Login = "Login",
}

const CreateAccount: React.FC<IConvertAccountProps> = ({
  app,
  next = () => updateUIState("choose-plan"),
}) => {
  const [modalVisibile, setModalVisibility] = useState(true);
  const [activeTab, setActiveTab] = useState<SignUpTabs>(SignUpTabs.Signup);

  const switchToLoginTab = () => setActiveTab(SignUpTabs.Login);
  const switchToSignupTab = () => setActiveTab(SignUpTabs.Signup);

  const closeModal = () => setModalVisibility(false);
  return (
    <Modal
      visible={modalVisibile}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={afterCloseModal}
    >
      <Box>
        <AppIcon iconUrl={app.icon} />
        <Tabs
          activeKey={activeTab}
          tabBarStyle={{
            display: "none",
          }}
        >
          <TabPane tab={SignUpTabs.Signup} key={SignUpTabs.Signup}>
            <Space direction="column">
              <Heading variant="h3" as="h3" textAlign="center">
                Sign Up &amp; Build Your App
              </Heading>
              <Text textAlign="center" mb={2}>
                How do you wish to sign up?
              </Text>
              <RegisterModalInner
                onGoogleAuth={onGoogleAuth(next)}
                onGithubAuth={onGithubAuth(next)}
                onTwitterAuth={onTwitterAuth(next)}
                onPasswordSignup={onPasswordSignup}
              />
              <Text textAlign="center" variant="body3">
                or, <a onClick={switchToLoginTab}>Login to existing account</a>.
              </Text>
            </Space>
          </TabPane>
          <TabPane tab={SignUpTabs.Login} key={SignUpTabs.Login}>
            <Box>
              <Anchor
                display="inline-block"
                mb={0}
                textAlign="left"
                onClick={switchToSignupTab}
              >
                <CaretLeftFilled />
                Sign up
              </Anchor>
              <LoginModalInner
                onGithubAuth={onGithubAuth(next)}
                onGoogleAuth={onGoogleAuth(next)}
                onTwitterAuth={onTwitterAuth(next)}
                onPasswordAuthCallback={next}
              />
            </Box>
          </TabPane>
        </Tabs>
      </Box>
    </Modal>
  );
};

export const CreateSubWithoutBuild: React.FC<IConvertAccountProps> = ({
  app,
  next = () => updateUIState("choose-plan"),
}) => {
  const user = useStore((state) => state.user);
  const [modalVisibile, setModalVisibility] = useState(true);
  const [activeTab, setActiveTab] = useState<SignUpTabs>(SignUpTabs.Signup);

  const switchToLoginTab = () => setActiveTab(SignUpTabs.Login);
  const switchToSignupTab = () => setActiveTab(SignUpTabs.Signup);

  const onAuthCallback = () => {
    setShouldCreateSubWithoutBuild(true);
    next();
  };

  useEffect(() => {
    if (user) {
      setShouldCreateSubWithoutBuild(true);
      updateUIState("choose-plan");
    }
  }, [user]);

  const closeModal = () => setModalVisibility(false);
  return (
    <Modal
      visible={modalVisibile}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={afterCloseModal}
    >
      <Box>
        <AppIcon iconUrl={app.icon} />
        <Tabs
          activeKey={activeTab}
          tabBarStyle={{
            display: "none",
          }}
        >
          <TabPane tab={SignUpTabs.Signup} key={SignUpTabs.Signup}>
            <Space direction="column">
              <SignUp onAuthCallback={onAuthCallback} />
              <Text textAlign="center" variant="body3">
                or, <a onClick={switchToLoginTab}>Login to existing account</a>.
              </Text>
            </Space>
          </TabPane>
          <TabPane tab={SignUpTabs.Login} key={SignUpTabs.Login}>
            <Box>
              <Anchor
                display="inline-block"
                mb={0}
                textAlign="left"
                onClick={switchToSignupTab}
              >
                <CaretLeftFilled />
                Sign up
              </Anchor>
              <LogIn onAuthCallback={onAuthCallback} />
            </Box>
          </TabPane>
        </Tabs>
      </Box>
    </Modal>
  );
};

export default CreateAccount;
