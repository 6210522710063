import { useEffect, useState } from "react";

export const useDelayedValue = <T>(currentValue: T, ms = 1000) => {
  const [value, setValue] = useState(currentValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(currentValue);
    }, ms);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentValue, ms]);

  return value;
};
