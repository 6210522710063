import React from "react";
import { Anchor, Box, Flex } from "../../atoms/Primitives";
import { NewAppModalContext } from "./types";
import { Alert, Button, Divider } from "antd";
import { Text } from "../../atoms/Text";
import { Heading } from "../../atoms/Heading";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";

export const CreateBuilderApp: React.FC<NewAppModalContext> = ({
  onCancel,
}) => {
  const { colors } = useTheme();

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Alert
        type="info"
        showIcon
        style={{ background: "transparent" }}
        message={
          <Flex
            css={{ flexDirection: "column", gap: "6px", color: colors.text }}
          >
            <Heading as="h6" variant="h6">
              Who should use ToDesktop Builder?
            </Heading>
            <Text variant="body3">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.todesktop.com/"
              >
                ToDesktop Builder
              </a>{" "}
              is suitable for those who have an existing web app and want to
              transform it into a cross-platform desktop app using a no-code
              interface. Here are some feature highlights:
            </Text>
            <ul>
              <li>
                Bootstrap a desktop app straight <b>from your web app</b>.
              </li>
              <li>
                Optionally add code to customize your app with{" "}
                <b>native features</b>.
              </li>
              <li>
                <b>One-click publish</b> your desktop app to your customers.
              </li>
            </ul>
          </Flex>
        }
      />
      <Divider />
      <Flex css={{ alignSelf: "end", gap: "4px" }}>
        <Button
          onClick={() => {
            window.location.href = `todesktop://login`;
          }}
        >
          Open ToDesktop Builder
        </Button>
        <Button
          href="https://builder.todesktop.com"
          type="primary"
          onClick={() => {
            onCancel();
          }}
        >
          Download ToDesktop Builder
        </Button>
      </Flex>
    </Flex>
  );
};
