// libs
import React, { useState } from "react";
import firebase from "firebase/app";

// components
import { StyledAntdCard } from "../atoms/StyledAntdCard";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { Box, Flex } from "../atoms/Primitives";
import { Space } from "../atoms/Space";
import { Button, Col, message, Modal, Row } from "antd";
import { RevealableText } from "../atoms/TextUtils";
import { FormInput } from "../atoms/FormUtils";

// utils
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { selectedApp, useStore } from "../../store";
import useForm from "../../~reusables/hooks/useForm";
import { environmentKeyValueValidation } from "../../~reusables/util/validationRules";
import { updateFirestoreApp } from "../../~reusables/actions";

/**
 * Note: delete ts ignores once Cancel Build is merged (has all the todesktop/shared type updates)
 */
export const EnvironmentConfigCard: React.FC = () => {
  return (
    <StyledAntdCard
      title={
        <Space direction="column" align="flex-start">
          <Flex alignItems="center">
            <Heading variant="h4" as="h4">
              Environment Variables
            </Heading>
          </Flex>
          <Text color="support">
            Set environment variables to be used in your builds
          </Text>
        </Space>
      }
    >
      <EnvironmentConfig />
    </StyledAntdCard>
  );
};

const paddingToMatchInputIndent = 4;
export const EnvironmentConfig: React.FC = () => {
  // @ts-ignore
  const { environmentVariables } = useStore(selectedApp);
  const sortedEnvironmentVariables =
    environmentVariables && typeof environmentVariables === "object"
      ? (Object.entries(environmentVariables).sort() as [string, string][])
      : [];

  return (
    <>
      <ConfigRow
        leftComponent={
          <Heading padding={paddingToMatchInputIndent} variant="h5" as="h5">
            Key
          </Heading>
        }
        centreComponent={
          <Heading padding={paddingToMatchInputIndent} variant="h5" as="h5">
            Value
          </Heading>
        }
        rightComponent={null}
      />
      <Box borderTop="1px solid" borderTopColor="greys.6" />
      {sortedEnvironmentVariables.map(([key, value], idx) => (
        <EditEnvironmentVariable
          shouldAlternateBg={idx % 2 !== 0}
          key={key}
          envKey={key}
          envValue={value}
        />
      ))}
      <AddEnvironmentVariable />
    </>
  );
};

const EditEnvironmentVariable: React.FC<{
  envKey: string;
  envValue: string;
  shouldAlternateBg: boolean;
}> = ({ envKey, envValue, shouldAlternateBg }) => {
  // form inputs require unique IDs, so assign these values to state
  // so that they won't change even if their parent props do
  const [formIds] = useState({ key: envKey, value: envValue });
  const [isEditing, setIsEditing] = useState(false);
  const { colors } = useTheme();

  const { values, handleChange, handleSubmit, errors, isSubmitting } = useForm(
    { [formIds.key]: envKey, [formIds.value]: envValue },
    async (form: { [key: string]: string }) => {
      await updateEnvironmentVariable(formIds.key, form[formIds.value]);
      setIsEditing(false);
    },
    environmentKeyValueValidation({ key: formIds.key, value: formIds.value })
  );

  return (
    <form>
      <ConfigRow
        shouldAlternateBg={shouldAlternateBg}
        leftComponent={
          isEditing ? (
            <FormInput
              error={errors[formIds.key]}
              name={formIds.key}
              placeholder="KEY"
              disabled
              value={values[formIds.key]}
            />
          ) : (
            <Text padding={paddingToMatchInputIndent}>{envKey}</Text>
          )
        }
        centreComponent={
          isEditing ? (
            <FormInput
              error={errors[formIds.value]}
              name={formIds.value}
              placeholder="VALUE"
              value={values[formIds.value]}
              onChange={handleChange}
              onKeyDown={(e) =>
                // have to do this manually, because form's onSubmit was also
                // reacting to click of the "Edit" button, thus submitting the form
                e.key === "Enter" && isEditing ? handleSubmit(e) : null
              }
            />
          ) : (
            envValue && (
              <Text padding={paddingToMatchInputIndent}>
                <RevealableText revealText={envValue} />
              </Text>
            )
          )
        }
        rightComponent={
          isEditing ? (
            <Space size="sm">
              <Button loading={isSubmitting} onClick={(e) => handleSubmit(e)}>
                Save
              </Button>
              <Button onClick={() => setIsEditing(false)} type="link">
                Cancel
              </Button>
            </Space>
          ) : (
            <Space size="sm">
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
              <Button
                type="link"
                style={{ color: colors.danger }}
                onClick={() => {
                  Modal.confirm({
                    content:
                      "Are you sure you want to delete this environment variable?",
                    onOk() {
                      updateEnvironmentVariable(
                        envKey,
                        firebase.firestore.FieldValue.delete()
                      );
                    },
                    okText: "Yes, Delete",
                    okType: "danger",
                    cancelText: "No",
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          )
        }
      />
    </form>
  );
};

enum uniqueFormIds {
  key = "addKey",
  value = "addValue",
}

const AddEnvironmentVariable: React.FC = () => {
  // @ts-ignore
  let { environmentVariables } = useStore(selectedApp);
  environmentVariables = environmentVariables || {};

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    resetForm,
  } = useForm(
    { [uniqueFormIds.key]: "", [uniqueFormIds.value]: "" },
    async (form: { [key: string]: string }) => {
      if (environmentVariables[form[uniqueFormIds.key]]) {
        return message.error("This environment variable key already exists");
      }

      await updateEnvironmentVariable(
        form[uniqueFormIds.key],
        form[uniqueFormIds.value]
      );

      resetForm();
    },
    environmentKeyValueValidation(uniqueFormIds)
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box paddingTop={6} borderTop="1px solid" borderTopColor="greys.6" />
      <ConfigRow
        leftComponent={
          <FormInput
            error={errors.addKey}
            name={uniqueFormIds.key}
            placeholder="KEY"
            value={values.addKey}
            onChange={handleChange}
          />
        }
        centreComponent={
          <FormInput
            error={errors.addValue}
            name={uniqueFormIds.value}
            placeholder="VALUE"
            value={values.addValue}
            onChange={handleChange}
          />
        }
        rightComponent={
          <Button loading={isSubmitting} htmlType="submit" type="primary">
            Add
          </Button>
        }
      />
    </form>
  );
};

const ConfigRow: React.FC<{
  leftComponent: JSX.Element;
  centreComponent: JSX.Element;
  rightComponent: JSX.Element;
  shouldAlternateBg?: boolean;
}> = ({
  leftComponent,
  centreComponent,
  rightComponent,
  shouldAlternateBg,
}) => {
  const { space } = useTheme();

  return (
    <Box
      backgroundColor={shouldAlternateBg ? "greys.8" : "white"}
      borderRadius={1}
      padding={`${space[5]}px ${space[6]}px`}
    >
      <Row gutter={24}>
        <Col sm={9}>{leftComponent}</Col>
        <Col sm={9}>{centreComponent}</Col>
        <Col sm={6}>{rightComponent}</Col>
      </Row>
    </Box>
  );
};

const updateEnvironmentVariable = async (key: string, value: unknown) =>
  updateFirestoreApp({
    // @ts-ignore
    environmentVariables: { [key]: value },
  });
