import React, { ReactNode } from "react";
import { NavLink, NavLinkProps, Route, RouteProps } from "react-router-dom";

export const TypedRoute: <P extends RoutePath>(
  props: RouteProps & { path: P | P[] }
) => JSX.Element = Route as any;

export interface LinkProps<P extends RoutePath>
  extends Omit<NavLinkProps, "to" | "params"> {
  to: P;
  params?: Record<string, string>;
  children?: ReactNode;
}

function Link<P extends RoutePath>(
  { to, params, children, ...props }: LinkProps<P>,
  ref: React.Ref<HTMLAnchorElement>
) {
  return (
    <NavLink to={createRoutePath(to, params)} ref={ref} {...props}>
      {children}
    </NavLink>
  );
}
export const TypedLink = React.forwardRef(Link);

export type RoutePath =
  | "/apps/:id/builds/:buildId/release"
  | "/apps/:id/builds/:buildId";

export const createRoutePath = <P extends RoutePath>(
  path: P,
  params?: Record<string, string>
): RoutePath => {
  let route: string = path;
  const paramObj: { [i: string]: string } = params || {};

  for (const key of Object.keys(paramObj)) {
    route = route.replace(`:${key}`, paramObj[key]);
  }

  return route as RoutePath;
};
