import React from "react";
import PackageHeaderBG from "../../~reusables/images/PackageHeaderBG";
import Checkmark from "../../~reusables/images/Checkmark";
import css from "@emotion/css";
import PlusSVG from "../../~reusables/images/Plus";
import { Tooltip } from "antd";

interface IFeatureObj {
  text: string;
  icon?: string;
  helpText?: string | JSX.Element;
}
interface IPackageFeatureProps {
  color?: string;
  feature: IFeatureObj;
}
const PackageFeature: React.SFC<IPackageFeatureProps> = ({
  feature,
  color,
}) => (
  <Tooltip title={feature.helpText}>
    <div
      css={css`
        font-size: 16px;
        font-weight: bold;
        padding-left: 17px;
      `}
    >
      {feature.icon === "plus" ? (
        <PlusSVG
          color={color}
          css={css`
            margin-left: 2px;
            margin-right: 16px;
            vertical-align: middle;
          `}
        />
      ) : (
        <Checkmark
          color={color}
          css={css`
            margin-right: 8px;
            vertical-align: middle;
          `}
        />
      )}
      {feature.text}
    </div>
  </Tooltip>
);

interface IPackageButtonProps {
  color?: string;
  content: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  shouldPinButtonToBottom?: boolean;
  disabledTooltip?: string | React.ReactNode;
}
const PackageButton: React.SFC<IPackageButtonProps> = ({
  color,
  content,
  shouldPinButtonToBottom,
  disabledTooltip,
  onClick,
  ...props
}) => {
  const isDisabled = !!disabledTooltip;
  return (
    <Tooltip title={disabledTooltip}>
      <button
        css={css`
          ${shouldPinButtonToBottom ? "position: absolute; bottom: 20px;" : ""}
          background: ${color};
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          color: #fff;
          border: 2px ${color} solid;
          display: block;
          margin: 30px 20px 0 20px;
          width: calc(100% - 40px);
          font-size: 16px;
          padding: 7px 0;
          font-weight: bold;
          cursor: ${isDisabled ? "not-allowed" : "pointer"};
          outline: none;
          &:hover {
            border-color: ${color};
            background: #fff;
            color: ${color};
          }
          &:active {
            transform: translateY(1px);
            box-shadow: none;
          }
        `}
        onClick={isDisabled ? () => {} : onClick}
        {...props}
      >
        {content}
      </button>
    </Tooltip>
  );
};

interface IPackageItemProps {
  color?: string;
  ctaText?: string;
  isEmphasized?: boolean;
  title: string;
  price: number | string;
  pricePrefixText?: string;
  pricePostfixText?: string;
  features: IFeatureObj[];
  onChoose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  shouldPinButtonToBottom?: boolean;
  disabledTooltip?: string | React.ReactNode;
}

const getPopularityEmphasis = () => {
  return (
    <>
      <span className="visually-hidden">(</span>
      <span
        className="popularity-emphasis"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          left: 50%;
          max-width: 100px;
          transform: translate(-50%, -50%);
          text-align: center;
          text-transform: uppercase;
          border-radius: 5px;
          background: #041162;
          font-size: 10px;
          font-weight: 600;
          box-shadow: rgba(0, 0, 0, 0.5) 0 1px 3px 0;
          overflow: hidden;

          ::before {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 50%;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.1);
          }
        `}
      >
        Most popular
      </span>
      <span className="visually-hidden">)</span>
    </>
  );
};

const PackageItem: React.SFC<IPackageItemProps> = ({
  color = "#000",
  ctaText = "Begin Free Trial",
  title,
  price,
  pricePrefixText,
  pricePostfixText = "/Month",
  features,
  isEmphasized,
  onChoose,
  shouldPinButtonToBottom,
  disabledTooltip,
  ...props
}) => (
  <div
    css={css`
      width: 278px;
      background: white;
      /* Top needs to be more than inner border-radius because we can't
      use overflow-hidden, because popularity emphasis overflows */
      border-radius: 10px 10px 5px 5px;
      box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px 0;
      padding-bottom: 20px;
      margin: 0 auto;
    `}
    {...props}
  >
    <div
      css={css`
        color: ${color};
        position: relative;
        height: 136px;
        width: 278px;
        margin-bottom: 10px;
      `}
    >
      <PackageHeaderBG
        css={css`
          position: absolute;
          border-radius: 5px;
        `}
      />
      <div
        css={css`
          position: relative;
          text-align: center;
        `}
      >
        <h2
          css={css`
            color: white;
            padding-top: 20px;
            font-weight: bold;
            font-size: 18px;
          `}
        >
          {title} {isEmphasized && getPopularityEmphasis()}
        </h2>
        <h3
          css={css`
            color: white;
            font-weight: bold;
          `}
        >
          <strong
            css={css`
              font-size: 22px;
            `}
          >
            {pricePrefixText && (
              <span
                css={css`
                  display: inline-block;
                  font-size: 14px;
                  margin-right: 5px;
                `}
              >
                {pricePrefixText}
              </span>
            )}
            {price}
          </strong>
          <span
            css={css`
              text-transform: uppercase;
              font-size: 10px;
            `}
          >
            {pricePostfixText}
          </span>
        </h3>
      </div>
    </div>

    {features.map((feature, i) => (
      <PackageFeature key={i} color={color} feature={feature} />
    ))}

    <PackageButton
      color={color}
      content={ctaText}
      onClick={onChoose}
      shouldPinButtonToBottom={shouldPinButtonToBottom}
      disabledTooltip={disabledTooltip}
    />
  </div>
);

export default PackageItem;
