import css from "@emotion/css";
import {
  Modal,
  Row,
  Col,
  Skeleton,
  Button,
  Popconfirm,
  Alert,
  Form,
  Empty,
} from "antd";
import { subscriptions as subscriptionsType } from "stripe";
import { AppIconIcon } from "../atoms/AppIcon";
import { updateUIState } from "../../~reusables/actions";
import React, { useState } from "react";
import { useStore } from "../../store";
import ReactTimeAgo from "react-time-ago";
import TextArea from "antd/lib/input/TextArea";
import SubscriptionStatusLabel from "../atoms/SubscriptionStatusLabel";
import { track } from "../../~reusables/util/analytics";
import {
  useRetrieveCustomer,
  useRetrieveUpcomingInvoice,
  cancelSubscription,
} from "../../~reusables/hooks/stripeHooks";
import { Heading } from "../atoms/Heading";
import { Box, Span } from "../atoms/Primitives";
import { Space } from "../atoms/Space";
import { IApp, IApp2 } from "@todesktop/shared";
import { isTDBuilderApp } from "../../~reusables/util/schemaChecks";
import { defaultIcon } from "../../~reusables/types";

interface ISubWithApp extends subscriptionsType.ISubscription {
  app: IApp;
}

const afterCloseModal = () => updateUIState(null);

const ManageSubscription: React.SFC = () => {
  const stripeCustomerId = useStore((state) => state.user.stripeCustomerId);
  const apps = useStore((state) => state.apps);
  const [modalVisibile, setModalVisibility] = useState(true);
  const [subToCancel, setSubToCancel] = useState(null);

  const { customer, isLoading, reloadCustomer } = useRetrieveCustomer(
    stripeCustomerId,
    {
      expand: ["subscriptions"],
    }
  );

  const customerSubscriptions =
    customer && customer.subscriptions ? customer.subscriptions.data : [];

  const subscriptions = customerSubscriptions.map((sub) => {
    return {
      ...sub,
      app: apps.find((app) => app.id === sub.metadata.appId),
    };
  });

  const closeModal = () => setModalVisibility(false);

  const requestCancelSubscription = (sub) => {
    setSubToCancel(sub);
  };
  const hideCancelSubscriptionModal = () => {
    setSubToCancel(null);
    reloadCustomer();
  };
  return (
    <>
      {subToCancel && (
        <CancelSubscription
          sub={subToCancel}
          onModalClose={hideCancelSubscriptionModal}
        />
      )}
      <Modal
        visible={!subToCancel && modalVisibile}
        footer={null}
        closable={true}
        centered={true}
        onCancel={closeModal}
        afterClose={!subToCancel ? afterCloseModal : undefined}
      >
        <Box>
          <Heading variant="h3" as="h3" textAlign="center" mb={4}>
            Manage your Subscriptions
          </Heading>
          <Skeleton
            css={css`
              margin-top: 16px;
            `}
            active={true}
            loading={isLoading}
            avatar={true}
          />
          {!isLoading && subscriptions.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Subscriptions associated with your account"
            />
          )}
          {subscriptions.map((sub) => (
            <SubscriptionDetails
              key={sub.id}
              sub={sub}
              stripeCustomerId={stripeCustomerId}
              onCancelRequest={requestCancelSubscription}
            />
          ))}
        </Box>
      </Modal>
    </>
  );
};

const formatCentsAsDollars = (num: number) => (num / 100).toFixed(2);

interface ISubscriptionDetailsProps {
  sub: ISubWithApp;
  stripeCustomerId: string;
  onCancelRequest: (ISubWithApp) => void;
}

const SubscriptionDetails: React.SFC<ISubscriptionDetailsProps> = ({
  sub,
  stripeCustomerId,
  onCancelRequest,
}) => {
  const { upcomingInvoice } = useRetrieveUpcomingInvoice(
    stripeCustomerId,
    sub.cancel_at ? null : sub.id
  );

  if (!(sub && sub.app)) {
    return <Skeleton active={true} avatar={true} />;
  }

  const cancelSubClick = () => {
    track({ event: "Subscription Cancel Request (1/2)" });
  };

  const cancelSubConfirm = () => {
    track({ event: "Subscription Cancel Request (2/2)" });
    onCancelRequest(sub);
  };

  return (
    <Row
      gutter={16}
      justify="space-around"
      align="middle"
      css={css`
        margin: 15px 0;
        padding: 15px 0;
        border-bottom: 2px #eee solid;
        &:last-child {
          border-bottom: none;
        }
      `}
    >
      <Col span={6}>
        <AppIconIcon iconUrl={getAppIcon(sub.app)} />
      </Col>
      {sub.cancel_at ? (
        <Col span={12}>
          <div>
            {getAppName(sub.app)}
            <SubscriptionStatusLabel status="cancelled" planId={sub.plan.id} />
          </div>
          <small>
            Subscription ends{" "}
            <b>
              <ReactTimeAgo date={sub.cancel_at * 1000} />
            </b>
          </small>
        </Col>
      ) : (
        <Col span={12}>
          <div>
            {getAppName(sub.app)}
            <SubscriptionStatusLabel status={sub.status} planId={sub.plan.id} />
          </div>
          <div title={`${sub.plan.nickname}`}>
            <Span fontSize={4} color="#111" fontWeight={6}>
              {upcomingInvoice ? (
                `$${formatCentsAsDollars(upcomingInvoice.amount_due)}`
              ) : (
                <Span color="#ddd">Loading next invoice…</Span>
              )}
            </Span>{" "}
            <Span fontSize={0} hidden={!upcomingInvoice}>
              /{sub.plan.interval}
            </Span>
          </div>
          <small>
            Next payment{" "}
            <b>
              <ReactTimeAgo date={sub.current_period_end * 1000} />
            </b>
          </small>
        </Col>
      )}
      <Col
        span={6}
        css={css`
          text-align: center;
        `}
      >
        {!sub.cancel_at && (
          <Popconfirm
            title="Are you sure you want to cancel your subscription?"
            onConfirm={cancelSubConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              css={css`
                margin: 0 auto;
              `}
              onClick={cancelSubClick}
            >
              Cancel
            </Button>
          </Popconfirm>
        )}
      </Col>
    </Row>
  );
};

interface ICancelSubscriptionProps {
  sub: ISubWithApp;
  onModalClose: () => void;
}
const CancelSubscription: React.SFC<ICancelSubscriptionProps> = ({
  sub,
  onModalClose,
}) => {
  const [modalVisibile, setModalVisibility] = useState(true);
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationError, setCancellationError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    setModalVisibility(false);
  };

  const confirmCancel = async () => {
    if (cancellationReason.length === 0) {
      setCancellationError("Please provide a cancellation reason");
      return;
    }
    setCancellationError(null);
    try {
      setLoading(true);
      await cancelSubscription({ subscriptionId: sub.id, cancellationReason });
      track({ event: "Subscription Cancelled Successfully" });
      closeModal();
    } catch (err) {
      Modal.error({
        title: "Error cancelling subscription",
        content: err.message,
      });
    }
    setLoading(false);
  };

  const changeCancellationReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCancellationReason(event.currentTarget.value);
  };
  return (
    <Modal
      visible={modalVisibile}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={onModalClose}
    >
      <Space direction="column" size="md" width="100%">
        <Heading variant="h3" as="h3" textAlign="center">
          Cancel {getAppName(sub.app)}
        </Heading>
        <Heading variant="h4" as="h4" textAlign="center">
          Sorry to see you go 😢
        </Heading>
        {sub.app.appType !== "electron" && (
          <Alert
            message="What this means:"
            description={
              <ul>
                <li>Auto-update will no longer work.</li>
                <li>Your app will continue to run for 2-3 months.</li>
                <li>
                  We will retire the app after this period for security reasons.
                </li>
                <li>After 2-3 months your app will no longer run.</li>
              </ul>
            }
            type="error"
            css={css`
              && .ant-alert-description {
                color: rgba(0, 0, 0, 0.85);
              }
            `}
          />
        )}
        <Form.Item
          label="Reason for cancellation"
          hasFeedback={!!cancellationError}
          validateStatus={cancellationError ? "error" : null}
          help={cancellationError}
          css={css`
            && {
              margin-bottom: 20px;
            }
          `}
        >
          <TextArea
            value={cancellationReason}
            autoFocus={true}
            onChange={changeCancellationReason}
          />
        </Form.Item>
        <div
          css={css`
            text-align: right;
          `}
        >
          <Button onClick={closeModal}>No, don't cancel</Button> &nbsp;&nbsp;
          <Button loading={isLoading} danger onClick={confirmCancel}>
            Yes, cancel
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

const getAppName = (app: IApp | IApp2) =>
  isTDBuilderApp(app) ? app.desktopApp.name : app?.name || "";

const getAppIcon = (app: IApp | IApp2) =>
  isTDBuilderApp(app) ? app.desktopApp.icon : app?.icon || defaultIcon;

export default ManageSubscription;
