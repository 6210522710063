import React from "react";
import { useStore, selectedApp } from "../../store";
import css from "@emotion/css";
import styled from "@emotion/styled";
import { AppleFilled } from "@ant-design/icons";
import wifiIcon from "../../~reusables/images/menubar-icons/wifi.png";
import userIcon from "../../~reusables/images/menubar-icons/user.png";
import batteryIcon from "../../~reusables/images/menubar-icons/battery.svg";
import bluetoothIcon from "../../~reusables/images/menubar-icons/bluetooth.png";
import audioIcon from "../../~reusables/images/menubar-icons/audio.png";
import CurrentTime from "../atoms/CurrentTime";

const MenubarContainer = styled.nav`
  font-family: "SF Display", sans-serif;
  padding: 0;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 22px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0.33em rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  transform: translate3d(0px, 0px, 0px);
  z-index: 2;
`;

interface IListProps {
  alignment?: "left" | "right";
}
const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  ${(props: IListProps) => props.alignment === "right" && "right: 0;"}
`;

const Item = styled.li`
  display: inline-flex;

  span {
    cursor: default;
    height: 20px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-decoration: none;
    color: black !important;
    position: relative;
    line-height: 1.3;
    z-index: 2;
  }
`;

const Menubar: React.SFC = () => {
  const app = useStore(selectedApp);

  return app ? (
    <MenubarContainer>
      <List>
        <Item>
          <span
            css={css`
              font-size: 1.15em;
              margin-left: 0.3em;
              padding-top: 0.05em;
              padding-bottom: 0.2em;
              line-height: 1.1;
            `}
          >
            <AppleFilled />
          </span>
        </Item>
        <Item>
          <span
            css={css`
              font-weight: bold;
            `}
          >
            {app.name}
          </span>
        </Item>
        <Item>
          <span>File</span>
        </Item>
        <Item>
          <span>Edit</span>
        </Item>
        <Item>
          <span>View</span>
        </Item>
        <Item>
          <span>Help</span>
        </Item>
      </List>

      <List alignment="right">
        <Item>
          <span>
            <img src={app.menubarIcon} height="20" width="20" />
          </span>
        </Item>

        <Item>
          <span>
            <img src={audioIcon} height="18" width="18" />
          </span>
        </Item>
        <Item>
          <span>
            <img src={bluetoothIcon} height="20" width="20" />
          </span>
        </Item>
        <Item>
          <span>
            <img src={batteryIcon} height="20" width="20" />
          </span>
        </Item>
        <Item>
          <span>
            <img src={wifiIcon} height="20" width="20" />
          </span>
        </Item>
        <Item>
          <span>
            <CurrentTime />
          </span>
        </Item>
        <Item>
          <span>
            <img src={userIcon} height="18" width="18" />
          </span>
        </Item>
      </List>
    </MenubarContainer>
  ) : null;
};

export default Menubar;
