import React, { SFC } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import recycleBinImg from "../../~reusables/images/icons/recycle-bin.png";
import StartMenu from "../molecules/StartMenu";
import { AppIconIcon, WinAppTitle } from "./AppIconIcon";

type modeEnum = "light" | "dark";

interface IAppTitleProps {
  mode?: modeEnum;
}
const AppTitle = styled.div`
  color: ${({ mode }: IAppTitleProps) => (mode === "dark" ? "#fff" : "#000")};
  background: ${({ mode }: IAppTitleProps) =>
    mode === "dark" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.6)"};
  display: inline-block;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 5px;
  user-select: none;
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
    Helvetica Neue, Arial, sans-serif;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IBasicProps {
  iconUrl?: string;
  title?: string;
  mode?: modeEnum;
  titleBelow?: boolean;
  iconStyle?: {};
  style?: {};
}
const MacAppIcon: SFC<IBasicProps> = ({
  iconUrl,
  title,
  mode = "light",
  titleBelow = false,
  iconStyle,
  style,
}) => (
  <div style={{ textAlign: "center", ...style }}>
    {titleBelow ? (
      <>
        <AppIconIcon src={iconUrl} titleBelow={titleBelow} style={iconStyle} />
        <br />
        <AppTitle mode={mode}>{title}</AppTitle>
      </>
    ) : (
      <>
        <AppTitle mode={mode}>{title}</AppTitle>
        <br />
        <AppIconIcon src={iconUrl} titleBelow={titleBelow} style={iconStyle} />
      </>
    )}
  </div>
);

export const WindowsAppIcon: SFC<IBasicProps> = ({
  iconUrl,
  title,
  mode = "light",
  iconStyle,
  style,
}) => (
  <>
    <div
      style={{
        textAlign: "center",
        width: 90,
        paddingTop: "20px",
        paddingBottom: "5px",
        ...style,
      }}
    >
      <AppIconIcon
        src={recycleBinImg}
        titleBelow={true}
        windows={true}
        size={50}
      />
      <br />
      <WinAppTitle>Recycle Bin</WinAppTitle>
    </div>
    <div
      style={{
        textAlign: "center",
        width: 90,
        paddingTop: "20px",
        paddingBottom: "5px",
      }}
    >
      <AppIconIcon
        src={iconUrl}
        titleBelow={true}
        windows={true}
        size={50}
        style={iconStyle}
      />
      <br />
      <WinAppTitle>{title}</WinAppTitle>
    </div>
    <StartMenu showTray={false}>
      <div
        css={css`
          position: absolute;
          left: 210px;
          top: 7px;
        `}
      >
        <AppIconIcon
          src={iconUrl}
          titleBelow={true}
          windows={true}
          size={26}
          iconStyle={iconStyle}
        />
      </div>
    </StartMenu>
  </>
);

export const LinuxAppIcon: SFC<IBasicProps> = ({
  iconUrl,
  title,
  mode = "light",
  iconStyle,
  style,
}) => (
  <>
    <div
      style={{
        textAlign: "center",
        width: 90,
        paddingTop: "20px",
        paddingBottom: "5px",
        ...style,
      }}
    >
      <AppIconIcon
        src={recycleBinImg}
        titleBelow={true}
        windows={true}
        size={50}
      />
      <br />
      <WinAppTitle>Recycle Bin</WinAppTitle>
    </div>
    <div
      style={{
        textAlign: "center",
        width: 90,
        paddingTop: "20px",
        paddingBottom: "5px",
      }}
    >
      <AppIconIcon
        src={iconUrl}
        titleBelow={true}
        windows={true}
        size={50}
        style={iconStyle}
      />
      <br />
      <WinAppTitle>{title}</WinAppTitle>
    </div>
    <StartMenu showTray={false}>
      <div
        css={css`
          position: absolute;
          left: 210px;
          top: 7px;
        `}
      >
        <AppIconIcon
          src={iconUrl}
          titleBelow={true}
          windows={true}
          size={26}
          iconStyle={iconStyle}
        />
      </div>
    </StartMenu>
  </>
);

interface IAppIcon extends IBasicProps {
  os?: "windows" | "mac";
}
const AppIcon: SFC<IAppIcon> = (props) =>
  props.os === "windows" ? (
    <WindowsAppIcon {...props} />
  ) : (
    <MacAppIcon {...props} />
  );

export default AppIcon;
