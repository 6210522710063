import React from "react";
import css from "@emotion/css";
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";

interface IPayButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  icon?: any;
}

const PayButton: React.SFC<IPayButtonProps> = ({
  children,
  loading,
  icon: Icon,
  ...props
}) => {
  return (
    <button
      disabled={!!loading}
      css={css`
        display: block;
        margin: 12px auto;
        background-color: hsl(213, 100%, 60%);
        color: white;
        border: none;
        padding: 8px 15px;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        ${!loading &&
        css`
          &:hover {
            transform: translateY(-1px);
            background-color: hsl(213, 100%, 65%);
          }
          &:active {
            transform: translateY(1px);
          }
        `}
      `}
      {...props}
    >
      <div
        css={css`
          float: left;
          margin-right: 20px;
        `}
      >
        {loading ? (
          <LoadingOutlined spin />
        ) : Icon ? (
          <Icon
            css={css`
              font-size: 24px;
            `}
          />
        ) : (
          <LockOutlined
            css={css`
              font-size: 24px;
            `}
          />
        )}
      </div>
      {children}
    </button>
  );
};

export default PayButton;
