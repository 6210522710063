// libs
import React, { ChangeEvent, useState } from "react";
import { Build, ReleaseRedirectionRule } from "@todesktop/shared";

// components
import { Alert, Button, message, Modal, Popconfirm } from "antd";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { FormInput } from "../atoms/FormUtils";
import { Container, Flex } from "../atoms/Primitives";
import { ReleaseEligibilityPopover } from "./ReleaseEligibilityPopover";
import Confetti from "react-dom-confetti";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

// utils
import { track } from "../../~reusables/util/analytics";
import { callFirebaseFunction } from "../../~reusables/firebase";
import { useStore, selectedApp } from "../../store";
import { selectedReleasedBuild } from "../../~reusables/actions/builds";
import {
  determineReleaseEligibility,
  MIN_RUNTIME_VERSION_FOR_DOWNGRADE,
} from "../../~reusables/util/determineReleaseEligibility";
import {
  isBuildSupportsSmokeTests,
  isSmokeTestRunning,
  smokeTestHasError,
  RUNTIME_VERSION_SUPPORTING_SMOKE_TESTS,
} from "../../~reusables/util/smokeTest";
import { useHistory } from "react-router";
import { ButtonProps } from "antd/lib/button";

const { confirm } = Modal;

const releaseBuild = callFirebaseFunction("releaseBuild");

const smokeTestAlertText = (build: Build) => {
  if (!isBuildSupportsSmokeTests(build)) {
    return (
      <>
        This build <b>does not support smoke tests</b>. <br />
        Please update @todesktop/runtime to v
        {RUNTIME_VERSION_SUPPORTING_SMOKE_TESTS} or higher to enable smoke
        tests.
        <br />
        <br />
      </>
    );
  }

  if (isBuildSupportsSmokeTests(build) && !build.smokeTest) {
    return (
      <>
        This build supports smoke tests, <br /> you can run them before
        releasing.
        <br />
        <br />
      </>
    );
  }

  if (build.smokeTest.isCanceled) {
    return (
      <>
        This build has smoke tests that have been cancelled. <br /> Please run
        them again before releasing.
        <br />
        <br />
      </>
    );
  }

  if (isSmokeTestRunning(build)) {
    return (
      <>
        This build is currently running smoke tests, <br /> you can wait for
        them to finish before releasing.
        <br />
        <br />
      </>
    );
  }

  if (smokeTestHasError(build)) {
    return (
      <>
        This build has smoke tests that have failed. <br /> Please fix them
        before releasing.
        <br />
        <br />
      </>
    );
  }

  return null;
};

export type ReleaseBuildButtonProps = {
  build: Build;
  partialReleaseOptions?: {
    redirection: ReleaseRedirectionRule;
  };
  onComplete: () => void;
} & ButtonProps;

export const ReleaseBuildButton: React.FC<ReleaseBuildButtonProps> = ({
  build,
  partialReleaseOptions,
  children,
  onComplete,
  ...props
}) => {
  const [state, setState] = useState({ popConfirmOpen: false });
  const [loadingReleaseRequest, setLoadingReleaseRequest] = useState(false);
  const releasedBuild = useStore(selectedReleasedBuild);
  const [showConfetti, setShowConfetti] = useState(false);

  const theme = useTheme();

  const app = useStore(selectedApp);
  const { isDowngrade, isReleaseable } = determineReleaseEligibility(
    build,
    releasedBuild
  );

  const showSmokeTestConfirm =
    build.smokeTest?.isCanceled ||
    smokeTestHasError(build) ||
    isSmokeTestRunning(build);

  async function onReleaseClick() {
    try {
      await confirmDowngrade();
      await confirmSmokeTest();
      await fireReleaseBuild({ appId: app.id, buildId: build.id });
    } catch (e) {
      if (e && "action" in e) {
        track({ event: "Release Build", properties: { action: e.action } });
      } else {
        Modal.error({
          title: `Error Releasing ${build.appName}`,
          content: e instanceof Error ? e.message : JSON.stringify(e),
        });
      }
    }
  }

  async function fireReleaseBuild({
    appId,
    buildId,
  }: {
    appId: string;
    buildId: string;
  }) {
    try {
      track({
        event: "Release Build",
        properties: { action: "confirm" },
      });
      setLoadingReleaseRequest(true);
      await releaseBuild({ appId, buildId, partialReleaseOptions });
      setLoadingReleaseRequest(false);
      onComplete();
      setShowConfetti(true);
      message.success("Release successful");
    } catch (error) {
      setLoadingReleaseRequest(false);
      Modal.error({
        title: `Error Releasing ${build.appName}`,
        content: error.message,
      });
    }
  }

  async function confirmDowngrade() {
    return new Promise<void>((resolve, reject) => {
      if (!isDowngrade) {
        resolve();
        return;
      }

      const buildVersion = build.appVersion;
      const latestVersion = releasedBuild.appVersion;

      function onType(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.value.trim() === buildVersion) {
          update({ okButtonProps: { disabled: false } });
        }
      }

      setState((prev) => ({ ...prev, popConfirmOpen: false }));
      const { update } = confirm({
        title: "Downgrade the build?",
        icon: null,
        okButtonProps: { disabled: true },
        content: (
          <>
            <Alert
              message={
                <>
                  You&apos;re trying to release a build as version{" "}
                  {buildVersion}, which is <b>older</b> than the latest
                  published version {latestVersion}. This may cause issues if
                  your customers are using a version of your desktop app that
                  contains a version of @todesktop/runtime that is earlier than
                  v{MIN_RUNTIME_VERSION_FOR_DOWNGRADE}.
                </>
              }
              type="error"
              showIcon
            />

            <p css={{ marginTop: theme.space[7] }}>
              Are you sure that you wish to downgrade to an earlier version? Are
              you satisfied that previous versions of your app contain a version
              of @todesktop/runtime that is v{MIN_RUNTIME_VERSION_FOR_DOWNGRADE}{" "}
              or higher?
            </p>

            <div>
              To continue, type the text <strong>{buildVersion}</strong> below
              to confirm:
            </div>

            <FormInput name="confirmNewVersion" onChange={onType} />
          </>
        ),
        onOk() {
          resolve();
        },
        onCancel() {
          reject({ action: "cancel" });
        },
      });
    });
  }

  async function confirmSmokeTest() {
    return new Promise<void>((resolve, reject) => {
      if (!showSmokeTestConfirm) {
        resolve();
        return;
      }

      setState((prev) => ({ ...prev, popConfirmOpen: false }));
      confirm({
        title: "Release without smoke tests?",
        icon: <ExclamationCircleOutlined />,
        content:
          "Are you sure that you wish to release this build without smoke tests?",
        onOk() {
          resolve();
        },
        onCancel() {
          reject({ action: "cancel" });
        },
      });
    });
  }

  if (!build) return null;

  return (
    <>
      <Container left="50%" top="20%" zIndex={1} position="fixed">
        <Confetti
          active={showConfetti}
          config={{
            angle: 90,
            spread: 70,
            startVelocity: 30,
          }}
        />
      </Container>
      <Popconfirm
        icon={null}
        open={state.popConfirmOpen}
        onOpenChange={(popConfirmOpen) => {
          setState((prev) => ({ ...prev, popConfirmOpen }));
        }}
        disabled={!isReleaseable}
        onCancel={() =>
          track({
            event: "Release Build",
            properties: { action: "cancel" },
          })
        }
        okText="Release"
        onConfirm={onReleaseClick}
        title={
          partialReleaseOptions ? (
            <>
              {smokeTestAlertText(build)}
              This will release {build.appName} v{build.appVersion}
              <br />{" "}
              {partialReleaseOptions.redirection.rule === "buildByIp"
                ? "to the specified IP addresses."
                : "to the selected platforms."}
            </>
          ) : (
            <>
              {smokeTestAlertText(build)}
              This will release {build.appName} at v{build.appVersion}, are you
              sure?
              <br />
              Your users will be auto-updated to this version.
            </>
          )
        }
      >
        <Flex style={{ flexDirection: "column" }}>
          <Button
            disabled={!isReleaseable}
            loading={loadingReleaseRequest}
            type="primary"
            style={{ width: "100%" }}
            {...props}
          >
            {children}
          </Button>
        </Flex>
      </Popconfirm>
    </>
  );
};

export const PrepareReleaseButton: React.FC<{
  build: Build;
}> = ({ build }) => {
  const history = useHistory();
  const app = useStore(selectedApp);
  const releasedBuild = useStore(selectedReleasedBuild);
  const {
    isLatestRelease,
    isLoading,
    isReleaseable,
    isPreparable,
  } = determineReleaseEligibility(build, releasedBuild);

  let Icon = CloseCircleOutlined;
  if (isReleaseable) Icon = CheckCircleOutlined;
  if (isPreparable) Icon = ExclamationCircleOutlined;
  if (isLoading) Icon = InfoCircleOutlined;
  if (isLatestRelease) Icon = CheckCircleOutlined;
  if (!build) return null;

  return (
    <ReleaseEligibilityPopover build={build}>
      <Button
        disabled={!isPreparable}
        type="primary"
        icon={<Icon />}
        style={{ width: "100%" }}
        onClick={() => {
          history.push(`/apps/${app.id}/builds/${build.id}/release`);
        }}
      >
        {isLatestRelease ? "View release" : "Prepare release"}
      </Button>
    </ReleaseEligibilityPopover>
  );
};
