import type firebase from "firebase/app";
import {
  getData,
  updateOrCreateData,
  getCollectionSnapshotWhere,
  deleteDoc,
} from "./common";
import { firestore } from "./firestore";
import { getCurrentUser } from "./auth";
import { UserIHaveSentInviteTo } from "@todesktop/shared";

export const getInvitedUserData = (id: string, email: string) =>
  getData<UserIHaveSentInviteTo>(
    firestore.doc(`/users/${id}/invitedUsers/${email}`)
  );

export const getInvitedUsers = (id: string) =>
  firestore.collection(`users/${id}/invitedUsers`);

export const onInvitedUsersUpdate = (
  id: string,
  handler: (
    arg: UserIHaveSentInviteTo[],
    snapshot?: firebase.firestore.QuerySnapshot
  ) => void
) => {
  if (id) {
    return getCollectionSnapshotWhere<UserIHaveSentInviteTo>(
      getInvitedUsers(id),
      { orderBy: { fieldPath: "email", direction: "desc" } },
      handler
    );
  }
};

const getInvitedUser = (id: string, email: string) =>
  firestore.doc(`users/${id}/invitedUsers/${email}`);

export const upsertInvitedUser = async (
  email: string,
  user: Partial<UserIHaveSentInviteTo>
) => {
  const userId = getCurrentUser().uid;
  await updateOrCreateData<UserIHaveSentInviteTo>(
    getInvitedUser(userId, email),
    {
      ...user,
    }
  );
};

export const deleteInvitedUser = async (email: string) => {
  const userId = getCurrentUser().uid;
  await deleteDoc(getInvitedUser(userId, email));
};
