// modules
import React from "react";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";
import { Link } from "react-router-dom";
import css from "@emotion/css";

// components
import { RightOutlined } from "@ant-design/icons";
import AppIcon from "../atoms/AppIcon";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { Space } from "../atoms/Space";
import { IApp, IApp2 } from "@todesktop/shared";

// logic

const AppSnippet: React.FC<{ app: IApp | IApp2 }> = ({ app }) => {
  const { colors, space } = useTheme();
  let details: { title: string; subtitle: string; icon: string };
  if (app.schemaVersion >= 2) {
    const { desktopApp } = app as IApp2;
    details = {
      icon: desktopApp.icon,
      title: desktopApp.name,
      subtitle: "ToDesktop Builder",
    };
  } else {
    const { name, icon, appType } = app as IApp;
    details = {
      title: name,
      icon: icon,
      subtitle:
        appType === "electron"
          ? "ToDesktop for Electron developers"
          : `ToDesktop Web${appType === "menubar" ? " (menubar)" : ""}`,
    };
  }

  return (
    <StyledAppSnippet to={`/apps/${app.id}`}>
      <section
        css={css`
          display: flex;
          align-items: center;
          h4,
          p {
            margin-left: ${space[5]}px;
          }
        `}
      >
        <AppIcon iconUrl={details.icon} />
        <Space direction="column" align="flex-start" size="xs">
          <Heading variant="h4" as="h4">
            {details.title}
          </Heading>
          <Text
            css={css`
              background: ${colors.blueGradient};
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            `}
          >
            {details.subtitle}
          </Text>
        </Space>
      </section>
      <section
        css={css`
          display: flex;
          align-items: center;
          p {
            margin-right: ${space[5]}px;
          }
        `}
      >
        <RightOutlined
          style={{
            color: colors.support,
            fontSize: "16px",
          }}
          type="right"
        />
      </section>
    </StyledAppSnippet>
  );
};

const StyledAppSnippet = styled(Link)`
  padding: ${(p) => `${p.theme.space[5]}px ${p.theme.space[7]}px`};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.colors.greys[9]};
  }

  h4,
  p {
    margin-bottom: 0;
  }
`;

export default AppSnippet;
