import firebase from "firebase/app";
import "firebase/functions";
import { stage } from "./config";

// Uncomment below to run local functions
if (process.env.NODE_ENV === "development") {
  firebase.functions().useEmulator("localhost", 5001);
}

export const callFirebaseFunction = (functionName: string) =>
  firebase.functions().httpsCallable(functionName);

if (stage === "dev") {
  interface ExtendedGlobal extends NodeJS.Global {
    callFirebaseFunction?: typeof callFirebaseFunction;
  }
  (global as ExtendedGlobal).callFirebaseFunction = callFirebaseFunction;
}

export const getAmplitudeMetrics = callFirebaseFunction("getAmplitudeMetrics");
