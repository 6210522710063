import styled from "@emotion/styled";
import { Spin } from "antd";
import React from "react";

const SpinContainer = styled.div`
  text-align: center;
`;

const Spinner = () => (
  <SpinContainer>
    <Spin size="large" />
  </SpinContainer>
);

export default Spinner;
