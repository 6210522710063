import { Col, Input, Row, Form } from "antd";
import React, { SFC, useState, useEffect, useContext, useRef } from "react";
import QuestionTooltip from "./QuestionTooltip";
// import useAppField from './util/useAppField';
import { AppFieldsContext } from "../../~reusables/contexts/AppFieldsContext";
import { InputProps } from "antd/lib/input";
import css from "@emotion/css";
import { Box } from "./Primitives";

interface IInputFieldProps {
  label: string;
  hiddenHelpText?: string | JSX.Element;
  last?: boolean;
  validationSchema?: any;
  value?: string;
  onChange: (value: string) => void;
  onImmediateChange?: (value: string) => void;
  hideLabel?: boolean;
  size?: InputProps["size"];
  simple?: boolean;
  dontValidateOnChangeUnlessErrored?: boolean;
  [x: string]: any;
  autoCapitalize?: boolean;
}

const InputField: SFC<IInputFieldProps> = ({
  label,
  hiddenHelpText,
  validationSchema,
  last,
  onChange,
  onImmediateChange,
  value,
  hideLabel = false,
  size = "middle",
  simple = false,
  dontValidateOnChangeUnlessErrored = false,
  autoCapitalize = false,
  ...inputFieldProps
}) => {
  const inputRef = useRef(undefined);
  const [fieldValue, setFieldValue] = useState(value);
  const [isFieldFocused, setFieldFocus] = useState(false);

  useEffect(() => {
    if (!isFieldFocused) {
      valueChangeHandler(value);
    }
  }, [value]);

  useEffect(() => {
    if (onImmediateChange) {
      onImmediateChange(fieldValue);
    }
  }, [fieldValue, onImmediateChange]);

  const onFocus = () => {
    setFieldFocus(true);
  };
  const onBlur = async () => {
    setFieldFocus(false);
    ctx.updateFieldValue(label, fieldValue);
    try {
      const validValue = validationSchema
        ? await validationSchema.validate(fieldValue)
        : fieldValue;
      onChange(validValue);
      setFieldError(null);
    } catch (error) {
      setFieldError(error.message);
    }
  };

  type MaybeString = string | undefined | null;
  const initialError: MaybeString = null;
  const [fieldError, setFieldError] = useState(initialError);

  const ctx = useContext(AppFieldsContext);
  useEffect(() => {
    ctx.registerField({
      id: label,
      validationSchema,
      value,
      setFieldError,
      inputRef,
    });
    return function cleanup() {
      ctx.unregisterField(label);
    };
  }, [label]);

  const inputChangeHandler = async (e: React.FormEvent<HTMLInputElement>) => {
    const { value: val } = e.currentTarget;
    valueChangeHandler(autoCapitalize ? val.toUpperCase() : val);
  };
  const valueChangeHandler = async (newValue: string) => {
    setFieldValue(newValue);
    if (dontValidateOnChangeUnlessErrored && !fieldError) {
      // Don't validate the field on change
      // UNLESS that field is already errored
      return;
    }
    try {
      if (validationSchema) {
        await validationSchema.validate(newValue);
      }
      setFieldError(null);
    } catch (error) {
      setFieldError(error.message);
    }
  };

  const formItem = (
    <Form.Item
      help={fieldError}
      colon={false}
      label={
        hideLabel ? null : (
          <>
            {label}
            {hiddenHelpText && <QuestionTooltip text={hiddenHelpText} />}
          </>
        )
      }
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      labelAlign="left"
      validateStatus={fieldError ? "error" : undefined}
      hasFeedback={true}
    >
      <Input
        ref={inputRef}
        value={fieldValue}
        size={size}
        onChange={inputChangeHandler}
        onBlur={onBlur}
        onFocus={onFocus}
        {...inputFieldProps}
        css={css`
          .ant-input-suffix {
            display: ${fieldError ? "none" : "auto"};
          }
        `}
      />
    </Form.Item>
  );

  if (simple) {
    return <Box mb={fieldError ? 0 : "-15px"}>{formItem}</Box>;
  }

  return (
    <Row
      gutter={32}
      align="middle"
      css={{
        marginBottom: last ? "-30px" : 0,
      }}
    >
      <Col span={24}>{formItem}</Col>
    </Row>
  );
};

export default InputField;
