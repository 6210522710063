import React from "react";
import { Button, Form, Input, message } from "antd";
import {
  callFirebaseFunction,
  upsertInvitedUser,
} from "../../~reusables/firebase";
import { useStore } from "../../store";
import merge from "lodash.merge";

const sendInviteUserEmail = callFirebaseFunction("sendInviteUserEmail");

export const InviteUserForm: React.FC = () => {
  const [form] = Form.useForm();
  const sender = useStore((state) => ({
    id: state.user.id,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    email: state.user.email,
  }));

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onFinish = async (values: { name: string; email: string }) => {
    const fallbackName = capitalize(sender.email.split("@")[0] || "Unknown");

    const data = {
      name: values.name,
      email: values.email.toLowerCase(),
      permissions: "default",
      // left-hand side acts as defaults if right-hand keys are undefined
      sender: merge({ firstName: fallbackName, lastName: "" }, sender),
    };

    try {
      await sendInviteUserEmail(data);
      await upsertInvitedUser(data.email, data);
      message.success(`Sent invite to ${data.email}`);
    } catch (err) {
      message.error(err.message);
    }
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="inline"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Please input an email", type: "email" },
        ]}
        style={{ marginRight: "12px" }}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input a name" }]}
        style={{ marginRight: "12px" }}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Invite
        </Button>
      </Form.Item>
    </Form>
  );
};
