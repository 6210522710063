// libs
import React from "react";
import { Build } from "@todesktop/shared";

// components
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Container, Flex, Box } from "../atoms/Primitives";
import { Text } from "../atoms/Text";
import { Popover } from "antd";

// utils
import { useStore } from "../../store";
import { selectedReleasedBuild } from "../../~reusables/actions/builds";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import {
  determineReleaseEligibility,
  EligibilityState,
  getReleaseEligibilityMessages,
} from "../../~reusables/util/determineReleaseEligibility";
import { isBuildSupportsSmokeTests } from "../../~reusables/util/smokeTest";

export const ReleaseEligibilityPopover: React.FC<{
  build: Build;
}> = ({ build, children }) => {
  const { colors, space } = useTheme();
  const releasedBuild = useStore(selectedReleasedBuild);

  const releaseEligibility = determineReleaseEligibility(build, releasedBuild);
  const {
    eligibility,
    isLatestRelease,
    isDowngrade,
    isReleaseable,
    isLoading,
  } = releaseEligibility;

  let [color, Icon, title] = [
    colors.danger,
    CloseCircleOutlined,
    "This build cannot be released",
  ];

  if (isReleaseable) {
    color = colors.success;
    Icon = CheckCircleOutlined;
    title = "This build can be released";
  }

  if (isLoading) {
    color = colors.title;
    Icon = LoadingOutlined;
    title = "Determining release eligibility";
  }

  if (
    (eligibility.smokeTestState === EligibilityState.ineligible ||
      eligibility.smokeTestState === EligibilityState.loading) &&
    isReleaseable
  ) {
    color = colors.warning;
    Icon = InfoCircleOutlined;
    title = "Build can be released at your caution";
  }

  if (eligibility.releaseState === EligibilityState.ineligible) {
    title = "This build has already been released";
  }

  const messages = getReleaseEligibilityMessages(
    releaseEligibility,
    build,
    releasedBuild
  );

  return (
    <Popover
      // Inline styles weren't working, className targeted in App.globalCSS.ts
      open={isLatestRelease ? false : undefined}
      overlayClassName="release-eligibility-popover"
      content={
        <Container bg="white" flexDirection="column">
          <Flex padding={6} alignItems="center">
            <Icon spin={isLoading} style={{ fontSize: 20, color }} />
            <Text color="title" ml={space[4]}>
              {title}
            </Text>
          </Flex>
          <Box>
            <EligibilityItem
              eligibilityState={eligibility.buildState}
              eligibleMessage={messages.buildStateSuccess}
              loadingMessage={messages.buildStateProgress}
              ineligibleMessage={messages.buildStateError}
            />
            <EligibilityItem
              eligibilityState={eligibility.buildState}
              eligibleMessage="Auto-updates are ready"
              loadingMessage="Auto-updates have not been prepared yet"
              ineligibleMessage="Auto-update have not been prepared"
            />
            <EligibilityItem
              eligibilityState={eligibility.codeSignState}
              eligibleMessage={messages.codeSignStateSuccess}
              loadingMessage={messages.codeSignStateProgress}
              ineligibleMessage={messages.codeSignStateError}
            />

            {!isDowngrade && (
              <EligibilityItem
                eligibilityState={eligibility.versionState}
                eligibleMessage={messages.versionStateSuccess}
                loadingMessage={messages.versionStateProgress}
                ineligibleMessage={messages.versionStateError}
              />
            )}

            {isBuildSupportsSmokeTests(build) && build.smokeTest && (
              <EligibilityItem
                eligibilityState={eligibility.smokeTestState}
                eligibleMessage={messages.smokeTestStateSuccess}
                loadingMessage={messages.smokeTestStateProgress}
                ineligibleMessage={messages.smokeTestStateError}
              />
            )}
          </Box>
        </Container>
      }
    >
      {children}
    </Popover>
  );
};

const EligibilityItem: React.FC<{
  eligibilityState: EligibilityState;
  eligibleMessage: string;
  ineligibleMessage: string;
  loadingMessage: string;
}> = ({
  eligibilityState,
  eligibleMessage,
  ineligibleMessage,
  loadingMessage,
}) => {
  const { colors, space } = useTheme();

  let [color, Icon, message] = [
    colors.danger,
    CloseOutlined,
    ineligibleMessage,
  ];

  if (eligibilityState === EligibilityState.eligible) {
    color = colors.success;
    Icon = CheckOutlined;
    message = eligibleMessage;
  }

  if (eligibilityState === EligibilityState.loading) {
    color = colors.text;
    Icon = LoadingOutlined;
    message = loadingMessage;
  }

  return (
    <Flex
      backgroundColor="greys.8"
      borderTop="1px solid"
      borderTopColor="greys.6"
      alignItems="center"
      paddingX={6}
      paddingY={4}
    >
      <Icon
        style={{ paddingLeft: space[3], color }}
        spin={eligibilityState === EligibilityState.loading}
      />
      <Text ml={5} variant="body3">
        {message}
      </Text>
    </Flex>
  );
};
