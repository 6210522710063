import React from "react";
import styled from "@emotion/styled";
import { Modal } from "antd";
import * as urlJoin from "url-join";

const Pre = styled.pre`
  overflow: scroll;
  font-family: Inconsolata, "Bitstream Vera Sans Mono", Consolas, Courier,
    monospace;
  background: #494f57;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 3px;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
  margin: 5px 0;
  margin-bottom: 15px;
`;

const Code = styled.code`
  font-family: Inconsolata, "Bitstream Vera Sans Mono", Consolas, Courier,
    monospace;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
  background: #494f57;
  padding: 2px 5px;
  border-radius: 3px;
  white-space: nowrap;
`;

const VarCode = styled.span`
  color: #f3712b;
`;
const KeywordCode = styled.span`
  color: #ffcc64;
`;
const FnCode = styled.span`
  color: #6aade0;
`;
export const showDeeplinkHelp = (
  protocol: string,
  url: string,
  name: string
) => {
  Modal.info({
    width: 580,
    maskClosable: true,
    title: "Using app protocol and deeplink",
    content: (
      <>
        <p>
          Protocols and deeplinks allow you to open your app using a hyperlink.
        </p>

        <h4>Simple Link</h4>
        <p>
          <b>
            <Code>{protocol}</Code> → <Code>{url}</Code>
          </b>
        </p>
        <p>
          This will open {name} app at the default location. If the app is
          already opened then the window will gain focus.
        </p>

        <h4>Deep Link</h4>
        <p>
          <b>
            <Code>{urlJoin(protocol, "foo", "bar")}</Code> →{" "}
            <Code>{urlJoin(url, "foo", "bar")}</Code>
          </b>
        </p>
        <p>
          Deeplinks allow you to specify a path to navigate to in your app. This
          will open {name} app at the <Code>{urlJoin("foo", "bar")}</Code> path.
        </p>
        <h4>Example</h4>
        <p>
          So, if you wanted to link from a website to a deeplink within {name}{" "}
          app then you could do something like this:
        </p>
        <Pre>
          &lt;<FnCode>a</FnCode> <VarCode>href</VarCode>=&quot;
          <KeywordCode>{urlJoin(protocol, "foo", "bar")}</KeywordCode>
          &quot;&gt;Open FooBar on {name} app&lt;/
          <KeywordCode>a</KeywordCode>&gt;
        </Pre>
      </>
    ),
  });
};

export const showDraggableTitlebarHelp = () => {
  Modal.info({
    width: 580,
    title: "Make transparent titlebar draggable",
    maskClosable: true,
    content: (
      <>
        <p>
          By default, a tranparent title bar is <i>not</i> draggable, you will
          need to add some CSS styles to your site to make the window draggable.
        </p>
        <p>
          You can give an element have the ability to <b>drag</b> the window by
          using{" "}
          <Code>
            <VarCode>-webkit-app-region</VarCode>: <FnCode>drag</FnCode>
          </Code>{" "}
          in css.
        </p>
        <p>
          If you want certain elements marked as <b>non-draggable</b> then you
          can use{" "}
          <Code>
            <VarCode>-webkit-app-region</VarCode>: <FnCode>no-drag</FnCode>
          </Code>
        </p>
        So, you might end up doing something like this:
        <Pre>
          <KeywordCode>html.todesktop .header</KeywordCode> {"{\n"}
          {"  "}
          <VarCode>-webkit-app-region</VarCode>: <FnCode>drag</FnCode>;{"\n"}
          {"}\n\n"}
          <KeywordCode>html.todesktop .header .nav</KeywordCode> {"{\n"}
          {"  "}
          <VarCode>-webkit-app-region</VarCode>: <FnCode>no-drag</FnCode>;{"\n"}
          {"}"}
          {"\n"}
        </Pre>
        The{" "}
        <Code>
          <KeywordCode>html.todesktop</KeywordCode>
        </Code>{" "}
        class pre-selector will ensure that the css is only applied when running
        as a desktop app.
      </>
    ),
  });
};
