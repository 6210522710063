import {
  AnalysisStatus,
  Arch,
  Build,
  BundlePhobiaItem,
  IApp,
  LinuxArtifactDownloads,
  LinuxArtifactName,
  MacArtifactDownloads,
  MacArtifactName,
  WindowsArtifactDownloads,
  WindowsArtifactName,
} from "@todesktop/shared";
import merge from "lodash.merge";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { selectedAppUser, useStore } from "../../../store";
import { selectedReleasedBuild } from "../../../~reusables/actions/builds";
import { setBuildData } from "../../../~reusables/firebase";
import { useDeepEffect } from "../../../~reusables/hooks/useDeep";
import { useDependencyAnalysisFunction } from "../../../~reusables/hooks/useFirebaseFunction";
import { DLStatsRequest, getDownloadLinkStats } from "../../../~reusables/util";
import { BuildArtifact } from "../../../~reusables/util/build";
import { IDownloadUrl, getDownloadUrl } from "../../../~reusables/util/urls";

export const useBuildArtifacts = (app: IApp, build: Build, release: Build) => {
  const releasedBuild = useStore(selectedReleasedBuild);
  const buildArtifacts: BuildArtifact[] = [];
  const releaseArtifacts: (BuildArtifact | null)[] = [];

  const pushBuildArtifacts = ({
    platform,
    architecture,
    artifactName,
    label,
    hasRelease,
    disabledTooltip = "",
  }: {
    platform: "linux" | "mac" | "windows";
    architecture: Arch;
    artifactName: LinuxArtifactName | MacArtifactName | WindowsArtifactName;
    label: string;
    hasRelease: boolean;
    disabledTooltip?: string;
  }) => {
    const artifactConfig = {
      key: `${platform}-${architecture}-${label}`,
      platform,
      architecture,
      artifact: label,
      disabledTooltip,
    };

    const urlConfig: IDownloadUrl = {
      appId: app.id,
      domain: app.customDomain ? app.customDomain : null,
      platform,
      arch: architecture,
      artifactName,
    };

    const statsConfig: DLStatsRequest = {
      arch: architecture,
      platform,
      artifactName,
      release: release,
    };

    buildArtifacts.push({
      ...artifactConfig,
      downloadUrl: getDownloadUrl({ ...urlConfig, buildId: build.id }),
      stats: getDownloadLinkStats(build, release, statsConfig),
    });

    releaseArtifacts.push(
      hasRelease
        ? {
            ...artifactConfig,
            downloadUrl: getDownloadUrl({ ...urlConfig, buildId: release.id }),
            stats: getDownloadLinkStats(release, null, statsConfig),
          }
        : null
    );
  };

  if (!build.mac?.shouldSkip && build.mac?.artifactDownloads) {
    const curr = build.mac.artifactDownloads as MacArtifactDownloads;
    const prev = release?.mac?.artifactDownloads as MacArtifactDownloads;

    if (curr.installer?.universal) {
      pushBuildArtifacts({
        platform: "mac",
        architecture: "universal",
        artifactName: "installer",
        label: "Installer",
        hasRelease: !!prev?.installer?.universal,
        disabledTooltip:
          releasedBuild?.id === build.id
            ? ""
            : "Only available for download on latest release",
      });
    }

    if (curr.dmg?.x64) {
      pushBuildArtifacts({
        platform: "mac",
        architecture: "x64",
        artifactName: "dmg",
        label: "DMG",
        hasRelease: !!prev?.dmg?.x64,
      });
    }

    if (curr.dmg?.arm64) {
      pushBuildArtifacts({
        platform: "mac",
        architecture: "arm64",
        artifactName: "dmg",
        label: "DMG",
        hasRelease: !!prev?.dmg?.arm64,
      });
    }

    if (curr.zip?.x64) {
      pushBuildArtifacts({
        platform: "mac",
        architecture: "x64",
        artifactName: "zip",
        label: "ZIP",
        hasRelease: !!prev?.zip?.x64,
      });
    }

    if (curr.zip?.arm64) {
      pushBuildArtifacts({
        platform: "mac",
        architecture: "arm64",
        artifactName: "zip",
        label: "ZIP",
        hasRelease: !!prev?.zip?.arm64,
      });
    }
  }

  if (!build.windows?.shouldSkip && build.windows?.artifactDownloads) {
    const curr = build.windows.artifactDownloads as WindowsArtifactDownloads;
    const prev = release?.windows
      ?.artifactDownloads as WindowsArtifactDownloads;

    if (curr.appx?.ia32) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "ia32",
        artifactName: "appx",
        label: "Windows Store",
        hasRelease: !!prev.appx?.ia32,
      });
    }

    if (curr.appx?.x64) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "x64",
        artifactName: "appx",
        label: "Windows Store",
        hasRelease: !!prev?.appx?.x64,
      });
    }

    if (curr.msi?.ia32) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "ia32",
        artifactName: "msi",
        label: "MSI",
        hasRelease: !!prev?.msi?.ia32,
      });
    }

    if (curr.msi?.x64) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "x64",
        artifactName: "msi",
        label: "MSI",
        hasRelease: !!prev?.msi?.x64,
      });
    }

    if (curr.nsis?.universal) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "universal",
        artifactName: "nsis",
        label: "NSIS",
        hasRelease: !!prev?.nsis?.universal,
      });
    }

    if (curr.nsis?.ia32) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "ia32",
        artifactName: "nsis",
        label: "NSIS",
        hasRelease: !!prev?.nsis?.ia32,
      });
    }

    if (curr.nsis?.x64) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "x64",
        artifactName: "nsis",
        label: "NSIS",
        hasRelease: !!prev?.nsis?.x64,
      });
    }

    if (curr.nsis?.arm64) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "arm64",
        artifactName: "nsis",
        label: "NSIS",
        hasRelease: !!prev?.nsis?.arm64,
      });
    }

    if (curr["nsis-web"]?.universal) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "universal",
        artifactName: "nsis-web",
        label: "NSIS Web",
        hasRelease: !!prev?.["nsis-web"]?.universal,
      });
    }

    if (curr["nsis-web"]?.ia32) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "ia32",
        artifactName: "nsis-web",
        label: "NSIS Web",
        hasRelease: !!prev?.["nsis-web"]?.ia32,
      });
    }

    if (curr["nsis-web"]?.x64) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "x64",
        artifactName: "nsis-web",
        label: "NSIS Web",
        hasRelease: !!prev?.["nsis-web"]?.x64,
      });
    }

    if (curr["nsis-web"]?.arm64) {
      pushBuildArtifacts({
        platform: "windows",
        architecture: "arm64",
        artifactName: "nsis-web",
        label: "NSIS Web",
        hasRelease: !!prev?.["nsis-web"]?.arm64,
      });
    }
  }

  if (!build.linux?.shouldSkip && build.linux?.artifactDownloads) {
    const curr = build.linux.artifactDownloads as LinuxArtifactDownloads;
    const prev = release?.linux?.artifactDownloads as LinuxArtifactDownloads;

    if (curr.appImage?.x64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "x64",
        artifactName: "appImage",
        label: "AppImage",
        hasRelease: !!prev?.appImage?.x64,
      });
    }

    if (curr.appImage?.arm64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "arm64",
        artifactName: "appImage",
        label: "AppImage",
        hasRelease: !!prev?.appImage?.arm64,
      });
    }

    if (curr.deb?.x64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "x64",
        artifactName: "deb",
        label: "Debian",
        hasRelease: !!prev?.deb?.x64,
      });
    }

    if (curr.deb?.arm64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "arm64",
        artifactName: "deb",
        label: "Debian",
        hasRelease: !!prev?.deb?.arm64,
      });
    }

    if (curr.rpm?.x64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "x64",
        artifactName: "rpm",
        label: "RPM",
        hasRelease: !!prev?.rpm?.x64,
      });
    }

    if (curr.rpm?.arm64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "arm64",
        artifactName: "rpm",
        label: "RPM",
        hasRelease: !!prev?.rpm?.arm64,
      });
    }

    if (curr.snap?.x64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "x64",
        artifactName: "snap",
        label: "Snap Store",
        hasRelease: !!prev?.snap?.x64,
      });
    }

    if (curr.snap?.arm64) {
      pushBuildArtifacts({
        platform: "linux",
        architecture: "arm64",
        artifactName: "snap",
        label: "Snap Store",
        hasRelease: !!prev?.snap?.arm64,
      });
    }
  }

  return { buildArtifacts, releaseArtifacts };
};

export const useDependencyAnalysisCall = (app: IApp, build?: Build) => {
  const analysisCall = useDependencyAnalysisFunction();

  useEffect(() => {
    if (!build?.id || build.dependencyAnalysis) return;
    analysisCall
      .callFn({ appId: app.id, buildId: build.id })
      .catch(console.error);
  }, [app.id, build?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return analysisCall;
};

export const useDependencyAnalysis = (build: Build, release?: Build) => {
  const analysis = useMemo(() => {
    const fallback: DependencyAnalysis = {
      hasLockFile: false,
      pmName: null,
      pmVersion: null,
      dependencies: {},
      devDependencies: {},
    };
    const buildResult = build?.dependencyAnalysis?.result;
    const releaseResult = release?.dependencyAnalysis?.result;

    const getAnalysisRows = (
      currentRows: DependencyAnalysis | undefined,
      previousRows: DependencyAnalysis | undefined
    ): DependencyAnalysisRow[] => {
      if (!currentRows) return [];

      const rows: DependencyAnalysisRow[] = [];
      Object.entries({
        dependencies: currentRows.dependencies,
        devDependencies: currentRows.devDependencies,
      }).forEach(([key, values]) => {
        const type = key as DependencyAnalysisRow["type"];

        const { dependencies, devDependencies } = previousRows;
        Object.entries(values).forEach(([dep, current]) => {
          const previous = dependencies?.[dep] || devDependencies?.[dep];

          rows.push({
            key: dep,
            name: dep,
            type,
            status: !previous
              ? "added"
              : previous.installedVersion === current.installedVersion
              ? "unchanged"
              : "updated",
            versions: { previous, current: current },
          });
        });
      });

      if (!previousRows) return rows;

      Object.entries({
        dependencies: previousRows.dependencies,
        devDependencies: previousRows.devDependencies,
      }).forEach(([key, values]) => {
        const type = key as DependencyAnalysisRow["type"];

        const { dependencies, devDependencies } = currentRows;
        Object.entries(values).forEach(([dep, previous]) => {
          const current = dependencies?.[dep] || devDependencies?.[dep];
          if (current) return;

          rows.push({
            key: dep,
            name: dep,
            type,
            status: "removed",
            versions: { previous, current: current },
          });
        });
      });

      return rows;
    };

    const buildAnalysis: DependencyAnalysis = buildResult
      ? JSON.parse(buildResult)
      : fallback;

    const releaseAnalysis: DependencyAnalysis = releaseResult
      ? JSON.parse(releaseResult)
      : fallback;

    return {
      buildAnalysis,
      releaseAnalysis,
      analysisRows: getAnalysisRows(buildAnalysis, releaseAnalysis),
    };
  }, [build.dependencyAnalysis, release?.dependencyAnalysis]);

  const buildStatus = build.dependencyAnalysis?.status;
  const releaseStatus = release?.dependencyAnalysis?.status;

  const loadingMessage =
    (build && !buildStatus) || (release && !releaseStatus)
      ? "loading"
      : buildStatus !== AnalysisStatus.done
      ? capitalize(`${buildStatus} current build`)
      : releaseStatus && releaseStatus !== AnalysisStatus.done
      ? capitalize(`${releaseStatus} comparative build`)
      : "";

  const { buildAnalysis, releaseAnalysis, analysisRows } = analysis;
  const lockfileWarning =
    !loadingMessage &&
    !buildAnalysis.hasLockFile &&
    analysisRows.some((row) => !row.versions?.current?.installedVersion)
      ? "Couldn't detect a package lockfile. Some of the resolved dependency versions may be incorrect."
      : "";

  return {
    buildAnalysis,
    releaseAnalysis,
    analysisRows,
    loadingMessage,
    lockfileWarning,
    buildStatus,
    releaseStatus,
  };
};

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type Version = { installedVersion: string; specifiedVersion: string };
export type DependencyAnalysis = {
  pmName: "npm" | "yarn" | "pnpm" | null;
  pmVersion: string | null;
  hasLockFile: boolean;
  dependencies: Record<string, Version>;
  devDependencies: Record<string, Version>;
};

export type DependencyAnalysisRow = {
  key: string;
  name: string;
  type: "dependencies" | "devDependencies";
  status: "added" | "updated" | "removed" | "unchanged";
  versions: { previous: Version | null; current: Version | null };
};

export const useDependencyAnalysisUpdate = (
  app: IApp,
  build: Build,
  deps: { name: string; version: string }[],
  setState: Dispatch<SetStateAction<DependencyAnalysisState>>
) => {
  const user = useStore(selectedAppUser);
  const [debouncedUpdate] = useDebouncedCallback<DebouncedUpdate>(
    async ({ userId, appId, buildId }, bundlePhobiaData) => {
      await setBuildData(userId, appId, buildId, { bundlePhobiaData });
    },
    1000
  );

  const queryBundlePhobia = useCallback(
    async (
      build: Build,
      dependencies: { name: string; version: string }[],
      ctx: { userId: string; appId: string; buildId: string },
      fetchOptions: RequestInit
    ) => {
      for (const { name, version } of dependencies) {
        const result = build.bundlePhobiaData?.[name]?.result;
        if (result) {
          setState((state) => {
            return merge({}, state, {
              [ctx.buildId]: { [name]: { error: null, result } },
            });
          });
        } else if (!version) {
          setState((state) => {
            return merge({}, state, {
              [ctx.buildId]: { [name]: { error: "N/A", result: null } },
            });
          });
        } else {
          try {
            const res = await fetch(
              `https://bundlephobia.com/api/size?package=${name}@${version}`,
              fetchOptions
            );

            if (!res.ok) throw new Error("Fetch failed");
            const result = await res.json();
            setState((state) => {
              const newState = merge({}, state, {
                [ctx.buildId]: {
                  [name]: {
                    error: null,
                    result: {
                      name: result.name,
                      description: result.description,
                      gzip: result.gzip,
                      size: result.size,
                    },
                  },
                },
              });

              debouncedUpdate(ctx, newState[ctx.buildId]);
              return newState;
            });
          } catch {
            setState((state) => {
              return merge({}, state, {
                [ctx.buildId]: {
                  [name]: { error: "Not available", result: null },
                },
              });
            });
          }
          await new Promise((resolve) => setTimeout(resolve, 200));
        }
      }
    },
    []
  );

  useDeepEffect(() => {
    if (!build) return;

    const ac = new AbortController();
    queryBundlePhobia(
      build,
      deps,
      { userId: user.id, appId: app.id, buildId: build.id },
      { signal: ac.signal }
    );
    return () => ac.abort();
  }, [user.id, app.id, build?.id, deps]);
};

export type DependencyAnalysisState = Record<
  Build["id"],
  Record<string, BundlePhobiaItem>
>;

type DebouncedUpdate = (
  ctx: { userId: string; appId: string; buildId: string },
  data: Build["bundlePhobiaData"]
) => void;
