import { IApp } from "@todesktop/shared";
import type firebase from "firebase/app";

export type IPlans =
  | "essential"
  | "professional"
  | "organization"
  | "enterprise"
  | "cli_founder"
  | "cli_performance"
  | "cli_scale"
  | "builder_essential"
  | "builder_professional";

export interface IRoles {
  owner?: boolean;
  collaborator?: boolean;
}

export interface ITeam {
  id?: string;
  name: string;
  apps?: IApp[];
  stripeCustomerId: string | null;
}

// teamName, userName, userEmail and userAvatar are denormalized.
// Must update them if they change in user or team collection.
export interface IUserTeam {
  id?: string;
  userId: string;
  teamId: string;
  teamName: string;
  userName: string;
  userEmail: string;
  userAvatar: string | null;
  userRole: keyof IRoles;
}

export interface IIcon {
  url: string;
  width: number;
  height: number;
  type: string;
}

export type DownloadLinkPlatformTypes =
  | "windows"
  | "mac"
  | "linux"
  | "universal";

export interface IAuthUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer V>
    ? ReadonlyArray<DeepPartial<V>>
    : DeepPartial<T[P]>;
};

export type Maybe<T> = T | null;
export type ValueOf<T> = T[keyof T];

export interface IFirestoreWhere {
  fieldPath: string | firebase.firestore.FieldPath;
  opStr: firebase.firestore.WhereFilterOp;
  value: any;
}

export interface IFirestoreOrderBy {
  fieldPath: string | firebase.firestore.FieldPath;
  direction: firebase.firestore.OrderByDirection;
}

export enum CertPlatform {
  Mac = "Mac",
  Windows = "Windows",
}

export interface IDomainCertificate {
  id: string;
  hostname: string;
  ssl: unknown;
  status: string;
  verification_errors: string[];
  ownership_verification: unknown;
  ownership_verification_http: unknown;
  created_at: string;
}

export interface IHSMCertificate {
  name: string;
  properties?: {
    createdOn: string;
    enabled: boolean;
    expiresOn: string;
    id: string;
    name: string;
    notBefore: string;
    recoveryLevel: string;
    updatedOn: string;
    vaultUrl: string;
    version: string;
    x509Thumbprint: any;
  };
  subject?: string;
  subjectAlternativeName?: string;
  issuer?: string;
}

export enum CertTabs {
  ViewCert = "ViewCert",
  AddCert = "AddCert",
  AddNotarization = "AddNotarization",
}

export type Platforms = "mac" | "windows" | "linux";
