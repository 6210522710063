import { Button, Collapse, Tooltip } from "antd";
import React, { useEffect } from "react";
import { Flex } from "../../../components/atoms/Primitives";
import { useAzureLogsFunction } from "../../../~reusables/hooks/useFirebaseFunction";
import { determineAnalysisStatus, useReleaseContext } from "./ReleaseContext";
import {
  PlatformIcons,
  ReleaseSection,
  ReleaseSectionProps,
  SectionLogs,
  StatusCheckIcon,
} from "./ReleaseSection";
import { useBuildLoggingContent } from "./useContent";

export const BuildLoggingSection: React.FC<ReleaseSectionProps> = (props) => {
  const {
    props: { app, build },
    state: { buildLogging },
    setAnalysisState,
  } = useReleaseContext();

  const azureLogsCall = useAzureLogsFunction();
  const { state, callFn } = azureLogsCall;
  useEffect(() => {
    if (state === "unused") {
      callFn({ appId: app.id, buildId: build.id }).catch(console.error);
    } else {
      setAnalysisState(
        "buildLogging",
        {
          states: [state],
          checks: {
            "logs-retrieved": determineAnalysisStatus([state], "warn"),
          },
        },
        "warn"
      );
    }
  }, [app.id, build.id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const { content } = useBuildLoggingContent();
  const logsRetrievedStatus = buildLogging.checks["logs-retrieved"];

  return (
    <ReleaseSection {...props}>
      <Flex css={{ justifyContent: "flex-end" }}>
        <Button
          loading={buildLogging.status === "progress"}
          disabled={
            buildLogging.status === "progress" ||
            buildLogging.status === "success"
          }
          onClick={() => {
            callFn({ appId: app.id, buildId: build.id }).catch(console.error);
          }}
        >
          Retrieve build logs
        </Button>
      </Flex>
      <Collapse>
        {[build.mac, build.windows, build.linux]
          .filter((build) => build && !build.shouldSkip)
          .map((platformBuild) => {
            const { platform } = platformBuild;

            const Icon = PlatformIcons[platform];
            const log = azureLogsCall.data ? azureLogsCall.data[platform] : "";

            return (
              <Collapse.Panel
                key={platform}
                header={
                  <Flex css={{ display: "inline-flex", alignItems: "center" }}>
                    <Icon style={{ marginRight: 6 }} />
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </Flex>
                }
                extra={
                  <Tooltip
                    title={content["logs-retrieved"][logsRetrievedStatus]}
                    placement="topRight"
                  >
                    <StatusCheckIcon status={logsRetrievedStatus} />
                  </Tooltip>
                }
              >
                {azureLogsCall.state === "success" && log ? (
                  <SectionLogs>{log}</SectionLogs>
                ) : azureLogsCall.state === "success" ? (
                  <SectionLogs>Could not retrieve logs.</SectionLogs>
                ) : azureLogsCall.state === "error" ? (
                  <SectionLogs>Error: {azureLogsCall.error}</SectionLogs>
                ) : (
                  <SectionLogs>Loading...</SectionLogs>
                )}
              </Collapse.Panel>
            );
          })}
      </Collapse>
    </ReleaseSection>
  );
};
