// modules
import React, { useState } from "react";
import css from "@emotion/css";
import {
  PlatformBuild,
  IAppBuildProgress,
  IApp,
  Build,
  BuildStatus,
} from "@todesktop/shared";
import AnimateHeight from "react-animate-height";

// components
import { Row, Col, Progress } from "antd";
import Checkmark from "../../~reusables/images/Checkmark";
import { AppleFilled, WindowsFilled, QqOutlined } from "@ant-design/icons";

// logic

interface IPlatformBuildProgressBarsProps {
  build: Build;
  app: IApp;
}

export const PlatformBuildProgressBars: React.SFC<IPlatformBuildProgressBarsProps> = ({
  build: { mac, windows, linux, status },
  app,
}) => {
  const macPercentage = !mac.shouldSkip ? mac.progressPercentage : 0;
  const windowsPercentage = !windows.shouldSkip
    ? windows.progressPercentage
    : 0;
  const linuxPercentage = !linux.shouldSkip ? linux.progressPercentage : 0;

  const activePlatforms = [
    !mac.shouldSkip,
    !windows.shouldSkip,
    !linux.shouldSkip,
  ].filter(Boolean).length;

  const averageProgress =
    (macPercentage + windowsPercentage + linuxPercentage) / activePlatforms;

  const anyProgressError =
    mac.progressActivityType === "error" ||
    windows.progressActivityType === "error" ||
    linux.progressActivityType === "error";

  const isCancelled = status === BuildStatus.cancelled;

  return (
    <>
      <Progress
        percent={averageProgress}
        showInfo={false}
        strokeColor={
          isCancelled
            ? "#A9A9A9"
            : {
                from: "#108ee9",
                to: "#87d068",
              }
        }
        status={
          anyProgressError
            ? "exception"
            : isCancelled
            ? "normal"
            : averageProgress < 100
            ? "active"
            : "success"
        }
      />
      {!mac.shouldSkip && (
        <PlatformProgress
          icon={AppleFilled}
          platform="mac"
          buildProgress={(mac as PlatformBuild) || ({} as PlatformBuild)}
          appId={app.id}
          isCancelled={isCancelled}
        />
      )}
      {!windows.shouldSkip && (
        <PlatformProgress
          icon={WindowsFilled}
          platform="windows"
          buildProgress={(windows as PlatformBuild) || ({} as PlatformBuild)}
          appId={app.id}
          isCancelled={isCancelled}
        />
      )}
      {!linux.shouldSkip && (
        <PlatformProgress
          icon={QqOutlined}
          platform="linux"
          buildProgress={(linux as PlatformBuild) || ({} as PlatformBuild)}
          appId={app.id}
          isCancelled={isCancelled}
        />
      )}
    </>
  );
};

interface IAppBuildProgressBarsProps {
  mac: IAppBuildProgress;
  windows: IAppBuildProgress;
  linux: IAppBuildProgress;
  app: IApp;
}

export const AppBuildProgressBars: React.SFC<IAppBuildProgressBarsProps> = ({
  mac,
  windows,
  linux,
  app,
}) => {
  const [showProgressDetail, setShowProgressDetail] = useState(false);
  const toggleShowProgressDetail = () =>
    setShowProgressDetail(!showProgressDetail);

  const macPercentage = !mac.shouldSkip ? mac.percent : 0;
  const windowsPercentage = !windows.shouldSkip ? windows.percent : 0;
  const linuxPercentage = !linux.shouldSkip ? linux.percent : 0;

  const activePlatforms = [
    !mac.shouldSkip,
    !windows.shouldSkip,
    !linux.shouldSkip,
  ].filter(Boolean).length;

  const averageProgress =
    (macPercentage + windowsPercentage + linuxPercentage) / activePlatforms;

  const anyProgressError =
    mac.type === "error" || windows.type === "error" || linux.type === "error";

  return (
    <>
      <Progress
        percent={averageProgress}
        showInfo={false}
        strokeColor={{
          from: "#108ee9",
          to: "#87d068",
        }}
        status={
          anyProgressError
            ? "exception"
            : averageProgress < 100
            ? "active"
            : "success"
        }
      />
      <AnimateHeight
        height={showProgressDetail || anyProgressError ? "auto" : 0}
      >
        {!mac.shouldSkip && (
          <PlatformProgress
            icon={AppleFilled}
            platform="mac"
            appProgress={mac}
            appId={app.id}
          />
        )}
        {!windows.shouldSkip && (
          <PlatformProgress
            icon={WindowsFilled}
            platform="windows"
            appProgress={windows}
            appId={app.id}
          />
        )}
        {!linux.shouldSkip && (
          <PlatformProgress
            icon={QqOutlined}
            platform="linux"
            appProgress={linux}
            appId={app.id}
          />
        )}
      </AnimateHeight>
      <div
        css={css`
          font-size: 0.7rem;
          text-align: right;
        `}
      >
        <a onClick={toggleShowProgressDetail}>
          [{showProgressDetail ? "Hide" : "Show more"} detail]
        </a>
      </div>
    </>
  );
};

interface IPlatformProgressProps {
  appId: IApp["id"];
  icon: any;
  platform: "windows" | "mac" | "linux";
  buildProgress?: PlatformBuild;
  appProgress?: IAppBuildProgress;
  isCancelled?: boolean;
}

const PlatformProgress: React.SFC<IPlatformProgressProps> = ({
  buildProgress,
  appProgress,
  icon: Icon,
  isCancelled,
}) => {
  const progress = buildProgress || appProgress;
  const [
    progressPercentage,
    progressActivityType,
    progressActivityName,
  ] = buildProgress
    ? [
        buildProgress.progressPercentage,
        buildProgress.progressActivityType,
        buildProgress.progressActivityName,
      ]
    : [appProgress.percent, appProgress.type, appProgress.message];

  return !progress ? null : (
    <Row
      css={css`
        margin: 15px 0;
      `}
    >
      <Col span={3}>
        <Icon
          css={css`
            font-size: 32px;
            color: rgba(0, 0, 0, 0.6) !important;
          `}
        />
        {progressPercentage === 100 && (
          <Checkmark
            circleColor={isCancelled ? "#A9A9A9" : "#52c41a"}
            color="white"
            animate={true}
            css={css`
              position: absolute;
              right: 15px;
              bottom: -5px;
              transform: scale(0.7);
            `}
          />
        )}
      </Col>
      <Col
        span={21}
        css={css`
          overflow: hidden;
          height: 40px;
        `}
      >
        <div
          css={css`
            height: 40px;
            font-size: 0.75rem;
            ${progressActivityType === "error" &&
            !isCancelled &&
            "color: #c00;"}

            .ant-progress-line {
              font-size: 10px;
            }
          `}
        >
          <div>{progressActivityName || "Queued"}</div>
          <Progress
            percent={progressPercentage}
            showInfo={false}
            strokeColor={isCancelled ? "#A9A9A9" : ""}
            status={
              progressActivityType === "error"
                ? "exception"
                : isCancelled
                ? "normal"
                : progressPercentage < 100
                ? "active"
                : "success"
            }
          />
        </div>
      </Col>
    </Row>
  );
};
