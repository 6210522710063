import css from "@emotion/css";
import { Modal } from "antd";
import AppIcon from "../atoms/AppIcon";
import { updateUIState, onPasswordSignup } from "../../~reusables/actions";
import React, { useState } from "react";
import PasswordAccountForm from "./PasswordAccountForm";
import { IGlobalState } from "../../store";
import { IApp } from "@todesktop/shared";

interface IConvertAccountProps {
  app: IApp;
}

let nextModal: IGlobalState["uiState"] = "convert-login";
const afterCloseModal = () => updateUIState(nextModal);

const PasswordSignup: React.FC<IConvertAccountProps> = ({ app }) => {
  const [modalVisibile, setModalVisibility] = useState(true);
  const closeModal = () => setModalVisibility(false);

  return (
    <Modal
      visible={modalVisibile}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={afterCloseModal}
    >
      <div
        css={css`
          margin: 0 auto;
        `}
      >
        <AppIcon iconUrl={app.icon} />
        <h2
          css={css`
            font-size: 32px;
            text-align: center;
            font-weight: normal;
          `}
        >
          Register your Account
        </h2>

        <PasswordAccountForm
          callback={onPasswordSignup(() => {
            nextModal = "choose-plan";
            closeModal();
          })}
          buttonText="Create Account"
        />
      </div>
    </Modal>
  );
};

export default PasswordSignup;
