import React from "react";

const PackageHeaderBG = (props: any) => (
  <svg width={278} height={136} {...props}>
    <defs>
      <path id="prefix__a" d="M0 0h278v146H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        d="M-57.792 152c29.135-36.103 74.384-51.9 135.747-47.39 89.03 19.444 149.835 19.444 182.412 0l36.565-24.255V-57H-57.792v209z"
        fill="currentColor"
        mask="url(#prefix__b)"
      />
      <path
        d="M-57.792 126.209c40.175 17.538 86.181 11.515 138.018-18.07 60.13-26.524 120.399-26.524 180.809 0l35.897 18.07V-57H-57.792v183.209z"
        fillOpacity={0.6}
        fill="currentColor"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

export default PackageHeaderBG;
