import React, { SFC } from "react";
import useInterval from "@use-it/interval";

const getCurrentDate = (): string => {
  const date = new Date();
  return date.toLocaleDateString();
};

const CurrentDate: SFC = () => {
  const [date, setDate] = React.useState(getCurrentDate());

  useInterval(() => {
    setDate(getCurrentDate());
  }, 1000000);

  return <>{date}</>;
};

export default CurrentDate;
