import * as yup from "yup";
import React, { Dispatch, SetStateAction, useReducer } from "react";

interface IAppField {
  id: string;
  validationSchema: yup.Schema<any>;
  value: string | number;
  setFieldError: Dispatch<SetStateAction<null>>;
  inputRef?: React.MutableRefObject<any>;
  imageRef?: React.MutableRefObject<any>;
}
type IAppFields = IAppField[];

export interface IAppFieldsContext {
  registerField: (field: IAppField) => void;
  unregisterField: (id: IAppField["id"]) => void;
  fields: IAppFields | undefined;
  updateFieldValue: (id: IAppField["id"], value: IAppField["value"]) => void;
  verifyFields: () => Promise<boolean>;
}
const AppFieldsContext = React.createContext<IAppFieldsContext>({
  registerField: () => {
    //
  },
  unregisterField: () => {
    //
  },
  fields: undefined,
  updateFieldValue: () => {
    //
  },
  verifyFields: async () => {
    return false;
  },
});

const updateFieldMap = (
  field: IAppField,
  id: IAppField["id"],
  value: IAppField["value"]
) => {
  if (field.id === id) {
    return {
      ...field,
      value,
    };
  }
  return field;
};

type FieldsAction =
  | { type: "ADD_FIELD"; fieldParams: IAppField }
  | { type: "REMOVE_FIELD"; id: string }
  | { type: "UPDATE_FIELD"; id: string; value: string | number };
const fieldsReducer = (fields: IAppFields = [], action: FieldsAction) => {
  switch (action.type) {
    case "ADD_FIELD":
      return [...fields, action.fieldParams];
    case "REMOVE_FIELD":
      return fields.filter((field) => field.id !== action.id);
    case "UPDATE_FIELD":
      return fields.map((field) =>
        updateFieldMap(field, action.id, action.value)
      );
    default:
      return fields;
  }
};

function AppFieldsProvider(props: any) {
  const [fields, dispatch] = useReducer(fieldsReducer, []);
  let liveFields = fields;

  const registerField = (fieldParams) => {
    const { validationSchema, value, setFieldError } = fieldParams;
    dispatch({
      type: "ADD_FIELD",
      fieldParams,
    });
    if (validationSchema) {
      validationSchema.validate(value).catch((err: any) => {
        setFieldError(err.message);
      });
    }
  };

  const unregisterField = (id: string) => {
    if (fields) {
      dispatch({
        type: "REMOVE_FIELD",
        id,
      });
    }
  };

  const updateFieldValue = (id: string, value: string | number) => {
    if (!fields) {
      return;
    }
    liveFields = liveFields.map((field) => updateFieldMap(field, id, value));
    dispatch({ type: "UPDATE_FIELD", value, id });
  };

  const refreshFieldValues = async () => {
    if (!fields) {
      return;
    }
    fields.forEach(async (field) => {
      if (!field) {
        return;
      }
      const { id, inputRef, imageRef, value } = field;

      if (imageRef && imageRef.current && imageRef.current.currentSrc) {
        const { currentSrc } = imageRef.current;
        if (value === currentSrc) {
          return;
        }
        updateFieldValue(id, currentSrc);
      } else if (
        inputRef &&
        inputRef.current &&
        inputRef.current.state &&
        inputRef.current.state.value
      ) {
        const { state } = inputRef.current;
        if (state.value === value) {
          return;
        }
        updateFieldValue(id, state.value);
      }
    });
  };

  const verifyFields = async () => {
    await refreshFieldValues();
    if (!liveFields) {
      return true;
    }
    const fieldValidationTests = await Promise.all(
      liveFields.map(async (field) => {
        if (!field) {
          return false;
        }
        const { validationSchema, setFieldError, value, inputRef } = field;
        try {
          if (validationSchema) {
            await validationSchema.validate(value);
          }
          setFieldError(null);
          return true;
        } catch (error) {
          setFieldError(error.message);
          if (inputRef && inputRef.current) {
            inputRef.current.focus();
          }
          return false;
        }
      })
    );
    if (fieldValidationTests.includes(false)) {
      return false;
    }
    return true;
  };

  const valueProps = {
    registerField,
    unregisterField,
    fields,
    updateFieldValue,
    verifyFields,
    refreshFieldValues,
  };

  return (
    <AppFieldsContext.Provider value={valueProps}>
      {props.children}
    </AppFieldsContext.Provider>
  );
}

const AppFieldsConsumer = AppFieldsContext.Consumer;

export { AppFieldsContext, AppFieldsProvider, AppFieldsConsumer };
