import React, { useState } from "react";
import { Flex } from "../../atoms/Primitives";
import { NewAppModalContext, NewAppType } from "./types";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  message,
} from "antd";
import { track } from "../../../~reusables/util/analytics";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import { Heading } from "../../atoms/Heading";
import { Text } from "../../atoms/Text";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";

const createNewApp = callFirebaseFunction("createNewApp");

export const CreateWebApp: React.FC<NewAppModalContext> = ({
  loadCreatedApp,
}) => {
  const { colors } = useTheme();
  const [loading, setLoading] = useState(false);
  const [isMenubarApp, setMenubarApp] = useState(false);
  const [webAppURL, setWebAppURL] = useState("");

  const onCreate = async () => {
    setLoading(true);
    track({
      event: "Submitted URL Form",
      properties: {
        url: webAppURL,
        name: "",
        host: "app",
        source: "create new app modal",
        type: NewAppType.Web,
      },
    });

    try {
      const { data } = await createNewApp({
        url: webAppURL,
        appType: isMenubarApp ? "menubar" : "app",
      });

      loadCreatedApp(data);
    } catch (err) {
      const { message: msg, details: app } = err;
      if (app && app.id) {
        Modal.confirm({
          title: msg,
          content: <>Are you sure you want to continue creating this app?</>,
          okText: "Yes, continue",
          cancelText: "No, change the app",
          onOk: () => loadCreatedApp(app),
          onCancel: () => setLoading(false),
        });
      } else {
        setLoading(false);
        message.error(
          "We had trouble completing this action. Please try again."
        );
      }
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === "Enter" && !loading ? onCreate() : null;

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Alert
        type="info"
        showIcon
        style={{ background: "transparent" }}
        message={
          <Flex
            css={{ flexDirection: "column", gap: "6px", color: colors.text }}
          >
            <Text variant="body3">
              ToDesktop Web is suitable for those who have an existing web app
              and want to transform it into a cross-platform desktop app using
              the online web interface. Here are some feature highlights:
            </Text>
            <ul>
              <li>
                Bootstrap a desktop app straight <b>from your web app</b>.
              </li>
              <li>
                Optionally add code to customize your app with{" "}
                <b>native features</b>.
              </li>
              <li>
                <b>One-click publish</b> your desktop app to your customers.
              </li>
            </ul>
          </Flex>
        }
      />
      <Divider />
      <Input
        value={webAppURL}
        onChange={(e) => setWebAppURL(e.target.value)}
        size="large"
        onKeyPress={handleKeypress}
        placeholder="https://www.mysite.com"
        autoFocus
      />
      <Checkbox
        style={{ marginTop: "12px" }}
        value={isMenubarApp}
        onChange={(e) => setMenubarApp(!isMenubarApp)}
      >
        Create as Menubar App
      </Checkbox>
      <Flex css={{ alignSelf: "end", gap: "4px" }}>
        <Button
          disabled={!webAppURL}
          loading={loading}
          type="primary"
          onClick={onCreate}
        >
          Create App
        </Button>
      </Flex>
    </Flex>
  );
};
