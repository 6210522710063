import React from "react";
import css from "@emotion/css";
import { Input, Form } from "antd";
import { ChangeEvent } from "react";
import { InputProps } from "antd/lib/input";

interface IFormItemProps {
  error?: string;
  label: string | JSX.Element;
  marginBottom?: string;
  htmlFor?: string;
  customCss?: any;
}
export const FormItem: React.SFC<IFormItemProps> = ({
  children,
  error,
  marginBottom = "0px",
  label,
  htmlFor,
  customCss,
}) => (
  <Form.Item
    label={label}
    help={error}
    validateStatus={error ? "error" : undefined}
    hasFeedback={true}
    colon={false}
    labelAlign="left"
    style={{
      display: "flex",
      flexDirection: "column",
    }}
    htmlFor={htmlFor}
    css={
      customCss ||
      css`
        && {
          margin-bottom: ${marginBottom};
          .ant-form-explain {
            font-size: 12px;
            min-height: 16px;
          }
          .ant-form-item-label {
            line-height: 24px;
            label {
              font-size: 15px;
              font-weight: 500;
            }
          }
        }
      `
    }
  >
    {children}
  </Form.Item>
);

interface IFormInput extends InputProps {
  error?: string;
  label?: string | JSX.Element;
  name: string;
  password?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | string[];
  disabled?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  addonAfter?: string | React.ReactNode;
}

export const FormInput: React.FC<IFormInput> = ({
  error,
  label,
  name,
  password,
  ...rest
}) => {
  return (
    <FormItem error={error} label={label} marginBottom="12px" htmlFor={name}>
      {password ? (
        <Input.Password id={name} name={name} {...rest} />
      ) : (
        <Input id={name} name={name} {...rest} />
      )}
    </FormItem>
  );
};
