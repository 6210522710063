import React from "react";

export const helpText = {
  trial: "Cancel anytime within the first 7 days and you won't be charged",
  email:
    "Have a question? Need help? Send support an email and we'll get back to you within 2 days",
  update:
    "Your app will always run with an up-to-date browser engine thanks to ToDesktop's Auto Update",
  sign: (
    <>
      We sign your desktop app with EV Microsoft Authenticode and Apple
      Gatekeeper certificates.
      <br />
      <br /> This is super-important because it means that your app will
      download and install seamlessly without triggering any warning dialogs.
    </>
  ),
  platform:
    "ToDesktop builds your app for every operating system. You will get a customised installer for Windows, Mac and Linux.",
  msi:
    "If you're pushing your app out to a large number of machines within an organization then MSI makes it much a much easier process for IT",
  concierge:
    "You can schedule a video/phone onboarding session where we'll take you through the product and make sure that you're happily onboard",
  everything: "Auto update and code signing are included",
  cancel: "There's no commitment. Cancel at any time without fees or penalties",
  launchAtStartup:
    "You can specify that your app should launch by default when a user starts their computer. Users will always have an option to toggle this setting in native app menus. This is further customizable through our JavaScript API.",
  priority:
    "Your emails get answered first and you get the option of schedule a  support session",
  users50: (
    <>
      This plan is limited to 50 monthly active users. We will allow a limited
      grace period but ultimately, you will need to move to the Professional
      plan if you continue to exceed the limit.
    </>
  ),
  users400: (
    <>
      This plan is limited to 400 monthly active users. We will allow a limited
      grace period but ultimately, if you continue to exceed the limit, you will
      be charged $10 per 100 users over the limit (per month).
    </>
  ),
  nativeNotifications:
    "You can send notifications to your users without requesting permission first. We intercept the notification and send a native OS notification to your user instead.",
  nativeInstallers:
    "Provides a customised installer for Windows, Mac and Linux. We even provide a magic link you can send your users which will detect their operating system and download the most up to date version of your app.",
  usersUnlimited:
    "No need to worry, there is no catch here; you will never be charged more based on the number of users you have.",
  appBadgeAndLabelAPIs:
    "Using our APIs, you can to set a badge count programmatically, show a label or progress bar, make the dock icon bounce, and more.",
  customInternalURLs: (
    <>
      Internal URLs are links that open within your desktop app window instead
      of an external browser window i.e. Chrome, Safari, Firefox etc. By
      default, login provider URLs (also known as OAuth) and URLs on the same
      domain as your app are considered internal. This option allows you to
      specify any extra URLs (or any that match a regular expression) to be
      treated as internal.
    </>
  ),
  customUserAgent:
    "Set a custom user agent. This is how your app will identify itself to servers.",
  launchInFullscreen: (
    <>
      When your app is launched, this setting causes your app window to start in
      full-screen mode. This does not prevent the user from exiting full-screen
      mode after the app has been launched.
    </>
  ),
  appProtocolAndDeeplinking: (
    <>
      You've probably seen app protocols before. Perhaps you've seen them with
      iTunes (itmss://) or Spotify (spotify://) or Slack (slack://). App
      protocols provide an easy way for you to launch your application from
      inside another application. One common use-cases is to launch a desktop
      application from a website. You can also add more information
      (spotify://...) which would bring you to a specific part of your desktop
      application. We call this a ‘deeplink’.
    </>
  ),
  accountManager: (
    <>
      You will have your own appointed account manager who is dedicated to
      making you and your app a success. They will provide you with demos, make
      sure you're on-boarded, and support you along the way, no matter what you
      need.
    </>
  ),
  customFeatures:
    "We will go above and beyond to make your app a success, including building custom features to suit your business needs.",
  cnameDownloads:
    "Have your own branded download links like download.yoursite.com instead of our standard dl.todesktop.app URLs. Fast, secure, branded, SEO-friendly.",
  analytics:
    "We measure what matters so you can get a deeper understanding of your customers and how they use your app.",
};
