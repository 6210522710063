import { Button, Modal, Space } from "antd";
import React, { useState } from "react";
import { selectedApp, useStore } from "../../store";
import { updateUIState } from "../../~reusables/actions";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { Flex } from "../atoms/Primitives";

export const PayToDesktopBuilder: React.FC = () => {
  const app = useStore(selectedApp);
  const { visible, userId } = useStore((state) => ({
    visible: state.uiState === "pay-todesktop-builder",
    userId: state.user?.id,
  }));

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => {
        updateUIState(undefined);
      }}
    >
      <Flex css={{ flexDirection: "column", gap: "16px" }}>
        <Heading variant="h4" as="h4">
          Subscription Required
        </Heading>
        <Text>Create a new subscription through the ToDesktop Builder app.</Text>
        <Space size="small">
          <Button href="https://builder.todesktop.com">
            Download ToDesktop Builder
          </Button>
          <Button
            type="primary"
            onClick={() => {
              window.location.href = `todesktop://subscribe-app?appId=${app.id}&userId=${userId}`;
            }}
          >
            Open in ToDesktop Builder
          </Button>
        </Space>
      </Flex>
    </Modal>
  );
};
