import { AppleFilled, QqOutlined, WindowsFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import { PlatformName } from "@todesktop/shared";
import { Button, Modal, Tabs } from "antd";
import React, { useEffect } from "react";
import { useAzureLogsFunction } from "../../~reusables/hooks/useFirebaseFunction";
import Spinner from "../atoms/Spinner";

const ViewAzureLog: React.FC<{
  appId: string;
  buildId: string;
  defaultPlatform: PlatformName;
  renderTrigger?: typeof defaultRenderTrigger;
}> = ({
  appId,
  buildId,
  defaultPlatform,
  renderTrigger = defaultRenderTrigger,
}) => {
  const [isShown, setIsShown] = React.useState(false);

  function show() {
    setIsShown(true);
  }

  function hide() {
    setIsShown(false);
  }

  return (
    <>
      {renderTrigger({ isShown, show })}
      {isShown && (
        <StyledModal
          footer={null}
          onCancel={hide}
          title="Build log"
          open={true}
          width="calc(100vw - 48px)"
        >
          <AzureLog
            appId={appId}
            buildId={buildId}
            defaultPlatform={defaultPlatform}
          />
        </StyledModal>
      )}
    </>
  );
};

const AzureLog: React.FC<{
  appId: string;
  buildId: string;
  defaultPlatform?: PlatformName;
}> = ({ appId, buildId, defaultPlatform }) => {
  const azureLogsCall = useAzureLogsFunction();

  useEffect(() => {
    azureLogsCall.callFn({ appId, buildId }).catch(console.error);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch (azureLogsCall.state) {
    case "success":
      return (
        <PlatformTabs
          defaultPlatform={defaultPlatform}
          renderNoData={() => <>No logs found</>}
        >
          {(platform) => {
            const log = azureLogsCall.data[platform];
            return log ? <StyledLog>{log}</StyledLog> : null;
          }}
        </PlatformTabs>
      );
    case "error":
      return <div>Error: {azureLogsCall.error}</div>;
    default:
      return <Spinner css={{ height: "100%" }} />;
  }
};

const PlatformTabs: React.FC<{
  children: (platform: PlatformName) => React.ReactNode;
  defaultPlatform?: PlatformName;
  renderNoData?: () => React.ReactElement;
}> = ({ children, defaultPlatform, renderNoData }) => {
  const platforms = [
    { icon: <AppleFilled />, name: PlatformName.mac, title: "Mac" },
    { icon: <WindowsFilled />, name: PlatformName.windows, title: "Windows" },
    { icon: <QqOutlined />, name: PlatformName.linux, title: "Linux" },
  ] as const;

  const tabs = platforms.map((platform) => {
    const childContent = children(platform.name);
    if (!childContent) {
      return null;
    }

    return (
      <Tabs.TabPane
        key={platform.name}
        tab={
          <>
            {platform.icon}
            {platform.title}
          </>
        }
        style={{ height: "100%" }}
      >
        {childContent}
      </Tabs.TabPane>
    );
  });

  if (tabs.filter(Boolean).length === 0) {
    return renderNoData?.() || null;
  }

  return (
    <Tabs animated={false} defaultActiveKey={defaultPlatform} size="large">
      {tabs}
    </Tabs>
  );
};

function defaultRenderTrigger({
  isShown,
  show,
}: {
  isShown: boolean;
  show: () => void;
}) {
  return (
    <Button size="small" onClick={show}>
      View Full Build Logs
    </Button>
  );
}

const StyledLog = styled.div`
  white-space: pre;
  overflow: auto;
  font-family: Inconsolata, "Bitstream Vera Sans Mono", Consolas, Courier,
    monospace;
`;

const StyledModal = styled(Modal)`
  &.ant-modal {
    height: calc(100vh - 48px);
    left: 24px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    top: 24px;

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      .ant-modal-body {
        align-items: center;
        display: flex;
        flex: auto;
        justify-content: center;
        overflow: hidden;
      }
    }
  }

  & .ant-tabs {
    height: 100%;
    width: 100%;
  }

  & .ant-tabs-content-holder {
    display: flex;
  }

  & .ant-tabs-tabpane {
    display: flex;
  }
`;

export default ViewAzureLog;
