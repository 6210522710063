// modules
import React, { useState } from "react";
import css from "@emotion/css";
import { IAppFromServerProps } from "@todesktop/shared";
import { withRouter } from "react-router-dom";
import webAppImg from "../../../~reusables/images/fromWebAppImg.png";
import electronImg from "../../../~reusables/images/fromElectronAppImg.png";

// components
import { Divider, Modal, Tabs } from "antd";

// logic
import { updateUIState, addAppFromServer } from "../../../~reusables/actions";
import { useStore } from "../../../store";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import { Heading } from "../../atoms/Heading";
import { Space } from "../../atoms/Space";
import { NewAppModalContext, NewAppType } from "./types";
import { CreateBuilderApp } from "./CreateBuilderApp";
import { CreateCLIApp } from "./CreateCliApp";

const CreateNewAppModal = ({ history }) => {
  const { colors } = useTheme();
  const { selectedTeam } = useStore((state) => ({
    selectedTeam: state.selectedTeam,
  }));

  const [isModalVisible, setModalVisibility] = useState(true);
  const [activeTab, setActiveTab] = useState<NewAppType | null>(null);

  const loadCreatedApp = (app: IAppFromServerProps) => {
    if (selectedTeam) {
      // ITodos - add app to team instead of user
    } else {
      addAppFromServer(app);
    }
    updateUIState(undefined);
    history.push(`/apps/${app.id}`);
  };

  const onCancel = () => setModalVisibility(false);

  const newAppModalContext: NewAppModalContext = {
    onCancel,
    loadCreatedApp,
  };

  return (
    <Modal
      visible={isModalVisible}
      closable={true}
      centered={true}
      onCancel={onCancel}
      afterClose={() => updateUIState(undefined)}
      footer={null}
    >
      <section>
        <Heading textAlign="center" variant="h3" as="h3">
          Create new app
        </Heading>
        <Space size="md" direction="column" />
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 16px;

            & > section {
              display: flex;
              flex: 1;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              border: 1px solid ${colors.greys[6]};
              border-radius: 4px;
              padding-bottom: 8px;
              overflow: hidden;

              div {
                width: 100%;
                border-bottom: 1px solid ${colors.greys[6]};

                margin-bottom: 12px;
                cursor: pointer;
                img {
                  width: 100%;
                }
              }
            }
          `}
        >
          <section
            onClick={() => setActiveTab(NewAppType.Builder)}
            css={css`
              border: ${activeTab === NewAppType.Builder
                ? `1px solid ${colors.primary} !important`
                : 0};
            `}
          >
            <div>
              <img height="108" width="216" src={webAppImg} />
            </div>
            <Heading css={{ cursor: "inherit" }} variant="h5" as="h5">
              Using ToDesktop Builder
            </Heading>
          </section>

          <section
            onClick={() => {
              setActiveTab(NewAppType.CLI);
            }}
            css={css`
              border: ${activeTab === NewAppType.CLI
                ? `1px solid ${colors.primary} !important`
                : 0};
            `}
          >
            <div>
              <img height="108" width="216" src={electronImg} />
            </div>
            <Heading css={{ cursor: "inheirt" }} variant="h5" as="h5">
              Using ToDesktop Platform
            </Heading>
          </section>
        </div>
        <Tabs activeKey={activeTab} tabBarStyle={{ display: "none" }}>
          <Tabs.TabPane key={NewAppType.Builder}>
            <Divider />
            <CreateBuilderApp {...newAppModalContext} />
          </Tabs.TabPane>
          <Tabs.TabPane key={NewAppType.CLI}>
            <Divider />
            <CreateCLIApp {...newAppModalContext} />
          </Tabs.TabPane>
        </Tabs>
      </section>
    </Modal>
  );
};

export default withRouter(CreateNewAppModal);
