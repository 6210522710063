/* eslint-disable react/display-name */
import {
  Build,
  IApp,
  PlatformName,
  SmokeTestProgress,
} from "@todesktop/shared";
import {
  Button,
  Collapse,
  Divider,
  Image,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Box, Flex } from "../../../components/atoms/Primitives";
import { selectedAppUser, useStore } from "../../../store";
import { selectedReleasedBuild } from "../../../~reusables/actions/builds";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import { useDeepEffect } from "../../../~reusables/hooks/useDeep";
import {
  useSmokeTestFunction,
  useSmokeTestLogsFunction,
} from "../../../~reusables/hooks/useFirebaseFunction";
import { useDelayedValue } from "../../../~reusables/hooks/useValue";
import {
  SmokeTestingChecks,
  Status,
  determineAnalysisStatus,
  useReleaseContext,
} from "./ReleaseContext";
import {
  PlatformIcons,
  ReleaseSection,
  ReleaseSectionProps,
  SectionLogs,
  StatusCheckIcon,
  StatusIconFilled,
} from "./ReleaseSection";
import { SmokeTestingContent, useSmokeTestingContent } from "./useContent";
import { PerformanceMetrics } from "./PerformanceMetrics";

const { Text, Title } = Typography;

export const SmokeTestingSection: React.FC<ReleaseSectionProps> = (props) => {
  const user = useStore(selectedAppUser);
  const {
    props: { app, build },
    state: { smokeTesting },
    setAnalysisState,
  } = useReleaseContext();
  const { mac, windows, linux } = build;

  const smokeTestCall = useSmokeTestFunction();
  const smokeTestLogsCall = useSmokeTestLogsFunction();

  const content = useSmokeTestingContent();
  const platformData = [
    { build: mac, smokeTest: build.smokeTest?.mac },
    { build: windows, smokeTest: build.smokeTest?.windows },
    { build: linux, smokeTest: build.smokeTest?.linux },
  ]
    .filter(
      ({ build, smokeTest }) =>
        build && !build.shouldSkip && smokeTest?.state !== "skipped"
    )
    .map((platformData) => ({
      ...platformData,
      data: getSmokeTestResult(
        "eager",
        platformData.build.platform,
        build,
        smokeTestCall,
        smokeTestLogsCall,
        content
      ),
    }));

  const [tab, setTab] = useState<PlatformName>(platformData[0]?.build.platform);

  const checks = {} as Record<SmokeTestingChecks, Status>;
  for (const key in smokeTesting.checks) {
    checks[key] = determineAnalysisStatus(
      platformData.map(({ data }) => data.statusRecord[key])
    );
  }

  const states = platformData.map(({ data }) => data.status);

  // Calling smokeTestLogsCall too quickly after the smoke tests have completed can return incomplete logs for whichever platform finished last.
  // From testing, it seems like we have to wait about 15 seconds after the last platform is complete before the logs are retrievable.
  // The alternative would be to add an additional "completedAt" flag that is set after the "Send Email" step of the smoke tests have completed.
  // This would instead add about 30 more seconds to the process, but wouldn't be backwards-compatible (while the useDelayedValue approach is).
  const smokeTestsCompleted = useDelayedValue(
    platformData.every(({ smokeTest }) =>
      ["done", "error", "skipped"].includes(smokeTest?.state)
    ),
    15000
  );

  useDeepEffect(() => {
    if (!build.smokeTest && smokeTestCall.state === "unused") {
      smokeTestCall
        .callFn({ buildId: build.id, appId: app.id, userId: user?.id })
        .catch(console.error);
    } else if (smokeTestsCompleted) {
      if (smokeTestLogsCall.state === "unused") {
        smokeTestLogsCall
          .callFn({ appId: app.id, buildId: build.id })
          .catch(console.error);
      } else {
        setAnalysisState("smokeTesting", { states, checks });
      }
    } else {
      setAnalysisState("smokeTesting", { states, checks });
    }
  }, [
    smokeTestsCompleted,
    smokeTestLogsCall.state,
    smokeTestCall.state,
    states,
    checks,
  ]);

  const loading =
    smokeTestCall.state === "calling" || smokeTesting.status === "progress";
  const disabled = loading || smokeTesting.status === "success";

  return (
    <ReleaseSection {...props}>
      <Tabs
        activeKey={tab}
        onChange={(key: PlatformName) => setTab(key)}
        tabBarExtraContent={
          <Button
            loading={loading}
            disabled={disabled}
            onClick={() => {
              smokeTestCall
                .callFn({ buildId: build.id, appId: app.id, userId: user?.id })
                .catch(console.error);
            }}
          >
            Run Smoke Tests
          </Button>
        }
      >
        {platformData.map((platformData) => {
          const { smokeTest, data } = platformData;
          const platform = platformData.build.platform;
          const Icon = PlatformIcons[platformData.build.platform];

          const hasScreenshot = Boolean(smokeTest?.screenshot);
          const hasPerformance = Boolean(smokeTest?.performance?.bc);

          return (
            <Tabs.TabPane
              key={platform}
              tab={
                <Flex
                  css={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Icon style={{ marginRight: 6 }} />
                  {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  <StatusCheckIcon status={data.status} />
                </Flex>
              }
            >
              <Flex css={{ flexDirection: "column", gap: "16px" }}>
                <SmokeTestResult
                  app={app}
                  build={build}
                  smokeTest={smokeTest}
                  {...data}
                />
                {hasScreenshot || hasPerformance ? (
                  <Box
                    css={{
                      border: "1px solid #D9D9D9",
                      borderRadius: "4px",
                      padding: "16px",
                    }}
                  >
                    <Flex style={{ flexWrap: "wrap", gap: "16px" }}>
                      {hasScreenshot && (
                        <Box css={{ flex: 3, flexBasis: "400px" }}>
                          <Image
                            src={smokeTest.screenshot}
                            preview={false}
                            style={{ borderRadius: "4px" }}
                          />
                        </Box>
                      )}
                      {hasPerformance && (
                        <PerformanceMetrics
                          build={build}
                          status={data.appPerformanceStatus}
                          smokeTestProgress={smokeTest}
                        />
                      )}
                    </Flex>
                  </Box>
                ) : null}
              </Flex>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </ReleaseSection>
  );
};

type StatusCheck = {
  key: string;
  title: string;
  status: Status;
  children: JSX.Element;
};

type SmokeTestResult = {
  appPerformanceStatus: Status;
  status: Status;
  statusPanels: StatusCheck[];
  statusRecord: Record<SmokeTestingChecks, Status>;
  totalChecks: number;
  passedChecks: number;
  failedChecks: number;
};

export const getSmokeTestResult = (
  mode: "lazy" | "eager",
  platform: PlatformName,
  build: Build,
  smokeTestCall: ReturnType<typeof useSmokeTestFunction>,
  smokeTestLogsCall: ReturnType<typeof useSmokeTestLogsFunction>,
  { content }: SmokeTestingContent
): SmokeTestResult => {
  const smokeTest = build.smokeTest?.[platform];
  const smokeTestLogs = smokeTestLogsCall.data?.[platform];

  const statusPanels: StatusCheck[] = [];
  const determineBooleanStatus = (succesful: boolean): Status => {
    if (!smokeTest || smokeTest.state === "progress") {
      return smokeTest
        ? determineAnalysisStatus([smokeTest.state], "error")
        : determineAnalysisStatus(
            [
              // remap success states to progress until `smokeTest` variable exists
              smokeTestCall.state === "success"
                ? "progress"
                : smokeTestCall.state,
            ],
            "warn"
          );
    }

    return succesful ? "success" : "error";
  };

  const statusRecord: Record<SmokeTestingChecks, Status> = {
    "past-auto-update": smokeTest?.abState
      ? determineAnalysisStatus([smokeTest.abState])
      : determineBooleanStatus(smokeTest?.appUpdated),
    "future-auto-update": smokeTest?.bcState
      ? determineAnalysisStatus([smokeTest.bcState])
      : determineBooleanStatus(smokeTest?.appUpdated),
    "app-launched": determineBooleanStatus(smokeTest?.appLaunched),
    "app-launched-without-error": determineBooleanStatus(
      smokeTest?.updatedAppHasNoMainErrors
    ),
    "app-test-logs": smokeTest
      ? determineAnalysisStatus([smokeTestLogsCall.state], "warn")
      : determineBooleanStatus(false),
  };

  let appPerformanceStatus = smokeTest?.performance?.bc
    ? determineAnalysisStatus([smokeTest.bcState])
    : determineBooleanStatus(false);

  if (!smokeTest && mode === "lazy") {
    appPerformanceStatus = "warning";
    for (const key in statusRecord) {
      statusRecord[key] = "warning";
    }
  }

  statusPanels.push({
    key: "1",
    title: content["app-launched"][statusRecord["app-launched"]],
    status: statusRecord["app-launched"],
    children: (
      <Box>
        The App Launched condition attempts to launch your application and take
        a screenshot of the rendered user interface. This is useful for getting
        a visual overview of your application and how it renders in different OS
        environments.{" "}
        <a
          href="https://www.notion.so/todesktop1/Smoke-Testing-App-Launched-d76ae401246645b284283d49ac871868?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          Learn more.
        </a>
      </Box>
    ),
  });

  statusPanels.push({
    key: "2",
    title:
      content["app-launched-without-error"][
        statusRecord["app-launched-without-error"]
      ],
    status: statusRecord["app-launched-without-error"],
    children: (
      <Box>
        ToDesktop’s Auto-Updated App feature validates whether your app launches
        without any main process errors.{" "}
        <a
          href="https://www.notion.so/todesktop1/Smoke-Testing-Auto-Updated-App-85343f59b22b429b99ac097fe01f6d82?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          Learn more.
        </a>
      </Box>
    ),
  });

  statusPanels.push({
    key: "3",
    title: content["app-test-logs"][statusRecord["app-test-logs"]],
    status: statusRecord["app-test-logs"],
    children: (
      <Box>
        The Smoke Test Logs condition forwards the logs that were obtained when
        running your application in the OS context of our continuous integration
        tool. These logs contain useful diagnostic information in the situation
        where one or more of the other smoke test assertions have failed.{" "}
        <a
          href="https://www.notion.so/todesktop1/Smoke-Testing-Smoke-Test-Logs-5e3b323eafaf4e83a4ac182cf178df9e?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          Learn more.
        </a>
        <Divider />
        <SectionLogs>{smokeTestLogs}</SectionLogs>
      </Box>
    ),
  });

  const states = Object.values(statusRecord);
  const totalChecks = states.length;
  const passedChecks = states.filter((check) => check === "success").length;
  const failedChecks = states.filter((check) => check === "error").length;

  return {
    appPerformanceStatus,
    statusPanels,
    statusRecord,
    totalChecks,
    passedChecks,
    failedChecks,
    // ignore app-test-logs when determining overall status and loading states
    status: determineAnalysisStatus([
      statusRecord["past-auto-update"],
      statusRecord["future-auto-update"],
      statusRecord["app-launched"],
      statusRecord["app-launched-without-error"],
    ]),
  };
};

type SmokeTestResultProps = SmokeTestResult & {
  app: IApp;
  build: Build;
  smokeTest?: SmokeTestProgress;
  style?: React.CSSProperties;
};

export const SmokeTestResult: React.FC<SmokeTestResultProps> = (props) => {
  const { app, build, statusPanels, statusRecord, status } = props;

  const selectedRelease = useStore(selectedReleasedBuild);
  const { colors } = useTheme();

  const { content } = useSmokeTestingContent();
  const pastAutoUpdateStatus = statusRecord["past-auto-update"];
  const futureAutoUpdateStatus = statusRecord["future-auto-update"];

  return (
    <Spin spinning={status === "progress"}>
      <Box
        style={{
          border: "1px solid #D9D9D9",
          borderRadius: "4px",
          ...(props.style || {}),
        }}
      >
        <Flex
          css={{
            borderBottom: "1px solid #D9D9D9",
            padding: "16px",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Fragment>
            <AutoUpdateIcon
              app={app}
              build={selectedRelease || build}
              version={
                selectedRelease && selectedRelease.id !== build.id
                  ? selectedRelease.appVersion
                  : `<${build.appVersion}`
              }
            />
            <AutoUpdateResult
              tooltip={content["past-auto-update"][pastAutoUpdateStatus]}
              status={pastAutoUpdateStatus}
            >
              <a
                href="https://www.notion.so/todesktop1/Smoke-Testing-Auto-Update-from-Past-Version-64dd4257a9f14af091db7fc189cde92a?pvs=4"
                target="_blank"
                rel="noreferrer"
              >
                past auto-update
              </a>
            </AutoUpdateResult>
          </Fragment>

          <AutoUpdateIcon app={app} build={build} version={build.appVersion} />
          <AutoUpdateResult
            tooltip={content["future-auto-update"][futureAutoUpdateStatus]}
            status={futureAutoUpdateStatus}
          >
            <a
              href="https://www.notion.so/todesktop1/Smoke-Testing-Auto-Update-to-Future-Version-b285dbfbc8c24cc7a4d9de75cc22c0a7?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              future auto-update
            </a>
          </AutoUpdateResult>
          <AutoUpdateIcon
            app={app}
            build={build}
            version={`>${build.appVersion}`}
          />
        </Flex>
        <Collapse
          expandIconPosition="right"
          expandIcon={() => (
            <Text style={{ color: colors.primary }}>Details</Text>
          )}
          bordered={false}
        >
          {statusPanels.map(({ key, title, status, children }) => {
            return (
              <Collapse.Panel
                key={key}
                header={
                  <Flex css={{ alignItems: "center", gap: "8px" }}>
                    <StatusCheckIcon status={status} />
                    <Box>{title}</Box>
                  </Flex>
                }
              >
                {children}
              </Collapse.Panel>
            );
          })}
        </Collapse>
        <Flex css={{ borderTop: "1px solid #D9D9D9" }}>
          {status === "success" ? (
            <SmokeTestSuccess {...props} />
          ) : status === "error" ? (
            <SmokeTestError {...props} />
          ) : status === "progress" ? (
            <SmokeTestInProgress {...props} />
          ) : (
            <SmokeTestWarning {...props} />
          )}
        </Flex>
      </Box>
    </Spin>
  );
};

const AutoUpdateIcon: React.FC<{
  version: string;
  app: IApp;
  build: Build;
}> = ({ version, build, app }) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
      }}
    >
      <img src={build.icon ? build.icon : app.icon} width={32} />
      <Text style={{ fontSize: "12px" }}>{version}</Text>
    </Flex>
  );
};

const AutoUpdateResult: React.FC<{ status: Status; tooltip: string }> = ({
  status,
  tooltip,
  children,
}) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip title={tooltip}>
        <StatusCheckIcon status={status} />
      </Tooltip>
      <Flex
        css={{
          width: "72px",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "8px",
          paddingBottom: "4px",
        }}
      >
        <Box
          css={{
            height: "1px",
            background: "rgba(000,000,000, 0.3)",
            width: "60%",
          }}
        />
        <Box
          css={{
            height: "0",
            width: "0",
            borderTop: "3px solid transparent",
            borderBottom: "3px solid transparent",
            borderLeft: `6px solid rgba(000,000,000, 0.3)`,
          }}
        />
      </Flex>
      <Text style={{ fontSize: "10px" }}>{children}</Text>
    </Flex>
  );
};

const SmokeTestSuccess: React.FC<SmokeTestResultProps> = ({
  passedChecks,
  totalChecks,
}) => {
  return (
    <Flex css={{ padding: "16px", gap: "12px", alignItems: "start" }}>
      <Flex css={{ marginTop: "6px" }}>
        <StatusIconFilled status="success" />
      </Flex>
      <Flex css={{ flexDirection: "column" }}>
        <Title level={5} style={{ margin: 0 }}>
          All checks passed
        </Title>
        <Text>
          {passedChecks} out of {totalChecks} checks have passed.
        </Text>
      </Flex>
    </Flex>
  );
};

const SmokeTestError: React.FC<SmokeTestResultProps> = ({
  failedChecks,
  totalChecks,
  smokeTest,
}) => {
  const message = smokeTest?.message;
  return (
    <Flex css={{ padding: "16px", gap: "12px", alignItems: "start" }}>
      <Flex css={{ marginTop: "6px" }}>
        <StatusIconFilled status="error" />
      </Flex>
      <Flex css={{ flexDirection: "column" }}>
        <Title level={5} style={{ margin: 0 }}>
          Some checks failed
        </Title>
        <Text style={{ fontSize: "13px" }}>
          {message
            ? `${message.charAt(0).toUpperCase() + message.slice(1)}. `
            : ""}
          {failedChecks} out of {totalChecks} checks have failed.
        </Text>
      </Flex>
    </Flex>
  );
};

const SmokeTestWarning: React.FC<SmokeTestResultProps> = ({
  passedChecks,
  totalChecks,
}) => {
  return (
    <Flex css={{ padding: "16px", gap: "12px", alignItems: "start" }}>
      <Flex css={{ marginTop: "6px" }}>
        <StatusIconFilled status="warning" />
      </Flex>
      <Flex css={{ flexDirection: "column" }}>
        <Title level={5} style={{ margin: 0 }}>
          Not all checks have passed
        </Title>
        <Text style={{ fontSize: "13px" }}>
          {passedChecks} out of {totalChecks} checks have passed.
        </Text>
      </Flex>
    </Flex>
  );
};

const SmokeTestInProgress: React.FC<SmokeTestResultProps> = ({
  passedChecks,
  totalChecks,
}) => {
  return (
    <Flex css={{ padding: "16px", gap: "12px", alignItems: "start" }}>
      <Flex css={{ marginTop: "6px" }}>
        <StatusIconFilled status="progress" />
      </Flex>
      <Flex css={{ flexDirection: "column" }}>
        <Title level={5} style={{ margin: 0 }}>
          Some checks in progress
        </Title>
        <Text style={{ fontSize: "13px" }}>
          {passedChecks} out of {totalChecks} checks have passed.
        </Text>
      </Flex>
    </Flex>
  );
};
