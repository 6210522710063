// modules
import css from "@emotion/css";
import { Build, isBuildCancellable } from "@todesktop/shared";
import { Tag, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";

// components
import { RightOutlined } from "@ant-design/icons";

// logic
import { useStore } from "../../store";
import { parseDate } from "../../~reusables/util";
import {
  EligibilityState,
  determineReleaseEligibility,
} from "../../~reusables/util/determineReleaseEligibility";
import { isBuildSupportsSmokeTests } from "../../~reusables/util/smokeTest";
import { BuildReleaseTag, BuildTag } from "../atoms/BuildTag";
import { Heading } from "../atoms/Heading";
import { Box } from "../atoms/Primitives";
import { CardIsEmpty } from "../atoms/StyledAntdCard";
import { Text } from "../atoms/Text";
import { CancelBuildButton } from "../molecules/CancelBuildButton";
import { RerunFailedBuildButton } from "../molecules/RerunFailedBuildButton";

interface IBuildSnippets {
  builds?: Build[];
  maxAmountToShow?: number;
}

export const BuildSnippets: React.FC<IBuildSnippets> = ({
  builds = [],
  maxAmountToShow = builds.length,
}) => {
  if (!builds.length) {
    return (
      <CardIsEmpty>
        <Text color="support">No builds yet</Text>
      </CardIsEmpty>
    );
  }

  return (
    <>
      {builds.slice(0, maxAmountToShow).map((build) => (
        <BuildSnippet key={build.id} build={build} />
      ))}
    </>
  );
};

const getTooltipAndTagProps = (smokeTestState) => {
  switch (smokeTestState) {
    case EligibilityState.loading: {
      return {
        tagProps: {
          color: "blue",
        },
        tooltip: "Smoke tests are running",
        text: "Running",
      };
    }
    case EligibilityState.ineligible: {
      return {
        tagProps: {
          color: "red",
        },
        tooltip: "Smoke tests failed",
        text: "Failed",
      };
    }
    case EligibilityState.eligible: {
      return {
        tagProps: {
          color: "green",
        },
        tooltip: "Smoke tests passed",
        text: "Passed",
      };
    }
  }
};

const BuildSnippet: React.FC<{ build: Build; showBorder?: boolean }> = ({
  build,
  showBorder = false,
}) => {
  const { colors, fontSizes, space } = useTheme();
  const {
    id,
    appVersion,
    appName,
    status,
    createdAt,
    releasedAt,
    versionControlInfo,
  } = build;
  const { commitId, versionControlSystemName } = versionControlInfo || {};
  const { appId } = useStore((state) => ({
    appId: state.selectedApp,
  }));

  const { eligibility } = determineReleaseEligibility(build);

  const date = new Date(parseDate(createdAt));
  const isValidDate = date && date.toLocaleString() !== "Invalid Date";

  const { tooltip, text, tagProps } = getTooltipAndTagProps(
    eligibility.smokeTestState
  );

  return (
    <StyledBuildSnippet
      css={css`
        border-bottom: ${showBorder ? `1px solid ${colors.greys[7]}` : 0};
      `}
      to={`/apps/${appId}/builds/${id}`}
    >
      <div>
        <Heading as="h5" variant="h5">
          {appName} {releasedAt ? "release" : "build"}{" "}
          {appVersion ? `v${appVersion}` : ""}
        </Heading>
        <BuildTag status={status} />
        <BuildReleaseTag build={build} style={{ marginLeft: space[5] }} />
        {commitId && (
          <Tooltip title="Commit ID">
            <Tag style={{ marginLeft: space[5] }}>
              {versionControlSystemName === "git"
                ? commitId.substring(0, 7)
                : commitId}
            </Tag>
          </Tooltip>
        )}

        {isBuildSupportsSmokeTests(build) && build.smokeTest && (
          <Tooltip title={tooltip}>
            <Tag style={{ ...tagProps, marginLeft: space[5] }}>
              Smoke Test {text}
            </Tag>
          </Tooltip>
        )}
        <Box marginLeft={5}>
          {isBuildCancellable(build) && (
            <CancelBuildButton build={build} shouldUseIcon={true} />
          )}
          <RerunFailedBuildButton build={build} shouldUseIcon />
        </Box>
      </div>
      <div>
        {isValidDate && (
          <Text color="support">
            <ReactTimeAgo date={date} />
          </Text>
        )}
        <RightOutlined
          style={{ fontSize: fontSizes[3], color: colors.support }}
        />
      </div>
    </StyledBuildSnippet>
  );
};

const StyledBuildSnippet = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => `${p.theme.space[5]}px ${p.theme.space[7]}px`};

  &:hover {
    background: ${(p) => p.theme.colors.greys[9]};
  }

  p,
  h5 {
    margin-bottom: 0;
  }

  p {
    text-align: right;
    @media only screen and (max-width: ${(p) => p.theme.breakpoints[1]}) {
      display: none;
    }
  }

  & > div:first-of-type {
    justify-content: flex-start;
    flex: 5;
  }

  & > div:last-of-type {
    justify-content: flex-end;
    flex: 3;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h5,
    p {
      margin-right: ${(p) => p.theme.space[5]}px;
      margin-top: ${(p) => p.theme.space[4]}px;
      margin-bottom: ${(p) => p.theme.space[4]}px;
    }
  }
`;

export default BuildSnippet;
