import React from "react";
import styled from "@emotion/styled";
import { ChromePicker } from "react-color";
import { CaretDownFilled } from "@ant-design/icons";
import tc from "tinycolor2";

const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  font-size: 7px;
  right: 8px;
  bottom: 8px;
  line-height: 0;
  color: ${(props: { isDark: boolean }) =>
    props.isDark ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.8)"};
`;

const InnerColor = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;

const Popover = styled.div`
  position: absolute;
  z-index: 2;
  line-height: 1;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

interface IProps {
  onChangeComplete: (color: { hex: string }) => any;
  color: string;
  popoverStyle?: object;
}

interface IState {
  displayColorPicker: boolean;
  color: string;
}

class SwatchPicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: props.color,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeComplete = (color: { hex: string }) => {
    this.setState({ color: color.hex });
    this.props.onChangeComplete(color);
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Swatch onClick={this.handleClick}>
          <IconContainer isDark={tc(this.state.color).getBrightness() > 128}>
            <CaretDownFilled />
          </IconContainer>
          <InnerColor color={this.state.color} />
        </Swatch>
        {this.state.displayColorPicker ? (
          <Popover style={this.props.popoverStyle}>
            <Cover onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              onChangeComplete={this.handleChangeComplete}
              disableAlpha={true}
            />
          </Popover>
        ) : null}
      </div>
    );
  }
}

export default SwatchPicker;
