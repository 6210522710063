// libs
import React from "react";
import { variant } from "styled-system";

// components
import { Typography, ITypography } from "./Primitives";

// utils
import styled, { Theme } from "../../~reusables/contexts/ThemeContext";

type TextVariants = "body1" | "body2" | "body3" | "caption";
interface IText extends ITypography {
  variant?: TextVariants;
  fontWeight?: number;
  color?: string;
}

const getSharedStyles = (t: Theme, p: IText) => ({
  color: p.color || t.colors.text,
  fontWeight: p.fontWeight || t.fontWeights[3],
  marginBottom: p.mb || 0,
});

const textVariants = (
  t: Theme,
  p: IText
): { [name in TextVariants]: object } => ({
  body1: {
    ...getSharedStyles(t, p),
    lineHeight: t.lineHeights.copy,
    fontSize: [t.fontSizes[3], t.fontSizes[4]],
  },
  body2: {
    ...getSharedStyles(t, p),
    lineHeight: t.lineHeights.copy,
    fontSize: [t.fontSizes[2], t.fontSizes[3]],
  },
  body3: {
    ...getSharedStyles(t, p),
    lineHeight: t.lineHeights.copy,
    fontSize: [t.fontSizes[1], t.fontSizes[2]],
  },
  caption: {
    ...getSharedStyles(t, p),
    color: t.colors.support,
    lineHeight: t.lineHeights.solid,
    fontSize: [t.fontSizes[0], t.fontSizes[1]],
    letterSpacing: t.letterSpacing.mega,
  },
});

export const Text: React.FC<IText> = ({ children, ...rest }) => (
  <StyledText {...rest}>{children}</StyledText>
);

const StyledText = styled(Typography)<IText>`
  ${({ theme, ...props }) =>
    variant({
      variants: textVariants(theme, props),
    })};
`;

Text.defaultProps = {
  variant: "body2",
};
