import { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import { callFirebaseFunction } from "../firebase/functions";
import { customers, invoices } from "stripe";

export const retrieveCustomer = callFirebaseFunction("retrieveCustomer");
export const retrieveUpcomingInvoice = callFirebaseFunction(
  "retrieveUpcomingInvoice"
);
export const cancelSubscription = callFirebaseFunction("cancelSubscription");

export const useRetrieveCustomer = (
  stripeCustomerId: string,
  {
    expand,
  }: {
    expand: string[];
  } = { expand: [] }
) => {
  const isMounted = useRef(false);
  const [isLoading, setLoading] = useState(true);
  const [customer, setCustomer] = useState<customers.ICustomer>(null);
  const [error, setError] = useState<string>(null);
  const [requestDate, setRequestDate] = useState<number>(Date.now());
  const reloadCustomer = () => {
    setCustomer(null);
    setLoading(true);
    setRequestDate(Date.now());
  };

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    if (!stripeCustomerId) {
      setLoading(false);
      return;
    }
    (async () => {
      setLoading(true);
      try {
        if (!isMounted.current) {
          return;
        }
        const c = await retrieveCustomer({ stripeCustomerId, expand });
        if (!isMounted.current) {
          return;
        }
        setCustomer(c.data);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    })();
  }, [stripeCustomerId, requestDate]);

  useEffect(() => {
    if (error) {
      Modal.error({
        title: "Error retrieving customer billing details",
        content: error,
      });
    }
  }, [error]);

  return { customer, isLoading, setCustomer, error, reloadCustomer };
};

export const useRetrieveUpcomingInvoice = (
  stripeCustomerId: string,
  subscriptionId: string | null
) => {
  const [isLoading, setLoading] = useState(true);
  const [upcomingInvoice, setUpcomingInvoice] = useState<invoices.IInvoice>(
    null
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!stripeCustomerId || !subscriptionId) {
      setLoading(false);
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const { data } = await retrieveUpcomingInvoice({
          stripeCustomerId,
          subscriptionId,
        });
        setUpcomingInvoice(data);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    })();
  }, [stripeCustomerId, subscriptionId]);

  useEffect(() => {
    if (error) {
      Modal.error({
        title: "Error retrieving upcoming invoice",
        content: error,
      });
    }
  }, [error]);

  return { upcomingInvoice, isLoading, setUpcomingInvoice, error };
};
