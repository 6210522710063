// modules
import React, { AnchorHTMLAttributes, useState } from "react";

// components
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  CopyOutlined,
  QuestionCircleFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

// logic
import { copyToClipboard } from "../../~reusables/util";
import styled from "../../~reusables/contexts/ThemeContext";

const StyledSpan = styled.span`
  margin-left: ${(p) => p.theme.space[4]}px;
  opacity: 0.7;
  transition: all 200ms ease;
  cursor: pointer;
  color: ${(p) => p.theme.colors.primary};
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

interface ICopyableText {
  displayText: string | React.ReactNode;
  copyText: string;
}

export const CopyableText: React.FC<ICopyableText> = ({
  displayText,
  copyText,
}) => (
  <span>
    {displayText}
    <StyledSpan onClick={copyToClipboard(copyText, copyText)}>
      <CopyOutlined />
    </StyledSpan>
  </span>
);

interface IRevealableText {
  revealText: string;
}

export const RevealableText: React.FC<IRevealableText> = ({ revealText }) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <strong>
      {isHidden ? revealText.replace(/./gi, "*") : revealText}
      <StyledSpan>
        {isHidden ? (
          <EyeInvisibleOutlined onClick={() => setIsHidden(!isHidden)} />
        ) : (
          <EyeOutlined onClick={() => setIsHidden(!isHidden)} />
        )}
      </StyledSpan>
    </strong>
  );
};

interface ITooltipText {
  toolTip: string | JSX.Element;
  text: string | JSX.Element;
}

export const TooltipText: React.FC<ITooltipText> = ({ toolTip, text }) => {
  return (
    <strong>
      {text}
      <Tooltip title={toolTip}>
        <StyledSpan>
          <QuestionCircleFilled />
        </StyledSpan>
      </Tooltip>
    </strong>
  );
};

export const Code = styled.code`
  background: #f1f1f1;
  color: #1890ff;
  border-radius: 2px;
  padding: 1px 3px;
  font-size: inherit;
`;

interface ITooltipLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to?: string;
}

export const TooltipLink: React.FC<ITooltipLink> = ({
  to,
  children,
  ...props
}) => {
  return to ? (
    <StyledLink to={to}>{children}</StyledLink>
  ) : (
    <StyledAnchor {...props}>{children}</StyledAnchor>
  );
};

export const StyledAnchor = styled.a`
  color: white;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;
