//  modules
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "../../~reusables/contexts/ThemeContext";

// components
import { StyledAntdCard } from "../../components/atoms/StyledAntdCard";
import { BuildSnippets } from "../../components/elements/BuildSnippet";

// logic
import { useStore } from "../../store";
import { returnSortedBuilds } from "../../~reusables/util";

const Builds: React.FC<RouteComponentProps> = () => {
  const builds = useStore((state) => state.buildState.builds);

  return (
    <StyledBuilds>
      <StyledAntdCard title="Builds" bodyStyle={{ padding: 0 }}>
        <BuildSnippets builds={returnSortedBuilds(builds)} />
      </StyledAntdCard>
    </StyledBuilds>
  );
};

const StyledBuilds = styled.main``;

export default Builds;
