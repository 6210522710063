// modules
import React, { useState, useEffect, useRef } from "react";
import css from "@emotion/css";

// components
import { Checkbox, Collapse, Button, Modal } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import buildAppChecklistImage from "../../~reusables/images/build-app-checklist-image.png";
import downloadButtonChecklistImage from "../../~reusables/images/download-button-checklist-image.png";
import nativeFeaturesChecklistImage from "../../~reusables/images/native-features-checklist-image.png";
import documentationChecklistImage from "../../~reusables/images/documentation-checklist-image.png";
import feedbackChecklistImage from "../../~reusables/images/feedback-checklist-image.png";
import DownloadButtonBuilder from "./DownloadButtonBuilder";

// functions/logic
import { updateFirestoreApp } from "../../~reusables/actions";
import { hrefLinks } from "../../~reusables/constants";
import { track } from "../../~reusables/util/analytics";
import { openExternalLink } from "../../~reusables/util";
import {
  useStore,
  $completedChecklistItems,
  selectedApp,
  $shouldMinimizeOnboarding,
  $macCertExists,
  $windowsCertExists,
} from "../../store";
import { CustomDomain } from "../molecules/CustomDomain";
import { Space } from "../atoms/Space";
import { MacCertificate } from "../molecules/Certificates/MacCertificate";
import { WindowsCertificate } from "../molecules/Certificates/WindowsCertificate";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import DocumentationLinks from "../molecules/DocumentationLinks";
import { StyledAntdCard, CardHeaderButton } from "../atoms/StyledAntdCard";
import { Artifacts } from "../molecules/ConfigurableArtifacts";
import { IApp, IChecklistItem } from "@todesktop/shared";

export const NoCodeChecklistAccordion: React.FC<{
  app: IApp;
}> = ({ app }) => {
  const completedItems = useStore($completedChecklistItems);

  const checklistItems = getNoCodeChecklistItems({
    feedbackCb: () => {
      window.Intercom
        ? window.Intercom("showNewMessage")
        : (window.location.href = "mailto:hi@todesktop.com");
      track({
        event: "Open Feedback Dialog",
        properties: { source: "checklist accordion item" },
      });
    },
    documentationCb: openExternalLink(hrefLinks.documentationOverview, {
      source: "checklist accordion item",
    }),
    downloadButtonCb: () => {
      setShowButtonBuilder(!showBtnBuilder);
      track({
        event: "Create Download Button",
        properties: { source: "checklist accordion item" },
      });
    },
  });

  const [showBtnBuilder, setShowButtonBuilder] = useState(false);
  // sets the default active key to be the first item not checked
  const [defaultActiveKey] = useState(
    getDefaultActiveKey(checklistItems, completedItems)
  );

  return (
    <>
      {app.subscription && (
        <Modal
          title="Create a Download Button for your Web App"
          centered
          footer={null}
          visible={showBtnBuilder}
          onCancel={() => setShowButtonBuilder(!showBtnBuilder)}
          closable={true}
        >
          <DownloadButtonBuilder app={app} />
        </Modal>
      )}
      <BaseCollapse
        defaultActiveKey={defaultActiveKey}
        checklistItems={checklistItems}
      >
        {checklistItems.map((checklistItem, index) => (
          <BaseCollapsePanel
            key={index}
            checklistItem={checklistItem}
            completedItems={completedItems}
          >
            <section
              css={css`
                .checklist-item-body {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;

                  div {
                    flex: 5;
                    margin-right: 8px;
                    margin-bottom: 12px;
                    min-width: 200px;

                    p,
                    li {
                      font-weight: 500;
                      font-size: 14px;
                    }

                    li {
                      margin-bottom: 6px;
                    }

                    a {
                      color: #4993f2;
                      text-decoration: underline;
                    }
                  }

                  .checklist-item-image {
                    flex: 4;
                    min-width: 240px;
                    border-radius: 6px;
                    img {
                      width: 100%;
                      border: 1px solid #a0aec0;
                      border-radius: 6px;
                    }
                  }
                }
              `}
            >
              {checklistItem.body}
            </section>
          </BaseCollapsePanel>
        ))}
      </BaseCollapse>
    </>
  );
};

export const CLIChecklistAccordion = () => {
  const completedItems = useStore($completedChecklistItems);
  const checklistItems = getCLIChecklistItems();
  const shouldMinimizeOnboarding = useStore($shouldMinimizeOnboarding);

  if (!shouldMinimizeOnboarding) {
    return (
      <StyledAntdCard
        title="Next steps"
        extra={
          <CardHeaderButton
            onClick={() =>
              updateChecklistItem("shouldMinimizeOnboarding", true)
            }
          >
            Minimize
          </CardHeaderButton>
        }
      >
        <BaseCollapse checklistItems={checklistItems}>
          {checklistItems.map((checklistItem, index) => (
            <BaseCollapsePanel
              key={index}
              checklistItem={checklistItem}
              completedItems={completedItems}
              disableManualCheckboxing={true}
            >
              {checklistItem.body}
            </BaseCollapsePanel>
          ))}
        </BaseCollapse>
        <DocumentationLinks />
      </StyledAntdCard>
    );
  }
  return null;
};

const BaseCollapse: React.FC<{
  defaultActiveKey?: number;
  checklistItems: IEnhancedChecklistItem[];
}> = ({ children, defaultActiveKey, checklistItems }) => (
  <Collapse
    accordion
    onChange={(key) => {
      if (key) {
        track({
          event: "Expanded Checklist Accordion",
          properties: {
            checklistItem: checklistItems[key as string].id,
          },
        });
      }
    }}
    defaultActiveKey={[defaultActiveKey]}
    style={{ border: "none" }}
    expandIcon={({ isActive }) => (
      <CaretRightOutlined
        style={{ fontSize: "16px" }}
        rotate={isActive ? 90 : 0}
      />
    )}
  >
    {children}
  </Collapse>
);

const BaseCollapsePanel: React.FC<{
  completedItems: IChecklistItem;
  key: number;
  disableManualCheckboxing?: boolean;
  checklistItem: IEnhancedChecklistItem;
}> = ({
  children,
  key,
  completedItems,
  disableManualCheckboxing,
  checklistItem: { id, title, emoji },
  ...props
}) => (
  <Collapse.Panel
    {...props}
    key={key}
    header={`${title} ${emoji}`}
    extra={
      <CustomCheckbox
        disabled={disableManualCheckboxing}
        scale={1.4}
        checked={completedItems[id]}
        onChange={(e) => {
          updateFirestoreApp(
            {
              meta: {
                completedChecklistItems: {
                  [id]: e.target.checked,
                },
              },
            },
            false
          );
          track({
            event: "Checked Checklist Item",
            properties: {
              item: id,
              checked: e.target.checked,
            },
          });
        }}
      />
    }
  >
    {children}
  </Collapse.Panel>
);

export const CustomCheckbox: React.FC<{
  scale: number;
  disabled?: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  checked: boolean;
  borderColor?: string;
}> = ({ scale = 1, disabled, onChange, checked, borderColor }) => (
  <Checkbox
    css={css`
      &.ant-checkbox-wrapper {
        transform: scale(${scale});
      }

      .ant-checkbox-inner,
      .ant-checkbox-checked {
        border-radius: 100%;
        border-color: ${borderColor || "auto"};
      }

      .ant-checkbox-checked {
        background-color: #006df6;
        border-color: #006df6;

        &:after {
          border-radius: 100%;
        }
      }
    `}
    onClick={(e) => e.stopPropagation()}
    onChange={(e) => {
      if (disabled) {
        return;
      }
      onChange(e);
    }}
    checked={checked}
  />
);

export const noCodeChecklistItemIds = {
  buildApp: "buildApp",
  downloadButton: "downloadButton",
  nativeFeatures: "nativeFeatures",
  documentation: "documentation",
  feedback: "feedback",
};

// The following is a list of up-to date checklist items
// The id, and whether it's checked or not (boolean) is stored
// in the db. Apart from the id, you can freely update any of the
// item properties. Can also add, remove and shift items as desired.
export const getNoCodeChecklistItems = (
  props: {
    buildAppCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    downloadButtonCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    notificationCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    documentationCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    feedbackCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  } = {}
) => [
  {
    id: "buildApp",
    title: "Build app with ToDesktop builder",
    body: (
      <div className="checklist-item-body">
        <div>
          <p>
            Use the ToDesktop builder to customize and create your app. Code
            signing, auto-updates, native installers and much more are included
            by default.
          </p>
        </div>
        <div className="checklist-item-image">
          <img src={buildAppChecklistImage} alt="Image" />
        </div>
      </div>
    ),
    emoji: "🛠",
    callback: props.buildAppCb,
  },
  {
    id: "downloadButton",
    title: "Add a desktop download button to your site",
    body: (
      <div className="checklist-item-body">
        <div>
          <p>
            Sharing your desktop app is just as important as building it. Use
            our button builder to design a download button to embed on your
            site.
          </p>
          <Button type="primary" onClick={props.downloadButtonCb}>
            Create download button
          </Button>
        </div>
        <div className="checklist-item-image">
          <img src={downloadButtonChecklistImage} alt="Image" />
        </div>
      </div>
    ),
    emoji: "⬇️",
    callback: props.downloadButtonCb,
  },
  {
    id: "nativeFeatures",
    title: "Use native desktop features",
    body: (
      <div className="checklist-item-body">
        <div>
          <p>
            Our Javascript API is the bridge between your web application and
            the desktop app. You can do the following with it:
          </p>
          <ul>
            <li>
              <a
                onClick={openExternalLink(hrefLinks.apiBadges, {
                  source: "checklist accordion item",
                })}
              >
                Add badges to your app icon
              </a>
            </li>
            <li>
              <a
                onClick={openExternalLink(hrefLinks.recipesNotifications, {
                  source: "checklist accordion item",
                })}
              >
                Send native notifictions
              </a>
            </li>
            <li>
              <a
                onClick={openExternalLink(hrefLinks.recipesSeparatingLogic, {
                  source: "checklist accordion item",
                })}
              >
                Separate desktop and app logic
              </a>
            </li>
          </ul>
        </div>
        <div className="checklist-item-image">
          <img src={nativeFeaturesChecklistImage} alt="Image" />
        </div>
      </div>
    ),
    emoji: "❇️",
    callback: props.notificationCb,
  },
  {
    id: "documentation",
    title: "Read the docs to customize your app further",
    body: (
      <div className="checklist-item-body">
        <div>
          <p>
            By now, you're a ToDesktop pro. Here you'll find details on how to
            further customize your desktop app.
          </p>
          <Button type="primary" onClick={props.documentationCb}>
            Read the docs
          </Button>
        </div>
        <div className="checklist-item-image">
          <img src={documentationChecklistImage} alt="Image" />
        </div>
      </div>
    ),
    emoji: "📑",
    callback: props.documentationCb,
  },
  {
    id: "feedback",
    title: "Send us feedback",
    body: (
      <div className="checklist-item-body">
        <div>
          <p>
            Got a feature you'd love us to implement? Let us know, we read and
            reply to all feedback.
          </p>
          <Button type="primary" onClick={props.feedbackCb}>
            Send feedback
          </Button>
        </div>
        <div className="checklist-item-image">
          <img src={feedbackChecklistImage} alt="Image" />
        </div>
      </div>
    ),
    emoji: "🚀",
    callback: props.feedbackCb,
  },
];

export const updateChecklistItem = (key: string, value: boolean) =>
  updateFirestoreApp(
    {
      meta: {
        completedChecklistItems: {
          [key]: value,
        },
      },
    },
    false
  );

export const useChecklistUpdate = () => {
  const {
    customDomain,
    meta,
    shouldCreate32BitWindowsArtifacts,
    shouldCreateArm64WindowsArtifacts,
    shouldCreateAppImages,
    shouldCreateAppXFiles,
    shouldCreateDMGs,
    shouldCreateDebianPackages,
    shouldCreateMSIInstallers,
    shouldCreateMacAppStoreFiles,
    shouldCreateMacPKG,
    shouldCreateMacZipInstallers,
    shouldCreateMacUniversalInstaller,
    shouldCreateNSISInstallers,
    shouldCreateNSISWebInstaller,
    shouldCreateRPMPackages,
    shouldCreateSnapFiles,
  } = useStore(selectedApp);
  const macCertExists = useStore($macCertExists);
  const windowsCertExists = useStore($windowsCertExists);
  const hasMounted = useRef(false);

  /**
   * CLI Apps
   */
  useEffect(() => {
    updateChecklistItem(
      cliChecklistItemIds.customDomain,
      customDomain ? true : false
    );
  }, [customDomain]);

  useEffect(() => {
    updateChecklistItem(
      cliChecklistItemIds.macCert,
      macCertExists ? true : false
    );
  }, [macCertExists]);

  useEffect(() => {
    updateChecklistItem(
      cliChecklistItemIds.windowsCert,
      windowsCertExists ? true : false
    );
  }, [windowsCertExists]);

  useEffect(() => {
    /**
     * Set artifacts onboarding as checked if user configures any of the
     * platform artifacts. By using `hasMounted`, we're just ensuring that
     * the updateChecklistItem function is not triggered on initial mount
     * and is only triggered if anything in the dependency array changes
     */
    if (hasMounted.current === true) {
      if (
        meta &&
        meta.completedChecklistItems &&
        !meta.completedChecklistItems[cliChecklistItemIds.artifacts]
      ) {
        updateChecklistItem(cliChecklistItemIds.artifacts, true);
      }
    } else {
      hasMounted.current = true;
    }
  }, [
    shouldCreate32BitWindowsArtifacts,
    shouldCreateArm64WindowsArtifacts,
    shouldCreateAppImages,
    shouldCreateAppXFiles,
    shouldCreateDMGs,
    shouldCreateDebianPackages,
    shouldCreateMSIInstallers,
    shouldCreateMacAppStoreFiles,
    shouldCreateMacPKG,
    shouldCreateMacZipInstallers,
    shouldCreateMacUniversalInstaller,
    shouldCreateNSISInstallers,
    shouldCreateNSISWebInstaller,
    shouldCreateRPMPackages,
    shouldCreateSnapFiles,
    meta,
  ]);

  /**
   * NoCode Apps
   */
  const hasBuiltApp =
    meta && meta.publishedVersions && meta.publishedVersions.version;

  useEffect(() => {
    if (hasBuiltApp) {
      updateChecklistItem(noCodeChecklistItemIds.buildApp, true);
    }
  }, [hasBuiltApp]);
};

export const cliChecklistItemIds = {
  macCert: "macCert",
  windowsCert: "windowsCert",
  customDomain: "customDomain",
  artifacts: "artifacts",
};

export const getCLIChecklistItems = (
  props: {
    customDomainCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    macCertCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    windowsCertCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    artifactsCb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  } = {}
) => [
  {
    id: cliChecklistItemIds.macCert,
    title: "Set up your Mac certificate",
    body: <MacCertificate />,
    emoji: "📄",
    callback: props.macCertCb,
  },
  {
    id: cliChecklistItemIds.windowsCert,
    title: "Set up your Windows certificate",
    body: <WindowsCertificate />,
    emoji: "📃",
    callback: props.windowsCertCb,
  },
  {
    id: cliChecklistItemIds.customDomain,
    title: "Set up your custom domain",
    body: (
      <Space size="md" direction="column">
        <CustomDomain />
      </Space>
    ),
    emoji: "🌎",
    callback: props.customDomainCb,
  },
  {
    id: cliChecklistItemIds.artifacts,
    title: "Configure your build artifacts",
    body: <Artifacts />,
    emoji: "🛠",
    callback: props.artifactsCb,
  },
];

export const getDefaultActiveKey = (
  checklistItems: IEnhancedChecklistItem[],
  completedItems: IChecklistItem
) =>
  Object.values(checklistItems)
    .map((item) => completedItems[item.id] === true)
    .findIndex((item) => item === false);

interface IEnhancedChecklistItem {
  id: string;
  title: string;
  body: JSX.Element;
  emoji: string;
  callback?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface CustomWindow extends Window {
  Intercom: any;
}
declare let window: CustomWindow;
