import { useHistory } from "react-router";
import queryString from "query-string";
import { Modal, Space, Divider, List, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import { updateUIState } from "../../~reusables/actions";
import { InviteUserForm } from "./InviteUserForm";
import { UsersList } from "./UsersList";
import {
  deleteAcceptedUser,
  deleteInvitedUser,
  getInvitedUserData,
  upsertAcceptedUser,
} from "../../~reusables/firebase";

export const ManageUsers: React.FC = () => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const [modalVisible, setModalVisibility] = useState(true);
  const { user, acceptedUsers, invitedUsers } = useStore((state) => ({
    user: state.user,
    acceptedUsers: state.acceptedUsers,
    invitedUsers: state.invitedUsers,
  }));

  const closeModal = () => setModalVisibility(false);

  const { id } = query || {};
  useEffect(() => {
    async function updateUsersIHaveAcceptedInvitesFrom() {
      if (id && typeof id === "string" && user.email) {
        // remove the url search params (?id=xyz) related to the invite
        history.replace(window.location.pathname);

        try {
          const invitedUser = await getInvitedUserData(id, user.email);

          if (invitedUser) {
            const { sender } = invitedUser;
            await upsertAcceptedUser(id, sender);
            message.success(
              `You now have delegated access to ${sender.firstName}'s apps`
            );
          }
        } catch (err) {
          message.error("Invite is invalid");
        }
      }
    }

    updateUsersIHaveAcceptedInvitesFrom();
  }, [id, user.email]);

  return (
    <Modal
      visible={modalVisible}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={() => updateUIState(null)}
    >
      <Space direction="vertical" size="large">
        <UsersList
          title="Managed invited users"
          description="Manage users who you would like to delegate account access to. Delegated users won't be able to create/delete apps or manage billing information."
        >
          <List
            dataSource={invitedUsers}
            header={<InviteUserForm />}
            renderItem={(item) => (
              <List.Item key={item.email}>
                <List.Item.Meta title={item.name} description={item.email} />
                <Button
                  size="small"
                  type="ghost"
                  danger
                  onClick={() => deleteInvitedUser(item.email)}
                >
                  Remove user
                </Button>
              </List.Item>
            )}
          />
        </UsersList>
        <Divider />
        <UsersList
          title="Manage delegated accounts"
          description="Manage the users who you have accepted invites from. "
        >
          <List
            dataSource={acceptedUsers}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={<a href="https://ant.design">{item.firstName}</a>}
                  description={item.email}
                />
                <Button
                  size="small"
                  type="ghost"
                  danger
                  onClick={() => deleteAcceptedUser(item.id)}
                >
                  Remove user
                </Button>
              </List.Item>
            )}
          />
        </UsersList>
      </Space>
    </Modal>
  );
};
