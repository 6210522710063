import React from "react";
import styled from "../../~reusables/contexts/ThemeContext";

export const BlockBackground: React.FC = ({ children }) => {
  return (
    <BlockBackgroundRoot>
      <div className="top-blocks">
        <div className="block-row">
          <div className="block" />
          <div className="block" />
          <div className="block" />
        </div>
        <div className="block-row">
          <div className="block" />
          <div className="block" />
        </div>
        <div className="block-row">
          <div className="block" />
        </div>
      </div>
      <div className="content-block">{children}</div>
      <div className="bottom-blocks">
        <div className="block-row">
          <div className="block" />
        </div>
        <div className="block-row">
          <div className="block" />
          <div className="block" />
        </div>
        <div className="block-row">
          <div className="block" />
          <div className="block" />
          <div className="block" />
        </div>
        <div className="block-row">
          <div className="block" />
          <div className="block" />
          <div className="block" />
          <div className="block" />
        </div>
      </div>
    </BlockBackgroundRoot>
  );
};

export const BlockBackgroundRoot = styled.main`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #007aff 0%, #1f47cd 100%);
  position: relative;

  .content-block {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin: 24px;
    }

    .styled-authenticate {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div,
      & > p {
        margin: ${(p) => p.theme.space[7]}px;

        span {
          color: ${(p) => p.theme.colors.primary};
          font-weight: ${(p) => p.theme.fontWeights[4]};
          cursor: pointer;
        }
      }

      & > section {
        border-radius: ${(p) => p.theme.radii[2]}px;
        background: ${(p) => p.theme.colors.white};
        padding: ${(p) => p.theme.space[8]}px;
        box-shadow: ${(p) => p.theme.shadows.card};
        -webkit-box-shadow: ${(p) => p.theme.shadows.card};
        -mox-box-shadow: ${(p) => p.theme.shadows.card};
      }
    }
  }

  .top-blocks,
  .bottom-blocks {
    position: absolute;
  }

  .top-blocks {
    top: 0;
    right: 0;
  }

  .top-blocks .block-row {
    justify-content: flex-end;
  }

  .bottom-blocks {
    bottom: 0;
  }

  .block-row {
    display: flex;
  }

  .block {
    background: #1840c6;
    width: 72px;
    height: 72px;
  }
`;
