import { store, update, IGlobalState, selectedApp } from "../../store";
import { onBuildListUpdate } from "../firebase";
import { Build, IApp } from "@todesktop/shared";
import { findReleasedBuildId } from "../util";

export const initialBuildState = {
  builds: {},
  hasBuildsDataLoaded: false,
  selectedBuild: "",
  releasedBuildId: "",
};
export const resetBuildSubscriptions = () => {
  if (buildListUnsubscribe) {
    buildListUnsubscribe();
    resetBuildState();
  }
};
let buildListUnsubscribe: () => any;

// this function re-executes whenever the selectedApp has changed
export const subscribeToBuildCollectionUpdates = (appId: IApp["id"]) => {
  resetBuildSubscriptions();

  const state = store.getRawState();
  const app = selectedApp(state);

  // unsubscribe from old listeners, but do not subscribe to new ones
  if (!app || app.appType !== "electron") {
    return setBuildsDataAsLoaded();
  }

  const user = state.appToUser[appId];
  if (user) {
    buildListUnsubscribe = onBuildListUpdate(
      user.id,
      appId,
      async (appBuilds) => {
        update((draft) => {
          appBuilds.forEach((build) => {
            draft.buildState.builds[build.id] = build;
          });

          if (!draft.buildState.releasedBuildId) {
            draft.buildState.releasedBuildId =
              app.meta?.latestReleaseBuildId || findReleasedBuildId(appBuilds);
          }
        });

        setBuildsDataAsLoaded();
      }
    );
  }
};

const resetBuildState = () =>
  update((draft) => {
    draft.buildState = initialBuildState;
  });

export const selectBuild = (buildId: Build["id"]) =>
  update((draft) => {
    draft.buildState.selectedBuild = buildId;
  });

export const selectedBuild = (state: IGlobalState) =>
  selectBuildFromID(state.buildState.selectedBuild)(state);

export const selectedReleasedBuild = (state: IGlobalState) =>
  selectBuildFromID(state.buildState.releasedBuildId)(state);

export const selectBuildFromID = (buildId: Build["id"]) => (
  state: IGlobalState
) => {
  const foundBuild = state.buildState.builds[buildId];

  if (foundBuild) {
    return foundBuild;
  }

  return null;
};

export const setBuildsDataAsLoaded = () => {
  update((draft) => {
    draft.buildState.hasBuildsDataLoaded = true;
  });
};
