/**
 * red #f5222d
 * orange #fa8c16
 * green #52c41a
 * blue #1677ff
 * gray #8c8c8c
 */

import { Typography } from "antd";
import React from "react";
import { Span } from "./Primitives";

const { Text } = Typography;

export interface PillProps {
  color: "gray" | "red" | "purple" | "orange" | "yellow" | "blue" | "green";
}

const fillMap: Record<PillProps["color"], string> = {
  gray: "#8c8c8c",
  red: "#f5222d",
  orange: "#fa8c16",
  yellow: "#fadb14",
  blue: "#1677ff",
  green: "#52c41a",
  purple: "#722ed1",
};

export const Pill: React.FC<PillProps> = ({ color, children }) => {
  return (
    <Span
      css={{
        display: "inline-flex",
        alignItems: "center",
        gap: "6px",
        border: `1px solid #D9D9D9`,
        padding: "0 8px",
        borderRadius: "16px",
      }}
    >
      <Span
        css={{
          display: "inline-block",
          background: fillMap[color],
          minWidth: "6px",
          minHeight: "6px",
          borderRadius: "100%",
        }}
      />
      <Text style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{children}</Text>
    </Span>
  );
};
