import React, { SFC } from "react";
import styled from "@emotion/styled";
import dock from "../../~reusables/images/dock@2x.png";
import macDesktop from "../../~reusables/images/bg.jpg";
import winDesktop from "../../~reusables/images/win-bg.jpg";
import AppIcon from "./AppIcon";
import Menubar from "./Menubar";
import { IApp } from "@todesktop/shared";

const Background = styled.div`
  background: ${(props: IBasicProps) =>
    props.os === "mac" ? `url(${macDesktop});` : `url(${winDesktop});`};
  width: 100%;
  /* width: 568px; */
  height: 293px;
  overflow: hidden;
  background-size: cover;
  position: relative;
  border-radius: 3px;
  ${(props: IBasicProps) =>
    props.noBorder &&
    `
  top: -120px;
  left: -80px;
  `};
`;

const DockBackground = styled.div`
  width: 313px;
  height: 115px;
  background-image: url(${dock});
  background-size: 100%;
  position: absolute;
  bottom: -1px;
  left: calc(50% - 157px);
`;

const Positioner = styled.div`
  position: absolute;
  bottom: 17px;
  width: 100%;
`;

interface IBasicProps {
  title?: string;
  iconUrl?: string;
  noBorder?: boolean;
  os?: "mac" | "windows";
  iconStyle?: {};
  appType?: IApp["appType"];
}

export const DockInner: SFC<IBasicProps> = ({ title, iconUrl, iconStyle }) => (
  <>
    <DockBackground />
    <Positioner>
      <AppIcon title={title} iconUrl={iconUrl} iconStyle={iconStyle} />
    </Positioner>
  </>
);

const Dock: SFC<IBasicProps> = ({ title, iconUrl, noBorder, iconStyle }) => (
  <Background noBorder={noBorder} os={"mac"}>
    <DockInner title={title} iconUrl={iconUrl} iconStyle={iconStyle} />
  </Background>
);

const Desktop: SFC<IBasicProps> = (props) => {
  if (props.os === "windows") {
    return (
      <Background>
        <AppIcon {...props} />
      </Background>
    );
  }

  if (props.appType === "menubar") {
    return (
      <Background os={"mac"}>
        <Menubar />
      </Background>
    );
  }

  return <Dock {...props} />;
};

export default Desktop;
