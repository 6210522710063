import React from "react";
import css from "@emotion/css";

import { IFlex, Flex, Container, Box } from "../atoms/Primitives";
import { Space } from "../atoms/Space";
import { Heading } from "../atoms/Heading";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { useWindowSize } from "../../~reusables/hooks/useWindowSize";

interface IChecklistTabs extends IFlex {
  activeKey: string;
  onChange: (value: string) => void;
}

export const ChecklistTabs: React.FC<IChecklistTabs> = ({
  children,
  activeKey,
  onChange,
  ...props
}) => {
  const items = React.Children.toArray(children);
  const displayTab = items.find((item: any) => item.props.tabKey === activeKey);

  const { breakpoints } = useTheme();
  const windowSize = useWindowSize();
  const isTablet = windowSize.width < parseInt(breakpoints[1]);

  return (
    <Flex {...props} flexDirection={["column", "column", "row"]}>
      <Space
        css={css`
          @media only screen and (max-width: 767px) {
            overflow-x: scroll;
          }
        `}
        minHeight="84px"
        bg="rgba(230, 230, 231, 0.5)"
        px={5}
        py={[6, 6, 8]}
        mr={[0, 0, "32px"]}
        mb={["16px", "16px", 0]}
        direction={isTablet ? "row" : "column"}
        align={["flex-start", "flex-start", "flex-end"]}
        size="lg"
        minWidth="150px"
      >
        {items.map(({ props }: any) => (
          <Container
            flexDirection={["column", "column", "row"]}
            position="relative"
            key={props.tabKey}
          >
            <Heading
              cursor="pointer"
              onClick={() => onChange(props.tabKey)}
              variant="h6"
              as="h6"
              pr={[0, 0, "16px"]}
              textAlign={["center", "center", "right"]}
              color={activeKey === props.tabKey ? "black" : "support"}
            >
              {props.tab}
            </Heading>
            <Container
              right={["auto", "auto", "-16px"]}
              bottom={["-24px", "-24px", "auto"]}
              position="absolute"
              css={css`
                @media only screen and (max-width: 767px) {
                  left: 50%;
                  transform: translateX(-50%);
                }
              `}
            >
              {props.extra}
            </Container>
          </Container>
        ))}
      </Space>
      <Box>{displayTab}</Box>
    </Flex>
  );
};

interface IChecklistTabsPane {
  tab: string;
  tabKey: string;
  extra: React.ReactNode;
}

export const ChecklistTabsPane: React.FC<IChecklistTabsPane> = ({
  children,
}) => {
  return <div>{children}</div>;
};
