import React from "react";
import { Button } from "antd";
import { ChartData } from "../../libs/chartjs";

export function convertToCSV(data: ChartData, labelColumn: string) {
  // convert the data array to a data map with keys as the labels
  // and values as the corresponding data points
  const dataMap = data.datasets.reduce((acc, dataset) => {
    dataset.data.forEach((point, i) => {
      const label = data.labels[i];

      if (typeof label === "string") {
        acc[label] = acc[label] || {};
        acc[label][dataset.label] = point;
      }
    });
    return acc;
  }, {});

  // create an array of labels
  const labels = Object.keys(dataMap);

  // create an array of dataset labels
  const datasetLabels = data.datasets.map((dataset) => dataset.label);

  // create the CSV string, with the labels as the first row and the
  // corresponding data points as the remaining rows
  const csv = [
    (labelColumn ? [labelColumn, ...datasetLabels] : datasetLabels).join(","),
    ...labels.map((label) =>
      [
        label,
        ...datasetLabels.map((datasetLabel) => dataMap[label][datasetLabel]),
      ].join(",")
    ),
  ];

  return csv.join("\n");
}

export const DonwloadCSV: React.FC<{ data: ChartData; name: string }> = ({
  data,
  name,
}) => {
  const csv = convertToCSV(data, "Date");
  const dataURI = "data:text/csv;charset=utf-8," + encodeURI(csv);

  return (
    <Button type="link" href={dataURI} download={`${name}.csv`}>
      Download CSV
    </Button>
  );
};
