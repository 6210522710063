import React, { SFC } from "react";
import useInterval from "@use-it/interval";

const getCurrentTime = (): string => {
  const date = new Date();
  const fullTime = date.toLocaleTimeString("en-GB");
  const shortTime = fullTime.substring(0, fullTime.length - 3);
  return shortTime;
};

const CurrentTime: SFC = () => {
  const [time, setTime] = React.useState(getCurrentTime());

  useInterval(() => {
    setTime(getCurrentTime());
  }, 10000);

  return <>{time}</>;
};

export default CurrentTime;
