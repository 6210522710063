import { InputNumber, Form, Checkbox } from "antd";
import React, { useState, useEffect, useContext, useRef } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { usePrevious, AppFieldsContext } from "../../~reusables/util";
import { InputNumberProps } from "antd/lib/input-number";
import { Space } from "./Space";

interface IBasicProps {
  label?: string | JSX.Element;
  onChange: (value: number) => void;
  onImmediateChange?: (value: number) => void;
  onToggleChange?: (enabled: boolean) => void;
  validationSchema?: any;
  value: number;
  toggleable?: boolean;
  toggleValue?: boolean;
}

const formatter: InputNumberProps["formatter"] = (value) =>
  value ? `${value}px` : "";
const parser: InputNumberProps["parser"] = (value) =>
  Number((value || "").replace("px", ""));

const NumberField: React.FC<IBasicProps> = ({
  label,
  onChange,
  onImmediateChange,
  onToggleChange,
  validationSchema,
  value,
  toggleable,
  toggleValue,
}) => {
  const inputRef = useRef(undefined);
  const [fieldValue, setFieldValue] = useState(value);
  const [isFieldFocused, setFieldFocus] = useState(false);
  const strLabel =
    typeof label === "string" ? label : label.props.children.toString();

  useEffect(() => {
    if (!isFieldFocused) {
      valueChangeHandler(value);
    }
  }, [value]);

  useEffect(() => {
    if (onImmediateChange) {
      onImmediateChange(fieldValue);
    }
  }, [fieldValue, onImmediateChange]);

  const prevToggle = usePrevious(toggleValue);

  useEffect(() => {
    if (prevToggle !== toggleValue) {
      setEnabled(toggleValue);
    }
  }, [toggleValue]);

  const onFocus = () => {
    setFieldFocus(true);
  };
  const onBlur = async () => {
    setFieldFocus(false);
    ctx.updateFieldValue(strLabel, fieldValue);
    try {
      const validValue = validationSchema
        ? await validationSchema.validate(fieldValue)
        : fieldValue;
      onChange(validValue);
      setFieldError(null);
    } catch (error) {
      setFieldError(error.message);
    }
  };

  type MaybeString = string | undefined | null;
  const initialError: MaybeString = null;
  const [fieldError, setFieldError] = useState(initialError);
  const [isEnabled, setEnabled] = useState(toggleable ? toggleValue : true);

  const ctx = useContext(AppFieldsContext);
  useEffect(() => {
    ctx.registerField({
      id: strLabel,
      validationSchema,
      value,
      setFieldError,
      inputRef,
    });
    return function cleanup() {
      ctx.unregisterField(strLabel);
    };
  }, [label]);

  const valueChangeHandler = async (newValue: number) => {
    setFieldValue(newValue);
    try {
      if (validationSchema) {
        await validationSchema.validate(newValue);
      }
      setFieldError(null);
    } catch (error) {
      setFieldError(error.message);
    }
  };

  const toggleChange = (ev: CheckboxChangeEvent) => {
    const { checked } = ev.target;
    toggleChangeBool(checked);
  };

  const toggleChangeBool = (checked: boolean) => {
    setEnabled(checked);
    if (onToggleChange) {
      onToggleChange(checked);
    }
  };

  const shownError = isEnabled && fieldError;
  return (
    <Form.Item
      validateStatus={shownError ? "error" : undefined}
      help={shownError}
    >
      {toggleable ? (
        <Checkbox
          checked={isEnabled}
          onChange={toggleChange}
          style={{ display: "block" }}
        >
          {label}
        </Checkbox>
      ) : (
        <div>{label}</div>
      )}
      <Space direction="column" />
      <InputNumber
        ref={inputRef}
        disabled={!isEnabled}
        value={isEnabled ? fieldValue : 0}
        onChange={valueChangeHandler}
        formatter={formatter}
        parser={parser}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Form.Item>
  );
};

export default NumberField;
