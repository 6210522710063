// modules
import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import debounce from "lodash.debounce";
import css from "@emotion/css";
import styled from "@emotion/styled";

// components
import { Form, Select, InputNumber, Checkbox, Input, Tabs } from "antd";
import {
  DesktopOutlined,
  WindowsFilled,
  AppleFilled,
  QqOutlined,
  CopyFilled,
} from "@ant-design/icons";
import SwatchPicker from "../molecules/SwatchPicker";

// logic
import { DownloadLinkPlatformTypes } from "../../~reusables/types/types";
import { copyToClipboard } from "../../~reusables/util";
import { updateFirestoreApp } from "../../~reusables/actions";
import { track } from "../../~reusables/util/analytics";
import { IApp, IDownloadButtonOptions } from "@todesktop/shared";

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const sansSerifSystemFontStack =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
const serifSystemFontStack =
  'Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif';

const BtnText = ({ text }) => <span>{text}</span>;
const DLIcon = () => (
  <svg viewBox="0 0 896 704" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path d="M560 546.3h-74.1V304c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v242.3H336c-6.7 0-10.4 7.7-6.3 12.9l112 141.7a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9z" />
      <path d="M747.4 206.7C701.6 85.9 584.9 0 448.2 0S194.8 85.8 149 206.6C63.3 229.1 0 307.2 0 400c0 110.5 89.5 200 199.9 200H240c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8h-40.1c-33.7 0-65.4-13.4-89-37.7-23.5-24.2-36-56.8-34.9-90.6.9-26.4 9.9-51.2 26.2-72.1 16.7-21.3 40.1-36.8 66.1-43.7l37.9-9.9 13.9-36.6c8.6-22.8 20.6-44.1 35.7-63.4a245.6 245.6 0 0152.4-49.9c41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.2c19.9 14 37.5 30.8 52.4 49.9 15.1 19.3 27.1 40.7 35.7 63.4l13.8 36.5 37.8 10C782.1 294.5 820 343.8 820 400c0 33.1-12.9 64.3-36.3 87.7a123.07 123.07 0 01-87.6 36.3H656c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h40.1C806.5 600 896 510.5 896 400c0-92.7-63.1-170.7-148.6-193.3z" />
    </g>
  </svg>
);
const AppIconImg = ({ app }) => <img src={app.icon} alt={app.name} />;
const StyledForm = styled(Form)`
  .ant-form-item {
    margin: 6px 0 12px 0;
  }
`;

const DownloadButtonBuilder: React.SFC<{
  app: IApp;
}> = ({ app }) => {
  const [appState] = useState(app);
  const [downloadButtons] = useState(
    app.meta && app.meta.downloadButtons
      ? app.meta.downloadButtons
      : {
          universal: null,
          mac: null,
          windows: null,
          linux: null,
        }
  );

  return (
    <Tabs
      defaultActiveKey="universal"
      onChange={(key) =>
        track({
          event: "Changed Download Button Tab",
          properties: { tab: key },
        })
      }
    >
      <TabPane
        css={css`
          padding: 0 0.5rem;
        `}
        tab={
          <>
            <DesktopOutlined />
            Universal
          </>
        }
        key="universal"
      >
        <DownloadButtonPane
          app={appState}
          type="universal"
          buttonOptions={downloadButtons.universal}
        />
      </TabPane>
      <TabPane
        tab={
          <>
            <WindowsFilled />
            Windows
          </>
        }
        key="windows"
      >
        <DownloadButtonPane
          app={appState}
          type="windows"
          buttonOptions={downloadButtons.windows}
        />
      </TabPane>
      <TabPane
        tab={
          <>
            <AppleFilled />
            Mac
          </>
        }
        key="mac"
      >
        <DownloadButtonPane
          app={appState}
          type="mac"
          buttonOptions={downloadButtons.mac}
        />
      </TabPane>
      {appState.appType !== "menubar" && (
        <TabPane
          tab={
            <>
              <QqOutlined />
              Linux
            </>
          }
          key="linux"
        >
          <DownloadButtonPane
            app={appState}
            type="linux"
            buttonOptions={downloadButtons.linux}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

const DownloadButtonPane: React.SFC<{
  app: IApp;
  type: DownloadLinkPlatformTypes;
  buttonOptions: IDownloadButtonOptions | null | undefined;
}> = ({ app, type, buttonOptions }) => {
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const isUniversal = type === "universal";
  const downloadUrl = `https://dl.todesktop.com/${app.id}/${
    isUniversal ? "" : type
  }`;

  const [downloadBtnFormState, setDownloadBtnFormState] = useState<
    IDownloadButtonOptions
  >(
    Object.assign(
      {
        buttonSize: "medium",
        fontFamily: "sans-serif",
        bgColor: "#1890ff",
        fgColor: "#ffffff",
        borderRadius: 4,
        showAppIcon: {
          enabled: false,
          alignment: "left",
        },
        showDownloadIcon: {
          enabled: true,
          alignment: "right",
        },
        btnText: `Download ${
          isUniversal ? "Desktop" : type[0].toUpperCase() + type.slice(1)
        } App`,
      },
      buttonOptions
    )
  );

  const updateForm = (property: string, val) =>
    setDownloadBtnFormState({ ...downloadBtnFormState, [property]: val });

  const updateFirestoreDebounce = debounce((property: string, val) => {
    updateFirestoreApp(
      {
        meta: {
          downloadButtons: {
            [type]: {
              [property]: val,
            },
          },
        },
      },
      false
    );
    track({
      event: "Update Button Builder Option",
      properties: {
        property,
        value: val,
      },
    });
  }, 1000);

  const updateFormAndFirestore = (property: string, val) => {
    updateForm(property, val);
    updateFirestoreDebounce(property, val);
  };

  const [btnContents, setBtnContents] = useState(
    <BtnText text={downloadBtnFormState.btnText} />
  );

  useEffect(() => {
    if (
      !downloadBtnFormState.showAppIcon.enabled &&
      !downloadBtnFormState.showDownloadIcon.enabled
    ) {
      setBtnContents(<BtnText text={downloadBtnFormState.btnText} />);
    }

    if (downloadBtnFormState.showAppIcon.enabled) {
      if (downloadBtnFormState.showAppIcon.alignment === "left") {
        setBtnContents(
          <>
            <AppIconImg app={app} />
            <BtnText text={downloadBtnFormState.btnText} />
          </>
        );
      } else {
        setBtnContents(
          <>
            <BtnText text={downloadBtnFormState.btnText} />
            <AppIconImg app={app} />
          </>
        );
      }
    }

    if (downloadBtnFormState.showDownloadIcon.enabled) {
      if (downloadBtnFormState.showDownloadIcon.alignment === "left") {
        setBtnContents(
          <>
            <DLIcon />
            <BtnText text={downloadBtnFormState.btnText} />
          </>
        );
      } else {
        setBtnContents(
          <>
            <BtnText text={downloadBtnFormState.btnText} />
            <DLIcon />
          </>
        );
      }
    }

    if (
      downloadBtnFormState.showAppIcon.enabled &&
      downloadBtnFormState.showDownloadIcon.enabled
    ) {
      if (
        downloadBtnFormState.showAppIcon.alignment === "left" &&
        downloadBtnFormState.showDownloadIcon.alignment === "left"
      ) {
        setBtnContents(
          <>
            <AppIconImg app={app} />
            <DLIcon />
            <BtnText text={downloadBtnFormState.btnText} />
          </>
        );
      }

      if (
        downloadBtnFormState.showAppIcon.alignment === "left" &&
        downloadBtnFormState.showDownloadIcon.alignment === "right"
      ) {
        setBtnContents(
          <>
            <AppIconImg app={app} />
            <BtnText text={downloadBtnFormState.btnText} />
            <DLIcon />
          </>
        );
      }

      if (
        downloadBtnFormState.showAppIcon.alignment === "right" &&
        downloadBtnFormState.showDownloadIcon.alignment === "left"
      ) {
        setBtnContents(
          <>
            <DLIcon />
            <BtnText text={downloadBtnFormState.btnText} />
            <AppIconImg app={app} />
          </>
        );
      }

      if (
        downloadBtnFormState.showAppIcon.alignment === "right" &&
        downloadBtnFormState.showDownloadIcon.alignment === "right"
      ) {
        setBtnContents(
          <>
            <BtnText text={downloadBtnFormState.btnText} />
            <AppIconImg app={app} />
            <DLIcon />
          </>
        );
      }
    }
  }, [
    downloadBtnFormState.showAppIcon,
    downloadBtnFormState.showDownloadIcon,
    downloadBtnFormState.btnText,
  ]);

  const [downloadBtnCSS, setDownloadBtnCSS] = useState("");
  useEffect(() => {
    setDownloadBtnCSS(`.todesktop-download-button-${type} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${
    downloadBtnFormState.buttonSize === "small"
      ? "36px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "54px"
      : "72px"
  };
  padding-left: ${
    downloadBtnFormState.buttonSize === "small"
      ? "10px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "15px"
      : "20px"
  };
  padding-right: ${
    downloadBtnFormState.buttonSize === "small"
      ? "10px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "15px"
      : "20px"
  };${
      downloadBtnFormState.borderRadius > 0
        ? `\n  border-radius: ${downloadBtnFormState.borderRadius}px;`
        : ""
    }
  background-color: ${downloadBtnFormState.bgColor};
  color: ${downloadBtnFormState.fgColor};
  font-family: ${
    downloadBtnFormState.fontFamily === "sans-serif"
      ? sansSerifSystemFontStack
      : serifSystemFontStack
  };
  text-decoration: none;
}
.todesktop-download-button-${type} > span {
  padding-left: ${
    downloadBtnFormState.buttonSize === "small"
      ? "5px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "10px"
      : "15px"
  };
  padding-right: ${
    downloadBtnFormState.buttonSize === "small"
      ? "5px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "10px"
      : "15px"
  };
  font-size: ${
    downloadBtnFormState.buttonSize === "small"
      ? "14px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "16px"
      : "18px"
  };
  line-height: 1;
  font-weight: 500;
  letter-spacing: .025ch;
}
.todesktop-download-button-${type} > img, .todesktop-download-button-${type} > svg {
  width: ${
    downloadBtnFormState.buttonSize === "small"
      ? "25px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "40px"
      : "50px"
  };
  padding-left: ${
    downloadBtnFormState.buttonSize === "small"
      ? "3px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "5px"
      : "8px"
  };
  padding-right: ${
    downloadBtnFormState.buttonSize === "small"
      ? "3px"
      : downloadBtnFormState.buttonSize === "medium"
      ? "5px"
      : "8px"
  };
}`);
  }, [
    downloadBtnFormState.buttonSize,
    downloadBtnFormState.bgColor,
    downloadBtnFormState.fgColor,
    downloadBtnFormState.fontFamily,
    downloadBtnFormState.borderRadius,
    downloadBtnFormState.showAppIcon.enabled,
    downloadBtnFormState.showDownloadIcon.enabled,
  ]);

  return (
    <StyledForm {...formItemLayout}>
      <Form.Item label="Button text">
        <Input
          value={downloadBtnFormState.btnText}
          onChange={(e) => updateForm("btnText", e.target.value)}
          onBlur={() =>
            updateFirestoreDebounce("btnText", downloadBtnFormState.btnText)
          }
        />
      </Form.Item>
      <Form.Item
        label="Background color"
        css={css`
          .ant-form-item-children {
            display: flex;
            height: 40px;
            > div {
              display: flex;
              align-items: center;
            }
          }
        `}
      >
        <SwatchPicker
          onChangeComplete={(color) =>
            updateFormAndFirestore("bgColor", color.hex)
          }
          color={downloadBtnFormState.bgColor}
          popoverStyle={{
            top: "60%",
            left: 30,
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <Checkbox
            onChange={(val) =>
              updateFormAndFirestore("showAppIcon", {
                ...downloadBtnFormState.showAppIcon,
                enabled: !downloadBtnFormState.showAppIcon.enabled,
              })
            }
            css={css`
              color: rgba(0, 0, 0, 0.85) !important;

              .ant-checkbox + span {
                padding-right: 0;
              }
            `}
            checked={downloadBtnFormState.showAppIcon.enabled}
          >
            Show App Icon
          </Checkbox>
        }
      >
        <Select
          defaultValue={downloadBtnFormState.showAppIcon.alignment}
          style={{ width: 120 }}
          onChange={(val) =>
            updateFormAndFirestore("showAppIcon", {
              ...downloadBtnFormState.showAppIcon,
              alignment: val,
            })
          }
        >
          <Option value="left">on left</Option>
          <Option value="right">on right</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Checkbox
            onChange={(val) =>
              updateFormAndFirestore("showDownloadIcon", {
                ...downloadBtnFormState.showDownloadIcon,
                enabled: !downloadBtnFormState.showDownloadIcon.enabled,
              })
            }
            css={css`
              color: rgba(0, 0, 0, 0.85) !important;

              .ant-checkbox + span {
                padding-right: 0;
              }
            `}
            checked={downloadBtnFormState.showDownloadIcon.enabled}
          >
            Show Download Icon
          </Checkbox>
        }
      >
        <Select
          defaultValue={downloadBtnFormState.showDownloadIcon.alignment}
          style={{ width: 120 }}
          onChange={(val) =>
            updateFormAndFirestore("showDownloadIcon", {
              ...downloadBtnFormState.showDownloadIcon,
              alignment: val,
            })
          }
        >
          <Option value="left">on left</Option>
          <Option value="right">on right</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <span
            css={css`
              cursor: pointer;
            `}
            onClick={() => setShouldShowMore(!shouldShowMore)}
          >
            {shouldShowMore ? "▼" : "▶"} Show {shouldShowMore ? "less" : "more"}{" "}
            options
          </span>
        }
      />
      {shouldShowMore && (
        <>
          <Form.Item label="Button size">
            <Select
              defaultValue={downloadBtnFormState.buttonSize}
              style={{ width: 120 }}
              onChange={(val) => updateFormAndFirestore("buttonSize", val)}
            >
              <Option value="small">Small</Option>
              <Option value="medium">Medium</Option>
              <Option value="large">Large</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Border radius">
            <InputNumber
              min={0}
              defaultValue={downloadBtnFormState.borderRadius}
              onChange={(num) => updateFormAndFirestore("borderRadius", num)}
            />
            <span> px</span>
          </Form.Item>
          <Form.Item
            label="Foreground color"
            css={css`
              .ant-form-item-children {
                display: flex;
                height: 40px;
                > div {
                  display: flex;
                  align-items: center;
                }
              }
            `}
          >
            <SwatchPicker
              onChangeComplete={(color) =>
                updateFormAndFirestore("fgColor", color.hex)
              }
              color={downloadBtnFormState.fgColor}
              popoverStyle={{
                top: "60%",
                left: 30,
              }}
            />
          </Form.Item>
          <Form.Item label="Font family">
            <Select
              defaultValue={downloadBtnFormState.fontFamily}
              style={{ width: 120 }}
              onChange={(val) => updateFormAndFirestore("fontFamily", val)}
            >
              <Option value="sans-serif">Sans Serif</Option>
              <Option value="serif">Serif</Option>
            </Select>
          </Form.Item>
        </>
      )}
      <Tabs
        defaultActiveKey="preview"
        onChange={(key) =>
          track({
            event: "Changed Download Preview Tab",
            properties: { tab: key },
          })
        }
      >
        <TabPane
          tab={
            <span>
              <AppleFilled />
              Preview
            </span>
          }
          key="preview"
        >
          <div
            css={css`
              ${downloadBtnCSS}
            `}
          >
            <a
              href={downloadUrl}
              className={`todesktop-download-button-${type}`}
              download
            >
              {btnContents}
            </a>
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              HTML
              <span
                onClick={copyToClipboard(
                  "HTML",
                  `<a href="${downloadUrl}" class="todesktop-download-button-${type}" download>${ReactDOMServer.renderToStaticMarkup(
                    btnContents
                  )}</a>`
                )}
                css={css`
                  margin-left: 15px;
                  opacity: 0.333;
                  transition: all 200ms ease;
                  &:hover {
                    opacity: 1;
                    transform: scale(1.1);
                  }
                `}
              >
                [copy <CopyFilled />]
              </span>
            </span>
          }
          key="html"
        >
          <TextArea
            autoSize
            css={css`
              resize: none;
              font-family: Inconsolata, "Bitstream Vera Sans Mono", Consolas,
                Courier, monospace;
            `}
            spellCheck={false}
            onClick={(e) => e.currentTarget.select()}
            value={`<a href="${downloadUrl}" class="todesktop-download-button-${type}" download>${ReactDOMServer.renderToStaticMarkup(
              btnContents
            )}</a>`}
          />
        </TabPane>

        <TabPane
          tab={
            <span>
              CSS
              <span
                onClick={copyToClipboard("CSS", downloadBtnCSS)}
                css={css`
                  margin-left: 15px;
                  opacity: 0.333;
                  transition: all 200ms ease;
                  &:hover {
                    opacity: 1;
                    transform: scale(1.1);
                  }
                `}
              >
                [copy <CopyFilled />]
              </span>
            </span>
          }
          key="css"
        >
          <TextArea
            autoSize
            css={css`
              resize: none;
              font-family: Inconsolata, "Bitstream Vera Sans Mono", Consolas,
                Courier, monospace;
            `}
            spellCheck={false}
            onClick={(e) => e.currentTarget.select()}
            value={downloadBtnCSS}
          />
        </TabPane>
      </Tabs>
    </StyledForm>
  );
};

export default DownloadButtonBuilder;
