import React from "react";
import { Switch } from "react-router";
import { TypedRoute } from "../../components/Router";
import { ReleaseRoute } from "./release/ReleaseRoute";
import { useStore } from "../../store";
import { Spin } from "antd";
import { Flex } from "../../components/atoms/Primitives";

export const ReleaseRouter: React.FC = () => {
  const hasDataLoaded = useStore(
    (state) => state.userDataLoaded && state.buildState.hasBuildsDataLoaded
  );

  if (!hasDataLoaded) {
    return (
      <Flex
        css={{
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </Flex>
    );
  }

  return (
    <Switch>
      <TypedRoute
        exact
        path="/apps/:id/builds/:buildId/release"
        render={(routeProps) => <ReleaseRoute {...routeProps} />}
      />
    </Switch>
  );
};
