import { Build } from "@todesktop/shared";

import { DeepPartial, IFirestoreOrderBy } from "../types";
import {
  getData,
  getSnapshot,
  getCollectionSnapshotWhere,
  setData,
} from "./common";
import { firestore } from "./firestore";
import type firebase from "firebase/app";

const getApplicationBuilds = (id: string, appId: string) =>
  firestore.collection(`users/${id}/applications/${appId}/builds`);

const getApplicationBuildData = (
  userId: string,
  appId: string,
  buildId: string
) => firestore.doc(`users/${userId}/applications/${appId}/builds/${buildId}`);

export const getBuildData = (userId: string, appId: string, buildId: string) =>
  getData<Build>(getApplicationBuildData(userId, appId, buildId));

export const setBuildData = (
  userId: string,
  appId: string,
  buildId: string,
  data: DeepPartial<Build>
) =>
  setData<DeepPartial<Build>>(
    getApplicationBuildData(userId, appId, buildId),
    data,
    { merge: true }
  );

const buildListConfig: IFirestoreOrderBy = {
  fieldPath: "createdAt",
  direction: "desc",
};

export const onBuildListUpdate = (
  id: string,
  appId: string,
  handler: (arg: Build[], snapshot?: firebase.firestore.QuerySnapshot) => void
) => {
  if (appId && id) {
    return getCollectionSnapshotWhere<Build>(
      getApplicationBuilds(id, appId),
      { orderBy: buildListConfig },
      handler
    );
  }
};

export const onBuildUpdate = (
  userId: string,
  buildId: string,
  appId: string,
  handler: (arg: Build, snapshot?: firebase.firestore.DocumentSnapshot) => void
) =>
  getSnapshot<Build>(getApplicationBuildData(userId, appId, buildId), handler);
