// modules
import React, { useState } from "react";
import css from "@emotion/css";
import {
  Build,
  WindowsArtifactDownloads,
  LinuxArtifactDownloads,
  MacArtifactDownloads,
  Arch,
  LinuxArtifactName,
  MacArtifactName,
  WindowsArtifactName,
  PlatformName,
  MacArch,
} from "@todesktop/shared";
import styled from "@emotion/styled";

// components
import { CardIsEmpty } from "../atoms/StyledAntdCard";
import { Tabs, Input, Tag, Tooltip } from "antd";
import {
  DesktopOutlined,
  WindowsFilled,
  AppleFilled,
  QqOutlined,
} from "@ant-design/icons";

// helpers
import {
  DownloadLinkPlatformTypes,
  Platforms,
} from "../../~reusables/types/types";
import { copyToClipboard } from "../../~reusables/util/copyToClipboard";
import { getDownloadUrl } from "../../~reusables/util/urls";
import { useStore, selectedApp } from "../../store";
import { track } from "../../~reusables/util/analytics";
import { Text } from "../atoms/Text";
import {
  getDownloadLinkStats,
  getPlatformArtifactSettingsMap,
  getPriorRelease,
} from "../../~reusables/util";
import { Anchor, Flex } from "../atoms/Primitives";
import { Space } from "../atoms/Space";
import { TooltipLink } from "../atoms/TextUtils";

const { TabPane } = Tabs;

const FeatureList = styled.ul`
  margin-top: 0.8rem;
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
  padding-bottom: 0;
  li {
    padding-left: 1.5rem;
    text-indent: -1.5rem;
    margin-top: 0.6rem;
    color: black;
  }

  li::before {
    content: "✅ ";
  }
`;

const DetectFeature: React.FC = () => (
  <FeatureList>
    <li>
      <b>Detects the operating system</b> and delivers the correct installer
      (.exe on Windows, .dmg on Mac, .AppImage on Linux)
    </li>
  </FeatureList>
);

const VersionFeature: React.FC<{ platform: string }> = ({ platform }) => (
  <FeatureList>
    <li>
      Delivers the <b>latest version</b> of your <b>{platform}</b> app.
    </li>
  </FeatureList>
);

const DownloadLinkTab: React.FC<{
  arch?: Arch | MacArch;
  artifactName?: LinuxArtifactName | MacArtifactName | WindowsArtifactName;
  build?: Build;
  label?: string;
  platform: DownloadLinkPlatformTypes;
  showBuildUrls?: boolean;
}> = ({ artifactName, build, platform, label, arch, showBuildUrls = true }) => {
  const [shouldShowNoCodeDomain, setShowNoCodeCustomDomain] = useState(true);
  const { id: appId, customDomain, appType } = useStore(selectedApp);
  const { releasedBuildId, builds } = useStore((state) => ({
    releasedBuildId: state.buildState.releasedBuildId,
    builds: state.buildState.builds,
  }));

  const shouldShowURLsWithoutBuildId =
    build && releasedBuildId === build.id && !showBuildUrls;

  const handleFocus = (event) => event.target.select();

  const downloadArg = { appId, platform, arch, artifactName };

  const isElectronApp = appType === "electron";
  const hrefValue =
    (shouldShowNoCodeDomain && customDomain && !isElectronApp) ||
    (shouldShowURLsWithoutBuildId && isElectronApp)
      ? getDownloadUrl({ ...downloadArg, domain: customDomain })
      : getDownloadUrl({
          ...downloadArg,
          buildId: build ? build.id : null,
          domain: isElectronApp && customDomain ? customDomain : null,
        });

  const {
    size,
    statusColor,
    percentChange,
    direction,
    release,
  } = getDownloadLinkStats(build, getPriorRelease(build, builds), {
    arch,
    artifactName,
    platform,
  });

  return (
    <>
      {(label || size) && (
        <Flex mb={4} color="text" alignItems="center">
          {label && (
            <Text fontWeight={600} variant="body3">
              {label}
            </Text>
          )}
          {size && (
            <Space>
              <Text variant="body3">
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {size}
              </Text>
              {release && percentChange && (
                <Tooltip
                  title={
                    <>
                      {percentChange} {direction}, compared to the{" "}
                      <TooltipLink to={`/apps/${appId}/builds/${release.id}`}>
                        <strong>
                          previous release (v{release.appVersion})
                        </strong>
                      </TooltipLink>
                      .
                    </>
                  }
                >
                  <Tag
                    style={{ borderRadius: "12px", border: 0, fontWeight: 700 }}
                    color={statusColor}
                  >
                    {percentChange}
                  </Tag>
                </Tooltip>
              )}
            </Space>
          )}
        </Flex>
      )}
      <Input
        value={hrefValue}
        onFocus={handleFocus}
        suffix={
          !build && customDomain ? (
            <Anchor
              onClick={() => setShowNoCodeCustomDomain(!shouldShowNoCodeDomain)}
            >
              Show {shouldShowNoCodeDomain ? "ToDesktop" : "custom"} domain
            </Anchor>
          ) : null
        }
      />
      <div
        css={css`
          text-align: right;
          font-size: 0.7rem;
        `}
      >
        <a
          css={css`
            margin-right: 0.5rem;
          `}
          onClick={copyToClipboard(
            `${label ? label : platform} download URL`,
            hrefValue,
            { platform, label }
          )}
        >
          [Copy to clipboard]
        </a>
        <a
          href={hrefValue}
          download={true}
          onClick={() => {
            track({
              event: "Download App To Desktop",
              properties: { platform, label },
            });
          }}
        >
          [Download]
        </a>
      </div>
    </>
  );
};

const DownloadLinkTabs: React.FC = () => {
  const app = useStore(selectedApp);
  const platformArtifactSettingsMap = getPlatformArtifactSettingsMap(app);
  const {
    shouldCreateAppImages,
    shouldCreateAppXFiles,
    shouldCreateMacUniversalInstaller,
    shouldCreateDMGs,
    shouldCreateAppleSiliconAssets,
    shouldCreateDebianPackages,
    shouldCreateMSIInstallers,
    shouldCreateMacAppStoreFiles,
    shouldCreateMacPKG,
    shouldCreateMacZipInstallers,
    shouldCreateNSISInstallers,
    shouldCreateNSISWebInstaller,
    shouldCreateRPMPackages,
    shouldCreateSnapFiles,
    shouldCreate32BitWindowsArtifacts,
    shouldCreateArm64WindowsArtifacts,
  } = app;

  console.log({ app });

  return (
    <Tabs defaultActiveKey="universal">
      <TabPane
        css={css`
          padding: 0 0.5rem;
        `}
        tab={
          <>
            <DesktopOutlined />
            Universal
          </>
        }
        key="universal"
      >
        <DownloadLinkTab platform="universal" />
        <FeatureList>
          <DetectFeature />
          <VersionFeature platform="desktop" />
        </FeatureList>
      </TabPane>
      {platformArtifactSettingsMap["windows"].some(Boolean) && (
        <TabPane
          tab={
            <>
              <WindowsFilled />
              Windows
            </>
          }
          key="windows"
        >
          <Space direction="column" width="100%" size="md">
            {shouldCreateAppXFiles && (
              <DownloadLinkTab
                platform="windows"
                artifactName="appx"
                arch="x64"
                label="Windows App Store (AppX x64)"
              />
            )}
            {shouldCreateMSIInstallers && (
              <DownloadLinkTab
                platform="windows"
                arch="x64"
                artifactName="msi"
                label="MSI x64"
              />
            )}
            {shouldCreate32BitWindowsArtifacts && shouldCreateMSIInstallers ? (
              <DownloadLinkTab
                platform="windows"
                arch="ia32"
                artifactName="msi"
                label="MSI ia32"
              />
            ) : null}
            {shouldCreateNSISWebInstaller && (
              <DownloadLinkTab
                platform="windows"
                arch="x64"
                artifactName="nsis-web"
                label="NSIS Web"
              />
            )}
            {shouldCreateNSISInstallers && (
              <DownloadLinkTab
                platform="windows"
                arch="x64"
                artifactName="nsis"
                label="NSIS x64"
              />
            )}
            {shouldCreate32BitWindowsArtifacts && shouldCreateNSISInstallers ? (
              <DownloadLinkTab
                platform="windows"
                arch="ia32"
                artifactName="nsis"
                label="NSIS ia32"
              />
            ) : null}
            {shouldCreateArm64WindowsArtifacts && shouldCreateNSISInstallers ? (
              <DownloadLinkTab
                platform="windows"
                arch="arm64"
                artifactName="nsis"
                label="NSIS arm64"
              />
            ) : null}
          </Space>
          <FeatureList>
            <VersionFeature platform="windows" />
          </FeatureList>
        </TabPane>
      )}
      {platformArtifactSettingsMap["mac"].some(Boolean) && (
        <TabPane
          tab={
            <>
              <AppleFilled />
              Mac
            </>
          }
          key="mac"
        >
          <Space direction="column" width="100%" size="md">
            {shouldCreateMacUniversalInstaller && (
              <DownloadLinkTab
                platform="mac"
                arch="universal"
                artifactName="installer"
                label="Mac Universal Installer"
              />
            )}
            {shouldCreateDMGs && (
              <DownloadLinkTab
                platform="mac"
                arch="x64"
                artifactName="dmg"
                label="DMG x64"
              />
            )}
            {shouldCreateAppleSiliconAssets && shouldCreateDMGs && (
              <DownloadLinkTab
                platform="mac"
                arch="arm64"
                artifactName="dmg"
                label="DMG arm64"
              />
            )}
            {shouldCreateMacZipInstallers && (
              <DownloadLinkTab
                platform="mac"
                arch="x64"
                artifactName="zip"
                label="ZIP x64"
              />
            )}
            {shouldCreateAppleSiliconAssets && shouldCreateMacZipInstallers && (
              <DownloadLinkTab
                platform="mac"
                arch="arm64"
                artifactName="zip"
                label="Zip arm64"
              />
            )}
            {shouldCreateMacAppStoreFiles && (
              <DownloadLinkTab
                platform="mac"
                artifactName="mas"
                arch="universal"
                label="Mac App Store"
              />
            )}
            {shouldCreateMacPKG && (
              <DownloadLinkTab
                platform="mac"
                artifactName="pkg"
                arch="x64"
                label="PKG x64"
              />
            )}
            {shouldCreateAppleSiliconAssets && shouldCreateMacPKG && (
              <DownloadLinkTab
                platform="mac"
                artifactName="pkg"
                arch="arm64"
                label="PKG arm64"
              />
            )}
          </Space>
          <FeatureList>
            <VersionFeature platform="mac" />
          </FeatureList>
        </TabPane>
      )}
      {platformArtifactSettingsMap["linux"].some(Boolean) && (
        <TabPane
          tab={
            <>
              <QqOutlined />
              Linux
            </>
          }
          key="linux"
        >
          <Space direction="column" width="100%" size="md">
            {shouldCreateAppImages && (
              <DownloadLinkTab
                platform="linux"
                arch="x64"
                artifactName="appImage"
                label="AppImage x64"
              />
            )}
            {shouldCreateDebianPackages && (
              <DownloadLinkTab
                platform="linux"
                arch="x64"
                artifactName="deb"
                label="Debian x64"
              />
            )}
            {shouldCreateRPMPackages && (
              <DownloadLinkTab
                platform="linux"
                arch="x64"
                artifactName="rpm"
                label="RPM x64"
              />
            )}
            {shouldCreateSnapFiles && (
              <DownloadLinkTab
                platform="linux"
                arch="x64"
                artifactName="snap"
                label="Snap Store x64"
              />
            )}
          </Space>
          <FeatureList>
            <VersionFeature platform="linux" />
          </FeatureList>
        </TabPane>
      )}
    </Tabs>
  );
};

export const ElectronDownloadLinkTabs: React.FC<{
  build: Build;
}> = ({ build }) => {
  const app = useStore(selectedApp);
  const releasedBuildId = useStore((state) => state.buildState.releasedBuildId);
  const isReleased = releasedBuildId === build.id;
  const { mac, windows, linux } = build;

  const platformArtifactSettingsMap = getPlatformArtifactSettingsMap(app);
  const shouldShowTabIfReleased = (platform: Platforms) =>
    isReleased ? platformArtifactSettingsMap[platform].some(Boolean) : true;

  const shouldShowMac = mac && !mac.shouldSkip && mac.status === "succeeded";
  const shouldShowWindows =
    windows && !windows.shouldSkip && windows.status === "succeeded";
  const shouldShowLinux =
    linux && !linux.shouldSkip && linux.status === "succeeded";
  const shouldShowUniversal =
    shouldShowMac && shouldShowWindows && shouldShowLinux;

  const defaultActiveKey = [
    { shouldShow: shouldShowUniversal, value: "universal" },
    { shouldShow: shouldShowMac, value: "mac" },
    { shouldShow: shouldShowWindows, value: "windows" },
    { shouldShow: shouldShowLinux, value: "linux" },
  ].find((platform) => platform.shouldShow === true);

  if (!defaultActiveKey) {
    return (
      <CardIsEmpty>
        <Text color="support">Not available yet</Text>
      </CardIsEmpty>
    );
  }

  return (
    <Tabs defaultActiveKey={defaultActiveKey.value}>
      {shouldShowUniversal && (
        <TabPane
          css={css`
            padding: 0 0.5rem;
          `}
          tab={
            <>
              <DesktopOutlined />
              Universal
            </>
          }
          key="universal"
        >
          <DownloadLinkTab
            showBuildUrls={!isReleased}
            build={build}
            platform="universal"
          />
          {isReleased && (
            <FeatureList>
              <DetectFeature />
            </FeatureList>
          )}
        </TabPane>
      )}
      {shouldShowWindows && shouldShowTabIfReleased("windows") && (
        <TabPane
          tab={
            <>
              <WindowsFilled />
              Windows
            </>
          }
          key="windows"
        >
          <WindowsLinks
            showBuildUrls={!isReleased}
            platform={PlatformName.windows}
            build={build}
          />
          {isReleased && (
            <FeatureList>
              <VersionFeature platform="windows" />
            </FeatureList>
          )}
        </TabPane>
      )}
      {shouldShowMac && shouldShowTabIfReleased("mac") && (
        <TabPane
          tab={
            <>
              <AppleFilled />
              Mac
            </>
          }
          key="mac"
        >
          <MacLinks
            showBuildUrls={!isReleased}
            platform={PlatformName.mac}
            build={build}
            isRelease={true}
          />
          {isReleased && (
            <FeatureList>
              <VersionFeature platform="mac" />
            </FeatureList>
          )}
        </TabPane>
      )}
      {shouldShowLinux && shouldShowTabIfReleased("linux") && (
        <TabPane
          tab={
            <>
              <QqOutlined />
              Linux
            </>
          }
          key="linux"
        >
          <LinuxLinks
            showBuildUrls={!isReleased}
            platform={PlatformName.linux}
            build={build}
          />
          {isReleased && (
            <FeatureList>
              <VersionFeature platform="linux" />
            </FeatureList>
          )}
        </TabPane>
      )}
    </Tabs>
  );
};

interface IPlatformLinks {
  platform: PlatformName;
  build: Build;
  showBuildUrls?: boolean;
  isRelease?: boolean;
}

export const WindowsLinks: React.FC<IPlatformLinks> = ({
  platform,
  build,
  showBuildUrls = true,
}) => {
  const { artifactDownloads } = build[platform];

  if (artifactDownloads) {
    const { msi, nsis, appx } = artifactDownloads as WindowsArtifactDownloads;

    return (
      <Space direction="column" width="100%" size="md">
        {appx && appx.ia32 ? (
          <DownloadLinkTab
            arch="ia32"
            artifactName="appx"
            build={build}
            label="Windows App Store (AppX ia32)"
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {appx && appx.x64 ? (
          <DownloadLinkTab
            label="Windows App Store (AppX x64)"
            arch="x64"
            artifactName="appx"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {msi && msi.ia32 ? (
          <DownloadLinkTab
            label="MSI ia32"
            arch="ia32"
            artifactName="msi"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {msi && msi.x64 ? (
          <DownloadLinkTab
            label="MSI x64"
            arch="x64"
            artifactName="msi"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {nsis && nsis.universal ? (
          <DownloadLinkTab
            label="NSIS Universal"
            arch="universal"
            artifactName="nsis"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {nsis && nsis.ia32 ? (
          <DownloadLinkTab
            label="NSIS ia32"
            arch="ia32"
            artifactName="nsis"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {nsis && nsis.x64 ? (
          <DownloadLinkTab
            label="NSIS x64"
            arch="x64"
            artifactName="nsis"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {nsis && nsis.arm64 ? (
          <DownloadLinkTab
            label="NSIS arm64"
            arch="arm64"
            artifactName="nsis"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {artifactDownloads["nsis-web"] &&
        artifactDownloads["nsis-web"]["universal"] ? (
          <DownloadLinkTab
            label="NSIS Web Universal"
            arch="universal"
            artifactName="nsis-web"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {artifactDownloads["nsis-web"] &&
        artifactDownloads["nsis-web"]["ia32"] ? (
          <DownloadLinkTab
            label="NSIS Web ia32"
            arch="ia32"
            artifactName="nsis-web"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {artifactDownloads["nsis-web"] &&
        artifactDownloads["nsis-web"]["x64"] ? (
          <DownloadLinkTab
            label="NSIS Web x64"
            arch="x64"
            artifactName="nsis-web"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {artifactDownloads["nsis-web"] &&
        artifactDownloads["nsis-web"]["arm64"] ? (
          <DownloadLinkTab
            label="NSIS Web arm64"
            arch="arm64"
            artifactName="nsis-web"
            build={build}
            platform="windows"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
      </Space>
    );
  } else {
    return (
      <DownloadLinkTab
        build={build}
        platform="windows"
        showBuildUrls={showBuildUrls}
      />
    );
  }
};

export const MacLinks: React.FC<IPlatformLinks> = ({
  platform,
  build,
  showBuildUrls = true,
  isRelease = false,
}) => {
  const { artifactDownloads } = build[platform];

  if (artifactDownloads) {
    const {
      dmg,
      zip,
      pkg,
      mas,
      installer,
    } = artifactDownloads as MacArtifactDownloads;

    return (
      <Space direction="column" width="100%" size="md">
        {isRelease ? (
          <>
            {installer && installer.universal ? (
              <DownloadLinkTab
                arch="universal"
                artifactName="installer"
                build={build}
                label="Mac Universal Installer"
                platform="mac"
                showBuildUrls={showBuildUrls}
              />
            ) : null}
          </>
        ) : null}
        {dmg && dmg.x64 ? (
          <DownloadLinkTab
            arch="x64"
            artifactName="dmg"
            build={build}
            label="DMG Intel"
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {dmg && dmg.arm64 ? (
          <DownloadLinkTab
            arch="arm64"
            artifactName="dmg"
            build={build}
            label="DMG Apple Silicon"
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {zip && zip.x64 ? (
          <DownloadLinkTab
            label="ZIP Intel"
            arch="x64"
            artifactName="zip"
            build={build}
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {zip && zip.arm64 ? (
          <DownloadLinkTab
            label="ZIP Apple Silicon"
            arch="arm64"
            artifactName="zip"
            build={build}
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {pkg && pkg.x64 ? (
          <DownloadLinkTab
            label="PKG Intel"
            arch="x64"
            artifactName="pkg"
            build={build}
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {pkg && pkg.arm64 ? (
          <DownloadLinkTab
            label="PKG Apple Silicon"
            arch="arm64"
            artifactName="pkg"
            build={build}
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {mas && mas.universal ? (
          <DownloadLinkTab
            label="Mac App Store"
            arch="universal"
            artifactName="mas"
            build={build}
            platform="mac"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
      </Space>
    );
  } else {
    return (
      <DownloadLinkTab
        build={build}
        platform="mac"
        showBuildUrls={showBuildUrls}
      />
    );
  }
};

export const LinuxLinks: React.FC<IPlatformLinks> = ({
  platform,
  build,
  showBuildUrls = true,
}) => {
  const { artifactDownloads } = build[platform];

  if (artifactDownloads) {
    const {
      appImage,
      deb,
      rpm,
      snap,
    } = artifactDownloads as LinuxArtifactDownloads;

    return (
      <Space direction="column" width="100%" size="md">
        {appImage && appImage.x64 ? (
          <DownloadLinkTab
            arch="x64"
            artifactName="appImage"
            build={build}
            label="AppImage x64"
            platform="linux"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {appImage && appImage.arm64 ? (
          <DownloadLinkTab
            arch="arm64"
            artifactName="appImage"
            build={build}
            label="AppImage arm64"
            platform="linux"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {deb && deb.x64 ? (
          <DownloadLinkTab
            label="Debian x64"
            arch="x64"
            artifactName="deb"
            build={build}
            platform="linux"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {deb && deb.arm64 ? (
          <DownloadLinkTab
            label="Debian arm64"
            arch="arm64"
            artifactName="deb"
            build={build}
            platform="linux"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {rpm && rpm.x64 ? (
          <DownloadLinkTab
            label="RPM x64"
            arch="x64"
            artifactName="rpm"
            build={build}
            platform="linux"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
        {snap && snap.x64 ? (
          <DownloadLinkTab
            label="Snap Store x64"
            arch="x64"
            artifactName="snap"
            build={build}
            platform="linux"
            showBuildUrls={showBuildUrls}
          />
        ) : null}
      </Space>
    );
  } else {
    return (
      <DownloadLinkTab
        build={build}
        platform="linux"
        showBuildUrls={showBuildUrls}
      />
    );
  }
};

export default DownloadLinkTabs;
