import { ReloadOutlined } from "@ant-design/icons";
import { Build, isBuildRunning } from "@todesktop/shared";
import { Button, message, Modal, Tooltip } from "antd";
import React from "react";
import { selectedApp, useStore } from "../../store";
import useFirebaseFunction from "../../~reusables/hooks/useFirebaseFunction";

export const RerunFailedBuildButton: React.FC<{
  build: Build;
  shouldUseIcon?: boolean;
}> = ({ build, shouldUseIcon = false }) => {
  const app = useStore(selectedApp);
  const rerunFailedBuild = useFirebaseFunction("rerunFailedBuild");
  const isLoading = rerunFailedBuild.state === "calling";

  async function onClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    try {
      await rerunFailedBuild.callFn({ appId: app.id, buildId: build.id });
      message.success("Build is being rerun");
    } catch (e) {
      Modal.error({ content: e.message, title: "Can't rerun the build" });
    }
  }

  if (!isBuildRerunable(build)) {
    return null;
  }

  if (shouldUseIcon) {
    return (
      <Tooltip title="Rerun failed build">
        <Button
          icon={<ReloadOutlined />}
          loading={isLoading}
          onClick={onClick}
          shape="circle"
          size="small"
        />
      </Tooltip>
    );
  }

  return (
    <Button onClick={onClick} loading={isLoading}>
      Rerun failed build
    </Button>
  );
};

function isBuildRerunable(build: Build) {
  const isAzureBuild = build.ciRunner === "azure";
  const isRunning = isBuildRunning(build);
  const isFailed = build.status === "failed";
  return isAzureBuild && !isRunning && isFailed;
}
