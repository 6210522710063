import firebase from "firebase/app";
import "firebase/auth";
import { IAuthUser } from "../types";
import { updateOrCreateUserData } from "./usersCollection";
import { callFirebaseFunction } from "./functions";
import { IUser } from "@todesktop/shared";

const googleProvider = new firebase.auth.GoogleAuthProvider();
// Get first and last name from scope
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");

const githubProvider = new firebase.auth.GithubAuthProvider();

const twitterProvider = new firebase.auth.TwitterAuthProvider();

const convertName = (displayName: string) => {
  if (!displayName) {
    return undefined;
  }

  const [firstName, lastName] = displayName.trim().split(" ");
  return { firstName, lastName };
};

const convertFirebaseToIAuthUser = ({
  email,
  uid,
  displayName,
}: firebase.User) => ({
  id: uid,
  email,
  ...convertName(displayName), // TODO temporary, make more intelligent conversion or ask user to confirm
});

const convertFirebaseCredentialToIUser = ({
  user,
  additionalUserInfo,
}: firebase.auth.UserCredential): IUser => {
  const iUser: IUser = {
    id: user.uid,
    email: user.email,
  };
  if (additionalUserInfo.profile) {
    const { profile } = additionalUserInfo as any;

    if (profile.given_name) {
      iUser.firstName = profile.given_name;
    }
    if (profile.family_name) {
      iUser.lastName = profile.family_name;
    }
    if (profile.picture) {
      iUser.avatar = profile.picture;
    }
  }
  return iUser;
};

export const signInWithGoogle = () => signIn(googleProvider);

export const signInWithTwitter = () => signIn(twitterProvider);

export const signInWithGithub = () => signIn(githubProvider);

const signIn = async (
  authProvider: firebase.auth.AuthProvider
): Promise<IUser> => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  const authCredential = await firebase.auth().signInWithPopup(authProvider);
  const iUser = convertFirebaseCredentialToIUser(authCredential);
  // TODO wrong place to do it (sign in + getting user logic here only). Consider moving to actions/initializeOnUserAuth

  await updateOrCreateUserData(iUser.id, iUser, {
    isNewUser: authCredential.additionalUserInfo
      ? authCredential.additionalUserInfo.isNewUser
      : false,
  });

  return iUser;
};

export const signInWithCustomToken = (token) =>
  firebase.auth().signInWithCustomToken(token);

export const signInWithPassword = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const signInWithAdminPassword = async (email, password) => {
  const loginAdmin = callFirebaseFunction("loginAdmin");
  const { data } = await loginAdmin({ email, password });
  firebase.auth().signInWithCustomToken(data.token);
};

export const signOut = (): Promise<void> => firebase.auth().signOut();

export const onUserAuth = (handler: (usr: IAuthUser) => void): (() => void) =>
  firebase.auth().onAuthStateChanged((user) => {
    handler(user ? convertFirebaseToIAuthUser(user) : undefined);
  });

export const updatePassword = (newPassword: string) =>
  firebase.auth().currentUser.updatePassword(newPassword);

export const updateProfile = (user: Partial<firebase.User>) =>
  firebase.auth().currentUser.updateProfile(user);

export const updateEmail = (email: firebase.User["email"]) =>
  firebase.auth().currentUser.updateEmail(email);

export const getIdToken = () => firebase.auth().currentUser.getIdTokenResult();

export const reauthenticate = (password) => {
  const user = firebase.auth().currentUser;
  const cred = firebase.auth.EmailAuthProvider.credential(user.email, password);
  return user.reauthenticateWithCredential(cred);
};

export const createPasswordUser = (email: string, password: string) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const getCurrentUser = () => firebase.auth().currentUser;

export const sendPasswordResetEmail = (email: string) =>
  firebase.auth().sendPasswordResetEmail(email);
