import { AnalysisStatus, Build, IApp } from "@todesktop/shared";
import merge from "lodash.merge";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { RouteComponentProps } from "react-router";
import { selectedApp, useStore } from "../../../store";
import { selectBuild, selectedBuild } from "../../../~reusables/actions/builds";
import { getPriorRelease } from "../../../~reusables/util";

export const ReleaseContext = createContext<ReleaseStore | undefined>(
  undefined
);

export const useReleaseContext = () => {
  const context = useContext(ReleaseContext);
  if (!context) {
    throw new Error("useReleaseContext must be used within a ReleaseContext");
  }
  return context;
};

export const useReleaseStore = ({
  match,
}: RouteComponentProps<{
  buildId: string;
  id: string;
}>): ReleaseStore => {
  const { buildId } = match.params;

  const app = useStore(selectedApp);
  const build = useStore(selectedBuild);
  const [state, setState] = useState<ReleaseContextState>({
    releaseId: "",
    basicValidation: {
      status: "progress",
      checks: {
        "successful-build": "progress",
        "code-signing": "progress",
        "release-versions": "progress",
        "released-status": "progress",
      },
    },
    smokeTesting: {
      status: "progress",
      checks: {
        "past-auto-update": "progress",
        "future-auto-update": "progress",
        "app-launched": "progress",
        "app-launched-without-error": "progress",
        "app-test-logs": "progress",
      },
    },
    buildLogging: {
      status: "progress",
      checks: { "logs-retrieved": "progress" },
    },
    buildArtifacts: {
      status: "progress",
      checks: {
        "artifact-sizes": "progress",
        dependencies: "progress",
        "todesktop-json": "progress",
      },
    },
    staticAnalysis: {
      status: "progress",
      checks: { "unacknowledged-issues": "progress" },
    },
  });

  const builds = useStore((state) => state.buildState.builds);
  const priorReleaseId = getPriorRelease(build, builds)?.id;

  useLayoutEffect(() => {
    if (!priorReleaseId || state.releaseId) return;
    setState((prev) => ({ ...prev, releaseId: priorReleaseId }));
  }, [state.releaseId, priorReleaseId]);

  const release = useMemo(() => {
    return builds[state.releaseId];
  }, [builds, state.releaseId]);

  useEffect(() => {
    selectBuild(buildId);
  }, [buildId]);

  const setAnalysisState: ReleaseStore["setAnalysisState"] = useCallback(
    (key, { states, checks }, mode) => {
      setState((prev) => {
        return merge({}, prev, {
          [key]: { checks, status: determineAnalysisStatus(states, mode) },
        });
      });
    },
    []
  );

  return {
    props: { app, build, release },
    state,
    setState,
    setAnalysisState,
  };
};

type StatusUnion =
  | keyof typeof AnalysisStatus
  | "unused"
  | "calling"
  | "error"
  | "progress"
  | "done"
  | "skipped"
  | "success"
  | "warning";

export const determineAnalysisStatus = (
  states: StatusUnion[],
  mode: "warn" | "error" = "error"
): Status => {
  const warnOnError = mode === "warn";

  const errorStates = warnOnError ? [] : ["error"];
  const warningStates = warnOnError ? ["warning", "error"] : ["warning"];
  const successStates = ["done", "success", "skipped"];

  return states.some((state) => errorStates.includes(state))
    ? "error"
    : states.some((state) => warningStates.includes(state))
    ? "warning"
    : states.every((state) => successStates.includes(state))
    ? "success"
    : "progress";
};

export enum ReleaseSectionEnum {
  basicValidation = "basicValidation",
  smokeTesting = "smokeTesting",
  buildArtifacts = "buildArtifacts",
  staticAnalysis = "staticAnalysis",
  buildLogging = "buildLogging",
}

export type Status = "warning" | "progress" | "success" | "error";
export type BasicValidationChecks =
  | "successful-build"
  | "code-signing"
  | "release-versions"
  | "released-status";
export type SmokeTestingChecks =
  | "past-auto-update"
  | "future-auto-update"
  | "app-launched"
  | "app-launched-without-error"
  | "app-test-logs";
export type FileSizeChecks =
  | "artifact-sizes"
  | "dependencies"
  | "todesktop-json";
export type StaticAnalysisChecks = "unacknowledged-issues";
export type BuildLoggingChecks = "logs-retrieved";

export type ReleaseContextState = ReleaseSectionStates & {
  releaseId: string;
};

export type ReleaseSectionStates = {
  basicValidation: ReleaseSectionState<BasicValidationChecks>;
  smokeTesting: ReleaseSectionState<SmokeTestingChecks>;
  buildArtifacts: ReleaseSectionState<FileSizeChecks>;
  staticAnalysis: ReleaseSectionState<StaticAnalysisChecks>;
  buildLogging: ReleaseSectionState<BuildLoggingChecks>;
};

export type ReleaseSectionState<T extends string> = {
  status: Status;
  checks: Record<T, Status>;
};

export type ReleaseStore = {
  props: { app: IApp; build: Build; release?: Build };
  state: ReleaseContextState;
  setState: Dispatch<SetStateAction<ReleaseContextState>>;
  setAnalysisState: <T extends keyof typeof ReleaseSectionEnum>(
    key: T,
    value: {
      checks?: ReleaseSectionStates[T]["checks"];
      states: StatusUnion[];
    },
    mode?: "warn" | "error"
  ) => void;
};
