import { useCallback, useState } from "react";
import { callFirebaseFunction } from "../firebase";

export default function useFirebaseFunction<T = unknown>(
  functionName: string
): FirebaseFunctionCall<T> {
  const [state, setState] = useState<State<T>>({ state: "unused" });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firebaseFn = useCallback(callFirebaseFunction(functionName), []);

  return {
    ...state,
    callFn(...args: unknown[]) {
      setState({ state: "calling" });
      return firebaseFn(...args)
        .then((res) => setState({ state: "success", data: res.data }))
        .catch((e) => {
          const error = e instanceof Error ? e : new Error(JSON.stringify(e));
          setState({ error: error.message, state: "error" });
          throw error;
        });
    },
  };
}

export const useDependencyAnalysisFunction = () => {
  return useFirebaseFunction("runDependencyAnalysis");
};

export const useStaticAnalysisFunction = () => {
  return useFirebaseFunction("runStaticAnalysis");
};

export const useAzureLogsFunction = () => {
  return useFirebaseFunction<{ linux: string; mac: string; windows: string }>(
    "getAzureLogs"
  );
};

export const useSmokeTestLogsFunction = () => {
  return useFirebaseFunction<{ linux: string; mac: string; windows: string }>(
    "getAzureSmokeTestLogs"
  );
};

export const useSmokeTestFunction = () => {
  return useFirebaseFunction("queueSmokeTest");
};

interface State<T = unknown> {
  data?: T;
  error?: string;
  state: "unused" | "calling" | "success" | "error";
}

export interface FirebaseFunctionCall<T = unknown> extends State<T> {
  callFn(...args: unknown[]): Promise<void>;
}
