// modules
import * as yup from "yup";
import React, { useState, useEffect, useContext, useRef } from "react";
import css from "@emotion/css";
import type firebase from "firebase/app";

// components
import { Spin, Tooltip, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// logic
import styled from "../../~reusables/contexts/ThemeContext";
import QuestionTooltip from "./QuestionTooltip";
import { abandonFileUpload } from "../../~reusables/actions";
import { FormItem } from "./FormUtils";
import { AppFieldsContext } from "../../~reusables/util";
import { Space } from "./Space";
import { UploadProps } from "antd/lib/upload";

const UploadContainer = styled.div`
  display: inline-block;
  ${(props: { error?: boolean }) =>
    props.error &&
    `
  .ant-upload.ant-upload-select-picture-card {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  }
  `};
`;

const ImageUploadProgressTrack = styled.div`
  background: rgba(24, 144, 255, 0.7);
  height: 10px;
  position: absolute;
  top: 76px;
  left: 0;
  width: ${({ percent }: { percent: number }) => percent || 0}%;
  border-top: 2px rgba(24, 144, 255, 1) solid;
`;

interface IUploadField {
  id?: string;
  label: string;
  labelIcon?: React.ReactNode;
  hiddenHelpText?: string | JSX.Element;
  tooltipText?: string;
  icon?: string;
  onChange?: any;
  customRequest?: any;
  isUploading: boolean;
  uploadPercent: number;
  error?: string;
  uploadedName?: string;
  appFieldsValidationSchema?: yup.Schema<any>;
  accept?: string;
  firebaseUploadTask?: firebase.storage.UploadTask;
  customCss?: any;
  beforeUpload?: UploadProps["beforeUpload"];
}

export const UploadField: React.FC<IUploadField> = ({
  id,
  label,
  labelIcon = null,
  hiddenHelpText,
  tooltipText,
  icon,
  onChange,
  customRequest,
  isUploading,
  uploadPercent,
  error,
  accept,
  uploadedName,
  firebaseUploadTask,
  appFieldsValidationSchema,
  customCss,
  beforeUpload,
}) => {
  let tooltipTimeout: any;
  const imageRef = useRef(undefined);
  const { registerField, unregisterField } = useContext(AppFieldsContext);
  const [fieldError, setFieldError] = useState("");

  const [
    { isUploadTooltipVisible, isInitialUploadTooltipVisible },
    setState,
  ] = useState({
    isUploadTooltipVisible: false,
    isInitialUploadTooltipVisible: false,
  });

  useEffect(() => {
    if (appFieldsValidationSchema) {
      registerField({
        id: label,
        validationSchema: appFieldsValidationSchema,
        value: icon,
        setFieldError,
        imageRef,
      });
    }

    return () => {
      if (appFieldsValidationSchema) {
        unregisterField(label);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (firebaseUploadTask) {
        abandonFileUpload(firebaseUploadTask);
      }
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, []);

  const makeTooltipVisible = () => {
    setState((prev) => ({ ...prev, isUploadTooltipVisible: true }));
  };

  const makeTooltipInvisible = () => {
    setState((prev) => ({ ...prev, isUploadTooltipVisible: false }));
  };

  const htmlFor = id || label.replace(/\W+/g, "-").toLowerCase(); // "My Label:" -> "my-label-";
  return (
    <FormItem
      error={error || fieldError}
      htmlFor={htmlFor}
      label={
        label ? (
          <>
            {labelIcon}
            {label}
            {hiddenHelpText && <QuestionTooltip text={hiddenHelpText} />}
          </>
        ) : null
      }
      customCss={customCss}
    >
      <Space>
        <div className="ant-upload-list ant-upload-list-picture-card">
          {isUploading ? (
            <div className="ant-upload-list-picture-card-container">
              <div className="ant-upload-list-item">
                <div className="ant-upload-list-item-info">
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    `}
                  >
                    <Spin />
                  </div>
                  <ImageUploadProgressTrack percent={uploadPercent} />
                </div>
              </div>
            </div>
          ) : (
            <div className="ant-upload-list-picture-card-container">
              <div className="ant-upload-list-item">
                <div className="ant-upload-list-item-info">
                  <span
                    className="ant-upload-list-item-thumbnail"
                    css={css`
                      &&& {
                        opacity: 1;
                      }
                    `}
                  >
                    <img ref={imageRef} src={icon} />
                  </span>
                </div>
                {uploadedName && (
                  <div
                    css={css`
                      margin-top: 8px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    `}
                  >
                    {uploadedName}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Tooltip
          visible={
            tooltipText &&
            (isInitialUploadTooltipVisible || isUploadTooltipVisible)
          }
          title={tooltipText}
          overlayStyle={{ zIndex: 1000 }}
        >
          <UploadContainer
            onMouseEnter={makeTooltipVisible}
            onMouseLeave={makeTooltipInvisible}
          >
            <Upload
              id={htmlFor}
              name={htmlFor}
              accept={accept}
              listType="picture-card"
              onChange={(info) => {
                setFieldError("");
                onChange(info);
              }}
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
            >
              <PlusOutlined />
              <div className="ant-upload-text">Upload</div>
            </Upload>
          </UploadContainer>
        </Tooltip>
      </Space>
    </FormItem>
  );
};
