import styled from "@emotion/styled";
interface IAppIconIconProps {
  titleBelow: boolean;
  size?: number;
  windows?: boolean;
  iconStyle?: any;
}
export const AppIconIcon = styled.img`
  display: inline-block;
  width: ${({ size }: IAppIconIconProps) => (size ? size : 90)}px;
  height: ${({ size }: IAppIconIconProps) => (size ? size : 90)}px;
  margin-top: ${({ titleBelow }: IAppIconIconProps) => (titleBelow ? 0 : 30)}px;
  margin-bottom: ${({ titleBelow, windows }: IAppIconIconProps) =>
    !titleBelow ? 0 : windows ? 5 : 15}px;
  border: 0;
  user-drag: none;
  user-select: none;
`;

export const WinAppTitle = styled.div`
  color: #fff;
  display: inline-block;
  padding: 3px 5px;
  font-weight: 500;
  font-size: 11px;
  user-select: none;
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
    Helvetica Neue, Arial, sans-serif;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
`;
