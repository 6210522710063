import { injectGlobal } from "emotion";
import checkqueredBackgroundPNG from "./~reusables/images/checkquered-background.png";

// This is the default CSS included in create-react-app.
export default injectGlobal`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8f8 !important;
  }
  .ant-dropdown-menu-item-group-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    display: none;
    content: '';
  }

  a:focus {
    text-decoration: none !important;
  }

  .ant-upload-list-picture-card {
    .ant-upload-list-item-info {
      background: url(${checkqueredBackgroundPNG}) !important;
    }
  }

  .release-eligibility-popover .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-form-item-label label {
    font-weight: 500;
    font-size: 15px;
  }

  /* .hue-horizontal {
    background: linear-gradient(to right, #00f 0%, #f0f 17%, #f00 33%, #f00 50%, #ff0 67%, #0f0 83%, #0ff 100%) !important;
  } */

  /* Hide visually but still readable */
  .visually-hidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
  }
`;
