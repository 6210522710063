// modules
import React from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";

// components
import { Button, Card } from "antd";
import { Box, Flex } from "./Primitives";

export const CardHeaderButton: React.FC<{
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}> = ({ to, children, onClick }) => {
  const btn = (
    <Button type="link" onClick={onClick}>
      <b>{children}</b>
    </Button>
  );

  return to ? <Link to={to}>{btn}</Link> : btn;
};

export const CardIsEmpty = ({ children }) => (
  <Flex justifyContent="center" alignItems="center" p={6}>
    {children}
  </Flex>
);

interface IStyledAntCard {
  title?: string | React.ReactNode;
  extra?: React.ReactNode;
  bodyStyle?: React.CSSProperties;
  loading?: boolean;
}

export const StyledAntdCard: React.FC<IStyledAntCard> = ({
  children,
  title,
  extra,
  loading,
  bodyStyle = {},
  ...cardProps
}) => {
  const { colors, radii, space } = useTheme();
  return (
    <StyledCard
      loading={loading}
      title={title}
      extra={extra}
      {...cardProps}
      style={{ borderRadius: radii[2] }}
      bodyStyle={{ padding: `${space[6]}px ${space[7]}px`, ...bodyStyle }}
      headStyle={{
        background: colors.greys[8],
        borderTopLeftRadius: radii[2],
        borderTopRightRadius: radii[2],
        borderBottom: 0,
        fontWeight: 600,
      }}
      bordered={false}
    >
      {children}
    </StyledCard>
  );
};

interface IVerticallySplitCard {
  leftSplitChildren: JSX.Element;
  rightSplitChildren: JSX.Element;
  loading?: boolean;
}

export const VerticallySplitCard: React.FC<IVerticallySplitCard> = ({
  loading,
  leftSplitChildren,
  rightSplitChildren,
  ...cardProps
}) => {
  const { radii, colors } = useTheme();

  return (
    <StyledCard
      loading={loading}
      {...cardProps}
      style={{ borderRadius: radii[2], overflow: "hidden" }}
      bodyStyle={{ padding: 0 }}
      bordered={false}
    >
      <Flex flexWrap="wrap">
        <Box flex="6 1 360px">{leftSplitChildren}</Box>
        <Box flex="4 1 240px" backgroundColor={colors.greys[8]}>
          {rightSplitChildren}
        </Box>
      </Flex>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  box-shadow: ${(p) => p.theme.shadows.card};
  -webkit-box-shadow: ${(p) => p.theme.shadows.card};
  -mox-box-shadow: ${(p) => p.theme.shadows.card};
  margin-bottom: ${(p) => p.theme.space[8]}px !important;
`;
