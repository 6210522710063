// modules
import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { Empty, Button } from "antd";

// components
import { StyledAntdCard } from "../atoms/StyledAntdCard";

// logic
import { useNavigation } from "../../~reusables/contexts/NavigationContext";
import { useStore } from "../../store";
import { DummyAppLoader } from "../elements/DummyAppLoader";

interface IProtectedApp extends RouteComponentProps {
  component: React.FC<RouteComponentProps>;
}

export const ProtectedApp: React.FC<IProtectedApp> = ({
  component: Component,
  ...routeProps
}) => {
  const {
    navState: { appLevelNav },
  } = useNavigation();
  const hasDataLoaded = useStore(
    (state) => state.userDataLoaded && state.buildState.hasBuildsDataLoaded
  );

  if (appLevelNav.showLoader || !hasDataLoaded) {
    return <DummyAppLoader loading />;
  }

  if (appLevelNav.items.length) {
    return <Component {...routeProps} />;
  }

  return (
    <StyledAntdCard title="Application not found">
      <Empty>
        <Link to="/">
          <Button type="primary">Back to home</Button>
        </Link>
      </Empty>
    </StyledAntdCard>
  );
};
