import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Confetti from "react-dom-confetti";
import { keyframes, css } from "@emotion/core";
import { useBoolean } from "react-hanger";
import Checkmark from "../../~reusables/images/Checkmark";

const spin = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  15% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  85% {
    transform: rotate(360deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`;

const click = keyframes`
  0% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(6px)
  }
  100% {
    transform: translateY(0)
  }
`;

const waiting = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
`;

const animatedSpin = css`
  animation-name: ${spin};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
`;

const animatedClick = css`
  animation-name: ${click};
  animation-duration: 250ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`;

const animatedWaiting = css`
  animation-name: ${waiting};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
`;

const AppIconContainer = styled.div`
  height: 64px;
  width: 64px;
  margin: 0 auto;
  margin-bottom: 10px;
  position: relative;
`;

interface IAppIconIconProps extends IAppIconProps {
  doClick?: boolean;
}
export const dynamicIconUrl = (props: IAppIconIconProps) => props.iconUrl;
export const AppIconIcon = styled.div`
  background: url("${dynamicIconUrl}") no-repeat center center;
  background-size: 64px 64px;
  height: 64px;
  width: 64px;
  margin: 0 auto;

  ${(props) => props.animate && animatedSpin}
  ${(props) => props.doClick && animatedClick}
  /* transition: transform 500ms linear; */
  ${(props) => props.waiting && animatedWaiting}
`;

function useDeferredBoolean(completed: IAppIconProps["completed"], ms: number) {
  const showConfetti = useBoolean(false);
  useEffect(() => {
    if (completed) {
      const timerId = setTimeout(() => {
        showConfetti.setTrue();
      }, ms);
      return () => clearTimeout(timerId);
    }
    return;
  }, [completed]);
  return showConfetti.value;
}

interface IAppIconProps {
  iconUrl?: string;
  animate?: boolean;
  completed?: boolean;
  waiting?: boolean;
}
const AppIcon: React.SFC<IAppIconProps> = (props) => {
  const { completed } = props;
  const doClick = useDeferredBoolean(completed, 1500);
  const showConfetti = useDeferredBoolean(completed, 1900);
  const appIconIconEl = useRef<HTMLDivElement>(null);

  const [animate, setAnimate] = useState(props.animate);
  useEffect(() => {
    if (animate && !props.animate && appIconIconEl.current) {
      appIconIconEl.current.addEventListener("animationiteration", () => {
        setAnimate(false);
      });
    }
  }, [animate]);
  return (
    <AppIconContainer>
      <Confetti
        active={showConfetti}
        css={css`
          left: calc(50% - 18px);
        `}
        config={{
          angle: 90,
          spread: 70,
          startVelocity: 30,
          elementCount: 30,
        }}
      />
      <AppIconIcon
        {...props}
        ref={appIconIconEl}
        doClick={doClick}
        animate={animate}
      />
      {showConfetti && (
        <Checkmark
          circleColor="#52c41a"
          color="white"
          animate={true}
          animateDelay="3s"
          css={css`
            position: absolute;
            right: -5px;
            bottom: -5px;
          `}
        />
      )}
    </AppIconContainer>
  );
};

export default AppIcon;
