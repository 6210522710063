import { Alert, Row, Col } from "antd";
import React from "react";
import { Text } from "../atoms/Text";
import { hrefLinks } from "../../~reusables/constants/hrefLinks";
import { useTheme } from "../../~reusables/contexts/ThemeContext";

const DocumentationLinks: React.FC = () => {
  const { space } = useTheme();

  return (
    <Alert
      type="info"
      showIcon
      style={{ background: "white", marginTop: space[6] }}
      message={
        <div>
          <Text variant="body3" mb={5}>
            For more information, view our documentation.
          </Text>

          <Row>
            <Col span={12}>
              <Text variant="body3" fontWeight={600}>
                Library Documentation:
              </Text>
              <ul>
                <li>
                  <a
                    href={hrefLinks.cliNpmDocs}
                    target="_blank"
                    rel="noreferrer"
                  >
                    CLI
                  </a>
                </li>
                <li>
                  <a
                    href={hrefLinks.runtimeNpmDocs}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Runtime
                  </a>
                </li>
              </ul>
            </Col>

            <Col span={12}>
              <Text variant="body3" fontWeight={600}>
                Migration Guides:
              </Text>
              <ul>
                <li>
                  <a
                    href={hrefLinks.migrationGuides.general}
                    target="_blank"
                    rel="noreferrer"
                  >
                    general
                  </a>
                </li>
                <li>
                  <a
                    href={hrefLinks.migrationGuides.electronBuilder}
                    target="_blank"
                    rel="noreferrer"
                  >
                    electron-builder
                  </a>
                </li>
                <li>
                  <a
                    href={hrefLinks.migrationGuides.electronWebpack}
                    target="_blank"
                    rel="noreferrer"
                  >
                    electron-webpack
                  </a>
                </li>
                <li>
                  <a
                    href={hrefLinks.migrationGuides.electronForge}
                    target="_blank"
                    rel="noreferrer"
                  >
                    electron-forge
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      }
    />
  );
};

export default DocumentationLinks;
