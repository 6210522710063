import React, { useState } from "react";
import { Radio } from "antd";
import { AppleFilled, WindowsFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";
import { changeOSPreview } from "../../~reusables/actions";
import { RadioChangeEvent } from "antd/lib/radio";
import switchImg from "../../~reusables/images/switch-helper-try-it.svg";
import { useStore } from "../../store";
import { track } from "../../~reusables/util/analytics";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const swingIn = keyframes`
  0%,
  10%, 100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  1% {
    transform: rotate(4deg);
  }
  2%,
  4%,
  6% {
    transform: rotate(-5deg);
  }
  3%,
  5%,
  7% {
    transform: rotate(5deg);
  }
  8% {
    transform: rotate(-4deg);
  }
  9% {
    transform: rotate(4deg);
  }
`;

const swingInAnimation = css`
  animation: ${swingIn} 7s cubic-bezier(0.455, 0.03, 0.515, 0.955) both infinite;
`;

interface ISwitchImageProps {
  animate?: boolean;
}
const SwitchImage = styled.img`
  position: absolute;
  margin-left: 10px;
  ${(props: ISwitchImageProps) => props.animate && swingInAnimation}

  margin-top: 8px;
  width: 90px;
  @media screen and (min-width: 420px) {
    margin-top: initial;
    width: initial;
  }
`;

const isWindows = window.navigator.platform.includes("Win");
const osSwitchOptions = [
  { value: "mac", Icon: AppleFilled, title: "Mac" },
  { value: "windows", Icon: WindowsFilled, title: "Windows" },
];

if (isWindows) {
  osSwitchOptions.reverse();
}

interface IOSPreviewSwitcherProps {
  hideHelpArrow?: boolean;
}
const OSPreviewSwitcher: React.FC<IOSPreviewSwitcherProps> = ({
  hideHelpArrow,
}) => {
  const [hasSwitcherBeenUsed, setHasSwitcherBeenUsed] = useState(false);
  const previewScreenshotOS = useStore((state) => state.previewScreenshotOS);
  const uiState = useStore((state) => state.uiState);

  const radioChangeOSPreview = (e: RadioChangeEvent) => {
    changeOSPreview(e.target.value);
    setHasSwitcherBeenUsed(true);
    track({
      event: "Preview OS",
      properties: { operatingSystem: e.target.value },
    });
  };

  return (
    <div
      css={css`
        overflow: hidden;
      `}
    >
      <div
        css={css`
          margin: 20px 0;
          /* float: right; */
        `}
      >
        <span
          css={css`
            font-weight: bold;
            font-size: 16px;
            padding-right: 20px;
          `}
        >
          Preview OS:
        </span>
        <RadioGroup value={previewScreenshotOS} onChange={radioChangeOSPreview}>
          {osSwitchOptions.map(({ Icon, value, title }, i) => (
            <RadioButton value={value} key={i}>
              <Icon style={{ marginRight: 10 }} />
              {title}
            </RadioButton>
          ))}
        </RadioGroup>
        <SwitchImage
          src={switchImg}
          hidden={hideHelpArrow || hasSwitcherBeenUsed}
          animate={!uiState}
        />
      </div>
    </div>
  );
};

export default OSPreviewSwitcher;
