import { useStore } from "../../../store";
import { selectedReleasedBuild } from "../../../~reusables/actions/builds";
import {
  determineReleaseEligibility,
  getReleaseEligibilityMessages,
} from "../../../~reusables/util/determineReleaseEligibility";
import {
  BasicValidationChecks,
  BuildLoggingChecks,
  FileSizeChecks,
  SmokeTestingChecks,
  StaticAnalysisChecks,
  Status,
  useReleaseContext,
} from "./ReleaseContext";

export type StatusCheckContent<T extends string> = {
  title: Record<Status, string>;
  content: Record<T, Record<Status, string>>;
};

export const useContent = () => {
  const basicValidation = useBasicValidationContent();
  const smokeTesting = useSmokeTestingContent();
  const buildArtifacts = useBuildArtifactsContent();
  const staticAnalysis = useStaticAnalysisContent();
  const buildLogging = useBuildLoggingContent();

  return {
    basicValidation,
    smokeTesting,
    buildArtifacts,
    staticAnalysis,
    buildLogging,
  };
};

export type BasicValidationContent = StatusCheckContent<BasicValidationChecks>;
export const useBasicValidationContent = (): BasicValidationContent => {
  const {
    props: { build },
  } = useReleaseContext();
  const releasedBuild = useStore(selectedReleasedBuild);
  const releaseEligibility = determineReleaseEligibility(build, releasedBuild);

  const messages = getReleaseEligibilityMessages(
    releaseEligibility,
    build,
    releasedBuild
  );

  return {
    title: {
      progress: "Basic validation in progress",
      success: "Basic validation succeeded",
      warning: "Basic validation warnings",
      error: "Basic validation unsuccessful",
    },
    content: {
      "successful-build": {
        progress: messages.buildStateProgress,
        success: messages.buildStateSuccess,
        warning: messages.buildStateSuccess,
        error: messages.buildStateError,
      },
      "code-signing": {
        progress: messages.codeSignStateProgress,
        success: messages.codeSignStateSuccess,
        warning: messages.codeSignStateSuccess,
        error: messages.codeSignStateError,
      },
      "release-versions": {
        progress: messages.versionStateProgress,
        success: messages.versionStateSuccess,
        warning: messages.versionStateSuccess,
        error: messages.versionStateError,
      },
      "released-status": {
        progress: messages.releaseStateProgress,
        success: messages.releaseStateSuccess,
        warning: messages.releaseStateError,
        error: messages.releaseStateError,
      },
    },
  };
};

export type SmokeTestingContent = StatusCheckContent<SmokeTestingChecks>;
export const useSmokeTestingContent = (): SmokeTestingContent => {
  return {
    title: {
      progress: "Smoke tests in progress",
      success: "Smoke tests succeeded",
      warning: "Smoke tests warnings",
      error: "Smoke tests unsuccessful",
    },
    content: {
      "past-auto-update": {
        progress: "Attempting to auto-update from past version...",
        success: "Successfully auto-updated from a past version",
        warning: "Waiting to auto-update from a past version",
        error: "Failed to auto-update from a past version",
      },
      "future-auto-update": {
        progress: "Attempting to auto-update to future version...",
        success: "Successfully auto-updated to a future version",
        warning: "Waiting to auto-update to a future version",
        error: "Failed to auto-update to a future version",
      },
      "app-launched": {
        progress: "Attempting to launch app...",
        success: "Successfully launched app",
        warning: "Waiting to launch app",
        error: "Failed to launch app",
      },
      "app-launched-without-error": {
        progress: "Attempting to launch app without errors...",
        success: "Successfully launched app without errors",
        warning: "Waiting to launch app without errors",
        error: "Failed to launch app without errors",
      },
      "app-test-logs": {
        progress: "Retrieving smoke test logs...",
        success: "Retrieved smoke test logs",
        warning: "Waiting to retrieve smoke test logs",
        error: "Failed to retrieve smoke test logs",
      },
    },
  };
};

export type BuildArtifactsContent = StatusCheckContent<FileSizeChecks>;
export const useBuildArtifactsContent = (): BuildArtifactsContent => {
  return {
    title: {
      progress: "File validation in progress",
      success: "File validation succeeded",
      warning: "File validation warnings",
      error: "File validation unsuccessful",
    },
    content: {
      "artifact-sizes": {
        progress: "Retrieving build artifact sizes...",
        success: "Successfully retrieved build artifacts",
        warning: "Waiting to retrieve build artifacts",
        error: "Failed to retrieve build artifacts",
      },
      dependencies: {
        progress: "Retrieving project dependencies...",
        success: "Validated dependencies and development dependencies",
        warning: "Waiting to retrieve project dependencies",
        error: "Failed to retrieve project dependencies",
      },
      "todesktop-json": {
        progress: "Diffing the ToDesktop JSON configuration...",
        success: "Successfully diffed the ToDesktop JSON",
        warning: "Waiting to diff the ToDesktop JSON",
        error: "Failed to diff the ToDesktop JSON",
      },
    },
  };
};

export type StaticAnalysisContent = StatusCheckContent<StaticAnalysisChecks>;
export const useStaticAnalysisContent = (): StaticAnalysisContent => {
  return {
    title: {
      progress: "Static analysis in progress",
      success: "Static analysis succeeded",
      warning: "Static analysis warnings",
      error: "Static analysis unsuccessful",
    },
    content: {
      "unacknowledged-issues": {
        progress: "Detecting issues in code...",
        success: "High severity issues have been acknowledged",
        warning: "High severity issues have not been acknowledged",
        error: "High severity issues have not been acknowledged",
      },
    },
  };
};

export type BuildLoggingContent = StatusCheckContent<BuildLoggingChecks>;
export const useBuildLoggingContent = (): BuildLoggingContent => {
  return {
    title: {
      progress: "Build logging in progress",
      success: "Build logging succeeded",
      warning: "Build logging warnings",
      error: "Build logging unsuccessful",
    },
    content: {
      "logs-retrieved": {
        progress: "Retrieving platform build logs...",
        success: "Platform build logs have been retrieved",
        warning: "Failed to retrieve platform build logs",
        error: "Failed to retrieve platform build logs",
      },
    },
  };
};
