import { Space } from "antd";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { BlockBackground } from "../components/atoms/BlockBackground";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";

export const DesktopCheckoutRedirect: React.FC<RouteComponentProps<{
  appId: string;
}>> = ({ match }) => {
  useEffect(() => {
    const query = match.params.appId ? `?appId=${match.params.appId}` : "";
    window.location.href = `todesktop://desktop-checkout-redirect${query}`;
  }, [match.params.appId]);

  return (
    <BlockBackground>
      <Space direction="vertical" align="center" size="large">
        <Heading color="white" variant="h3" as="h3">
          Checkout session complete
        </Heading>
        <Text color="white">You can now return to the desktop app</Text>
      </Space>
    </BlockBackground>
  );
};
