import { IApp, IAppFromServerProps } from "@todesktop/shared";
import css from "@emotion/css";
import { Button, Form, Input, Modal, Tabs, Checkbox } from "antd";
import React from "react";
import queryString from "query-string";
import LoginModalInner from "../components/account/LoginModalInner";
import {
  addAppFromServer,
  userSignInWithGithub,
  userSignInWithGoogle,
  userSignInWithTwitter,
  removeUIState,
} from "../~reusables/actions";
import Spinner from "../components/atoms/Spinner";
import { IGlobalState, InjectStore } from "../store";
import { authCancelledCodes } from "../~reusables/constants";
import { callFirebaseFunction } from "../~reusables/firebase/functions";
import { track } from "../~reusables/util/analytics";
import { RouteComponentProps } from "react-router";
import { getTempApplicationData } from "../~reusables/firebase";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { TabPane } = Tabs;

export const createNewApp = callFirebaseFunction("createNewApp");

const initialState = {
  loading: false,
  url: "",
  hasError: false,
  errorText: "",
  isModalVisible: false,
  isMenubarApp: false,
};
type IState = Readonly<typeof initialState>;

function showModalError(message: string) {
  Modal.error({
    title: "Authentication Error",
    content: message,
  });
}

interface INewApp extends RouteComponentProps {
  apps: IApp[];
}

class NewApp extends React.Component<INewApp, IState> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    const appType = this.getAppType();
    const appId = this.getAppIdQueryString();
    if (appId) {
      const foundApp = this.props.apps.find((app) => app.id === appId);
      if (foundApp) {
        this.props.history.push(`/apps/${foundApp.id}`);
      } else {
        const tempApp = await getTempApplicationData(appId);

        if (tempApp) {
          addAppFromServer(tempApp);
        } else {
          this.props.history.replace("?newApp=true");
          this.setState({
            isModalVisible: true,
          });
        }
      }
      this.removeAppIdQueryString();
      return;
    }

    const url = this.getUrlQueryString();
    if (url) {
      this.setState({ url }, () => {
        this.formSubmit();
        this.removeUrlQueryString();
        return;
      });
    }

    const appQueryParams = this.getAppQueryParams();
    if (appQueryParams && appQueryParams.appUrl) {
      const { data } = await createNewApp({ appQueryParams, appType });
      this.nextStep(data);
      return;
    }

    this.setState({
      isModalVisible: true,
    });
  }

  getAppType = () => (this.state.isMenubarApp ? "menubar" : "app");

  getAppQueryParams = () => {
    if (!(this.props.location && this.props.location.search)) {
      return false;
    }
    const { appUrl, iconUrl, name } = queryString.parse(
      this.props.location.search
    );
    return { appUrl, iconUrl, name };
  };

  getUrlQueryString = () => {
    if (!(this.props.location && this.props.location.search)) {
      return false;
    }
    const { url } = queryString.parse(this.props.location.search);
    if (!url) {
      return false;
    }
    return url.toString();
  };

  getAppIdQueryString = () => {
    if (!(this.props.location && this.props.location.search)) {
      return false;
    }
    const { appId } = queryString.parse(this.props.location.search);
    if (!appId) {
      return false;
    }
    return appId.toString();
  };

  removeUrlQueryString = () => {
    if (this.getUrlQueryString) {
    }
  };

  removeAppIdQueryString = () => {
    if (this.getAppIdQueryString) {
    }
  };

  nextStep = (app: IAppFromServerProps) => {
    this.setState({
      loading: false,
      isModalVisible: false,
    });

    addAppFromServer(app);
    this.props.history.replace(`?appId=${app.id}`);
  };

  changeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({
      url: value,
    });
  };

  menubarAppToggle = (e: CheckboxChangeEvent) => {
    this.setState({
      isMenubarApp: e.target.checked,
    });
  };

  handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !this.state.loading) {
      this.formSubmit();
    }
  };

  errorModal = (details, message) => {
    Modal.confirm({
      title: message,
      content: (
        <>Are you sure you want to continue creating an app for this URL?</>
      ),
      okText: "Yes, continue",
      cancelText: "No, change the URL",
      onOk: () => {
        this.nextStep(details);
      },
      onCancel: () => {
        this.setState({
          loading: false,
        });
      },
    });
  };

  formSubmit = async () => {
    const { url } = this.state;
    const appType = this.getAppType();
    track({
      event: "Submitted URL Form",
      properties: {
        url,
        host: "app",
        source: "create new app modal",
      },
    });
    this.setState({
      loading: true,
    });
    try {
      const { data } = await createNewApp({ url, appType });
      this.nextStep(data);
    } catch (error) {
      const { message, details } = error;
      this.errorModal(details, message);
    }
  };

  onGoogleAuth = async () => {
    try {
      await userSignInWithGoogle();
    } catch (e) {
      if (!authCancelledCodes.includes(e.code)) {
        showModalError(e.message);
      }
    }
  };

  onTwitterAuth = async () => {
    try {
      await userSignInWithTwitter();
    } catch (e) {
      if (!authCancelledCodes.includes(e.code)) {
        showModalError(e.message);
      }
    }
  };

  onGithubAuth = async () => {
    try {
      await userSignInWithGithub();
    } catch (e) {
      if (!authCancelledCodes.includes(e.code)) {
        showModalError(e.message);
      }
    }
  };

  cancelNewApp = (hasExistingApps: boolean) => () => {
    if (hasExistingApps) {
      this.setState({
        isModalVisible: false,
      });
    }
  };

  destroyModal = () => {
    removeUIState();
  };

  render() {
    const { url, errorText, hasError, loading, isModalVisible } = this.state;

    return (
      <InjectStore>
        {({ user, userDataLoaded, apps }: IGlobalState) => {
          const hasExistingApps = !!(apps && apps.length);
          return (
            <>
              {userDataLoaded ? (
                <Modal
                  visible={isModalVisible}
                  footer={null}
                  closable={hasExistingApps}
                  centered={true}
                  afterClose={this.destroyModal}
                  onCancel={this.cancelNewApp(hasExistingApps)}
                  css={css`
                    .ant-modal-body {
                      padding: 6px 18px 18px 18px;
                    }
                  `}
                >
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Create new app" key="1">
                      <Form.Item
                        help={errorText}
                        colon={false}
                        label="Create a new app from website URL"
                        validateStatus={hasError ? "error" : null}
                        hasFeedback={true}
                      >
                        <Input
                          id="url"
                          name="url"
                          size="large"
                          value={url}
                          onChange={this.changeUrl}
                          onKeyPress={this.handleKeypress}
                          placeholder="https://www.mysite.com"
                        />
                      </Form.Item>
                      <Checkbox
                        value="isMenubar"
                        onChange={this.menubarAppToggle}
                      >
                        Create as Menubar App
                      </Checkbox>

                      <Button
                        type="primary"
                        css={css`
                          float: right;
                          margin-top: 20px;
                        `}
                        loading={loading}
                        onClick={this.formSubmit}
                      >
                        Create
                      </Button>
                    </TabPane>
                    {!user && (
                      <TabPane tab="Log in" key="2">
                        <LoginModalInner
                          onGoogleAuth={this.onGoogleAuth}
                          onGithubAuth={this.onGithubAuth}
                          onTwitterAuth={this.onTwitterAuth}
                        />
                      </TabPane>
                    )}
                  </Tabs>
                </Modal>
              ) : (
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <Spinner />
                </div>
              )}
            </>
          );
        }}
      </InjectStore>
    );
  }
}

export default NewApp;
