// modules
import React, { useState, useContext } from "react";
import css from "@emotion/css";

// components
import { Button, Dropdown, Menu, Badge, Modal, Alert } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import {
  getNoCodeChecklistItems,
  CustomCheckbox,
  updateChecklistItem,
  getCLIChecklistItems,
} from "./ChecklistItems";
import DownloadButtonBuilder from "./DownloadButtonBuilder";

// logic
import {
  useStore,
  selectedApp,
  $completedChecklistItems,
  $shouldMinimizeOnboarding,
} from "../../store";
import {
  beginBuildProcess,
  updateFirestoreApp,
} from "../../~reusables/actions";
import { hrefLinks } from "../../~reusables/constants";
import { track } from "../../~reusables/util/analytics";
import { openExternalLink, AppFieldsContext } from "../../~reusables/util";
import { Box } from "../atoms/Primitives";
import { Text } from "../atoms/Text";
import { Heading } from "../atoms/Heading";
import { Space } from "../atoms/Space";
import { DomainsModal } from "../molecules/CustomDomain";
import { CertPlatform } from "../../~reusables/types";
import { CertificateModal } from "../molecules/Certificates/SharedCertUtils";
import { ArtifactsModal } from "../molecules/ConfigurableArtifacts";

interface CustomWindow extends Window {
  Intercom: any;
}
declare let window: CustomWindow;

export const ChecklistDropdown: React.FC = () => {
  const app = useStore(selectedApp);

  if (app.appType === "electron") {
    return <CLIChecklist />;
  }

  return <NoCodeChecklist />;
};

const NoCodeChecklist: React.FC = () => {
  const ctx = useContext(AppFieldsContext);
  const app = useStore(selectedApp);
  const user = useStore((state) => state.user);

  const [showBtnBuilder, setShowButtonBuilder] = useState(false);
  const [alert, setAlert] = useState("");
  const completedItems = useStore($completedChecklistItems);

  const checklistItems = getNoCodeChecklistItems({
    buildAppCb: async () =>
      await beginBuildProcess(ctx, user, {
        source: "checklist dropdown item",
      }),
    downloadButtonCb: (e) => {
      track({
        event: "Create Download Button",
        properties: { source: "checklist dropdown item" },
      });
      if (app.subscription) {
        setShowButtonBuilder(!showBtnBuilder);
      } else {
        e.stopPropagation();
        setAlert("You need a built app to use the button builder");
        setTimeout(() => setAlert(""), 5000);
      }
    },
    notificationCb: openExternalLink(hrefLinks.recipesNotifications, {
      source: "checklist dropdown item",
    }),
    documentationCb: openExternalLink(hrefLinks.documentationOverview, {
      source: "checklist dropdown item",
    }),
    feedbackCb: () => {
      window.Intercom
        ? window.Intercom("showNewMessage")
        : (window.location.href = "mailto:hi@todesktop.com");
      track({
        event: "Open Feedback Dialog",
        properties: { source: "checklist dropdown item" },
      });
    },
  });

  // This prunes outdated checklist items to get an accurate number completed
  const [numberCompleted, numberToComplete] = getChecklistNumbers(
    completedItems,
    checklistItems
  );

  const overlay = (
    <Menu style={{ padding: "12px" }}>
      <WelcomeMessage
        numberCompleted={numberCompleted}
        length={checklistItems.length}
      />
      {alert && (
        <Alert showIcon={true} message={<div>{alert}</div>} type="info" />
      )}
      {checklistItems.map(({ id, title, emoji, callback }) => (
        <Menu.Item key={id} style={{ padding: "8px 4px" }}>
          <div onClick={callback}>
            <CustomCheckbox
              scale={1.2}
              checked={completedItems[id]}
              onChange={(e) => {
                updateFirestoreApp(
                  {
                    meta: {
                      completedChecklistItems: {
                        [id]: e.target.checked,
                      },
                    },
                  },
                  false
                );
                track({
                  event: "Checked Checklist Item",
                  properties: {
                    item: id,
                    checked: e.target.checked,
                  },
                });
              }}
            />
            <p
              css={css`
                display: inline;
                font-size: 15px;
                margin-left: 10px;
                cursor: pointer;
              `}
            >
              <span
                css={css`
                  color: ${completedItems[id] ? "#A0AEC0" : "inherit"};
                  text-decoration: ${completedItems[id]
                    ? "line-through"
                    : "underline"};
                `}
              >
                {title}
              </span>{" "}
              {emoji}
            </p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {app.subscription && (
        <Modal
          title="Create a Download Button for your Web App"
          centered
          footer={null}
          visible={showBtnBuilder}
          onCancel={() => setShowButtonBuilder(!showBtnBuilder)}
          closable={true}
        >
          <DownloadButtonBuilder app={app} />
        </Modal>
      )}
      <DropdownBadge numberToComplete={numberToComplete} overlay={overlay} />
    </>
  );
};

const CLIChecklist: React.FC = () => {
  const shouldMinimizeOnboarding = useStore($shouldMinimizeOnboarding);
  const [showCustomDomain, setShowCustomDomain] = useState(false);
  const [showMacCert, setShowMacCert] = useState(false);
  const [showWindowsCert, setShowWindowsCert] = useState(false);
  const [showArtifacts, setShowArtifacts] = useState(false);
  const completedItems = useStore($completedChecklistItems);

  if (!shouldMinimizeOnboarding) {
    return null;
  }

  const checklistItems = getCLIChecklistItems({
    customDomainCb: () => setShowCustomDomain(true),
    macCertCb: () => setShowMacCert(true),
    windowsCertCb: () => setShowWindowsCert(true),
    artifactsCb: () => setShowArtifacts(true),
  });

  const [numberCompleted, numberToComplete] = getChecklistNumbers(
    completedItems,
    checklistItems
  );

  const overlay = (
    <Menu style={{ padding: "12px" }}>
      <WelcomeMessage
        numberCompleted={numberCompleted}
        length={checklistItems.length}
      />
      {checklistItems.map(({ id, title, emoji, callback }) => (
        <Menu.Item key={id} style={{ padding: "8px 4px" }}>
          <div onClick={callback}>
            <CustomCheckbox
              scale={1.2}
              checked={completedItems[id]}
              onChange={(e) => {
                updateFirestoreApp(
                  {
                    meta: {
                      completedChecklistItems: {
                        [id]: e.target.checked,
                      },
                    },
                  },
                  false
                );
                track({
                  event: "Checked Checklist Item",
                  properties: {
                    item: id,
                    checked: e.target.checked,
                  },
                });
              }}
              disabled
            />
            <p
              css={css`
                display: inline;
                font-size: 15px;
                margin-left: 10px;
                cursor: pointer;
              `}
            >
              <span
                css={css`
                  color: ${completedItems[id] ? "#A0AEC0" : "inherit"};
                  text-decoration: ${completedItems[id]
                    ? "line-through"
                    : "underline"};
                `}
              >
                {title}
              </span>{" "}
              {emoji}
            </p>
          </div>
        </Menu.Item>
      ))}
      <Menu.Item
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => updateChecklistItem("shouldMinimizeOnboarding", false)}
      >
        <Button type="link">Put back in overview</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <ArtifactsModal
        showModal={showArtifacts}
        setShowModal={setShowArtifacts}
      />
      <DomainsModal
        showModal={showCustomDomain}
        setShowModal={setShowCustomDomain}
      />
      <CertificateModal
        platform={CertPlatform.Mac}
        showModal={showMacCert}
        setShowModal={setShowMacCert}
      />
      <CertificateModal
        platform={CertPlatform.Windows}
        showModal={showWindowsCert}
        setShowModal={setShowWindowsCert}
      />
      <DropdownBadge numberToComplete={numberToComplete} overlay={overlay} />
    </>
  );
};

const DropdownBadge: React.FC<{
  numberToComplete: number;
  overlay: JSX.Element;
}> = ({ numberToComplete, overlay }) => (
  <Dropdown trigger={["click"]} overlay={overlay}>
    <Box display={["none", "none", "flex"]} fontSize={3}>
      <Badge
        count={numberToComplete}
        offset={[-8, 0]}
        style={{ backgroundColor: "#3990ff" }}
      >
        <Button type="ghost">
          <CheckOutlined style={{ color: "white" }} />
        </Button>
      </Badge>
    </Box>
  </Dropdown>
);

const WelcomeMessage: React.FC<{
  numberCompleted: number;
  length: number;
}> = ({ numberCompleted, length }) => (
  <Space direction="column" align="flex-start" width="100%">
    <Heading variant="h5" as="h5">
      Welcome to ToDesktop 🎉
    </Heading>
    <Text variant="body3" pb={2}>
      Follow these steps to get up and running
    </Text>
    <Box width="100%" borderRadius="6px" bg="#f2f5f7" height="10px">
      <div
        css={css`
          width: ${(numberCompleted / length) * 100}%;
          border-radius: 6px;
          background: linear-gradient(158.58deg, #009eff 0%, #0242e4 101.17%);
          height: 10px;
        `}
      />
    </Box>
    <Space direction="column" />
  </Space>
);

function getChecklistNumbers(completedItems: any, checklistItems: any) {
  if (!completedItems) {
    return [0, 0];
  }

  const numberCompleted = Object.values(checklistItems)
    .map((item: any) => completedItems[item.id] === true)
    .filter(Boolean).length;
  const numberToComplete = checklistItems.length - numberCompleted;
  return [numberCompleted, numberToComplete];
}
