/*
 ** Sets the max width of the web app
 */
export const MAX__PAGE_WIDTH = 1280;

/*
 ** The pixel offset for the header gradient and overlapping content
 */
export const HEADER_PAGE_OFFSET = 40;

/**
 * Number of builds to show on page load in Builds
 */
export const BUILD_STATE_BUILDS_PAGE_LIMIT = 10;

/**
 * Number of builds to show in Electron app overview
 */
export const BUILD_STATE_BUILDS_OVERVIEW_LIMIT = 5;
