export const hrefLinks = {
  documentationOverview:
    "https://todesktop.com/docs/introduction/getting-started",
  optionsLaunchAtStartup:
    "https://todesktop.com/docs/app-options/launch-at-startup",
  optionsDefiningInternalUrls:
    "https://todesktop.com/docs/app-options/defining-internal-urls",
  optionsAppProtocols:
    "https://todesktop.com/docs/app-options/app-protocols-and-deeplinks",
  customizeCustomCSS:
    "https://todesktop.com/docs/recipes/add-custom-css-to-your-desktop-app",
  customizeCustomJavascript:
    "https://todesktop.com/docs/recipes/add-custom-javascript-to-your-desktop-app",
  customizeTransparentTitlebar:
    "https://todesktop.com/docs/recipes/make-a-transparent-titlebar-draggable",
  apiInfo: "https://todesktop.com/docs/API/client-core.app-",
  apiBadges:
    "https://todesktop.com/docs/recipes/app-icon-badging-bouncing-and-progress-bar",
  recipesNotifications:
    "https://todesktop.com/docs/recipes/sending-notifications",
  recipesSeparatingLogic:
    "https://todesktop.com/docs/recipes/seperate-desktop-from-web-app-logic",
  recipesStoringData: "https://todesktop.com/docs/recipes/storing-data",
  recipesDownloadLinks:
    "https://todesktop.com/docs/recipes/download-links-from-your-website",
  recipesGoogleOAuth: "https://todesktop.com/docs/recipes/google-oauth-screen",
  macCertSetup:
    "https://www.notion.so/todesktop1/Process-for-getting-code-signing-notarization-working-for-MacOS-apps-7299197234394c748715e8f23cf0b094",
  windowsCertSetup:
    "https://www.notion.so/todesktop1/Process-for-getting-HSM-certs-up-and-running-9d3b33cee7b84872af9880eed76d34b2",
  roadmap: "https://feedback.todesktop.com/",
  cliNpmDocs: "https://www.npmjs.com/package/@todesktop/cli",
  runtimeNpmDocs: "https://www.npmjs.com/package/@todesktop/runtime",
  migrationGuides: {
    general:
      "https://www.notion.so/todesktop1/How-to-integrate-ToDesktop-into-your-Electron-application-7c2fc9abe49b47e6bd774604955ef553",
    electronBuilder:
      "https://www.notion.so/todesktop1/How-to-migrate-from-electron-builder-to-ToDesktop-86d3e847c6874d1183f198a5439d4c01",
    electronWebpack:
      "https://www.notion.so/todesktop1/How-to-integrate-ToDesktop-into-an-electron-webpack-project-2a014e6d620f4b329b0a93cade832ce3",
    electronForge:
      "https://www.notion.so/todesktop1/How-to-migrate-from-electron-forge-to-ToDesktop-4c878440261d49bca63ef0bd177c00c4",
  },
};
