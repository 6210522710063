// modules
import { Build, BuildStatus } from "@todesktop/shared";
import React from "react";

// components
import { Tag, TagProps, Tooltip } from "antd";
import { selectedApp, useStore } from "../../store";

type TagStatus = BuildStatus | "released" | "done" | "preparation" | "error";
interface IBuildTag {
  isLatestRelease?: boolean;
  status: TagStatus;
  style?: React.CSSProperties;
}

const getTooltipAndTagProps = (
  status: TagStatus,
  options: { isLatestRelease: boolean }
) => {
  const { isLatestRelease } = options;
  switch (status) {
    case BuildStatus.failed:
    case "error":
      return {
        tagProps: {
          color: "red",
        },
        tooltip: "One of the platform builds have failed",
      };
    case BuildStatus.cancelled:
      return {
        tagProps: {
          color: "#A9A9A9",
        },
        tooltip: "This build was cancelled",
      };
    case BuildStatus.succeeded:
    case "done":
      return {
        tagProps: {
          color: "blue",
        },
        tooltip: "All of the platform builds have succeeded",
      };
    case "released":
      return {
        tagProps: {
          color: "green",
          style: {
            // A little lighter than the default if it's not the latest
            borderColor: isLatestRelease ? "" : "#c4fd98",
          },
        },
        tooltip:
          "This build has been released" +
          (isLatestRelease ? " and is the latest of all releases" : ""),
      };
    case "queued":
      return {
        tagProps: {
          color: "",
        },
        tooltip: "This build is currently queued",
      };
    case "preparation":
      return {
        tagProps: {
          color: "",
        },
        tooltip: "This build is currently being prepared",
      };
    case "building":
    default:
      return {
        tagProps: {
          color: "orange",
        },
        tooltip: "At least one platform build is still building",
      };
  }
};

export const BuildTag: React.FC<IBuildTag> = ({
  isLatestRelease,
  status,
  style = {},
}) => {
  const { tagProps, tooltip } = getTooltipAndTagProps(status, {
    isLatestRelease,
  });

  return (
    <Tooltip title={tooltip}>
      <Tag
        {...tagProps}
        style={{ ...tagProps.style, ...style, marginRight: "0" }}
      >
        {isLatestRelease ? "latest release" : status}
      </Tag>
    </Tooltip>
  );
};

type ReleaseStatus = "release" | "latest-release" | "partial-release";
export const BuildReleaseTag: React.FC<{ build: Build } & TagProps> = ({
  build,
  style = {},
  ...props
}) => {
  const app = useStore(selectedApp);
  const releasedBuildId = useStore((state) => state.buildState.releasedBuildId);

  if (!build.releasedAt && !build.partiallyReleasedAt) return null;

  const redirection = app?.meta?.releaseRedirections?.find(
    (r) =>
      (r.rule === "buildByIp" || r.rule === "buildByPlatform") &&
      r.buildId === build.id
  );

  if (releasedBuildId === build.id) {
    return (
      <Tooltip title="This build has been released and is the latest of all releases">
        <Tag color="green" style={{ marginRight: "0", ...style }} {...props}>
          latest release
        </Tag>
      </Tooltip>
    );
  } else if (redirection?.rule === "buildByPlatform") {
    return (
      <Tooltip
        title={`This build has been released to the following platforms: ${redirection.platforms.join(
          ", "
        )}`}
      >
        <Tag color="green" style={{ marginRight: "0", ...style }} {...props}>
          partial release
        </Tag>
      </Tooltip>
    );
  } else if (redirection?.rule === "buildByIp") {
    return (
      <Tooltip
        title={`This build has been released to the following IP addresses: ${redirection.ipList.join(
          ", "
        )}`}
      >
        <Tag color="green" style={{ marginRight: "0", ...style }} {...props}>
          partial release
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="This build has been previously released">
      <Tag color="green" style={{ marginRight: "0", ...style }} {...props}>
        previous release
      </Tag>
    </Tooltip>
  );
};
