import React from "react";
import { Row, Col } from "antd";
import { StyledAntdCard } from "../atoms/StyledAntdCard";

export const DummyAppLoader = ({ loading }: { loading: boolean }) => (
  <>
    <Row gutter={24}>
      <Col lg={12} span={24}>
        <StyledAntdCard title=" " loading={loading} />
      </Col>
      <Col lg={12} span={24}>
        <StyledAntdCard title=" " loading={loading} />
      </Col>
    </Row>
    <Row gutter={24}>
      <Col lg={12} span={24}>
        <StyledAntdCard title=" " loading={loading} />
      </Col>
      <Col lg={12} span={24}>
        <StyledAntdCard title=" " loading={loading} />
      </Col>
    </Row>
  </>
);
