import { Space, Tooltip, Select } from "antd";
import React from "react";
import {
  selectedApp,
  useStore,
  appHasActiveSubscription,
  $hasFeatureAccess,
} from "../../../store";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import { updateFirestoreApp } from "../../../~reusables/actions";
import { FormItem } from "../../atoms/FormUtils";
import { InfoCircleOutlined, StarOutlined } from "@ant-design/icons";
import { HasFeatureGuard } from "../HasFeatureGuard";
import buildArtifactsFeatureImg from "../../../~reusables/images/buildArtifactsFeature.svg";
import { IPlans } from "../../../~reusables/types";
import { planGroups } from "../../../~reusables/data/plans";

export const NSISConfig: React.FC = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <IdentityName />
    </Space>
  );
};

export const IdentityName: React.FC = () => {
  const { space } = useTheme();
  const { nsisConfig } = useStore(selectedApp);
  const hasActiveSubscription = useStore(appHasActiveSubscription);
  const hasFeatureAccess = useStore($hasFeatureAccess(planGroups.pro.plans));

  type SelectTypes = "perUser" | "perMachine" | "userChoice";
  const defaultSelectValue: SelectTypes = "perUser";
  let selectValue: SelectTypes = defaultSelectValue;

  if (nsisConfig) {
    const { oneClick, perMachine } = nsisConfig;
    if (oneClick && perMachine) {
      selectValue = "perMachine";
    } else if (!oneClick && !perMachine) {
      selectValue = "userChoice";
    } else {
      // default to perUser
      selectValue = "perUser";
    }
  }

  const callback = (value: SelectTypes) => {
    if (value === "perMachine") {
      updateFirestoreApp({ nsisConfig: { oneClick: true, perMachine: true } });
    } else if (value === "userChoice") {
      updateFirestoreApp({
        nsisConfig: { oneClick: false, perMachine: false },
      });
    } else {
      // default to perUser
      updateFirestoreApp({ nsisConfig: { oneClick: true, perMachine: false } });
    }
  };

  return (
    <HasFeatureGuard
      src={buildArtifactsFeatureImg}
      alt="Mac, Windows and Linux graphic showing the configurable build artifacts"
      name="Multiple Windows Installation types"
      description="Choose to install the app on all user profiles or simply offers the user the choice during installation."
      featurePlans={planGroups.pro.plans}
      planName={planGroups.pro.name}
    >
      <FormItem
        label={
          hasActiveSubscription && hasFeatureAccess ? (
            "Installation Type"
          ) : (
            <Tooltip
              title={`Customizing installation type is only available on the Professional plan.`}
            >
              <StarOutlined
                style={{ marginRight: space[3], color: "#f9c606" }}
              />
              Installation Type
            </Tooltip>
          )
        }
        marginBottom="12px"
        htmlFor="InstallationType"
      >
        <Select
          disabled={!hasFeatureAccess}
          value={selectValue}
          defaultValue={defaultSelectValue}
          onChange={callback}
          style={{ width: 200, marginRight: 10 }}
          id="installationType"
        >
          <Select.Option value="perUser">Per user install</Select.Option>
          <Select.Option value="perMachine">Per machine install</Select.Option>
          <Select.Option value="userChoice">Let the user choose</Select.Option>
        </Select>
        <Tooltip
          title={
            <ul>
              <li>
                <b>Per-user install</b> (default) — Installation will occur only
                on the user’s profile.
              </li>
              <li>
                <b>Per-machine install</b> — Installation will occur for{" "}
                <b>all</b> users on the machine.
              </li>
              <li>
                <b>Let the user choose</b> — The installation will ask the user
                if they wish to install per-user or per-machine.
              </li>
            </ul>
          }
        >
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
        </Tooltip>
      </FormItem>
    </HasFeatureGuard>
  );
};
