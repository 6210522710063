// libs
import React, { useState } from "react";
import { Build, isBuildRunning, isBuildCancellable } from "@todesktop/shared";

// components
import { Button, message, Modal, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

// logic
import { callFirebaseFunction } from "../../~reusables/firebase";
import { selectedApp, useStore } from "../../store";

const cancelBuild = callFirebaseFunction("cancelBuild");

export const CancelBuildButton: React.FC<{
  build: Build;
  shouldUseIcon?: boolean;
}> = ({ build, shouldUseIcon = false }) => {
  const [loadingCancelRequest, setLoadingCancelRequest] = useState(false);
  const app = useStore(selectedApp);
  const canCancelBuild = isBuildCancellable(build);
  const isBuildOngoing = isBuildRunning(build);

  if (!isBuildOngoing) {
    return null;
  }

  return (
    <Tooltip
      title={
        canCancelBuild
          ? "Cancel build"
          : isBuildOngoing
          ? "This build can't be cancelled (the build job isn't running)"
          : `This build ${
              build.status === "cancelled" ? "was cancelled" : "has completed"
            }`
      }
    >
      <Button
        onClick={async (e) => {
          try {
            e.stopPropagation();
            e.preventDefault();
            setLoadingCancelRequest(true);
            await cancelBuild({ appId: app.id, buildId: build.id });
            message.success("Build cancelled");
          } catch (error) {
            Modal.error({
              title: "Error cancelling build",
              content: error.message,
            });
          }
          setLoadingCancelRequest(false);
        }}
        disabled={!canCancelBuild}
        icon={shouldUseIcon ? <CloseOutlined /> : undefined}
        loading={loadingCancelRequest}
        shape={shouldUseIcon ? "circle" : undefined}
        size={shouldUseIcon ? "small" : undefined}
      >
        {shouldUseIcon ? null : "Cancel build"}
      </Button>
    </Tooltip>
  );
};
