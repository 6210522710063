import { IApp, IAppFromServerProps, ISwitchableValue } from "@todesktop/shared";

export const readSwitchableValue = <T>(
  value: ISwitchableValue<T>,
  fallback: any = null
) => {
  return value && value.enabled ? value.value : fallback; // TODO null is temporary, as firebase doesn't support undefined
};

export const isSwitchableEnabled = <T>(value: ISwitchableValue<T>) => {
  return value && value.enabled;
};

export const convertToNullIfEmpty = (value: any) =>
  value === undefined ? null : value;

export function createDefaultDesktopApp(appProps: IAppFromServerProps) {
  switch (appProps.appType) {
    case "app":
      return createNoCodeApp(appProps);
    case "menubar":
      return createNoCodeMenubarApp(appProps);
    // @ts-ignore - update types
    case "electron":
      return createElectronApp(appProps);
    default:
      return createNoCodeApp(appProps);
  }
}

export const defaultIcon =
  "https://download.todesktop.com/default-icons/blank-icon.png";

export function createElectronApp(appProps: IAppFromServerProps) {
  const { name, id, appType } = appProps;

  const electronApp = {
    id,
    name: name || "My App",
    appType,
    icon: defaultIcon,
    meta: {
      publishedVersions: {},
    },
    shouldCreateAppImages: true,
    shouldCreateMacUniversalInstaller: true,
    shouldCreateAppleSiliconAssets: true,
    shouldCreateNSISInstallers: true,
  };

  return electronApp as IApp;
}

const sharedNoCodeMeta = {
  schemaVersion: 2,
  appIterations: 0,
  isAppChanged: false,
  isFrameBlocked: false,
  publishedVersions: {},
  completedChecklistItems: {},
};

const sharedNoCodeWindowOptions = {
  isMaximizable: true,
  isResizable: true,
  isMinimizable: true,
  isFullScreenStartEnabled: false,
  isAlwaysOnTop: false,
  isAutoHideMenuBar: false,
  isTitlebarTransparent: false,
  isInsetTitlebarTransparent: false,
};

const sharedNoCodeAppOptions = {
  isSingleInstance: false,
  isDevToolsDisabled: false,
  isContextMenuDisabled: false,
  isWebSecurityDisabled: false,
  isBackgroundThrottlingPrevented: false,
  isGoogleOAuthExternal: false,
  customUserAgent: { enabled: false, value: "" },
  internalUrls: { enabled: false, value: "" },
  appProtocol: { enabled: false, value: "" },
  subscription: null,
};

export function createNoCodeApp(appProps: IAppFromServerProps) {
  const { name, id, iconUrl, url, meta, appModelId, appType } = appProps;

  const defaultApp: IApp = {
    id,
    appType: appType || "app",
    appModelId,
    url,
    name: name || "My App",
    icon: iconUrl || defaultIcon,
    shouldCreateAppImages: true,
    shouldCreateMacUniversalInstaller: true,
    shouldCreateNSISInstallers: true,
    shouldLaunchAtStartupByDefault: false,
    ...sharedNoCodeAppOptions,
    windowOptions: {
      width: 800,
      height: 600,
      minWidth: { enabled: false, value: 600 },
      minHeight: { enabled: false, value: 400 },
      maxWidth: { enabled: false, value: 1200 },
      maxHeight: { enabled: false, value: 1000 },
      ...sharedNoCodeWindowOptions,
    },
    meta: {
      ...sharedNoCodeMeta,
      ...meta,
    },
  };

  return defaultApp;
}

export function createNoCodeMenubarApp(appProps: IAppFromServerProps) {
  const { name, id, iconUrl, url, meta, appModelId, appType } = appProps;

  const menubarApp: IApp = {
    id,
    appType,
    appModelId,
    url,
    name: name || "My App",
    icon: iconUrl || defaultIcon,
    trayIcon: iconUrl || defaultIcon,
    menubarIcon: iconUrl || defaultIcon,
    shouldCreateAppImages: true,
    shouldCreateMacUniversalInstaller: true,
    shouldCreateNSISInstallers: true,
    shouldLaunchAtStartupByDefault: true,
    toggleVisibilityKeyboardShortcut: {
      enabled: false,
      value: "CommandOrControl+Shift+A",
    },
    ...sharedNoCodeAppOptions,
    windowOptions: {
      width: 400,
      height: 500,
      minWidth: { enabled: false, value: 280 },
      minHeight: { enabled: false, value: 300 },
      maxWidth: { enabled: false, value: 500 },
      maxHeight: { enabled: false, value: 700 },
      ...sharedNoCodeWindowOptions,
    },
    meta: {
      ...sharedNoCodeMeta,
      ...meta,
    },
  };

  return menubarApp;
}
