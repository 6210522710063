/* eslint-disable react/display-name */
import {
  ArrowRightOutlined,
  CopyOutlined,
  DownloadOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Arch, Build, IApp } from "@todesktop/shared";
import {
  Alert,
  Button,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import isEqual from "lodash.isequal";
import React, { useEffect, useState } from "react";
import ReactDiffViewer from "react-diff-viewer";
import semver from "semver";
import { Pill } from "../../../components/atoms/Pill";
import { Box, Flex } from "../../../components/atoms/Primitives";
import { TooltipLink } from "../../../components/atoms/TextUtils";
import { selectedApp, useStore } from "../../../store";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import {
  DownloadLinkStats,
  copyToClipboard,
  getPercentChange,
} from "../../../~reusables/util";
import { BuildArtifact } from "../../../~reusables/util/build";
import {
  FileSizeChecks,
  Status,
  determineAnalysisStatus,
  useReleaseContext,
} from "./ReleaseContext";
import {
  PlatformIcons,
  ReleaseSection,
  ReleaseSectionProps,
} from "./ReleaseSection";
import {
  DependencyAnalysisRow,
  DependencyAnalysisState,
  useBuildArtifacts,
  useDependencyAnalysis,
  useDependencyAnalysisCall,
  useDependencyAnalysisUpdate,
} from "./useBuildArtifactsSection";

const { Text } = Typography;

export const BuildArtifactsSection: React.FC<ReleaseSectionProps> = (props) => {
  const {
    props: { app, build, release },
    setAnalysisState,
  } = useReleaseContext();

  const {
    lockfileWarning,
    loadingMessage,
    buildStatus,
    releaseStatus,
    analysisRows,
  } = useDependencyAnalysis(build, release);
  const buildAnalysisCall = useDependencyAnalysisCall(app, build);
  const releaseAnalysisCall = useDependencyAnalysisCall(app, release);

  const { buildArtifacts } = useBuildArtifacts(app, build, release);

  useEffect(() => {
    const states = [buildAnalysisCall.state, releaseAnalysisCall.state];
    const checks: Record<FileSizeChecks, Status> = {
      "artifact-sizes": determineAnalysisStatus([buildStatus]),
      dependencies: determineAnalysisStatus([buildStatus]),
      "todesktop-json": "success",
    };

    if (buildStatus) {
      setAnalysisState(
        "buildArtifacts",
        { states: Object.values(checks), checks },
        "warn"
      );
    } else {
      setAnalysisState("buildArtifacts", { states, checks }, "warn");
    }
  }, [
    releaseAnalysisCall.state,
    buildAnalysisCall.state,
    buildStatus,
    releaseStatus,
    setAnalysisState,
  ]);

  return (
    <ReleaseSection {...props}>
      <Flex css={{ flexDirection: "column", gap: "40px" }}>
        <ArtifactsTable style={{ width: "100%" }} dataSource={buildArtifacts} />
        <DependencyAnalysisTable
          app={app}
          build={build}
          release={release}
          dataSource={loadingMessage ? [] : analysisRows}
          loading={loadingMessage ? { tip: loadingMessage } : false}
          title={() => {
            return lockfileWarning ? (
              <Alert type="info" message={lockfileWarning} />
            ) : null;
          }}
        />
        <ToDesktopJSONDiff build={build} release={release} />
      </Flex>
    </ReleaseSection>
  );
};

export const ArtifactsTable: React.FC<TableProps<BuildArtifact>> = (props) => {
  const app = useStore(selectedApp);

  const columns: ColumnsType<BuildArtifact> = [
    {
      title: "OS",
      dataIndex: "platform",
      key: "platform",
      render: (platform: string) => {
        const Icon = PlatformIcons[platform];
        const title = platform[0].toLocaleUpperCase() + platform.substring(1);
        return (
          <Tooltip title={title}>
            <Icon />
          </Tooltip>
        );
      },
    },
    {
      title: "Artifact",
      dataIndex: "artifact",
      key: "artifact",
      render: (artifact) => {
        return <Text css={{ whiteSpace: "nowrap" }}>{artifact}</Text>;
      },
    },
    {
      title: "Architecture",
      dataIndex: "architecture",
      key: "architecture",
      render: (architecture: Arch) => {
        return <Pill color="gray">{architecture}</Pill>;
      },
    },
    {
      title: "Size",
      dataIndex: "stats",
      key: "stats",
      render: (stats: DownloadLinkStats) => {
        if (!stats?.size) return null;
        const { size, statusColor, percentChange, direction, release } = stats;
        return (
          <Space style={{ whiteSpace: "nowrap" }}>
            <Text>{size}</Text>
            {release && percentChange && (
              <Tooltip
                title={
                  <>
                    {percentChange} {direction}, compared to the{" "}
                    <TooltipLink to={`/apps/${app.id}/builds/${release.id}`}>
                      <strong>previous release (v{release.appVersion})</strong>
                    </TooltipLink>
                    .
                  </>
                }
              >
                <Tag
                  style={{ borderRadius: "12px", border: 0, fontWeight: 700 }}
                  color={statusColor}
                >
                  {percentChange}
                </Tag>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "downloadUrl",
      key: "downloadUrl",
      render: (downloadUrl, record) => {
        if (record.disabledTooltip) {
          return (
            <Space>
              <Tooltip title={record.disabledTooltip}>
                <Button disabled size="small" icon={<CopyOutlined />} />
              </Tooltip>
              <Tooltip title={record.disabledTooltip}>
                <Button disabled size="small" icon={<DownloadOutlined />} />
              </Tooltip>
            </Space>
          );
        }

        return (
          <Space>
            <Button
              size="small"
              icon={<CopyOutlined />}
              onClick={copyToClipboard("download URL", downloadUrl)}
            />
            <a href={downloadUrl} download>
              <Button
                download={downloadUrl}
                size="small"
                icon={<DownloadOutlined />}
              />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      {...props}
    />
  );
};

interface DependencyAnalysisTableProps
  extends TableProps<DependencyAnalysisRow> {
  app: IApp;
  build: Build;
  release: Build;
}

export const DependencyAnalysisTable: React.FC<DependencyAnalysisTableProps> = ({
  app,
  build,
  release,
  ...props
}) => {
  const [analysisState, setAnalysisState] = useState<DependencyAnalysisState>(
    {}
  );

  const buildDeps = props.dataSource.map((row) => ({
    name: row.name,
    version: row.versions.current?.installedVersion,
  }));
  useDependencyAnalysisUpdate(app, build, buildDeps, setAnalysisState);

  const releaseDeps = props.dataSource.map((row) => ({
    name: row.name,
    version: row.versions.previous?.installedVersion,
  }));
  useDependencyAnalysisUpdate(app, release, releaseDeps, setAnalysisState);

  const columns: ColumnsType<DependencyAnalysisRow> = [
    {
      title: "Dependencies",
      dataIndex: "name",
      key: "name",
      filters: (["dependencies", "devDependencies"] as const).map((key) => ({
        value: key,
        text: <AnalysisTypeTag type={key} />,
      })),
      onFilter: (value: string, record) => {
        return record.type === value;
      },
      render: (name: DependencyAnalysisRow["name"], row) => {
        return <AnalysisTypeTag type={row.type}>{name}</AnalysisTypeTag>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      defaultFilteredValue: ["added", "updated", "removed"],
      filters: (["unchanged", "added", "updated", "removed"] as const).map(
        (key) => ({
          value: key,
          text: <AnalysisStatusTag status={key} />,
        })
      ),
      onFilter: (value: string, record) => {
        return record.status === value;
      },
      render: (status: DependencyAnalysisRow["status"]) => {
        return <AnalysisStatusTag status={status} />;
      },
    },
    {
      title: () => "Version",
      dataIndex: "versions",
      key: "versions",
      render: (
        { previous, current }: DependencyAnalysisRow["versions"],
        row
      ) => {
        const shouldCompareVersions =
          current?.installedVersion &&
          previous?.installedVersion &&
          row.status === "updated";

        if (shouldCompareVersions) {
          const [hasMajorChanged, hasMinorChanged, hasPatchChanged] = [
            semver.major,
            semver.minor,
            semver.patch,
          ].map((fn) => {
            try {
              return (
                fn(previous.installedVersion) !== fn(current.installedVersion)
              );
            } catch {
              return false;
            }
          });

          const splitCurrentVersions = current.installedVersion.split(".");
          const css = hasMajorChanged
            ? { ".major": { color: "#f5222d", fontWeight: 500 } }
            : hasMinorChanged
            ? { ".minor": { color: "#1677ff", fontWeight: 500 } }
            : hasPatchChanged
            ? { ".patch": { color: "#52c41a", fontWeight: 500 } }
            : {};

          return (
            <Flex
              css={{
                alignItems: "center",
                gap: "6px",
                whiteSpace: "nowrap",
                ...css,
              }}
            >
              <Text>{previous.installedVersion}</Text>
              <Box css={{ svg: { width: "10px" } }}>
                <ArrowRightOutlined />
              </Box>
              <Text>
                {splitCurrentVersions.map((version, i) => {
                  let className = "major";
                  if (i > 0) className += " minor";
                  if (i > 1) className += " patch";

                  return (
                    <span key={i} className={className}>
                      {version}
                      {i === splitCurrentVersions.length - 1 ? "" : "."}
                    </span>
                  );
                })}
              </Text>
            </Flex>
          );
        } else if (current?.installedVersion) {
          return <Text>{current.installedVersion}</Text>;
        } else if (current?.specifiedVersion) {
          return (
            <Tooltip title="Exact installed version could not be detected. This is the version specified in your package.json.">
              {current?.specifiedVersion}
              <WarningFilled
                style={{
                  color: "orange",
                  marginLeft: 5,
                }}
              />
            </Tooltip>
          );
        }

        return null;
      },
    },
    {
      title: () => "Size",
      dataIndex: "size",
      key: "size",
      render: (data, row) => {
        const current = analysisState[build.id]?.[row.name];
        const comparative = analysisState[release?.id]?.[row.name];

        if (!current) {
          return <Skeleton.Button size="small" active />;
        } else if (current.error === "N/A") {
          return (
            <Tooltip title="Exact installed version could not be detected. Therefore it is not possible to calculate the size of this dependency.">
              <Text type="danger">{current.error}</Text>
            </Tooltip>
          );
        } else if (current.error) {
          return <Text type="danger">{current.error}</Text>;
        }

        const {
          direction,
          percentChange,
          size,
          statusColor,
        } = getPercentChange(current?.result?.size, comparative?.result?.size);

        return (
          <Space>
            <Text>{size}</Text>
            {comparative && percentChange && (
              <Tooltip
                title={`${percentChange} ${direction}, compared to the previous version.`}
              >
                <Tag
                  style={{ borderRadius: "12px", border: 0, fontWeight: 700 }}
                  color={statusColor}
                >
                  {percentChange}
                </Tag>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      pagination={
        props.dataSource.length > 15 ? { size: "small", pageSize: 15 } : false
      }
      scroll={{ x: true }}
      {...props}
    />
  );
};

const AnalysisTypeTag: React.FC<{
  type: DependencyAnalysisRow["type"];
}> = ({ type, children }) => {
  switch (type) {
    case "dependencies":
      return <Tag color="blue">{children || "dependency"}</Tag>;
    case "devDependencies":
      return (
        <Tag color="cyan" style={{ whiteSpace: "nowrap" }}>
          {children || "dev dependency"}
        </Tag>
      );
  }
};

const AnalysisStatusTag: React.FC<{
  status: DependencyAnalysisRow["status"];
}> = ({ status }) => {
  switch (status) {
    case "unchanged":
      return <Pill color="gray">Unchanged</Pill>;
    case "added":
      return <Pill color="green">Added</Pill>;
    case "updated":
      return <Pill color="blue">Updated</Pill>;
    case "removed":
      return <Pill color="purple">Removed</Pill>;
  }
};

export const ToDesktopJSONDiff: React.FC<{
  build: Build;
  release: Build;
}> = ({ build, release }) => {
  const { colors } = useTheme();

  const format = (str) => JSON.stringify(JSON.parse(str), undefined, 2);
  const newConfig = build.projectConfig ? format(build.projectConfig) : "";
  const oldConfig = release?.projectConfig ? format(release.projectConfig) : "";
  if (!newConfig && !oldConfig) return null;

  return (
    <Flex
      css={{
        flexDirection: "column",
        borderBottom: `1px solid rgba(000,000,000, 0.06)`,
      }}
    >
      <Box
        css={{
          background: "#fafafa",
          padding: "8px",
          borderBottom: `1px solid rgba(000,000,000, 0.06)`,
        }}
      >
        <Text style={{ fontWeight: 500 }}>
          ToDesktop JSON {isEqual(newConfig, oldConfig) ? " (Unchanged)" : ""}
        </Text>
      </Box>
      <ReactDiffViewer
        oldValue={oldConfig || newConfig} // would otherwise show a positive diff the first time a build is done
        newValue={newConfig}
        splitView={false}
        hideLineNumbers={true}
        styles={{
          variables: {},
          diffContainer: {},
          diffRemoved: {},
          diffAdded: {},
          marker: {},
          highlightedLine: { border: "1px solid red" },
          highlightedGutter: { border: "1px solid red" },
          gutter: { border: "1px solid red" },
          line: { fontSize: "12px" },
          wordDiff: {},
          wordAdded: {},
          wordRemoved: {},
          codeFoldGutter: {},
          // @ts-expect-error - codeFold supported, but not typed
          codeFold: {
            background: "#fafafa",
            fontFamily: "sans-serif",
            fontSize: "12px",
            color: colors.title,
            a: { textDecoration: "none !important" },
          },
          emptyLine: {},
          emptyGutter: {},
          contentText: {},
        }}
      />
    </Flex>
  );
};
