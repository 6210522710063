import React from "react";

interface IPlusProps {
  color?: string;
}
const PlusSVG: React.SFC<IPlusProps> = (props) => (
  <svg width={14} height={15} {...props}>
    <path
      fill={props.color || "#000"}
      d="M8.978 9.296v4.792H4.82V9.296H.328V5.288H4.82V.496h4.158v4.792h4.493v4.008z"
      fillRule="evenodd"
    />
  </svg>
);

export default PlusSVG;
