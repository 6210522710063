// modules
import React, { useState } from "react";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";
import { NavLink, Link, RouteComponentProps } from "react-router-dom";
import css from "@emotion/css";
import { DownOutlined } from "@ant-design/icons";
import { useTitle } from "../../~reusables/hooks/useTitle";

// components
import MaxWidthContainer from "./MaxWidthContainer";
import logoSVG from "../../~reusables/images/logo-new-with-transparent-bg.svg";
import { HEADER_PAGE_OFFSET } from "../../~reusables/constants/metrics";
import { Avatar, Button, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";

// logic
import { useStore, selectedApp, update } from "../../store";
import {
  goToManageSubscriptions,
  goToManageAccessToken,
  goToManageAccount,
  createCustomerPortalSession,
  goToManageUsers,
} from "../../~reusables/actions";
import { signOut } from "../../~reusables/firebase";
import { useNavigation } from "../../~reusables/contexts/NavigationContext";
import { Heading } from "../atoms/Heading";
import { ChecklistDropdown } from "../edit/ChecklistDropdown";
import { Space } from "../atoms/Space";
import { Flex } from "../atoms/Primitives";
import { IApp2 } from "@todesktop/shared";
import { Spin } from "antd";
import { hrefLinks } from "../../~reusables/constants";

const WebAppHeader: React.FC<RouteComponentProps> = () => {
  const {
    navState: { appLevelNav, teamLevelNavItems },
  } = useNavigation();
  const { user, selectedTeam } = useStore((state) => ({
    user: state.user,
    selectedTeam: state.selectedTeam,
  }));
  const { breakpoints } = useTheme();

  const app = useStore(selectedApp);

  const shouldShowAppNav = !!appLevelNav.items.length;
  useTitle(shouldShowAppNav ? app?.name : null);

  const navigationItems = shouldShowAppNav
    ? appLevelNav.items
    : teamLevelNavItems;

  const homeNav =
    selectedTeam && selectedTeam.name
      ? selectedTeam.name
      : user
      ? user.email
      : "";

  async function manageBilling() {
    update((state) => ({ ...state, userDataLoaded: false }));

    await createCustomerPortalSession();
  }

  return (
    <StyledHeader>
      <MaxWidthContainer>
        <section className="top-level">
          <section>
            <div>
              {user ? (
                <Link to="/">
                  <img
                    height="50"
                    width="55.5"
                    src={logoSVG}
                    alt="ToDesktop white logo"
                  />
                </Link>
              ) : (
                <img
                  height="50"
                  width="55.5"
                  src={logoSVG}
                  alt="ToDesktop white logo"
                />
              )}
            </div>
            <ul
              css={css`
                @media only screen and (max-width: ${breakpoints[1]}) {
                  border: 1px solid yellow;
                  display: none !important;
                }
              `}
            >
              <li>
                <Heading variant="h3" as="h3">
                  <NavLink
                    to="/"
                    activeClassName={`${!appLevelNav.items
                      .length}-active-team-link`}
                  >
                    {homeNav}
                  </NavLink>
                </Heading>
              </li>

              {shouldShowAppNav && app && (
                <li>
                  <Heading variant="h3" as="h3">
                    <NavLink
                      to={`/apps/${app.id}`}
                      activeClassName="active-nav-link"
                    >
                      {app.schemaVersion >= 2
                        ? ((app as unknown) as IApp2).desktopApp.name
                        : app.name}
                    </NavLink>
                  </Heading>
                </li>
              )}
            </ul>
          </section>
          <section>
            <Space size="md">
              {shouldShowAppNav && app?.appType === "electron" && (
                <>
                  <Button
                    type="ghost"
                    style={{ color: "white", marginRight: 10 }}
                    href={"https://www.npmjs.com/package/@todesktop/cli"}
                    target="_blank"
                  >
                    CLI Docs
                  </Button>
                  <Button
                    type="ghost"
                    style={{ color: "white" }}
                    href={"https://www.npmjs.com/package/@todesktop/runtime"}
                    target="_blank"
                  >
                    Runtime Docs
                  </Button>
                </>
              )}
              {shouldShowAppNav && app?.appType != "electron" && (
                <Button
                  type="ghost"
                  style={{ color: "white" }}
                  href={hrefLinks.documentationOverview}
                  target="_blank"
                >
                  Docs
                </Button>
              )}
              {user && (
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      { label: <Link to="/">{homeNav}</Link>, key: "home" },
                      {
                        label: (
                          <a onClick={goToManageAccessToken}>
                            Manage Access Token
                          </a>
                        ),
                        key: "manage-token",
                      },
                      {
                        disabled: user?.stripeCustomerId ? false : true,
                        label: <a onClick={manageBilling}>Manage Billing</a>,
                        key: "manage-billing",
                      },
                      {
                        label: (
                          <a onClick={goToManageSubscriptions}>
                            Manage Subscriptions
                          </a>
                        ),
                        key: "manage-sub",
                      },
                      {
                        label: (
                          <a onClick={goToManageAccount}>Manage Account</a>
                        ),
                        key: "manage-account",
                      },
                      {
                        label: <a onClick={goToManageUsers}>Manage Users</a>,
                        key: "manage-users",
                      },
                      user.isAdmin && {
                        label: <Link to="/super-admin">🧙‍♂️ Super Admin</Link>,
                        key: "super-admin",
                      },
                      {
                        label: (
                          <a
                            onClick={async () => {
                              await signOut();
                            }}
                          >
                            Log Out
                          </a>
                        ),
                        key: "log-out",
                      },
                    ],
                  }}
                >
                  {user.avatar ? (
                    <AvatarIcon src={user.avatar} />
                  ) : (
                    <AvatarIcon Icon={UserOutlined} />
                  )}
                </Dropdown>
              )}
            </Space>
          </section>
        </section>
        <hr />
        <Flex justifyContent="space-between">
          <ul>
            {user &&
              navigationItems.map(({ title, to, exact }) => (
                <li key={title}>
                  <Heading variant="h4" as="h4">
                    <NavLink
                      exact={exact}
                      to={to}
                      activeClassName="active-nav-link"
                    >
                      {title}
                    </NavLink>
                  </Heading>
                </li>
              ))}
          </ul>
          {shouldShowAppNav && app?.schemaVersion !== 2 ? (
            <ChecklistDropdown />
          ) : null}
        </Flex>
      </MaxWidthContainer>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background: ${(p) => p.theme.colors.blueGradientAlternateAngle};
  padding-bottom: ${HEADER_PAGE_OFFSET}px;

  & > div {
    hr {
      border: 0;
      height: 0;
      border-top: 1px solid ${(p) => p.theme.colors.lightBackground};
      opacity: 0.5;
      margin: ${(p) => p.theme.space[6]}px 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      li {
        margin-right: ${(p) => p.theme.space[7]}px;

        @media only screen and (max-width: ${(p) => p.theme.breakpoints[1]}) {
          margin-right: ${(p) => p.theme.space[6]}px;
        }

        h3,
        h4 {
          margin-bottom: 0;
        }

        a {
          color: ${(p) => p.theme.colors.greys[7]};
          opacity: 0.8;
        }

        a.active-nav-link,
        a.true-active-team-link {
          color: ${(p) => p.theme.colors.white};
          opacity: 1;
        }
      }
    }

    .top-level {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > section:first-of-type {
        display: flex;
        align-items: center;
        img {
          margin-right: ${(p) => p.theme.space[6]}px;
        }
      }
    }
  }
`;

const AvatarIcon: React.FC<{ Icon?: any; src?: string }> = ({
  src,
  Icon,
  ...attrs
}) => {
  const { space, colors } = useTheme();
  return (
    <div
      css={css`
        cursor: pointer;
        display: flex;
        align-items: center;
      `}
      {...attrs}
    >
      <Avatar src={src} icon={Icon ? <Icon /> : null} />
      <DownOutlined style={{ marginLeft: space[4], color: colors.greys[5] }} />
    </div>
  );
};

export default WebAppHeader;
