import React, { SFC } from "react";
import { Tooltip } from "antd";
import styled from "@emotion/styled";

const QuestionMark = styled.span`
  border: 1px rgba(0, 0, 0, 0.2) solid;
  color: #444;
  opacity: 0.7;
  border-radius: 100%;
  font-size: 13px;
  width: 20px;
  display: inline-block;
  text-align: center;
  height: 20px;
  vertical-align: middle;
  line-height: 18px;
  margin-left: 7px;
  margin-right: 15px;
  margin-top: -2px;
  font-weight: bold;
  cursor: help;
  transition: all 0.5s;
  &:hover {
    border: 1px #000 solid;
    opacity: 0.8;
    color: white;
    background: black;
  }
`;

interface IQuestionTooltipProps {
  text?: string | JSX.Element;
}
const QuestionTooltip: SFC<IQuestionTooltipProps> = ({ text }) =>
  text ? (
    <Tooltip title={text}>
      <QuestionMark>?</QuestionMark>
    </Tooltip>
  ) : null;

export default QuestionTooltip;
