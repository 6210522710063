/* eslint-disable react/display-name */
import { Build, SmokeTestProgress } from "@todesktop/shared";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Box, Flex } from "../../../components/atoms/Primitives";
import QuestionTooltip from "../../../components/atoms/QuestionTooltip";
import { useStore } from "../../../store";
import { Status } from "./ReleaseContext";
import { StatusIconFilled } from "./ReleaseSection";

const { Text } = Typography;

export const PerformanceMetrics: React.FC<{
  build: Build;
  status: Status;
  smokeTestProgress: SmokeTestProgress;
}> = ({ build: bcBuild, status, smokeTestProgress }) => {
  const abBuild = useStore((state) => {
    return state.buildState.builds[smokeTestProgress.buildAId];
  });

  const ab = smokeTestProgress?.performance?.ab;
  const bc = smokeTestProgress?.performance?.bc;

  let dataSource: PerformanceMetricsDataSource[] = [];
  dataSource.push({
    key: "memoryUsageAllProcessesMb",
    metric: "All process memory usage",
    abPerformance: { type: "memory", value: ab?.memoryUsageAllProcessesMb },
    bcPerformance: { type: "memory", value: bc?.memoryUsageAllProcessesMb },
    tooltip: "Memory usage by the main, renderer, and helper processes",
  });

  dataSource.push({
    key: "memoryUsageMainProcessMb",
    metric: "Main process memory usage",
    abPerformance: { type: "memory", value: ab?.memoryUsageMainProcessMb },
    bcPerformance: { type: "memory", value: bc?.memoryUsageMainProcessMb },
    tooltip: "Memory usage by the main process",
  });

  if (bc?.memoryUsageRendererProcessMb) {
    dataSource.push({
      key: "memoryUsageRendererProcessMb",
      metric: "Renderer process memory usage",
      abPerformance: {
        type: "memory",
        value: ab?.memoryUsageRendererProcessMb,
      },
      bcPerformance: {
        type: "memory",
        value: bc?.memoryUsageRendererProcessMb,
      },
      tooltip: "Memory usage by the renderer process",
    });
  }

  dataSource.push({
    key: "appReadyMs",
    metric: "App load time",
    abPerformance: { type: "time", value: ab?.appReadyMs },
    bcPerformance: { type: "time", value: bc?.appReadyMs },
    tooltip:
      "The time between the initial creation time and the app is ready event.",
  });

  if (bc?.webContentsDomReadyMs) {
    dataSource.push({
      key: "webContentsDomReadyMs",
      metric: "Web contents load time",
      abPerformance: { type: "time", value: ab?.webContentsDomReadyMs },
      bcPerformance: { type: "time", value: bc?.webContentsDomReadyMs },
      tooltip:
        "The time between the initial creation time and the dom is ready event.",
    });
  }

  dataSource = dataSource.filter(({ abPerformance, bcPerformance }) => {
    return abPerformance.value || bcPerformance.value;
  });

  if (!dataSource.length) return null;

  const columns: ColumnsType<PerformanceMetricsDataSource> = [];

  columns.push({
    title: "Metric",
    dataIndex: "metric",
    key: "metric",
    render: (value: PerformanceMetricsDataSource["metric"], row) => {
      return (
        <span>
          <Text>{value}</Text>
          <QuestionTooltip text={row.tooltip} />
        </span>
      );
    },
  });

  if (ab && abBuild) {
    columns.push({
      title: `v${abBuild.appVersion}`,
      dataIndex: "abPerformance",
      key: "abPerformance",
      align: "right",
      render: (value: PerformanceMetricsDataSource["abPerformance"]) => {
        return <PerformanceDisplay result={value} />;
      },
    });
  }

  if (bc && bcBuild) {
    columns.push({
      title: `v${bcBuild.appVersion}`,
      dataIndex: "bcPerformance",
      key: "bcPerformance",
      align: "right",
      render: (value: PerformanceMetricsDataSource["bcPerformance"]) => {
        return <PerformanceDisplay result={value} />;
      },
    });
  }

  return (
    <Box css={{ flex: 2, flexBasis: "360px" }}>
      <Table
        size="small"
        pagination={false}
        title={() => {
          false;
          return (
            <Flex
              css={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text strong>
                Performance metrics, {smokeTestProgress?.vm?.image}
              </Text>
              <StatusIconFilled status={status} />
            </Flex>
          );
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </Box>
  );
};

type PerformanceMetricsDataSource = {
  key: string;
  metric: string;
  tooltip: string;
  abPerformance: PerformanceResult;
  bcPerformance: PerformanceResult;
};

interface PerformanceResult {
  type: "memory" | "time";
  value: number;
}

const PerformanceDisplay: React.FC<{
  result: PerformanceResult;
}> = ({ result }) => {
  if (result.type === "memory") {
    return <Text>{result.value} MB</Text>;
  }

  return <Text>{(result.value / 1000).toFixed(2)}s</Text>;
};
