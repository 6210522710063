// modules
import React, { useEffect, useState } from "react";
import css from "@emotion/css";
import queryString from "query-string";
import { RouteComponentProps } from "react-router-dom";

// components
import { Divider, Tabs } from "antd";
import { General } from "./General";

// utils
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import { useWindowSize } from "../../../~reusables/hooks/useWindowSize";
import { selectedApp, useStore } from "../../../store";
import { DomainsCard } from "../../../components/molecules/CustomDomain";
import { ArtifactsCard } from "../../../components/molecules/ConfigurableArtifacts";
import { CertPlatform } from "../../../~reusables/types";
import { CertificateDropdown } from "../../../components/molecules/Certificates/SharedCertUtils";
import { EnvironmentConfigCard } from "../../../components/molecules/EnvironmentConfig";
import { StyledAntdCard } from "../../../components/atoms/StyledAntdCard";
import { MacCertificate } from "../../../components/molecules/Certificates/MacCertificate";
import { WindowsCertificate } from "../../../components/molecules/Certificates/WindowsCertificate";
import { Certificate } from "../../../components/molecules/Certificates/Certificate";

export enum SettingsTabs {
  General = "General",
  BuildDeploy = "BuildDeploy",
  Certificates = "Certificates",
}

export const AppSettings: React.FC<RouteComponentProps<
  unknown,
  unknown,
  { tab?: SettingsTabs }
>> = ({ location }) => {
  const user = useStore((state) => state.user);
  const app = useStore(selectedApp);
  const windowSize = useWindowSize();
  const [activeTab, setActiveTab] = useState<SettingsTabs>(
    location.state && location.state.tab
      ? location.state.tab
      : SettingsTabs.General
  );
  const { shadows, radii, breakpoints, space } = useTheme();
  const isTablet = windowSize.width < parseInt(breakpoints[1]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query["uistate"] === "settings-certificates") {
      setActiveTab(SettingsTabs.Certificates);
    }
  }, [location.search]);

  return (
    <div
      css={css`
        & > .ant-tabs .ant-tabs-content-holder {
          border: none;
        }

        & > .ant-tabs > .ant-tabs-nav .ant-tabs-tab {
          border-bottom: ${isTablet ? "none" : "1px #d9d9d9 solid"};
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }
      `}
    >
      <Tabs
        tabPosition={isTablet ? "top" : "left"}
        activeKey={activeTab}
        tabBarStyle={{
          backgroundColor: "white",
          boxShadow: shadows.shallow,
          borderRadius: radii[2],
          marginBottom: isTablet ? space[7] : "auto",
          width: isTablet ? "100%" : "auto",
          marginRight: isTablet ? "auto" : space[4],
          padding: isTablet ? `0 ${space[3]}px` : "auto",
        }}
        size={isTablet ? "middle" : "large"}
        style={{
          width: "100%",
          overflow: "visible",
        }}
        onChange={(key) => setActiveTab(key as SettingsTabs)}
        items={[
          {
            key: SettingsTabs.General,
            label: "General",
            children: <General />,
          },
          {
            key: SettingsTabs.BuildDeploy,
            label: "Build and Deploy",
            children: (
              <>
                <DomainsCard />
                <ArtifactsCard />
                {app.appType === "electron" && <EnvironmentConfigCard />}
              </>
            ),
          },
          {
            key: SettingsTabs.Certificates,
            label: "Certificates",
            children: (
              <>
                <StyledAntdCard
                  title="Mac Certificates"
                  extra={<CertificateDropdown platform={CertPlatform.Mac} />}
                >
                  <MacCertificate />
                  <Divider />
                  <Certificate
                    target="mac-installer"
                    label="Developer ID Installer Certificate"
                  />
                  {user?.featureFlags?.macAppStore && (
                    <>
                      <Divider />
                      <Certificate
                        target="mas-dev"
                        label="Mac App Store Development Certificate"
                      />
                      <Divider />
                      <Certificate
                        target="mas"
                        label="Mac App Store Distribution Certificate"
                      />
                      <Divider />
                      <Certificate
                        target="mas-installer"
                        label="Mac App Store Installer Certificate"
                      />
                    </>
                  )}
                </StyledAntdCard>
                <StyledAntdCard
                  title="Windows Certificate"
                  extra={
                    <CertificateDropdown platform={CertPlatform.Windows} />
                  }
                >
                  <WindowsCertificate />
                </StyledAntdCard>
              </>
            ),
          },
        ]}
      ></Tabs>
    </div>
  );
};
