import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Modal, Popover, Typography } from "antd";
import React, { useState } from "react";
import { Flex } from "../../../components/atoms/Primitives";
import { ReleaseSectionEnum } from "./ReleaseContext";
import { useStore } from "../../../store";
import { setUserData } from "../../../~reusables/firebase";

const { Title, Text } = Typography;

export const ReleaseTutorialModal: React.FC = () => {
  const user = useStore((state) => state.user);
  const [open, onOpenChange] = useState(
    user.seenReleaseTutorial ? false : true
  );

  return (
    <Modal
      open={open}
      destroyOnClose
      onCancel={async () => {
        onOpenChange(false);
        await setUserData(user.id, { seenReleaseTutorial: true });
      }}
      footer={null}
      width="620px"
      style={{ maxWidth: "calc(100vw - 32px)" }}
    >
      <Flex css={{ flexDirection: "column", gap: "48px" }}>
        <Flex css={{ flexDirection: "column", gap: "20px" }}>
          <img
            src="/release-tutorials/release-preview.png"
            style={{ borderRadius: "4px" }}
          />
          <Flex css={{ flexDirection: "column" }}>
            <Title level={4} style={{ marginBottom: 4 }}>
              Welcome to the new release view
            </Title>
            <Text style={{ marginBottom: "8px" }}>
              We've made multiple improvements to how we handle releases at
              ToDesktop.
            </Text>
            <Text>
              The new release process now proactively validates your application
              across five key areas. Once you're happy with the validation
              checks, you can then proceed to release your application with
              confidence.
            </Text>
          </Flex>
        </Flex>
        <BasicValidationTutorial />
        <SmokeTestingTutorial />
        <BuildArtifactsTutorial />
        <StaticAnalysisTutorial />
        <BuildLoggingTutorial />
      </Flex>
    </Modal>
  );
};

export const BasicValidationTutorial: React.FC = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "12px" }}>
      <Flex css={{ flexDirection: "column", gap: "12px" }}>
        <Divider>Basic Validation</Divider>
        <Text>
          As a first step, we validate your application's basic properties. For
          example, ToDesktop will prevent you from releasing an application with
          the same version as your latest release, but will allow you to upgrade
          and downgrade versions if the version numbers are appropriate.
        </Text>
      </Flex>
      <video width="100%" muted autoPlay loop>
        <source
          src="/release-tutorials/basic-validation-tutorial.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export const SmokeTestingTutorial: React.FC = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "12px" }}>
      <Flex css={{ flexDirection: "column", gap: "12px" }}>
        <Divider>Smoke Testing</Divider>
        <Text>
          Smoke tests run your application across windows, mac, and linux. These
          tests confirm that the app launches and that it can successfully
          auto-update across past and future versions. If you are experiencing
          consistent smoke test failures, please contact us at{" "}
          <a href="mailto:hi@todesktop.com">hi@todesktop.com</a>.
        </Text>
      </Flex>
      <video width="100%" muted autoPlay loop>
        <source
          src="/release-tutorials/smoke-testing-tutorial.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export const BuildArtifactsTutorial: React.FC = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "12px" }}>
      <Flex css={{ flexDirection: "column", gap: "12px" }}>
        <Divider>Build Artifacts</Divider>
        <Text>
          We list all of your project's build artifacts you have created and
          display whether they have significantly changed in size. We also list
          your project's dependencies and flag dependencies that have been
          added, updated, or removed.
        </Text>
      </Flex>
      <video width="100%" muted autoPlay loop>
        <source
          src="/release-tutorials/build-artifacts-tutorial.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export const StaticAnalysisTutorial: React.FC = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "12px" }}>
      <Flex css={{ flexDirection: "column", gap: "12px" }}>
        <Divider>Static Analysis</Divider>
        <Text>
          We statically analyze your application and report issues to you that
          require attention. Issues with a "High" severity require action before
          you can proceed with a release. You can resolve these issues by either
          updating the source code and rebuilding your application, or by
          toggling a checkbox to mark these issue as safe to ignore.
        </Text>
      </Flex>
      <video width="100%" muted autoPlay loop>
        <source
          src="/release-tutorials/static-analysis-tutorial.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export const BuildLoggingTutorial: React.FC = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "12px" }}>
      <Flex css={{ flexDirection: "column", gap: "12px" }}>
        <Divider>Build Logging</Divider>
        <Text>
          Build logs gives you visibility into the app building process across
          Windows, Mac, and Linux. We report a warning if we're unable to
          retrieve logs, but this warning can usually be safely ignored.
        </Text>
      </Flex>
      <video width="100%" muted autoPlay loop>
        <source
          src="/release-tutorials/build-logging-tutorial.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export const ReleaseSectionPopover: React.FC<{
  section: ReleaseSectionEnum;
}> = ({ section }) => {
  const tutorialMap: Record<ReleaseSectionEnum, React.ReactNode> = {
    basicValidation: <BasicValidationTutorial />,
    smokeTesting: <SmokeTestingTutorial />,
    buildArtifacts: <BuildArtifactsTutorial />,
    staticAnalysis: <StaticAnalysisTutorial />,
    buildLogging: <BuildLoggingTutorial />,
  };

  return (
    <Popover
      content={tutorialMap[section]}
      overlayStyle={{ maxWidth: "440px" }}
    >
      <QuestionCircleOutlined />
    </Popover>
  );
};
