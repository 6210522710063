// modules
import React, { useState } from "react";
import css from "@emotion/css";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";

// components
import { Modal, Button, Input } from "antd";
import { CopyOutlined } from "@ant-design/icons";

// logic
import { updateUIState, generateAccessToken } from "../../~reusables/actions";
import { copyToClipboard } from "../../~reusables/util";
import { useStore } from "../../store";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { Space } from "../atoms/Space";
import { hrefLinks } from "../../~reusables/constants";

const ManageAccessToken: React.FC = () => {
  const { colors, space } = useTheme();
  const user = useStore((state) => state.user);
  const [isModalVisibile, setModalVisibility] = useState(true);

  return (
    <StyledModal
      visible={isModalVisibile}
      closable={true}
      centered={true}
      onCancel={() => setModalVisibility(false)}
      afterClose={() => updateUIState(undefined)}
      footer={
        <Button onClick={() => generateAccessToken()} type="primary">
          Generate new access token
        </Button>
      }
      css={css`
        overflow: hidden;
      `}
    >
      <Space direction="column">
        <Heading variant="h3" as="h3">
          Manage your Access Token
        </Heading>
        <Text color="support">
          Access tokens are used to interact with the{" "}
          <a
            href={hrefLinks.cliNpmDocs}
            target="_blank"
            rel="noopener noreferrer"
          >
            ToDesktop CLI
          </a>
          .
        </Text>
      </Space>
      <div
        css={css`
          margin: ${space[8]}px ${space[6]}px ${space[7]}px ${space[6]}px;
        `}
      >
        <Input.Password
          placeholder="access token"
          addonAfter={
            <CopyOutlined
              onClick={copyToClipboard("access token", user.accessToken || "")}
            />
          }
          value={user.accessToken || ""}
        />
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  h3,
  p {
    text-align: center;
  }
`;

export default ManageAccessToken;
