// modules
import React, { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";

// components
import NoCodeApp from "../../components/molecules/NoCodeApp";

// logic
import { useStore, selectedApp } from "../../store";
import { DummyAppLoader } from "../../components/elements/DummyAppLoader";
import { DesktopBuilderApp } from "../../components/molecules/DesktopBuilderApp";

const CLIApp = React.lazy(() => import("../../components/molecules/CLIApp"));

const AppOverview: React.FC<RouteComponentProps> = () => {
  const app = useStore(selectedApp);

  if (!app) return null;

  if (app?.schemaVersion >= 2) {
    return <DesktopBuilderApp />;
  }

  return app.appType === "electron" ? (
    <Suspense fallback={<DummyAppLoader loading={true} />}>
      <CLIApp />
    </Suspense>
  ) : (
    <NoCodeApp />
  );
};

export default AppOverview;
