// modules
import React, { Suspense, useContext, useEffect } from "react";
import css from "@emotion/css";
import queryString from "query-string";
import { withRouter, RouteComponentProps } from "react-router-dom";

// components
import { Row, Col, Button } from "antd";
import Card from "../atoms/Card";
import AppOptions from "../edit/AppOptions";
import OSPreviewSwitcher from "../edit/OSPreviewSwitcher";
import AppCogDropdownButton from "../edit/AppCogDropdownButton";
import AppTitle from "../edit/AppTitle";
import IconPicker from "../edit/AppIconPicker";
import WindowDimensionsInput from "../edit/WindowDimensions";
import PreviewAppIcon from "../edit/PreviewAppIcon";
import WindowOptions from "../edit/WindowOptions";
import { StyledAntdCard } from "../atoms/StyledAntdCard";
import MenubarIconPicker from "../edit/MenubarIconPicker";
import TrayIconPicker from "../edit/TrayIconPicker";
import NewApp from "../../pages/NewApp";

// logic
import { useStore, selectedApp, appIsBuilding } from "../../store";
import { AppFieldsContext, usePrevious } from "../../~reusables/util";
import { beginBuildProcess, updateUIState } from "../../~reusables/actions";
import BuildApp from "../build/BuildApp";
import { DummyAppLoader } from "../elements/DummyAppLoader";
import { DomainsCard } from "./CustomDomain";
import { ArtifactsCard } from "./ConfigurableArtifacts";

const ScaledApp = React.lazy(() => import("../edit/ScaledApp"));

const BuildWebAppButton: React.FC = () => {
  const ctx = useContext(AppFieldsContext);
  const user = useStore((state) => state.user);
  const app = useStore(selectedApp);
  const hasBuiltApp =
    app &&
    app.meta &&
    app.meta.publishedVersions &&
    app.meta.publishedVersions.version;

  return (
    <Button
      css={css`
        margin-left: 12px;
        width: 140px;
      `}
      size="large"
      type="primary"
      onClick={async () =>
        await beginBuildProcess(ctx, user, {
          source: "web app",
        })
      }
    >
      {hasBuiltApp ? "Rebuild app" : "Build app"}
    </Button>
  );
};

interface IFromNoCodeApp extends RouteComponentProps {
  builderViewQuery?: queryString.ParsedQuery<string>;
}

const FromNoCodeApp: React.FC<IFromNoCodeApp> = ({
  builderViewQuery,
  ...routeProps
}) => {
  // enter secret modal
  const app = useStore(selectedApp);
  const { uiState, apps } = useStore((state) => ({
    uiState: state.uiState,
    apps: state.apps,
  }));

  const previousAppId = usePrevious(app ? app.id : "");
  const isAppBuilding = useStore(appIsBuilding);

  useEffect(() => {
    if (!app?.meta?.currentBuildProgress) {
      updateUIState(null);
    } else if (isAppBuilding) {
      updateUIState("build");
    }
  }, [isAppBuilding, app, previousAppId, window.location.pathname]);

  useEffect(() => {
    return () => {
      updateUIState(null);
    };
  }, []);

  if (!app && builderViewQuery) {
    return (
      <>
        <DummyAppLoader loading={true} />
        <NewApp apps={apps} {...routeProps} />
      </>
    );
  }

  return (
    <>
      {uiState === "build" ? <BuildApp app={app} /> : null}
      <Row gutter={24}>
        <Col lg={12} span={24}>
          <StyledAntdCard
            title="Application"
            extra={
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <AppCogDropdownButton noTopMargin={true} />
                <BuildWebAppButton />
              </div>
            }
          >
            <AppTitle />
            <IconPicker />
            {app && app.appType === "menubar" && (
              <Row gutter={24}>
                <Col lg={12} span={24}>
                  <MenubarIconPicker />
                </Col>
                <Col lg={12} span={24}>
                  <TrayIconPicker />
                </Col>
              </Row>
            )}
          </StyledAntdCard>
        </Col>

        <Col lg={12} span={24}>
          <PreviewAppIcon />
          <div
            css={css`
              margin-top: -24px;
            `}
          >
            <OSPreviewSwitcher />
          </div>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col lg={8} span={24}>
          <StyledAntdCard title="App Options">
            <AppOptions showTitle={false} />
          </StyledAntdCard>
          <StyledAntdCard title="Window Options">
            <WindowOptions showTitle={false} />
          </StyledAntdCard>
        </Col>
        <Col lg={16} span={24}>
          <StyledAntdCard title="Window Dimensions">
            <WindowDimensionsInput showTitle={false} />
          </StyledAntdCard>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <OSPreviewSwitcher hideHelpArrow={true} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Card noPadding={true}>
            <Suspense fallback={null}>
              <ScaledApp />
            </Suspense>
          </Card>
        </Col>
      </Row>
      {builderViewQuery && (
        <Row gutter={24}>
          <Col lg={12} span={24}>
            <ArtifactsCard />
          </Col>
          <Col lg={12} span={24}>
            <DomainsCard />
          </Col>
        </Row>
      )}
    </>
  );
};

export default withRouter(FromNoCodeApp);
