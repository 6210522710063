import css from "@emotion/css";
import { Modal, Row, Skeleton, Button, Popconfirm, Popover } from "antd";
import { updateUIState } from "../../~reusables/actions";
import { callFirebaseFunction } from "../../~reusables/firebase/functions";
import { signOut } from "../../~reusables/firebase/auth";
import React, { useState } from "react";
import { useStore } from "../../store";
import { useRetrieveCustomer } from "../../~reusables/hooks/stripeHooks";
import { goToManageSubscriptions } from "../../~reusables/actions";
import { Heading } from "../atoms/Heading";
import { Box } from "../atoms/Primitives";

const afterCloseModal = () => updateUIState(null);

const ManageSubscription: React.SFC = () => {
  const stripeCustomerId = useStore((state) => state.user.stripeCustomerId);
  const apps = useStore((state) => state.apps);
  const [modalVisibile, setModalVisibility] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const { customer, isLoading } = useRetrieveCustomer(stripeCustomerId);

  const customerSubscriptions =
    customer && customer.subscriptions ? customer.subscriptions.data : [];

  const subscriptions = customerSubscriptions.map((sub) => {
    return {
      ...sub,
      app: apps.find((app) => app.id === sub.metadata.appId),
    };
  });

  const closeModal = () => setModalVisibility(false);

  const showManageSubscriptionModal = () => {
    closeModal();
    goToManageSubscriptions();
  };

  const deleteAccount = () => {
    setIsDeleting(true);
    callFirebaseFunction("deleteAccount")({ stripeCustomerId })
      .then(async (res) => {
        setIsDeleting(false);
        if ("success" in res.data && res.data.success) {
          updateUIState(null);
          await signOut();
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        Modal.error({
          title: "Error deleting account",
          content: err.message,
        });
      });
  };

  return (
    <>
      <Modal
        visible={modalVisibile}
        footer={null}
        closable={true}
        centered={true}
        onCancel={closeModal}
        afterClose={afterCloseModal}
      >
        <Box>
          <Heading variant="h3" as="h3" textAlign="center" mb={4}>
            Manage your Account
          </Heading>
          <Row
            gutter={16}
            justify="space-around"
            align="middle"
            css={css`
              margin-top: 15px;
              border-bottom: 2px #eee solid;
              &:last-child {
                border-bottom: none;
              }
            `}
          >
            <Skeleton
              css={css`
                margin-top: 16px;
              `}
              active={true}
              loading={isLoading}
              avatar={true}
            />
            {!isLoading ? (
              subscriptions.length === 0 ? (
                <Popconfirm
                  title="Are you sure you want to delete your account"
                  onConfirm={deleteAccount}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    loading={isDeleting}
                    type="primary"
                    css={css`
                      margin: 0 auto;
                    `}
                  >
                    Delete Account
                  </Button>
                </Popconfirm>
              ) : (
                <Popover
                  content={() => (
                    <span>
                      Please{" "}
                      <a onClick={showManageSubscriptionModal}>
                        cancel your subscriptions
                      </a>{" "}
                      first before deleting
                    </span>
                  )}
                  trigger="click"
                >
                  <Button
                    danger
                    type="primary"
                    css={css`
                      margin: 0 auto;
                    `}
                  >
                    Delete Account
                  </Button>
                </Popover>
              )
            ) : null}
          </Row>
        </Box>
      </Modal>
    </>
  );
};

export default ManageSubscription;
