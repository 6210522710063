import { Space, Tooltip } from "antd";
import React from "react";
import useForm from "../../../~reusables/hooks/useForm";
import { selectedApp, useStore } from "../../../store";
import { updateFirestoreApp } from "../../../~reusables/actions";
import { FormInput } from "../../atoms/FormUtils";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  appxDisplayNameValidation,
  appxIdentityNameValidation,
  appxPublisherDisplayNameValidation,
  appxPublisherValidation,
} from "../../../~reusables/util/validationRules";

export const AppxConfig: React.FC = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <IdentityName />
      <DisplayName />
      <Publisher />
      <PublisherDisplayName />
    </Space>
  );
};
export const IdentityName: React.FC = () => {
  const { appxConfig, name } = useStore(selectedApp);

  const callback = async ({ identityName }: { identityName: string }) => {
    appxIdentityNameValidation.validate({ identityName });
    updateFirestoreApp({ appxConfig: { identityName } });
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    { identityName: appxConfig?.identityName || "" },
    callback,
    appxIdentityNameValidation
  );

  return (
    <FormInput
      label="Identity Name"
      error={errors.identityName}
      value={values.identityName}
      name="identityName"
      onChange={handleChange}
      onBlur={handleSubmit}
      placeholder={name.replace(/^[0-9]+|[^a-z0-9]/gi, "")}
      suffix={
        <Tooltip title="Describes the contents of the package. Must be between 3 and 50 characters in length, consisting of alpha-numeric, period, and dash characters.">
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
        </Tooltip>
      }
    />
  );
};

export const DisplayName: React.FC = () => {
  const { appxConfig, name } = useStore(selectedApp);

  const callback = async ({ displayName }: { displayName: string }) => {
    updateFirestoreApp({ appxConfig: { displayName } });
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    { displayName: appxConfig?.displayName || "" },
    callback,
    appxDisplayNameValidation
  );

  return (
    <FormInput
      label="Display Name"
      error={errors.displayName}
      name="displayName"
      value={values.displayName}
      onChange={handleChange}
      onBlur={handleSubmit}
      placeholder={name}
      suffix={
        <Tooltip title="A friendly name that can be displayed to users. Must be reserved on Microsoft App Center.">
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
        </Tooltip>
      }
    />
  );
};

export const Publisher: React.FC = () => {
  const { appxConfig } = useStore(selectedApp);

  const callback = async ({ publisher }: { publisher: string }) => {
    updateFirestoreApp({ appxConfig: { publisher } });
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    { publisher: appxConfig?.publisher || "" },
    callback,
    appxPublisherValidation
  );

  return (
    <FormInput
      label="Publisher"
      error={errors.publisher}
      value={values.publisher}
      name="publisher"
      onChange={handleChange}
      onBlur={handleSubmit}
      placeholder="CN=ms"
      suffix={
        <Tooltip title="Describes the publisher information. Leave blank if meant for Windows Store distribution. Otherwise the Publisher attribute must match the publisher subject information of the certificate used to sign the package. Must be between 1 and 8192 characters that obeys a particular naming format.">
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
        </Tooltip>
      }
    />
  );
};

export const PublisherDisplayName: React.FC = () => {
  const { appxConfig, name } = useStore(selectedApp);

  const callback = async ({
    publisherDisplayName,
  }: {
    publisherDisplayName: string;
  }) => {
    updateFirestoreApp({ appxConfig: { publisherDisplayName } });
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    { publisherDisplayName: appxConfig?.publisherDisplayName || "" },
    callback,
    appxPublisherDisplayNameValidation
  );

  return (
    <FormInput
      label="Publisher Display Name"
      error={errors.publisherDisplayName}
      name="publisherDisplayName"
      placeholder={name}
      value={values.publisherDisplayName}
      onChange={handleChange}
      onBlur={handleSubmit}
      suffix={
        <Tooltip title="A friendly name for the publisher that can be displayed to users. ">
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
        </Tooltip>
      }
    />
  );
};
