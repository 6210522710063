import { selectedAppUser, useStore } from "../../store";

export const useAppOwnerGuard = (): {
  isOwner: boolean;
  ownerGuard?: string;
} => {
  const appUser = useStore(selectedAppUser);

  if (appUser.isOwner) {
    return { isOwner: true };
  }

  return {
    isOwner: false,
    ownerGuard: `This action can only be completed by the app's owner, ${appUser.label}`,
  };
};
