import React from "react";
import useForm from "../../~reusables/hooks/useForm";
import { upgradeAccountValidation } from "../../~reusables/util/validationRules";
import { Row, Col, Form } from "antd";
import css from "@emotion/css";
import LoginButton from "./LoginButton";
import { FormInput } from "../atoms/FormUtils";
import { IUser } from "@todesktop/shared";

interface IPasswordAccountFormProps {
  user?: IUser;
  callback: (values: IPasswordAccountForm) => void | Promise<any>;
  autoFocusPassword?: boolean;
  buttonText?: string;
}

const defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

export type IPasswordAccountForm = typeof defaultFormData;

const PasswordAccountForm: React.SFC<IPasswordAccountFormProps> = ({
  user,
  callback,
  autoFocusPassword,
  buttonText,
}) => {
  const formData: IPasswordAccountForm = {
    ...defaultFormData,
    ...user,
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    formError,
  } = useForm(formData, callback, upgradeAccountValidation);

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Row
        gutter={16}
        css={css`
          margin: 16px 0;
        `}
      >
        <Col span={12}>
          <FormInput
            error={errors.firstName}
            label="First Name"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            disabled={isSubmitting}
            autoComplete="given-name"
            autoFocus={!autoFocusPassword}
          />
        </Col>
        <Col span={12}>
          <FormInput
            error={errors.lastName}
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            disabled={isSubmitting}
            autoComplete="family-name"
          />
        </Col>
      </Row>

      <Row
        gutter={16}
        css={css`
          margin: 16px 0;
        `}
      >
        <Col span={24}>
          <FormInput
            error={errors.email}
            label="Email"
            name="email"
            onChange={handleChange}
            value={values.email}
            disabled={isSubmitting}
            autoComplete="email"
          />
        </Col>
      </Row>

      <Row
        gutter={16}
        css={css`
          margin: 16px 0;
        `}
      >
        <Col span={12}>
          <FormInput
            error={errors.password}
            label="Password"
            name="password"
            onChange={handleChange}
            value={values.password}
            autoFocus={!!autoFocusPassword}
            disabled={isSubmitting}
            autoComplete="new-password"
            password
          />
        </Col>
        <Col span={12}>
          <FormInput
            error={errors.passwordConfirmation}
            label="Confirm Password"
            name="passwordConfirmation"
            onChange={handleChange}
            value={values.passwordConfirmation}
            disabled={isSubmitting}
            autoComplete="new-password"
            password
          />
        </Col>
      </Row>

      {formError && (
        <div
          css={css`
            color: white;
            background: hsl(357, 91%, 45%);
            border: 1px hsl(357, 91%, 40%) solid;
            border-radius: 3px;
            padding: 10px;
            text-align: center;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          `}
        >
          {formError}
        </div>
      )}

      <LoginButton
        kind="password"
        css={css`
          margin-top: 32px;
        `}
        type="submit"
        loading={isSubmitting}
      >
        {buttonText || "Submit"}
      </LoginButton>
    </Form>
  );
};

export default PasswordAccountForm;
