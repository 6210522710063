// modules
import React, { createContext, useState, useEffect, useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useStore } from "../../store";
import { selectApp } from "../actions";
import { usePrevious } from "../util";
import { setBuildsDataAsLoaded } from "../actions/builds";

// available routes
export const defaultPathVariables = ["/", "/settings", "/super-admin"];
export const appPathVariables = [
  "/apps/:id",
  "/apps/:id/builds",
  "/apps/:id/builds/:buildId",
  "/apps/:id/crash-reports",
  "/apps/:id/analytics",
  "/apps/:id/settings",
];

interface INavItem {
  title: string;
  to: string;
  exact: boolean;
}

interface INavState {
  teamLevelNavItems: INavItem[];
  appLevelNav: {
    showLoader: boolean;
    items: INavItem[];
  };
}

const initialNavState: INavState = {
  teamLevelNavItems: [
    { title: "Apps", to: "/", exact: true },
    // { title: 'Settings', to: '/settings' }
  ],
  appLevelNav: {
    showLoader: true,
    items: [],
  },
};

const NavigationContext = createContext<{
  navState: INavState;
  setNavState: React.Dispatch<React.SetStateAction<INavState>>;
}>({
  navState: initialNavState,
  setNavState: () => {},
});

export const useNavigation = () => useContext(NavigationContext);

const NavigationContextProvider: React.FC<RouteComponentProps> = ({
  children,
  location,
}) => {
  const [navState, setNavState] = useState(initialNavState);
  const { selectedApp, apps, userDataLoaded } = useStore((state) => ({
    selectedApp: state.selectedApp,
    apps: state.apps,
    userDataLoaded: state.userDataLoaded,
  }));

  const previousAppId = usePrevious(selectedApp);

  useEffect(() => {
    const arr =
      location.pathname.match("/apps/([^/]*)/") ||
      location.pathname.match("/apps/([^/]*)");

    if (arr !== null && arr[1]) {
      const app = apps.find((app) => app.id === arr[1]);
      if (app) {
        if (app.id !== previousAppId) {
          selectApp(app);
        }
        return setNavState({
          ...navState,
          appLevelNav: {
            showLoader: false,
            items:
              app.appType === "electron"
                ? [
                    { title: "Overview", to: `/apps/${arr[1]}`, exact: true },
                    {
                      title: "Builds",
                      to: `/apps/${arr[1]}/builds`,
                      exact: false,
                    },
                    {
                      title: "Analytics",
                      to: `/apps/${arr[1]}/analytics`,
                      exact: false,
                    },
                    {
                      title: "Settings",
                      to: `/apps/${arr[1]}/settings`,
                      exact: false,
                    },
                  ]
                : [
                    { title: "Overview", to: `/apps/${arr[1]}`, exact: true },
                    {
                      title: "Analytics",
                      to: `/apps/${arr[1]}/analytics`,
                      exact: false,
                    },
                    {
                      title: "Settings",
                      to: `/apps/${arr[1]}/settings`,
                      exact: false,
                    },
                  ],
          },
        });
      }

      setBuildsDataAsLoaded();
      return setNavState({
        ...navState,
        appLevelNav: {
          showLoader: false,
          items: [],
        },
      });
    }

    setNavState({
      ...navState,
      appLevelNav: {
        showLoader: true,
        items: [],
      },
    });
  }, [location.pathname, userDataLoaded, apps]);

  return (
    <NavigationContext.Provider value={{ navState, setNavState }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default withRouter(NavigationContextProvider);
