import React from "react";
import { useStore, selectedApp, update, store } from "../../store";
import Field from "../atoms/InputField";
import {
  updateAppLiveField,
  updateFirestoreApp,
} from "../../~reusables/actions";
import { appTitleValidation } from "@todesktop/shared";
import { track } from "../../~reusables/util/analytics";

const updateAppTitle = (value: string) => {
  updateFirestoreApp({
    name: value,
  });
  track({ event: "Update App Title" });
};

const updateAppTitleLive = updateAppLiveField("appTitle");

const AppTitle: React.SFC = () => {
  const app = useStore(selectedApp);

  return app ? (
    <Field
      validationSchema={appTitleValidation}
      onChange={updateAppTitle}
      onImmediateChange={updateAppTitleLive}
      label="App Title"
      dontValidateOnChangeUnlessErrored={true}
      value={app.name}
      size="large"
      hiddenHelpText={
        <>
          This is the app title as it will appear in the dock or start menu.
          <br /> Short app titles are best.
        </>
      }
    />
  ) : null;
};

export default AppTitle;
