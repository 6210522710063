import React from "react";
import { useStore, selectedApp, getLiveField } from "../../store";
import Card from "../atoms/Card";
import Dock from "./Dock";
import { useDebounce } from "use-debounce";

const PreviewAppIcon: React.SFC = () => {
  const previewScreenshotOS = useStore((state) => state.previewScreenshotOS);
  const app = useStore(selectedApp);
  const appTitleLive = useStore(getLiveField("appTitle"));
  const [appTitle] = useDebounce(appTitleLive, 300);

  return app ? (
    <Card noPadding={true}>
      <Dock
        title={appTitle || app.name}
        iconUrl={app.icon}
        os={previewScreenshotOS}
        appType={app.appType}
      />
    </Card>
  ) : null;
};

export default PreviewAppIcon;
