import React from "react";
import css from "@emotion/css";
import { Modal, Form } from "antd";
import AppIcon from "../atoms/AppIcon";
import { removeUIState } from "../../~reusables/actions";
import styled from "@emotion/styled-base";
import { useStore } from "../../store";
import {
  updateOrCreateUserData,
  updatePassword,
  updateProfile,
  updateEmail,
  reauthenticate,
} from "../../~reusables/firebase";
import PasswordAccountForm from "./PasswordAccountForm";
import { useState } from "react";
import { IApp } from "@todesktop/shared";

interface IConvertAccountProps {
  app: IApp;
}

const Label = styled("label")`
  && {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    line-height: 24px;
  }
`;

interface IFormItemProps {
  error?: string;
  label: string;
}
const FormItem: React.SFC<IFormItemProps> = ({ children, error, label }) => (
  <Form.Item
    label={<Label>{label}</Label>}
    help={error}
    validateStatus={error ? "error" : undefined}
    hasFeedback={true}
    colon={false}
    css={css`
      && {
        margin-bottom: 0;
        .ant-form-item-label {
          line-height: 24px;
        }
      }
    `}
  >
    {children}
  </Form.Item>
);

interface IUpgradeAccountForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}
const ResetPasswordUpgradedAccount: React.FC<IConvertAccountProps> = ({
  app,
}) => {
  const user = useStore((state) => state.user);
  const [modalVisibile, setModalVisibility] = useState(true);

  const updateAccount = async (values) => {
    await reauthenticate(app.secret);
    await updateEmail(values.email);
    await reauthenticate(app.secret);
    await updatePassword(values.password);
    // TODO: Populate profile by trigger (not manually)
    await updateProfile({
      displayName: `${values.firstName} ${values.lastName}`,
    });
    await updateOrCreateUserData(
      user.id,
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        isOldAccountThatNeedsNewPassword: false,
      },
      {
        isNewUser: false,
      }
    );
    setModalVisibility(false);
  };
  return (
    <Modal
      visible={user.isOldAccountThatNeedsNewPassword || modalVisibile}
      footer={null}
      closable={false}
      centered={true}
      afterClose={removeUIState}
    >
      <div
        css={css`
          margin: 0 auto;
        `}
      >
        {app?.icon && <AppIcon iconUrl={app.icon} />}
        <h2
          css={css`
            font-size: 32px;
            text-align: center;
            font-weight: normal;
          `}
        >
          Upgrade Account Security
        </h2>
        <p>
          We have upgraded your account to use <b>password authentication</b>{" "}
          instead of an ID and Secret. This will make your account{" "}
          <b>more secure</b> and enable you to <b>manage multiple apps</b>.
        </p>
        <p>
          To complete this process, please confirm your details and set your new
          password below.
        </p>
        <PasswordAccountForm
          buttonText="Update Account"
          callback={updateAccount}
          user={user}
          autoFocusPassword={true}
        />
      </div>
    </Modal>
  );
};

export default ResetPasswordUpgradedAccount;
