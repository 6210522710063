import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { cache } from "emotion";
import { CacheProvider } from "@emotion/core";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://f4a9b899abae4b56bfbe452b937d43bf@sentry.io/1438425",
  });
}

JavascriptTimeAgo.locale(en);

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <CacheProvider value={cache}>
      <App />
    </CacheProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
