import semver from "semver";
import { Build } from "@todesktop/shared";

export const RUNTIME_VERSION_SUPPORTING_SMOKE_TESTS = "1.2.1";

export const isBuildSupportsSmokeTests = (build: Build) => {
  const cleanRuntimeVersion = build.todesktopRuntimeVersionSpecified
    // converts "^1.5.7-beta.2" to "^1.5.7" (ignore everything after the '-')
    ?.split("-")[0]
    ?.replace(
      // converts "^1.5.7" to "1.5.7" (removes anything that's not a number or a dot, such as "^")
      /[^0-9.]/g,
      ""
    );

  try {
    const buildSupportsSmokeTests = build.todesktopRuntimeVersionSpecified
      ? semver.gte(cleanRuntimeVersion, RUNTIME_VERSION_SUPPORTING_SMOKE_TESTS)
      : false;

    return buildSupportsSmokeTests;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const isSmokeTestRunning = (build: Build) => {
  return ["mac", "windows", "linux"].some(
    (platform) =>
      build.smokeTest &&
      build.smokeTest[platform]?.state === "progress" &&
      !build.smokeTest.isCanceled
  );
};

export function smokeTestHasError(build: Build) {
  return ["mac", "windows", "linux"].some(
    (platform) =>
      build.smokeTest &&
      build.smokeTest[platform]?.state === "error" &&
      !build.smokeTest.isCanceled
  );
}
