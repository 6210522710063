import { Alert, Col, Row, Space, Typography } from "antd";
import React from "react";
import {
  Anchor as AnchorLink,
  Box,
  Flex,
} from "../../../components/atoms/Primitives";
import { CopyableText } from "../../../components/atoms/TextUtils";
import { useStore } from "../../../store";
import { selectedReleasedBuild } from "../../../~reusables/actions/builds";
import { useDeepLayoutEffect } from "../../../~reusables/hooks/useDeep";
import { parseBuildCommit } from "../../../~reusables/util/build";
import {
  EligibilityState,
  determineReleaseEligibility,
} from "../../../~reusables/util/determineReleaseEligibility";
import {
  BasicValidationChecks,
  Status,
  useReleaseContext,
} from "./ReleaseContext";
import {
  ReleaseSection,
  ReleaseSectionProps,
  StatusCheckIcon,
} from "./ReleaseSection";
import { useBasicValidationContent } from "./useContent";

const { Title, Text } = Typography;

export const BasicValidationSection: React.FC<ReleaseSectionProps> = (
  props
) => {
  const {
    props: { app, build },
    state: { basicValidation },
    setAnalysisState,
  } = useReleaseContext();

  const releasedBuild = useStore(selectedReleasedBuild);
  const {
    eligibility,
    isDowngrade,
    isLatestRelease,
  } = determineReleaseEligibility(build, releasedBuild);
  const { href, displayText, copyText } = parseBuildCommit(build);
  const { appName, appVersion, electronVersionUsed, icon, startedAt } = build;

  useDeepLayoutEffect(() => {
    const determineStatus = (
      state: EligibilityState,
      successStatus: Status = "success"
    ): Status => {
      if (state === EligibilityState.loading) return "progress";
      return state === EligibilityState.eligible ? successStatus : "error";
    };

    const {
      versionState,
      buildState,
      codeSignState,
      releaseState,
    } = eligibility;
    const buildStatus = determineStatus(buildState);
    const codeSigningStatus = determineStatus(codeSignState);
    const versionStatus = determineStatus(
      isLatestRelease ? EligibilityState.eligible : versionState,
      isDowngrade ? "warning" : "success"
    );
    const releasedStatus = determineStatus(releaseState);

    setAnalysisState("basicValidation", {
      states: [buildStatus, codeSigningStatus, versionStatus, releasedStatus],
      checks: {
        "successful-build": buildStatus,
        "code-signing": codeSigningStatus,
        "release-versions": versionStatus,
        "released-status": releasedStatus,
      },
    });
  }, [appVersion, eligibility, isLatestRelease]);

  const { content } = useBasicValidationContent();
  const validationKeys: BasicValidationChecks[] = [
    "successful-build",
    "code-signing",
    "release-versions",
  ];

  return (
    <ReleaseSection {...props}>
      <Flex css={{ alignItems: "center", gap: "2px" }}>
        <img src={icon ? icon : app.icon} width={32} />
        <Title level={5} style={{ margin: 0 }}>
          {appName}
        </Title>
      </Flex>

      <Row gutter={[8, 12]}>
        {[
          {
            label: "App version",
            component: <Text>v{appVersion}</Text>,
          },
          {
            label: "Electron version",
            component: <Text>v{electronVersionUsed}</Text>,
          },
          {
            label: "Commit hash",
            component: (
              <Text>
                {href ? (
                  <CopyableText
                    displayText={
                      <AnchorLink href={href} target="_blank" rel="noreferrer">
                        {displayText}
                      </AnchorLink>
                    }
                    copyText={copyText}
                  />
                ) : (
                  <CopyableText displayText={displayText} copyText={copyText} />
                )}
              </Text>
            ),
          },
          {
            label: "Creation Date",
            component: <Text>{new Date(startedAt).toLocaleString()}</Text>,
          },
        ].map(({ label, component }) => {
          return (
            <Col key={label} xs={12} md={6}>
              <Space direction="vertical">
                <Text type="secondary">{label}</Text>
                <Box>{component}</Box>
              </Space>
            </Col>
          );
        })}
      </Row>
      <Alert
        type={
          basicValidation.status === "progress"
            ? "info"
            : basicValidation.status
        }
        message={
          <Flex css={{ flexDirection: "column", gap: "4px" }}>
            {validationKeys.map((key) => {
              const status = basicValidation.checks[key];
              return (
                <Flex key={key} css={{ alignItems: "center", gap: "8px" }}>
                  <StatusCheckIcon status={status} />
                  <Text style={{ margin: 0 }}>{content[key][status]}</Text>
                </Flex>
              );
            })}
          </Flex>
        }
      />
    </ReleaseSection>
  );
};
