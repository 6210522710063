// libs
import { variant } from "styled-system";

// components
import { Typography, ITypography } from "./Primitives";

// utils
import styled, { Theme } from "../../~reusables/contexts/ThemeContext";

type HeadingVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface IHeading extends ITypography {
  as: HeadingVariants;
  variant?: HeadingVariants;
  cursor?: string;
  color?: string;
}

const getSharedStyles = (t: Theme, p: IHeading) => ({
  color: p.color || t.colors.title,
  marginBottom: p.mb || 0,
});

const headingVariants = (
  t: Theme,
  p: IHeading
): { [name in HeadingVariants]: object } => ({
  h1: {
    fontSize: [t.fontSizes[6], t.fontSizes[7]],
    fontWeight: t.fontWeights[5],
    lineHeight: t.lineHeights.solid,
    ...getSharedStyles(t, p),
  },
  h2: {
    fontSize: [t.fontSizes[5], t.fontSizes[6]],
    fontWeight: t.fontWeights[5],
    lineHeight: t.lineHeights.solid,
    ...getSharedStyles(t, p),
  },
  h3: {
    fontSize: [t.fontSizes[4], t.fontSizes[5]],
    fontWeight: t.fontWeights[5],
    lineHeight: t.lineHeights.title,
    ...getSharedStyles(t, p),
  },
  h4: {
    fontSize: [t.fontSizes[3], t.fontSizes[4]],
    fontWeight: t.fontWeights[4],
    lineHeight: t.lineHeights.title,
    ...getSharedStyles(t, p),
  },
  h5: {
    ...getSharedStyles(t, p),
    fontSize: [t.fontSizes[2], t.fontSizes[3]],
    fontWeight: t.fontWeights[4],
    lineHeight: t.lineHeights.copy,
  },
  h6: {
    fontSize: [t.fontSizes[1], t.fontSizes[2]],
    fontWeight: t.fontWeights[4],
    lineHeight: t.lineHeights.copy,
    ...getSharedStyles(t, p),
  },
});

export const Heading = styled(Typography)<IHeading>`
  cursor: ${(props) => props.cursor || "auto"};

  ${({ theme, ...props }) =>
    variant({
      variants: headingVariants(theme, props),
    })}
`;

Heading.defaultProps = {
  variant: "h1",
};
