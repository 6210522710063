// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function toBinaryString(v, includeLength) {
  var length = v.length;
  var n = length << 2;
  if (includeLength) {
    var m = v[length - 1];
    n -= 4;
    if (m < n - 3 || m > n) {
      return null;
    }
    n = m;
  }
  for (var i = 0; i < length; i++) {
    v[i] = String.fromCharCode(
      v[i] & 0xff,
      (v[i] >>> 8) & 0xff,
      (v[i] >>> 16) & 0xff,
      (v[i] >>> 24) & 0xff
    );
  }
  var result = v.join("");
  if (includeLength) {
    return result.substring(0, n);
  }
  return result;
}

function toUint32Array(bs, includeLength) {
  if (!bs) {
    return;
  }
  var length = bs.length;
  var n = length >> 2;
  if ((length & 3) !== 0) {
    ++n;
  }
  var v;
  if (includeLength) {
    v = new Array(n + 1);
    v[n] = length;
  } else {
    v = new Array(n);
  }
  for (var i = 0; i < length; ++i) {
    v[i >> 2] |= bs.charCodeAt(i) << ((i & 3) << 3);
  }
  return v;
}

function int32(i) {
  return i & 0xffffffff;
}

function mx(sum, y, z, p, e, k) {
  return (
    (((z >>> 5) ^ (y << 2)) + ((y >>> 3) ^ (z << 4))) ^
    ((sum ^ y) + (k[(p & 3) ^ e] ^ z))
  );
}

function fixk(k) {
  if (!k) {
    return 0;
  }
  if (k.length < 4) k.length = 4;
  return k;
}

function encryptUint32Array(v, k) {
  const DELTA = 0x9e3779b9;
  var length = v.length;
  var n = length - 1;
  var y, z, sum, e, p, q;
  z = v[n];
  sum = 0;
  for (q = Math.floor(6 + 52 / length) | 0; q > 0; --q) {
    sum = int32(sum + DELTA);
    e = (sum >>> 2) & 3;
    for (p = 0; p < n; ++p) {
      y = v[p + 1];
      z = v[p] = int32(v[p] + mx(sum, y, z, p, e, k));
    }
    y = v[0];
    z = v[n] = int32(v[n] + mx(sum, y, z, n, e, k));
  }
  return v;
}

function utf8Encode(str) {
  // eslint-disable-next-line
  if (/^[\x00-\x7f]*$/.test(str)) {
    return str;
  }
  var buf = [];
  var n = str.length;
  for (var i = 0, j = 0; i < n; ++i, ++j) {
    var codeUnit = str.charCodeAt(i);
    if (codeUnit < 0x80) {
      buf[j] = str.charAt(i);
    } else if (codeUnit < 0x800) {
      buf[j] = String.fromCharCode(
        0xc0 | (codeUnit >> 6),
        0x80 | (codeUnit & 0x3f)
      );
    } else if (codeUnit < 0xd800 || codeUnit > 0xdfff) {
      buf[j] = String.fromCharCode(
        0xe0 | (codeUnit >> 12),
        0x80 | ((codeUnit >> 6) & 0x3f),
        0x80 | (codeUnit & 0x3f)
      );
    } else {
      if (i + 1 < n) {
        var nextCodeUnit = str.charCodeAt(i + 1);
        if (
          codeUnit < 0xdc00 &&
          0xdc00 <= nextCodeUnit &&
          nextCodeUnit <= 0xdfff
        ) {
          var rune =
            (((codeUnit & 0x03ff) << 10) | (nextCodeUnit & 0x03ff)) + 0x010000;
          buf[j] = String.fromCharCode(
            0xf0 | ((rune >> 18) & 0x3f),
            0x80 | ((rune >> 12) & 0x3f),
            0x80 | ((rune >> 6) & 0x3f),
            0x80 | (rune & 0x3f)
          );
          ++i;
          continue;
        }
      }
      throw new Error("Malformed string");
    }
  }
  return buf.join("");
}

function encrypt(data, key) {
  if (data === undefined || data === null || data.length === 0) {
    return data;
  }
  data = utf8Encode(data);
  key = utf8Encode(key);
  return toBinaryString(
    encryptUint32Array(
      toUint32Array(data, true),
      fixk(toUint32Array(key, false))
    ),
    false
  );
}

function encryptToBase64(data, key) {
  return global.btoa(encrypt(data, key));
}

module.exports = {
  encryptToBase64,
};
