import {
  AppleFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  ExclamationOutlined,
  LoadingOutlined,
  QqOutlined,
  WindowsFilled,
} from "@ant-design/icons";
import { PlatformName } from "@todesktop/shared";
import { Anchor, Divider, Tooltip, Typography } from "antd";
import React from "react";
import { Box, Flex } from "../../../components/atoms/Primitives";
import type { ReleaseSectionEnum, Status } from "./ReleaseContext";
import { useReleaseContext } from "./ReleaseContext";
import { ReleaseSectionPopover } from "./ReleaseTutorial";
import { useContent } from "./useContent";

const { Text } = Typography;

export interface ReleaseSectionProps {
  section: ReleaseSectionEnum;
  dividerStyle?: React.CSSProperties;
  css?: Record<string, string>;
}

export const ReleaseSection: React.FC<ReleaseSectionProps> = ({
  children,
  css = {},
  section,
  dividerStyle = {},
}) => {
  const labelMap: Record<ReleaseSectionEnum, string> = {
    basicValidation: "Basic Validation",
    smokeTesting: "Smoke Testing",
    buildArtifacts: "Build Artifacts",
    staticAnalysis: "Static Analysis",
    buildLogging: "Build Logging",
  };

  return (
    <Flex id={section} css={{ flexDirection: "column", gap: "16px", ...css }}>
      <Divider
        style={{
          margin: "48px 0",
          borderColor: "rgba(000,000,000, 0.1)",
          ...dividerStyle,
        }}
      >
        <Flex
          style={{ display: "inline-flex", alignItems: "center", gap: "8px" }}
        >
          <SectionIcon section={section} />
          {labelMap[section]}
          <ReleaseSectionPopover section={section} />
        </Flex>
      </Divider>
      {children}
    </Flex>
  );
};

export const SectionLink: React.FC<{ section: ReleaseSectionEnum }> = ({
  children,
  section,
}) => {
  return (
    <Anchor.Link
      href={`#${section}`}
      title={
        <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text>{children}</Text>
          <SectionIcon section={section} />
        </Flex>
      }
    />
  );
};

export const SectionLogs: React.FC = ({ children }) => {
  return (
    <Box
      css={{
        fontSize: "12px",
        whiteSpace: "pre",
        overflow: "auto",
        fontFamily: `Inconsolata, "Bitstream Vera Sans Mono", Consolas, Courier, monospace;`,
      }}
    >
      {children}
    </Box>
  );
};

export const SectionIcon: React.FC<{
  section: ReleaseSectionEnum;
}> = ({ section }) => {
  const { state } = useReleaseContext();
  const status = state[section].status;
  const content = useContent();

  const tooltipMap: Record<ReleaseSectionEnum, Record<Status, string>> = {
    basicValidation: content.basicValidation.title,
    smokeTesting: content.smokeTesting.title,
    buildArtifacts: content.buildArtifacts.title,
    staticAnalysis: content.staticAnalysis.title,
    buildLogging: content.buildLogging.title,
  };

  return (
    <Tooltip title={tooltipMap[section][status]}>
      <StatusIcon status={status} />
    </Tooltip>
  );
};

export const StatusIcon: React.FC<{
  status: Status;
}> = ({ status, ...props }) => {
  switch (status) {
    case "progress":
      return (
        <ClockCircleOutlined
          {...props}
          style={{ color: "#1677ff", fontSize: "16px" }}
        />
      );
    case "warning":
      return (
        <ExclamationCircleOutlined
          {...props}
          style={{ color: "#fa8c16", fontSize: "16px" }}
        />
      );
    case "success":
      return (
        <CheckCircleOutlined
          {...props}
          style={{ color: "#389e0d", fontSize: "16px" }}
        />
      );
    case "error":
      return (
        <CloseCircleOutlined
          {...props}
          style={{ color: "#cf1322", fontSize: "16px" }}
        />
      );
  }

  return null;
};

export const StatusIconFilled: React.FC<{
  status: Status;
}> = ({ status, ...props }) => {
  switch (status) {
    case "progress":
      return (
        <ClockCircleFilled
          {...props}
          style={{ color: "#1677ff", fontSize: "16px" }}
        />
      );
    case "warning":
      return (
        <ExclamationCircleFilled
          {...props}
          style={{ color: "#fa8c16", fontSize: "16px" }}
        />
      );
    case "success":
      return (
        <CheckCircleFilled
          {...props}
          style={{ color: "#389e0d", fontSize: "16px" }}
        />
      );
    case "error":
      return (
        <CloseCircleFilled
          {...props}
          style={{ color: "#cf1322", fontSize: "16px" }}
        />
      );
  }

  return null;
};

export const StatusCheckIcon: React.FC<{
  status: Status;
}> = ({ status, ...props }) => {
  switch (status) {
    case "progress":
      return (
        <LoadingOutlined
          {...props}
          style={{ color: "#1677ff", fontSize: "12px" }}
        />
      );
    case "warning":
      return (
        <ExclamationOutlined
          {...props}
          style={{ color: "#fa8c16", fontSize: "12px" }}
        />
      );
    case "success":
      return (
        <CheckOutlined
          {...props}
          style={{ color: "#389e0d", fontSize: "12px" }}
        />
      );
    case "error":
      return (
        <CloseOutlined
          {...props}
          style={{ color: "#cf1322", fontSize: "12px" }}
        />
      );
  }

  return null;
};

export const PlatformIcons: Record<PlatformName, typeof QqOutlined> = {
  mac: AppleFilled,
  windows: WindowsFilled,
  linux: QqOutlined,
};
