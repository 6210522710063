import React from "react";
import { keyframes, css } from "@emotion/core";

const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.3);
  }
  99% {
    opacity: 1;
    transform: scale(1);
  }
`;
const animatedAppear = (animateDelay?: string) => css`
  animation-name: ${appear};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-delay: ${animateDelay || "0s"};
  animation-fill-mode: both;
`;

interface ICheckmarkProps {
  color?: string;
  circleColor?: string;
  animate?: boolean;
  animateDelay?: string;
}

const Checkmark: React.SFC<ICheckmarkProps> = ({
  color,
  circleColor,
  animate,
  animateDelay,
  ...props
}) => (
  <span css={animate && animatedAppear(animateDelay)}>
    <svg width={23} height={23} {...props}>
      {circleColor && (
        <path
          d="M11.5 0C17.852 0 23 5.15 23 11.5 23 17.852 17.852 23 11.5 23 5.15 23 0 17.852 0 11.5 0 5.15 5.15 0 11.5 0z"
          fill={circleColor}
        />
      )}
      <path
        d="M15.767,6.243 L8.65,13.53 L6.72,11.267 C5.878,10.537 4.55,11.389 5.154,12.482 L7.444,16.447 C7.807,16.933 8.651,17.419 9.494,16.447 C9.857,15.961 16.731,7.337 16.731,7.337 C17.577,6.364 16.491,5.515 15.769,6.243 L15.767,6.243 Z"
        fill={color || "#000"}
      />
    </svg>
  </span>
);

export default Checkmark;
