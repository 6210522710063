// modules
import React from "react";

// components
import { Row, Col } from "antd";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { Text } from "./Text";
import { Box } from "./Primitives";

interface ILabelledItem {
  label: string | React.ReactNode;
  altBg?: boolean;
  padding?: string;
}

export const LabelledItem: React.FC<ILabelledItem> = ({
  children,
  label,
  altBg,
  padding,
}) => {
  const { space } = useTheme();

  return (
    <Box
      backgroundColor={altBg ? "greys.8" : "white"}
      borderRadius={1}
      padding={padding ? padding : `${space[5]}px ${space[6]}px`}
    >
      <Row gutter={24}>
        <Col xs={12} sm={12} md={12} lg={10} span={24}>
          {typeof label === "string" ? (
            <Text fontWeight={600}>{label}</Text>
          ) : (
            label
          )}
        </Col>
        <Col sm={12} md={12} lg={14} span={24}>
          {children}
        </Col>
      </Row>
    </Box>
  );
};
