import React from "react";
import copy from "copy-to-clipboard";
import { message } from "antd";
import { track } from "./analytics";

export const copyToClipboard = (
  name: string,
  textToCopy: string,
  meta = {}
) => () => {
  copy(textToCopy);
  message.info(
    <>
      Copied <b>{name}</b> to clipboard
    </>
  );
  track({
    event: "Copied to clipboard",
    properties: { copiedText: name, ...meta },
  });
};
