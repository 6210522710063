import React from "react";
import css from "@emotion/css";
import {
  GoogleOutlined,
  GithubOutlined,
  LockOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import XIcon from "../atoms/XIcon";

const loginTemplateVars = {
  x: {
    bgColor: "hsl(203, 89%, 53%)",
    bgColorHover: "hsl(203, 94%, 58%)",
    Icon: XIcon,
  },
  google: {
    bgColor: "hsl(217, 89%, 60%)",
    bgColorHover: "hsl(217, 94%, 65%)",
    Icon: GoogleOutlined,
  },
  github: {
    bgColor: "hsl(0, 0%, 35%)",
    bgColorHover: "hsl(0, 0%, 40%)",
    Icon: GithubOutlined,
  },
  password: {
    bgColor: "hsl(37, 92%, 49%)",
    bgColorHover: "hsl(37, 97%, 54%)",
    Icon: LockOutlined,
  },
};

interface ILoginButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind: keyof typeof loginTemplateVars;
  loading?: boolean;
}

const LoginButton: React.SFC<ILoginButtonProps> = ({
  kind,
  children,
  loading,
  ...props
}) => {
  const { Icon, bgColor, bgColorHover } = loginTemplateVars[kind];

  return (
    <button
      disabled={!!loading}
      css={css`
        display: flex;
        align-items: center;
        background-color: ${bgColor};
        color: white;
        border: none;
        padding: 8px 15px;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        font-size: 15px;
        &:hover {
          transform: translateY(-1px);
          background-color: ${bgColorHover};
        }
        &:active {
          transform: translateY(1px);
        }
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        `}
      >
        {loading ? (
          <LoadingOutlined
            spin
            css={css`
              font-size: 24px;
            `}
          />
        ) : (
          <Icon
            css={css`
              font-size: 24px;
            `}
          />
        )}
      </div>
      <div>{children}</div>
    </button>
  );
};

export default LoginButton;
