import React from "react";
import { selectedApp, useStore } from "../../../store";
import useForm from "../../../~reusables/hooks/useForm";
import { FormItem } from "../../atoms/FormUtils";
import { Input } from "antd";
import { updateFirestoreApp } from "../../../~reusables/actions";

export const SnapConfig: React.FC = () => {
  const { snapStore } = useStore(selectedApp);

  const callback = async ({ description }: { description: string }) => {
    updateFirestoreApp({ snapStore: { description } });
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    { description: snapStore?.description || "" },
    callback
  );

  return (
    <FormItem
      error={errors.description}
      label="App description for Snap Store"
      htmlFor="description"
    >
      <Input.TextArea
        rows={3}
        value={values.description}
        onChange={handleChange}
        name="description"
        onBlur={handleSubmit}
      />
    </FormItem>
  );
};
