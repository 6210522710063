import React, { SFC } from "react";
import styled from "@emotion/styled";

import startMenuImg from "../../~reusables/images/start-menu.png";
import { AppIconIcon } from "../edit/AppIconIcon";
import css from "@emotion/css";
import {
  UpOutlined,
  CloudOutlined,
  WifiOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import CurrentTime from "../atoms/CurrentTime";
import CurrentDate from "../atoms/CurrentDate";
import { useStore, selectedApp } from "../../store";

const StartMenuInner = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 43px;
  background: #004172 url(${startMenuImg}) no-repeat 10px 5px;
  background-size: 190px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px -1px 3px;
`;

const Item = styled.div`
  display: inline-block;
  line-height: 1;
  vertical-align: top;
  margin: 0 10px;
`;

interface IStartMenuProps {
  icon?: string;
  iconStyle?: {};
  showTray?: boolean;
}

const StartMenu: SFC<IStartMenuProps> = ({ iconStyle, showTray = false }) => {
  const app = useStore(selectedApp);
  return (
    <StartMenuInner>
      <div
        css={css`
          position: absolute;
          left: 210px;
          top: 7px;
        `}
      >
        <AppIconIcon
          src={app.icon}
          titleBelow={true}
          windows={true}
          size={26}
          iconStyle={iconStyle}
        />
      </div>
      {showTray && (
        <div
          css={css`
            position: absolute;
            right: 20px;
            top: 0;
          `}
        >
          <Item
            css={css`
              padding-top: 12px;
            `}
          >
            <UpOutlined
              css={css`
                && {
                  color: #fff;
                  font-size: 18px;
                }
              `}
            />
          </Item>
          <Item
            css={css`
              padding-top: 12px;
            `}
          >
            <img src={app.trayIcon} height="20" width="20" />
          </Item>
          <Item
            css={css`
              padding-top: 12px;
            `}
          >
            <CloudOutlined
              css={css`
                && {
                  color: #fff;
                  font-size: 18px;
                }
              `}
            />
          </Item>
          <Item
            css={css`
              padding-top: 12px;
            `}
          >
            <WifiOutlined
              css={css`
                && {
                  color: #fff;
                  font-size: 18px;
                }
              `}
            />
          </Item>
          <Item
            css={css`
              padding-top: 12px;
            `}
          >
            <UserOutlined
              css={css`
                && {
                  color: #fff;
                  font-size: 18px;
                }
              `}
            />
          </Item>
          <Item
            css={css`
              padding-top: 3px;
              > div {
                display: block;
                text-align: center;
                margin-top: 5px;
                font-size: 11.3px;
                color: white;
              }
            `}
          >
            <div>
              <CurrentTime />
            </div>
            <div>
              <CurrentDate />
            </div>
          </Item>

          <Item
            css={css`
              padding-top: 12px;
            `}
          >
            <BellOutlined
              css={css`
                && {
                  color: #fff;
                  font-size: 18px;
                }
              `}
            />
          </Item>
        </div>
      )}
    </StartMenuInner>
  );
};

export default StartMenu;
