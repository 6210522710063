import React, { useEffect, useRef } from "react";
import css from "@emotion/css";
import { Checkbox, Alert } from "antd";
import QuestionTooltip from "../atoms/QuestionTooltip";
import styled from "@emotion/styled";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { updateFirestoreApp } from "../../~reusables/actions";
import {
  internalAppRegexValidation,
  appProtocolValidation,
  IApp,
} from "@todesktop/shared";
import { useStore, selectedApp } from "../../store";
import Field from "../atoms/InputField";
import { showDeeplinkHelp } from "../elements/HelpDialogs";
import { track } from "../../~reusables/util/analytics";
import * as Yup from "yup";
import { isSwitchableEnabled } from "../../~reusables/types";
import { TooltipLink } from "../atoms/TextUtils";
import { hrefLinks } from "../../~reusables/constants";

interface IFieldContainerProps {
  inset?: boolean;
}
const FieldContainer = styled.div`
  margin: 10px 0;
  ${(props: IFieldContainerProps) => props.inset && "padding-left: 20px"}
`;

const updateAppBoolean = (field: keyof IApp) => (e: CheckboxChangeEvent) => {
  const { checked } = e.target;
  updateFirestoreApp({ [field]: checked });
  track({ event: "Update App Option", properties: { field, checked } });
};

const updateAppSwitchEnabled = (field: keyof IApp) => (
  e: CheckboxChangeEvent
) => {
  const { checked } = e.target;
  updateFirestoreApp({ [field]: { enabled: checked } });
  track({ event: "Update App Option", properties: { field, checked } });
};

const updateAppSwitchValue = (field: keyof IApp) => (value: string) => {
  // full keyboard shortcut is expected in the firebase collection
  if (field === "toggleVisibilityKeyboardShortcut")
    return updateFirestoreApp({
      [field]: { value: "CommandOrControl+Shift+" + value.toUpperCase() },
    });

  updateFirestoreApp({ [field]: { value } });
};

function usePrevious(value) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AppOptions: React.SFC<{ showTitle?: boolean }> = ({
  showTitle = true,
}) => {
  const app = useStore(selectedApp);
  const isMenubar = app.appType === "menubar";

  const {
    url: appUrl,
    name: appName,
    customUserAgent,
    internalUrls,
    appProtocol,
    isDevToolsDisabled,
    isContextMenuDisabled,
    isWebSecurityDisabled,
    isBackgroundThrottlingPrevented,
    isGoogleOAuthExternal,
    toggleVisibilityKeyboardShortcut,
    shouldLaunchAtStartupByDefault,
  } = app;

  const prevHasAppProtocol = usePrevious(isSwitchableEnabled(appProtocol));

  if (
    isSwitchableEnabled(appProtocol) &&
    !prevHasAppProtocol &&
    (!(appProtocol && appProtocol.value) || appProtocol.value === "")
  ) {
    updateAppSwitchValue("appProtocol")(
      `${appName.replace(/[^A-Za-z]+/g, "")}://`.toLowerCase()
    );
  }

  const deeplinkHelpClick = () =>
    showDeeplinkHelp(appProtocol.value, appUrl, appName);

  return (
    <>
      {showTitle && (
        <h3
          css={css`
            font-weight: bold;
            font-size: 16px;
          `}
        >
          App Options
        </h3>
      )}
      <FieldContainer>
        <Checkbox
          checked={shouldLaunchAtStartupByDefault}
          onChange={updateAppBoolean("shouldLaunchAtStartupByDefault")}
        >
          Launch at Startup by Default
          <QuestionTooltip
            text={
              <>
                Launches the app by default when a user starts their computer.
                Users will always have an option to toggle this setting in
                native app menus. This is further customizable through our
                JavaScript API. Mac and Windows only.
                <br />
                <TooltipLink
                  href={hrefLinks.optionsLaunchAtStartup}
                  target="_blank"
                >
                  <b>Read more here &raquo;</b>
                </TooltipLink>
              </>
            }
          />
        </Checkbox>
      </FieldContainer>
      <FieldContainer>
        <Checkbox
          checked={isDevToolsDisabled}
          onChange={updateAppBoolean("isDevToolsDisabled")}
        >
          Disable DevTools
          <QuestionTooltip text="Disables the Chromium Devtools from being accessed by users of your app" />
        </Checkbox>
      </FieldContainer>

      <FieldContainer>
        <Checkbox
          checked={isContextMenuDisabled}
          onChange={updateAppBoolean("isContextMenuDisabled")}
        >
          Disable Right Click Context Menu
          <QuestionTooltip text="Disables a context menu from appearing when a user right clicks within your app window" />
        </Checkbox>
      </FieldContainer>

      <FieldContainer>
        <Checkbox
          checked={isSwitchableEnabled(customUserAgent)}
          onChange={updateAppSwitchEnabled("customUserAgent")}
        >
          Custom User Agent
          <QuestionTooltip text="Set a custom user agent. This is how your app will identify itself to web servers" />
        </Checkbox>
      </FieldContainer>

      <FieldContainer
        inset={true}
        hidden={!isSwitchableEnabled(customUserAgent)}
      >
        <Field
          label="Custom User Agent"
          hideLabel={true}
          simple={true}
          onChange={updateAppSwitchValue("customUserAgent")}
          placeholder="Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36"
          disabled={!isSwitchableEnabled(customUserAgent)}
          value={customUserAgent.value}
        />
      </FieldContainer>

      <FieldContainer>
        <Checkbox
          checked={isSwitchableEnabled(internalUrls)}
          onChange={updateAppSwitchEnabled("internalUrls")}
        >
          Internal URLs
          <QuestionTooltip
            text={
              <>
                Internal URLs are links that open within your desktop app window
                instead of an external browser window. By default, login
                provider URLs and URLs on the same domain as your app are
                considered internal.
                <br />
                <br />
                This option allows you to specify additional internal URLs by
                using a Regular Expression.
                <br />
                <br />
                As an example: if you wanted URLs on the Apple and Amazon
                domains to be considered internal, then the following Regular
                Expression would be used:
                <br />
                <code style={{ fontSize: "0.7rem" }}>
                  .*(apple\.com|amazon\.com).*
                </code>
                <br />
                <TooltipLink
                  href={hrefLinks.optionsDefiningInternalUrls}
                  target="_blank"
                >
                  <b>Read more here &raquo;</b>
                </TooltipLink>
              </>
            }
          />
        </Checkbox>
      </FieldContainer>

      <FieldContainer inset={true} hidden={!isSwitchableEnabled(internalUrls)}>
        {isSwitchableEnabled(internalUrls) && (
          <Field
            label="Internal URLs"
            hideLabel={true}
            simple={true}
            validationSchema={internalAppRegexValidation}
            onChange={updateAppSwitchValue("internalUrls")}
            placeholder={`.*(apple.com|amazon.com).*`}
            disabled={!isSwitchableEnabled(internalUrls)}
            value={internalUrls.value}
            prefix="/"
            suffix="/i"
          />
        )}
      </FieldContainer>

      <FieldContainer>
        <Checkbox
          checked={isSwitchableEnabled(appProtocol)}
          onChange={updateAppSwitchEnabled("appProtocol")}
        >
          Support App Protocol (deeplink)
          <QuestionTooltip
            text={
              <>
                Set an App Pro©tocol. This allows you to open your application
                from a web browser.
                <br />
                <TooltipLink
                  href={hrefLinks.optionsAppProtocols}
                  target="_blank"
                >
                  <b>Read more here &raquo;</b>
                </TooltipLink>
              </>
            }
          />
        </Checkbox>
      </FieldContainer>

      <FieldContainer inset={true} hidden={!isSwitchableEnabled(appProtocol)}>
        {isSwitchableEnabled(appProtocol) && (
          <>
            <Field
              label="App Protocol"
              hideLabel={true}
              simple={true}
              onChange={updateAppSwitchValue("appProtocol")}
              value={appProtocol.value}
              validationSchema={appProtocolValidation}
              disabled={!isSwitchableEnabled(appProtocol)}
            />
            <Alert
              message={
                <a onClick={deeplinkHelpClick}>
                  Using app protocol and deeplink
                </a>
              }
              type="info"
              showIcon={true}
              style={{
                cursor: "pointer",
                marginTop: 15,
              }}
            />
          </>
        )}
      </FieldContainer>
      <FieldContainer>
        <Checkbox
          checked={isWebSecurityDisabled}
          onChange={updateAppBoolean("isWebSecurityDisabled")}
        >
          Disable Web Security
          <QuestionTooltip text="This will disable same-origin policy and will allow insecure content to run in your app. In general, only use this option if you are sure." />
        </Checkbox>
      </FieldContainer>
      <FieldContainer>
        <Checkbox
          checked={isBackgroundThrottlingPrevented}
          onChange={updateAppBoolean("isBackgroundThrottlingPrevented")}
        >
          Prevent Background Throttling
          <QuestionTooltip text="By default animations are paused and timers are throttled to once per second when your application becomes background. This option will disable these optimisations." />
        </Checkbox>
      </FieldContainer>
      <FieldContainer>
        <Checkbox
          checked={isGoogleOAuthExternal}
          onChange={updateAppBoolean("isGoogleOAuthExternal")}
        >
          Open Google OAuth Screen in Browser
          <QuestionTooltip
            text={
              <>
                Google sometimes blocks OAuth consent screens from being in
                apps. Enabling this will open OAuth screens in a user's default
                browser.
                <br />
                <TooltipLink
                  href={hrefLinks.recipesGoogleOAuth}
                  target="_blank"
                >
                  <b>Read more here &raquo;</b>
                </TooltipLink>
              </>
            }
          />
        </Checkbox>
      </FieldContainer>
      {isMenubar && (
        <>
          <FieldContainer>
            <Checkbox
              checked={isSwitchableEnabled(toggleVisibilityKeyboardShortcut)}
              onChange={updateAppSwitchEnabled(
                "toggleVisibilityKeyboardShortcut"
              )}
            >
              Visibility Keyboard Shortcut
              <QuestionTooltip
                text={
                  <>
                    If enabled, a keyboard shortcut will be used to toggle the
                    visibility of the app between a visible and a hidden state.
                    An alphanumeric character is required.
                  </>
                }
              />
            </Checkbox>
          </FieldContainer>
          <FieldContainer
            inset={true}
            hidden={!isSwitchableEnabled(toggleVisibilityKeyboardShortcut)}
          >
            {isSwitchableEnabled(toggleVisibilityKeyboardShortcut) && (
              <Field
                autoCapitalize
                addonBefore="Command or Control + Shift +"
                label="Visibility Keyboard Shortcut"
                hideLabel={true}
                simple={true}
                validationSchema={Yup.string()
                  .required()
                  .label("Visibility Keyboard Shortcut")
                  .test(
                    "is-single-alphanumeric-character",
                    "Must be a single alphanumeric character",
                    (value) => (value ? /^[a-z0-9]$/i.test(value) : false)
                  )}
                onChange={updateAppSwitchValue(
                  "toggleVisibilityKeyboardShortcut"
                )}
                placeholder={"A"}
                disabled={
                  !isSwitchableEnabled(toggleVisibilityKeyboardShortcut)
                }
                value={
                  toggleVisibilityKeyboardShortcut.value
                    ? toggleVisibilityKeyboardShortcut.value.charAt(
                        toggleVisibilityKeyboardShortcut.value.length - 1
                      )
                    : toggleVisibilityKeyboardShortcut.value
                }
              />
            )}
          </FieldContainer>
        </>
      )}
    </>
  );
};

export default AppOptions;
