import { IAppFromServerProps } from "@todesktop/shared";

export interface NewAppModalContext {
  onCancel: () => void;
  loadCreatedApp: (app: IAppFromServerProps) => void;
}

export enum NewAppType {
  Builder = "Builder",
  Web = "Web",
  CLI = "CLI",
}
