import React from "react";
import css from "@emotion/css";
import { Button } from "antd";
import { SettingOutlined, DownOutlined } from "@ant-design/icons";

import AppDropdownMenu from "./AppDropdownMenu";

const AppCogDropdownButton: React.SFC<{ noTopMargin?: boolean }> = ({
  noTopMargin = false,
}) => {
  return (
    <>
      <div
        css={css`
          font-size: 16px;
          text-align: right;
          margin: ${noTopMargin ? "0" : "20px 0"};
        `}
      >
        <AppDropdownMenu>
          <Button>
            <SettingOutlined /> <DownOutlined />
          </Button>
        </AppDropdownMenu>
      </div>
    </>
  );
};

export default AppCogDropdownButton;
