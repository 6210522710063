import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { config } from "./config";

if (process.env.NODE_ENV === "development") {
  firebase.firestore.setLogLevel("silent");
}
const firebaseDB = firebase.initializeApp(config);
export const firestore = firebaseDB.firestore();
export const storageRef = firebase.storage().ref();
