// libs
import React, { useState } from "react";
import { Modal, Button } from "antd";
import css from "@emotion/css";

// components
import { IPlans } from "../../~reusables/types";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { Space } from "../atoms/Space";

// utils
import { useStore, $hasFeatureAccess } from "../../store";
import { handleChatClick } from "../build/ChoosePackage";

interface IHasFeatureGuard {
  src: string;
  alt: string;
  name: string;
  description: string;
  planName: string;
  featurePlans: IPlans[];
}

export const HasFeatureGuard: React.FC<IHasFeatureGuard> = ({
  children,
  src,
  alt,
  name,
  description,
  planName,
  featurePlans,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const hasFeatureAccess = useStore($hasFeatureAccess(featurePlans));

  const onCaptureInteraction = (e: React.MouseEvent | React.FormEvent) => {
    if (hasFeatureAccess) {
      // do nothing, otherwise show pop up with feature details
    } else {
      e.preventDefault();
      e.stopPropagation();
      setModalVisibility(true);
    }
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => setModalVisibility(false)}
        closable={true}
        centered={true}
        afterClose={() => setModalVisibility(false)}
        footer={false}
        width={624}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          css={css`
            margin-top: 0px;
            width: 440px;
            padding: 4px;
          `}
        >
          <img width="100%" src={src} alt={alt} />
        </div>
        <Space direction="column">
          <Heading variant="h4" as="h4">
            {name}
          </Heading>
          <Text>{description}</Text>
          <Space direction="column" />
          <Button
            onClick={(e) =>
              handleChatClick(e, `Hi, could you upgrade me to ${planName}?`)
            }
            type="primary"
            size="large"
          >
            Contact us to upgrade to {planName}
          </Button>
        </Space>
      </Modal>
      <div
        onSubmitCapture={onCaptureInteraction}
        onClickCapture={onCaptureInteraction}
      >
        {children}
      </div>
    </>
  );
};
