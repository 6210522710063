import styled from "@emotion/styled";

interface ICardProps {
  noPadding?: boolean;
  width?: string | number;
}
const Card = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  /* float: left; */
  /* margin: 0 2%; */
  margin-bottom: 30px;
  background: white;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  padding: ${({ noPadding }: ICardProps) => (noPadding ? 0 : "15px")};
`;

export default Card;
