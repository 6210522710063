// modules
import formatDistance from "date-fns/formatDistance";
import React from "react";
import { Build, BuildStatus, IApp, isBuildRunning } from "@todesktop/shared";
import css from "@emotion/css";
import { Alert, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import githubUrlFromGit from "github-url-from-git";
import gitUrlParse from "git-url-parse";
import isEmpty from "lodash.isempty";

// components
import { BuildReleaseTag, BuildTag } from "../atoms/BuildTag";
import { Heading } from "../atoms/Heading";
import { Anchor, Box, Flex } from "../atoms/Primitives";
import { VerticallySplitCard } from "../atoms/StyledAntdCard";
import { PlatformBuildProgressBars } from "./BuildProgressBars";
import AppIcon from "../atoms/AppIcon";
import { Space } from "../atoms/Space";
import { PrepareReleaseButton } from "./ReleaseBuildButton";
import { CancelBuildButton } from "./CancelBuildButton";
import { RerunFailedBuildButton } from "./RerunFailedBuildButton";
import { Text } from "../atoms/Text";
import { CopyableText } from "../atoms/TextUtils";

// logic
import { selectedApp, useStore } from "../../store";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { SettingsTabs } from "../../pages/AppViews/Settings/AppSettings";
import {
  getNumberOfLaterBuilds,
  getCodeSignSkipExplanation,
  getPlatformBuildsNotCodeSigned,
} from "../../~reusables/util/common";

export const BuildOverviewCard: React.FC<{ build: Build }> = ({ build }) => {
  const { space } = useTheme();
  const app = useStore(selectedApp);
  const isBuildDone = !isBuildRunning(build);
  const { appName, appVersion, status, icon } = build;

  return (
    <VerticallySplitCard
      leftSplitChildren={
        <Box paddingY={6} paddingX={7}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            mb={6}
          >
            <Heading mb={4} variant="h4" as="h4">
              {appName} {appVersion ? `v${appVersion}` : ""}
            </Heading>
            <Flex mb={4} alignItems="center">
              <BuildTag status={status} />
              <BuildReleaseTag build={build} style={{ marginLeft: space[5] }} />
            </Flex>
          </Flex>
          <CodeSignWarningAlert app={app} build={build} />
          <Flex flexWrap="wrap">
            <AppIcon iconUrl={icon ? icon : app.icon} />
            <Space size="md" />
            <Flex flex="1 1 240px" flexDirection="column">
              <PlatformBuildProgressBars build={build} app={app} />
            </Flex>
          </Flex>
        </Box>
      }
      rightSplitChildren={
        <Box paddingY={6} paddingX={7}>
          <Space
            size="md"
            justifyContent="flex-end"
            borderBottom="1px solid"
            borderBottomColor="greys.6"
            paddingBottom={7}
            marginBottom={7}
          >
            <CancelBuildButton build={build} />
            <RerunFailedBuildButton build={build} />
            <PrepareReleaseButton build={build} />
          </Space>
          <Space size="md" direction="column" width="100%">
            <BuildIDSummaryItem build={build} app={app} />
            <BuildNumberSummaryItem build={build} app={app} />
            {build.versionControlInfo && !isEmpty(build.versionControlInfo) ? (
              <CommitSummaryItem build={build} app={app} />
            ) : null}
            {isBuildDone && (build.electronVersionUsed || build.appVersion) && (
              <VersionsSummaryItem app={app} build={build} />
            )}
            {build.startedAt && (
              <StartedAtSummaryItem build={build} app={app} />
            )}
            {build.createdAt && build.endedAt && (
              <DurationSummaryItem build={build} app={app} />
            )}
            <ReleasedAtSummaryItem build={build} app={app} />
          </Space>
        </Box>
      }
    />
  );
};

interface ISummaryItem {
  build: Build;
  app: IApp;
}

const BuildIDSummaryItem: React.FC<ISummaryItem> = ({ build }) => {
  return (
    <SummaryItem label="Build ID">
      <Text variant="body3">
        <CopyableText displayText={`${build.id}`} copyText={build.id} />
      </Text>
    </SummaryItem>
  );
};

const BuildNumberSummaryItem: React.FC<ISummaryItem> = ({ build }) => {
  const builds = useStore((state) => state.buildState.builds);
  const numberOfLaterBuilds = getNumberOfLaterBuilds(build, builds);

  return (
    <SummaryItem label="Build">
      <Text variant="body3">
        {numberOfLaterBuilds
          ? `This is ${numberOfLaterBuilds} ${
              numberOfLaterBuilds > 1 ? "builds" : "build"
            } behind the latest`
          : "This is the latest build"}
      </Text>
    </SummaryItem>
  );
};

const CommitSummaryItem: React.FC<ISummaryItem> = ({
  build: { versionControlInfo },
}) => {
  const { colors, space } = useTheme();
  const {
    repositoryRemoteUrl,
    commitId,
    versionControlSystemName,
    hasUncommittedChanges,
  } = versionControlInfo;

  const displayText =
    versionControlSystemName === "git" ? commitId.substring(0, 7) : commitId;

  return (
    <SummaryItem
      label="Commit"
      icon={
        hasUncommittedChanges && (
          <Tooltip
            title={
              "There were uncommited changes at the time this version was built"
            }
          >
            <InfoCircleFilled
              style={{ color: colors.warning, marginTop: space[4] }}
            />
          </Tooltip>
        )
      }
    >
      <Text color="support" variant="body3" display="inline">
        <CopyableText
          displayText={
            repositoryRemoteUrl &&
            gitUrlParse(repositoryRemoteUrl).source === "github.com" ? (
              <Anchor
                href={
                  githubUrlFromGit(repositoryRemoteUrl) + "/commit/" + commitId
                }
                target="_blank"
                rel="noreferrer"
              >
                {displayText}
              </Anchor>
            ) : (
              displayText
            )
          }
          copyText={commitId}
        />
      </Text>
    </SummaryItem>
  );
};

const VersionsSummaryItem: React.FC<ISummaryItem> = ({
  build: { electronVersionUsed, appVersion },
}) => {
  return (
    <SummaryItem label="Versions">
      {appVersion && (
        <Text variant="body3">Application version v{appVersion}</Text>
      )}
      {electronVersionUsed && (
        <Text variant="body3">Electron version v{electronVersionUsed}</Text>
      )}
    </SummaryItem>
  );
};

const StartedAtSummaryItem: React.FC<ISummaryItem> = ({
  build: { startedAt },
}) => (
  <SummaryItem label="Start date">
    <Text variant="body3">{new Date(startedAt).toLocaleString()}</Text>
  </SummaryItem>
);

const DurationSummaryItem: React.FC<ISummaryItem> = ({
  build: { createdAt, endedAt, startedAt },
}) => (
  <SummaryItem label="Duration">
    <Text variant="body3">
      {formatDistance(new Date(startedAt || createdAt), new Date(endedAt))}
    </Text>
  </SummaryItem>
);

const ReleasedAtSummaryItem: React.FC<ISummaryItem> = ({
  build: { releasedAt },
}) => (
  <SummaryItem label="Release date">
    <Text variant="body3">
      {releasedAt ? new Date(releasedAt).toLocaleString() : "Unreleased"}
    </Text>
  </SummaryItem>
);

const CodeSignWarningAlert: React.FC<ISummaryItem> = ({ build, app }) => {
  const platformBuildsNotCodeSigned = getPlatformBuildsNotCodeSigned(build);

  const doesAppUseToDesktopCerts = [
    "2003055s2r2u9sx", // Birdseed
    "2002218im5w6vkf", // Dark
    "200312rbb400bzo", // Linear
    "2003241lzgn20jd", // Novachat
    "200702l3phtgk95", // Perfect Recall
    "2003096gmmnl0g1", // Sunsama
    "200505xzg148pa7", // ToDesktop Quick Start
  ].includes(app.id);

  const successfulPlatforms = platformBuildsNotCodeSigned.filter(
    ([_, { status }]) => status === BuildStatus.succeeded
  );

  if (!successfulPlatforms.length || doesAppUseToDesktopCerts) {
    return null;
  }

  return (
    <Alert
      style={{ marginBottom: 12 }}
      message={
        <>
          <Text variant="body3">
            Some platforms were not code-signed. You may see warnings from your
            operating system when launching this app version:
            <br />
          </Text>
          <ul>
            {successfulPlatforms.map(([platform, { codeSignSkipReason }]) => {
              const nameCapitalized =
                platform.charAt(0).toUpperCase() + platform.slice(1);

              return (
                <li key={platform}>
                  {nameCapitalized}:{" "}
                  {getCodeSignSkipExplanation(codeSignSkipReason)}.{" "}
                  {codeSignSkipReason === "no-cert" && app.id && (
                    <Link
                      to={{
                        pathname: `/apps/${app.id}/settings`,
                        state: { tab: SettingsTabs.Certificates },
                      }}
                    >
                      Configure your {nameCapitalized} certificate for future
                      builds.
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      }
      type="warning"
      showIcon
      closable
    />
  );
};

const SummaryItem: React.FC<{
  label: string;
  icon?: React.ReactNode;
}> = ({ children, label, icon }) => {
  const { colors } = useTheme();
  return (
    <Flex alignItems="flex-start">
      <Space mr={5} align="center" flex="0 0 96px">
        <Text
          variant="body3"
          css={css`
            color: ${colors.support};
            max-width: 96px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {label}
        </Text>
        {icon && icon}
      </Space>

      <Box flex="1">{children}</Box>
    </Flex>
  );
};
