// modules
import React, { useState, useEffect } from "react";
import {
  Route,
  Redirect,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import queryString from "query-string";
import css from "@emotion/css";

// components
import NoCodeApp from "../../components/molecules/NoCodeApp";
import WebAppHeader from "../../components/layout/WebAppHeader";
import ModalsContainer from "../../components/layout/ModalsContainer";

// logic
import { useStore } from "../../store";
import MaxWidthContainer from "../layout/MaxWidthContainer";
import { HEADER_PAGE_OFFSET } from "../../~reusables/constants/metrics";
import { DummyAppLoader } from "../elements/DummyAppLoader";

interface IProtectedRoute extends RouteComponentProps {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({
  component: Component,
  ...rest
}) => {
  const user = useStore((state) => state.user);
  const [query, setQuery] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    if (queryString.parse(window.location.search)) {
      setQuery(queryString.parse(window.location.search));
    } else {
      setQuery(null);
    }
  }, [window.location.search]);

  /**
   * If an app query exists, render a small subset of the web app
   * which allows the user to use the app builder in an isolated manner
   */
  if (query["appId"] || query["appUrl"] || query["newApp"]) {
    return <BuilderOnlyView query={query} {...rest} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={`/login?redirectTo=${window.location.pathname}${window.location.search}`}
          />
        )
      }
    />
  );
};

interface IBuilderView extends RouteComponentProps {
  query?: queryString.ParsedQuery<string>;
}

const BuilderOnlyView: React.FC<IBuilderView> = ({ query, ...routeProps }) => {
  const userDataLoaded = useStore((state) => state.userDataLoaded);

  return (
    <>
      <WebAppHeader {...routeProps} />
      <div
        css={css`
          & > div {
            margin-top: -${HEADER_PAGE_OFFSET}px;
            padding-top: 0;
          }
        `}
      >
        <MaxWidthContainer>
          {userDataLoaded ? (
            <NoCodeApp builderViewQuery={query} />
          ) : (
            <DummyAppLoader loading={!userDataLoaded} />
          )}
        </MaxWidthContainer>
        <ModalsContainer {...routeProps} />
      </div>
    </>
  );
};

export default withRouter(ProtectedRoute);
