// modules
import React from "react";

// components
import { StyledAntdCard } from "../../../components/atoms/StyledAntdCard";
import { LabelledItem } from "../../../components/atoms/LabelledItem";
import { useStore, selectedApp } from "../../../store";
import { Text } from "../../../components/atoms/Text";

export const General: React.FC = () => {
  const { id, appPkgName, name, appModelId } = useStore(selectedApp);

  const appInformation = [
    { label: "ToDesktop ID:", value: id },
    { label: "App name:", value: name },
    ...(appPkgName ? [{ label: "Package name:", value: appPkgName }] : []),
    ...(appModelId ? [{ label: "App ID:", value: appModelId }] : []),
  ];

  return (
    <>
      <StyledAntdCard title="App information">
        {appInformation.map(({ label, value }, idx) => (
          <LabelledItem
            key={label}
            label={label}
            altBg={idx % 2 !== 0 ? true : false}
          >
            <Text>{value}</Text>
          </LabelledItem>
        ))}
      </StyledAntdCard>
      {/* <StyledAntdCard title="Subscription">Subscription</StyledAntdCard>
      <StyledAntdCard title="Danger zone">Delete app</StyledAntdCard> */}
    </>
  );
};
