import React, { useState } from "react";
import css from "@emotion/css";
import { Link } from "react-router-dom";
import { Modal, Divider, Card, Button } from "antd";
import { SmileTwoTone } from "@ant-design/icons";
import styled from "@emotion/styled";
import AppIcon from "../atoms/AppIcon";
import {
  updateUIState,
  initialBuildProgressState,
} from "../../~reusables/actions";
import DownloadLinkTabs from "../molecules/DownloadLinkTabs";
import AnimateHeight from "react-animate-height";
import DownloadButtonBuilder from "../edit/DownloadButtonBuilder";
import { NoCodeChecklistAccordion } from "../edit/ChecklistItems";
import { useStore } from "../../store";
import { updateFirestoreApp } from "../../~reusables/actions/application";
import { AppBuildProgressBars } from "../molecules/BuildProgressBars";
import { IApp } from "@todesktop/shared";

const BlueLink = styled.a`
  font-weight: bold;
  border-bottom: 3px #b3daff solid;
  padding: 2px 5px;
  margin-left: 5px;
  &:hover {
    border-bottom-color: #cce6ff;
  }
`;

const cancelBuildModal = (isComplete) => () => {
  if (isComplete) {
    updateUIState(null);
  }
};

interface IBuildAppProps {
  app: IApp;
}

const showDownloadButtonOnBuilder = false;

const BuildApp: React.SFC<IBuildAppProps> = ({ app }) => {
  const { user } = useStore((state) => ({
    user: state.user,
  }));
  const [showThingsToDo, setShowThingsToDo] = useState(false);
  const toggleShowThingsToDo = () => setShowThingsToDo(!showThingsToDo);
  const meta = app && app.meta;
  const currentBuildProgress = meta && meta.currentBuildProgress;

  const mac = currentBuildProgress
    ? currentBuildProgress.mac
    : initialBuildProgressState;
  const windows = currentBuildProgress
    ? currentBuildProgress.windows
    : initialBuildProgressState;
  const linux = currentBuildProgress
    ? currentBuildProgress.linux
    : initialBuildProgressState;

  const isNotStarted = !!(
    mac.percent === 0 &&
    windows.percent === 0 &&
    linux.percent === 0
  );
  const isComplete = !!(
    mac.type === "done" &&
    windows.type === "done" &&
    linux.type === "done"
  );
  const isCompleteOrError = !!(
    (mac.type === "done" || mac.type === "error") &&
    (windows.type === "done" || windows.type === "error") &&
    (linux.type === "done" || linux.type === "error")
  );

  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={cancelBuildModal(isCompleteOrError)}
      closable={isCompleteOrError}
      centered={true}
      width={620}
      css={css`
        color: black;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
        `}
      >
        <div
          css={css`
            width: 64px;
            display: flex;
            flex-direction: column;
          `}
        >
          <AppIcon
            iconUrl={app.icon}
            waiting={isNotStarted}
            animate={!isComplete}
            completed={isComplete}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;
            margin-left: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <h2
              css={css`
                margin-bottom: 0.2em;
              `}
            >
              {app.name}
            </h2>
            {user.isAdmin && (
              <Button
                onClick={async () => {
                  await updateFirestoreApp({
                    meta: {
                      currentBuildProgress: null,
                    },
                  });
                  updateUIState(null);
                }}
              >
                Delete Build Progress
              </Button>
            )}
            {!isCompleteOrError && (
              <Link to="/">
                <Button>Back to home</Button>
              </Link>
            )}
          </div>
          <AppBuildProgressBars
            mac={mac}
            windows={windows}
            linux={linux}
            app={app}
          />
        </div>
      </div>
      {isComplete && <DownloadLinkTabs />}

      {showDownloadButtonOnBuilder && (
        <div
          css={css`
            margin: 20px 0;
          `}
        >
          <Card title="Create a Download Button for your Website">
            <DownloadButtonBuilder app={app} />
          </Card>
        </div>
      )}

      <div
        css={css`
          padding: 0 0.5rem;
        `}
      >
        <p
          hidden={isComplete}
          css={css`
            margin-top: 8px;
          `}
        >
          Your desktop app is building. This usually takes 10 minutes but may
          take up to 30 minutes.
        </p>
        <p
          css={css`
            margin-bottom: 8px;
          `}
        >
          {isComplete ? (
            <>
              <br />
              <BlueLink onClick={toggleShowThingsToDo}>
                {showThingsToDo ? "▼" : "▶"} &nbsp; What else can I do?
              </BlueLink>
            </>
          ) : (
            "While you're waiting, here are some things that you can do:"
          )}
        </p>
        <AnimateHeight height={showThingsToDo || !isComplete ? "auto" : 0}>
          <NoCodeChecklistAccordion app={app} />
        </AnimateHeight>
      </div>

      <div hidden={isComplete}>
        <Divider />
        <div
          css={css`
            float: left;
          `}
        >
          <SmileTwoTone
            css={css`
              font-size: 42px;
            `}
          />
        </div>
        <p
          css={css`
            text-align: center;
          `}
        >
          <b>Don't worry you can close this page at any time.</b>
          <br />
          We will email you all the details when the build is finished.
        </p>
      </div>
    </Modal>
  );
};

export default BuildApp;
